<template>
    <div>
        <div v-if="isBusy">
                <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
        <div v-else>
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="serviceType.name">{{serviceType.name}}</h3>
                <h3 class="md-title" style="flex: 1" v-else>New Service Type</h3>
            
                <md-button class="md-primary md-raised" @click="add" :disabled="isBusy">Save Service Type</md-button>
            </md-toolbar>

            <div class="main-inner-wrapper">
                <CRow :gutters="true">
                    <CCol sm="12" lg="5">
                        <md-card>
                            <md-card-header>
                                <div class="md-title">Service Type info</div>
                            </md-card-header>

                            <md-card-content>
                                <CRow>
                                    <CCol sm="12">
                                        <md-field :class="getValidationClass('name')">
                                            <label>Name</label>
                                            <md-input v-model="serviceType.name"></md-input>
                                            <span class="md-error" v-if="!$v.serviceType.name.required">Name is required</span>
                                        </md-field>
                                        <md-field :class="getValidationClass('jobType')">
                                            <md-select v-model="serviceType.jobType" name="vehicle_type" id="job-type" placeholder="Service Type">
                                                <md-option v-for="jobType in jobTypes" :value="jobType.id" v-bind:key="jobType.id">{{jobType.name}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.serviceType.jobType.required">Service type is required</span>
                                        </md-field>
                                        <md-field>
                                            <label>Type</label>
                                            <md-input v-model="serviceType.type" readonly></md-input>
                                        </md-field>
                                        <md-field :class="getValidationClass('vehicleType')">
                                            <md-select  v-model="serviceType.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" multiple>
                                                <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="!$v.serviceType.vehicleType.required">Vehicle Type is required</span>
                                        </md-field>
                                    </CCol>
                                </CRow>
                                
                            </md-card-content>
                        
                        </md-card>
                    </CCol>
                </CRow>
            </div>
        </div>


        
        
    </div>
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
    name: 'NewServiceType',
    mixins: [validationMixin],
    
    data() {
        return {
            isBusy: true,
            serviceType: {
                name: null,
                jobType: null,
                type: null,
                order:null,
                vehicleType:[]
            },
            jobTypes: [],
            jobTypesRef:  this.$firebase.firestore().collection('job-types'),
            vehicleTypes: []
            //ref:   firebase.firestore().collection('users').doc(this.$root.uid).collection('service-types'),
            
           
        }
    },
    
    methods: {
        async obtainViewResources() {
            this.isBusy = true;
            await this.getJobtypes()
            await this.getVehicleTypes()
            this.isBusy = false;
        },
        async getJobtypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                th.jobTypes = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                    th.jobTypes.push(dataToSave);
                        
                    });
                    resolve()
                });
            }) 
        },
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.vehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        add(){
             if(this.validate()) {
                let th = this;
                this.isBusy = true
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('service-types')
                
                .add(th.serviceType)
            
                .then(function(docRef) {
                    console.log("Tutorial created with ID: ", docRef.id);
                    th.$router.push({path: `/service-types`})
                    this.isBusy = false
                })
                .catch(function(error) {
                    console.error("Error adding Tutorial: ", error);
                    this.isBusy = false
                });
             } else {
                 this.$v.serviceType.$touch()
                 
             }
        },
        validate(){
            let name,jobType,vehicleType;
            name = this.$v.serviceType.name.$invalid;
            jobType = this.$v.serviceType.jobType.$invalid;
            vehicleType = this.$v.serviceType.vehicleType.$invalid;
           
            return !name && !jobType && !vehicleType ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.serviceType[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
       
    },
    watch: {
        'serviceType.jobType': {
            handler: function(val,oldVal) {
                
                for (let i = 0; i < this.jobTypes.length; i++) {
                   if(this.jobTypes[i].id === val) {
                        this.serviceType.type = this.jobTypes[i].type
                   }
                }
            }   
        },
    },
    created () {
        this.obtainViewResources();
    },
    validations: {
       
        serviceType: {
            
            name: {
                required
            },
            jobType: {
                required
            },
            vehicleType: {
                required
            }
            
        }
    }
}
</script>  