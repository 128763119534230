<template>
    <div>
        
        <md-button class="md-raised md-primary" @click="UserProfile()">Update custom claims</md-button>
    </div>
</template>
<script>
import axios from "./../../helpers/axios";

export default {
    name: "Fixer",
    
    computed: {
        // map `this.user` to `this.$store.getters.user`
        user() {
            return this.$store.state.user;
        }
    },
    created() {

        //this.updateClock();
        this.getUserData()
    },
    data() {
        return {
        
        };
    },
    methods: {
        async UserProfile() {
            let th = this
            let data = {
                uid: 'sqLWNMuoxBezHLvfSJoSNrf2BTt1',
                properties: {
                    role: 'driver',
                    subrole: 'owner-operator',
                    code: 'dop-1002'
                }
            }
            return new Promise((resolve, reject) => {
                axios.post('update-user-claims',data).then(response =>{
                    console.log(response);
                    
                    resolve(response)
                    //th.$router.push({path: '/users/drivers'})
                }).catch(e => {
                    console.log(e);
                    
                    reject(e)
                    
                }) 
            })
        },
        getUserData() {
            console.log(this.user);

        },
        
    },
};
</script>
  