<template>
  <div>
    <md-toolbar v-show="!alternateToolBar.active" md-elevation="0" ref="masterToolbar" class="master-toolbar">
        <div class="md-layout md-gutter md-alignment-center-left">
          
          
          <div v-show="!filters.searchById" class="md-layout-item ">
            <md-field>
              <label for="preset">Preset</label>
              <md-select v-model="filters.preset" name="preset" id="preset">
                <md-option :disabled="true" value="custom">Custom</md-option>
                <md-option value="today">Today</md-option>
                <md-option value="yesterday">Yesterday</md-option>
                <md-option value="tomorrow">Tomorrow</md-option>
                <md-option value="this month">This month</md-option>
                <md-option value="previous month">Previous month</md-option>
                <md-option value="next month">Next month</md-option>
                <md-option value="this week">This week</md-option>
                <md-option value="last week">Last week</md-option>
                <md-option value="next week">Next week</md-option>
                
              </md-select>
            </md-field>
            
          </div>
          
          <div v-show="!filters.searchById" class="md-layout-item">
            <VueCtkDateTimePicker
             
              v-model="fromDateString" 
              :format="'YYYY-MM-DD'"
              :formatted="'llll (HH:mm [HS])'"
              :output-format="'x'"
              :only-date="true"
              :label="'From'"
              :auto-close="true"
              :input="fromPickerInput()"
              :dark="true" 
              

             />
              <!--md-datepicker 
                  v-model="filters.fromDate" 
                  value="number" 
                  :md-immediately="true" 
                  ref="fromDatepicker"  
                  :md-open-on-focus="true"
                  :md-opened="fromPickerOpened()"
                  :md-closed="fromPickerClosed()"
                  
                >
                <label>From</label>
              </md-datepicker-->
          </div>
          <div  v-show="!filters.searchById" class="md-layout-item">
            <VueCtkDateTimePicker
           
              v-model="toDateString" 
              :format="'YYYY-MM-DD'"
              :formatted="'llll (HH:mm [HS])'"
              :output-format="'x'"
              :only-date="true"
              :label="'To'"
              :auto-close="true"
              :input="toPickerInput()"
              :dark="true" 

             />
              <!--md-datepicker 
                  v-model="filters.toDate" 
                  value="number" 
                  :md-immediately="true" 
                  ref="toDatepicker"  
                  :md-open-on-focus="true"
                  >
                <label>To</label>
              </md-datepicker-->
          </div>
          
          
        
          <div v-show="!filters.searchById" class="md-layout-item">
            <md-field md-inline>
              <md-icon>search</md-icon>
              <label>Search</label>
              <md-input v-model="filters.search"  :disabled="reservations.mdData.length == 0"></md-input>
            </md-field>

          </div>
          <div v-show="!filters.searchById" class="md-layout-item"> 
            <md-field>
              <label for="">Company profile</label>
                <md-select v-model="filters.companyProfile" name="company_profile" id="company-profile" placeholder="Company" >
                    <md-option :value="0">Show All</md-option>
                    <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                </md-select>
              
            </md-field>
          </div>
          
          <div v-show="filters.searchById" class="md-layout-item">
            <md-field md-inline>
              <md-icon>search</md-icon>
              <label>Type ID</label>
              <md-input type="number" v-model="filters.goToID" ></md-input>
            </md-field>

          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="">Filter type</label>
                <md-select v-model="filters.searchById" placeholder="Filter type" >
                    <md-option :value="false">Configurable</md-option>
                    <md-option :value="true">Filter by ID</md-option>
                </md-select>
              
            </md-field>
          </div>
          <!--div v-show="!filters.searchById" class="md-layout-item">
            <md-button class="md-raised" @click="fireQuery(null)"><md-icon class="mr-2">filter_alt</md-icon>Apply filters</md-button>
          </div-->
          <div v-show="filters.searchById" class="md-layout-item">
            <md-button class="md-raised" @click="fireQuery(null)"><md-icon class="mr-2">filter_alt</md-icon>Search res ID</md-button>
          </div>
          <div  class="md-layout-item ">
            <md-button class="md-primary md-raised full-width-button" @click="newReservation">New reservation</md-button>
          </div>
        
          
        </div>
    </md-toolbar>
    <md-toolbar v-show="alternateToolBar.active">
        <div class="md-toolbar-section-start">
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="12">
              <span class="md-title ml-0">Bulk actions</span>
            </CCol>
            <CCol sm="12" md="12" lg="12" xl="12">
              <div class="md-body">
                <b class="mr-1">{{alternateToolBar.count}} </b> {{alternateToolBar.count == 1 ? 'item' : 'items'}} selected (
                <span v-for="(selelectedItem, index) in alternateToolBar.items" v-bind:key="'selelectedItem-' + selelectedItem.id">
                    {{ alternateToolBar.items.length - 1 == index ? selelectedItem.readableId : alternateToolBar.count == 1 ? selelectedItem.readableId : selelectedItem.readableId + ',' }}  
                </span>
                )
              </div>
            </CCol>
          
          </CRow>
          
        </div>

        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button" @click="doBulk('delete')">
            <md-icon>delete</md-icon>
          </md-button>
        </div>
    </md-toolbar> 
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar>
    <div class="table-master-wrapper">
      <transition name="fixedcols" >
        <div class="fixed-table-columns" v-if="!isBusy && tableFixedColumns.data && tableFixedColumns.data.length > 0 && tableFixedColumns.show" style="padding-top:37px">
            <div class="fixed-table-header" style="height:36px;">
              <div class="first-col" :style="{width: tableFixedColumns.data[0].columns.first + 'px'}">ID</div>
              <div class="second-col" :style="{width: tableFixedColumns.data[0].columns.second + 'px'}">Day</div>
            </div>
            <div class="fixed-columns-content" :style="{height: tableContentHeight + 'px'}" ref="fixedColumnsContent" :class ="{'shadowed': fixedColShadowed}">
              <div v-for="(row,index) in tableFixedColumns.data" v-bind:key="index + '-tableFixedColumnsData' + row.columns.id" >
                  <div v-if="row.columns.id" :style="{ height : row.height + 'px'}">
                    <div class="res-col first" :style="{width: row.columns.first + 'px',height : row.height + 'px'}"><a @click="editOrView( {id: row.columns.realid})">{{ row.columns.id }}</a></div>
                    <div class="res-col second" :style="{width: row.columns.second + 'px',height : row.height + 'px'}"> 
                     
                        {{ parseInt(row.columns.date) | moment('timezone', row.columns.timezone , 'MMMM Do YYYY') }} <br>
                         <b>{{ parseInt(row.columns.date) | moment('timezone', row.columns.timezone , 'hh:mm A (HH:mm [HS])') }}</b>
                        
                  
                    </div>
                  </div>
                  <div v-else :style="{ height : row.height + 'px',  lineHeight  : row.height + 'px'}">
                    <div class="date-row">{{row.columns.date}}</div>
                  </div>
              </div>
            </div>
        </div>
      </transition>
      <md-table  class="up-table" v-model="filteredReservationsList"   md-sort="day" md-sort-order="asc" :class="'up-' + dialogLeft" md-fixed-header v-if="!isBusy" ref="masterTable" >
        <template v-for="(item, index) in filteredReservationsList" >
          <!--md-table-row v-bind:key="'a' + item.id" v-if="index === 0" class="blue-back">
            <md-table-cell colspan="15" class="date-bar">        
                {{ parseInt(item.day) | moment("dddd, MMMM Do YYYY") }}
            </md-table-cell>
          </md-table-row-->
          <md-table-row v-bind:key="'b' + item.id" v-if="index > 0 && isDiffDay(parseInt(item.day),parseInt(filteredReservationsList[index - 1].day))" class="blue-back" >
            <md-table-cell colspan="15" class="date-bar">        
                {{ parseInt(item.day) | moment("dddd, MMMM Do YYYY") }}
            </md-table-cell>
          </md-table-row>
          <template>
            <md-table-row  v-bind:key="'reservation-' + item.id + '-' + index" :data-datetime="item.day" :data-timezone="item.companyProfile.timeZone" :data-realid="item.id">
              <md-table-cell md-label="" class="compress-padding">
                <md-checkbox v-model="item.checked" @change="checkboxSelected(item)"></md-checkbox>
              </md-table-cell> 
              <md-table-cell md-label="ID" class="compress-padding"><a @click="editOrView(item)">{{ item.readableId }}</a></md-table-cell>
              <md-table-cell md-label="Day" md-sort-by="day" class="datetime-column">
                  <!-- {{ item.day | moment("dddd") }}<br> -->
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <div class="no-wrap">
                        <span class="time-info">
                        {{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'MMMM Do YYYY') }} <br>
                         <b>{{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'hh:mm A (HH:mm [HS])') }}</b>
                         </span>
                     
                      </div>
                      <div class="no-wrap mt-2">
                       <span class="up-chip  up-text-confirmed mb-1 mr-2">Time Zone {{ item.companyProfile.timeZone }}</span>
                       <span class="up-chip  up-text-secondary mb-1">UTC {{ parseInt(item.day) | moment('timezone', item.companyProfile.timeZone , 'Z') }}</span>
                      </div>
                
                       
                      <div class="mt-2" v-html="checkTimeToGo(parseInt(item.day))"></div>
                      
                    </div>
                    <!--div class="md-layout-item">
                      <md-button @click="openModal('daytime',item)" class="md-icon-button md-mini up-edition">
                        <md-icon>edit</md-icon>
                      </md-button>
                    </div-->
                  </div>
                  
                  
              </md-table-cell>
              <md-table-cell md-label="Res. status" v-if="user.data.role === 'company-admin'">
                  <md-field :class="item.reservationStatus" class="select-field">
                    <md-select v-model="item.reservationStatus" @md-selected="updateReservation(item)" >
                        <md-option v-for="reservationStatus in reservationStatuses" :value="reservationStatus.id" v-bind:key="reservationStatus.id">{{reservationStatus.name}}</md-option>
                    </md-select>
                  </md-field>
              </md-table-cell>
              <md-table-cell md-label="Driver" v-if="user.data.role === 'company-admin'">
                <div class="md-layout md-alignment-center-left">
                  <div class="md-layout-item flex-1">
                    <md-chip class="mb-2 mt-2 full-width-button" :class="[ statusClass(statuses[item.driverStatus])  ]">Status: {{ statuses[item.driverStatus] }}</md-chip>
                    <md-button class="ml-0 md-raised md-secondary md-dense full-width-button" @click="startDriverAssignment(item)">
                     {{  statuses[item.driverStatus] === 'Offered' ? 'Manage offers' : ''}} 
                     {{  statuses[item.driverStatus] === 'Unassigned' ? 'Start assignment' : ''}} 
                     {{  statuses[item.driverStatus] === 'Assigned' ? 'Manage assignment' : ''}} 
                    </md-button>
                      
                  </div>
                  <div class="md-layout-item flex-none pl-3">
                      <!--md-button class="md-icon-button mr-0" @click=openAuxAssignModal(item)>
                        <md-icon>info</md-icon>
                      </md-button-->                    
                  </div>
                </div>
                  <!--md-field :class="item.driverStatus" class="select-field">
                    <md-select v-model="item.driverStatus" @md-selected="updateReservation(item)">
                        <md-option v-for="driverStatus in driverStatuses" :value="driverStatus.id" v-bind:key="driverStatus.id">{{driverStatus.name}}</md-option>
                    </md-select>
                  </md-field-->
              </md-table-cell>
              <md-table-cell md-label="PU / ST / DO">
                <div class="md-layout md-alignment-center-left">
                  <div class="md-layout-item flex-1">
                    <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200">
                      <b>PU:</b> {{item.pu.formatted_address}}
                      <md-tooltip md-direction="top" md-delay="500"><b>PU:</b> {{item.pu.formatted_address}}</md-tooltip>
                    </div>
                    <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200" v-if="item.waypoints.length > 0">
                        <div v-for="(waypoint,index) in item.waypoints" class="location-text no-wrap" v-bind:key="'waypoint' + index">
                          <b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}
                          <md-tooltip md-direction="top" md-delay="500"><b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}</md-tooltip>
                        </div>
                    </div>
                    <div class="location-text no-wrap text-magic-ellipsis max-width-200">
                      <b>DO:</b> {{item.do.formatted_address}}
                       <md-tooltip md-direction="top" md-delay="500"><b>DO:</b> {{item.do.formatted_address}}</md-tooltip>
                    </div>
                  </div>
                  <div class="md-layout-item flex-none pl-2">
                    <md-button @click="openMapDialog(item)" class="min-width-55">
                      <md-icon>route</md-icon>
                       <md-tooltip md-direction="top" md-delay="1000">View map</md-tooltip>
                    </md-button>
                  </div>
                </div>
                 
              </md-table-cell>
              <md-table-cell md-label="Flight schedule" v-if="user.data.role === 'company-admin'">
                  <div v-if="item.flightPack && item.flightPack.schedule">
                    <span class="md-body-1 up-text-primary  no-wrap font-weight-bold cursor-pointer" @click="openFlightDialog(item)" >
                        <span v-if="item.flightPack.icao != null && item.flightPack.icao != ''">
                            {{ item.flightPack.icao }}
                        </span>
                        <span v-if="item.flightPack.icao != null && item.flightPack.icao != '' && item.flightPack.iata != null && item.flightPack.iata != ''">/</span>
                        <span v-if="item.flightPack.iata != null && item.flightPack.iata != ''">
                            {{ item.flightPack.iata }}
                        </span>

                        {{item.flightPack.number}}
                      
                        <md-icon class="mini-icon ml-2">
                          info
                        </md-icon>
                        <md-tooltip md-direction="top" md-delay="1000">View flight details</md-tooltip>
                    </span>
                    <!--md-button @click="openFlightDialog(item)" class="ml-3">
                      <md-tooltip md-direction="top">Detailed flight info</md-tooltip>
                      <md-icon @click="openFlightDialog(item)">info</md-icon>
                    </md-button-->
                  
                    <div class="md-layout md-alignment-center-center">
                      <div class="md-layout-item" >
                        <div v-if="getJobTypeName(item.jobType.jobType) == 'To Airport'">
                          <div class="md-caption">Scheduled departure</div>
                          <span class="md-body-2 text-magic-ellipsis"  v-if="item.flightPack.originAirport.iata">{{item.flightPack.schedule.origin}}  / {{item.flightPack.originAirport.iata }}</span>

                          
                          <div class="no-wrap md-body-2">{{ item.flightPack.schedule.departuretime | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          
                          
                        </div>
                    
                        <div v-if="getJobTypeName(item.jobType.jobType) == 'From airport'">
                          <div class="md-caption">Scheduled arrival</div>
                          <span class="md-body-2 text-magic-ellipsis" :class="{'up-line-through': item.flightPack.diverted  }"  v-if="item.flightPack.destinationAirport.iata">{{item.flightPack.schedule.destination}} / {{item.flightPack.destinationAirport.iata }}</span>
                          <span class="md-body-2 text-magic-ellipsis ml-2"   v-if="item.flightPack.diverted && item.flightPack.divertedDestinationAirport.iata">{{item.flightPack.divertedLive.destination}} / {{item.flightPack.divertedDestinationAirport.iata }}</span>
                          
                          <div class="no-wrap md-body-2" :class="{'up-line-through': item.flightPack.diverted  }">{{ item.flightPack.schedule.arrivaltime | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          <div class="no-wrap md-body-2" v-if="item.flightPack.diverted" >{{ item.flightPack.divertedLive.estimatedarrivaltime | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  


                
              </md-table-cell>
              <md-table-cell md-label="Flight live status" v-if="user.data.role === 'company-admin'">
                  <div v-if="item.flightPack && item.flightPack.schedule">
                    <div class="md-layout md-alignment-center-center">
                        <div class="md-layout-item" >
                          <div v-if="getJobTypeName(item.jobType.jobType) == 'To Airport'">
                            <div v-if="item.flightPack.live">
                              
                              <md-chip v-if="item.flightPack.live.actualdeparturetime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                              <md-chip v-else-if="item.flightPack.live.actualdeparturetime == 0" class="mini uplimo-orange">On land</md-chip> 
                              <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                          
                              
                              <div v-if="item.flightPack.live.actualdeparturetime == 0" class="md-subheading up-text-caution">Gate departure {{ item.flightPack.live.filed_departuretime | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                              <div v-else-if="item.flightPack.live.actualdeparturetime > 0" class="md-subheading  no-wrap up-text-confirmed">{{ item.flightPack.live.actualdeparturetime | moment('timezone', item.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                              <div class="md-caption no-wrap up-bg-dark p-1 pr-2 pl-2 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                          </div>
                          <div v-if="getJobTypeName(item.jobType.jobType) == 'From airport'">
                            <div v-if="item.flightPack.live">
                                
                                <md-chip v-if="item.flightPack.live.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                <md-chip v-else-if="item.flightPack.live.actualarrivaltime == 0 && item.flightPack.live.actualdeparturetime > 0" class="mini uplimo-yellow">On air</md-chip> 
                                <md-chip v-else-if="item.flightPack.live.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
                            
                                
                                <div v-if="item.flightPack.live.actualarrivaltime == 0" class="md-subheading up-text-caution">ETA {{ item.flightPack.live.estimatedarrivaltime | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div v-else-if="item.flightPack.live.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed">{{ item.flightPack.live.actualarrivaltime | moment('timezone', item.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div class="md-caption no-wrap up-bg-dark pr-2 pl-2 p-1 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                            <div v-if="item.flightPack.divertedLive">
                                
                                <md-chip v-if="item.flightPack.diverted" class="mini uplimo-red">Diverted</md-chip> 
                                <md-chip v-if="item.flightPack.divertedLive.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                <md-chip v-else-if="item.flightPack.divertedLive.actualarrivaltime == 0 && item.flightPack.divertedLive.actualdeparturetime > 0" class="mini uplimo-yellow">On air</md-chip> 
                                <md-chip v-else-if="item.flightPack.divertedLive.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
                            
                                
                                <div v-if="item.flightPack.divertedLive.actualarrivaltime == 0" class="md-subheading up-text-caution">ETA {{ item.flightPack.divertedLive.estimatedarrivaltime | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div v-else-if="item.flightPack.divertedLive.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed">{{ item.flightPack.divertedLive.actualarrivaltime | moment('timezone', item.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                <div class="md-caption no-wrap up-bg-dark pr-2 pl-2 p-1 br-1 rounded-pill mt-2 text-white">Last updated at {{ item.flightPack.lastLiveUpdate | moment('timezone', item.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                            </div>
                          </div>
                         </div>
                    </div>
                  </div>
                  <!--md-button @click="fireCheck">Fire check</md-button-->      
              </md-table-cell>
              <md-table-cell md-label="Passenger">
                <div v-if="item.passenger">
                    <div class="md-layout passenger-layout">
                        <div class="md-layout-item">
                          <p class="passenger-item">{{item.passenger.firstName}} {{item.passenger.lastName}}</p>
                          <p class="passenger-item">{{item.passenger.email}}</p>
                          <p class="passenger-item">{{item.passenger.phone}}</p>
                          
                        </div>
                        <div class="md-layout-item">
                          <div class="qty-container">
                            <md-icon>emoji_people</md-icon>
                            <span class="big-qty">{{item.passenger.qty}}</span>
                          </div>
                          <div class="qty-container">
                            <md-icon>luggage</md-icon>
                            <span class="big-qty">{{item.passenger.luggage}}</span>
                          </div>
                          
                        </div>
                        <div class="md-layout-item">
                          <md-button @click="openModal('passenger',item)" class="md-icon-button md-mini up-edition">
                            <md-icon>edit</md-icon>
                          </md-button>
                        </div>
                    </div>
                          
                </div>

              </md-table-cell>
              <md-table-cell md-label="Extras">
                <div class="md-layout">
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.meetAndGreet" @change="updateReservation(item)"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.meetAndGreet }">thumb_up</md-icon>
                      
                  </div>
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.childSeat" @change="updateReservation(item)"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.childSeat }">child_friendly</md-icon>
                      
                  </div>
                  <div class="md-layout-item with-switch">
                    <md-switch v-model="item.accessible" @change="updateReservation(item)"></md-switch>
                    <md-icon v-bind:class="{ 'active-icon': item.accessible }">accessible</md-icon>
                      
                  </div>
                </div>
      
              </md-table-cell>
              <md-table-cell md-label="Trip status">
                  <md-field :class="item.tripStatus" class="select-field">
                    <md-select v-model="item.tripStatus" @md-selected="updateReservation(item)">
                        <md-option v-for="tripStatus in tripStatuses" :value="tripStatus.id" v-bind:key="tripStatus.id">{{tripStatus.name}}</md-option>
                    </md-select>
                  </md-field>
              </md-table-cell>
              <md-table-cell md-label="Vehicle type" v-if="user.data.role === 'company-admin'">
                  <md-field :class="item.vehicleType" class="select-field">
                    <md-select v-model="item.vehicleType.id" @md-selected="updateReservation(item)">
                        <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                    </md-select>
                  </md-field>
              </md-table-cell>
              <md-table-cell md-label="Price" class="price" :class="paymentStatus(item.paymentStatus)" v-if="user.data.role === 'company-admin'">
                  {{ item.generatedPrice ? roundNumber(item.generatedPrice) : '' }}
              </md-table-cell>
              <md-table-cell class="miles" md-label="Miles">
                  {{item.miles}}
              </md-table-cell>
              <md-table-cell md-label="Notes" class="note-cell">
                <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :autoplay="true" :loop="true" :adjustableHeight="false">
                  <slide v-for="(note,ind) in item.notes"
                      v-bind:key="'note-' + ind"
                      :data-index="ind"
                      :data-name="note.type + 'note'"
                      >
                    <div class="note-label">{{note.label}}</div>
                    <p class="no-wrap note-text max-width-100 text-magic-ellipsis">{{note.text}}</p>
                    
                  </slide>
                
                </carousel>
              </md-table-cell>
            </md-table-row>
          </template>
          
        </template>
        
        <md-table-pagination
          :md-page-size="rowsPerPage"
          :md-page-options="[1,2,3,5,10,15,20,25,30]"
          :md-update="updatePagination"
          :md-data="reservations"
          :md-paginated-data.sync="filteredReservationsList" 
          v-show="reservations.mdData.length > 0"
          
          />
        </md-table>
      <md-empty-state
           md-icon="manage_search"
          v-if="filteredReservationsList.length == 0 && !isBusy"
          md-label="No reservations found"
          :md-description="`No reservations found for this query.`">
          <md-button class="md-primary md-raised" @click="newReservation">Create New Reservation</md-button>
      </md-empty-state>  
    </div>
    
   
    <md-snackbar md-position="center" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackMessage}}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Ok</md-button>
    </md-snackbar>
    <md-dialog :md-active.sync="driverAssignment.auxModal" :style="dialogDimensions" :md-fullscreen="false" class="free-dialog">
      <md-dialog-title v-if="driverAssignment.actualRes">Reservation id {{driverAssignment.actualRes.readableId}}</md-dialog-title>
      <div class="modal-up-content" v-if="driverAssignment.actualRes">
        <div v-if="driverAssignment.actualRes.offers && driverAssignment.actualRes.offers.length > 0">
          <md-table>
            <md-table-row>
            
              <md-table-head>Email</md-table-head>
              <md-table-head>Code</md-table-head>
              
            </md-table-row>
              <md-table-row v-for="offer in driverAssignment.actualRes.offers" v-bind:key="offer.id">
              
              <md-table-cell>{{ offer.email}}</md-table-cell>
              <md-table-cell>{{ offer.code }}</md-table-cell>
            
            </md-table-row>
          </md-table>
          
          
        
        </div>
        <div v-if="statuses[driverAssignment.actualRes.driverStatus] === 'Unassigned'">
          <p>Click "Start Driver Assignment" to begin offering</p>
        </div>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="driverAssignment.auxModal = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showDialog" :style="dialogDimensions" :md-fullscreen="false" class="date-dialog">
      <md-dialog-title>Reservation id {{modalInfo.resId}}</md-dialog-title>
      <div class="modal-up-content">
          <div v-show="modalInfo.type === 'daytime'">
            <VueCtkDateTimePicker 
                            v-model="modalInfo.daytime.day" 
                            :dark="true" 
                            :inline="true" 
                            :formatted="'llll (HH:mm [HS])'"
                            :format="'YYYY-MM-DD HH:mm'"
                            :output-format="'X'"
                            :color="'#448aff'"
                            :button-color="'#448aff'"	
                            :minute-interval="5"
                        />
            
          </div>
          <div v-show="modalInfo.type === 'passenger'">
              <div class="md-layout md-gutter">
                  <div class="md-layout-item">
                    <md-field>
                        <label for="">First Name</label>
                        <md-input v-model="modalInfo.passenger.firstName" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                    <md-field>
                        <label for="">Last Name</label>
                        <md-input v-model="modalInfo.passenger.lastName" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                    <md-field>
                        <label for="">Email</label>
                        <md-input v-model="modalInfo.passenger.email" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                    <md-field>
                        <label for="">Phone</label>
                        <md-input v-model="modalInfo.passenger.phone" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                        <label for="">Passenger qty</label>
                        <md-input v-model="modalInfo.passenger.qty" type="number" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                    <md-field>
                        <label for="">Luggage qty</label>
                        <md-input v-model="modalInfo.passenger.luggage" type="number" @change="updateReservation(modalInfo.reservation)"></md-input>
                    </md-field>
                  </div>
              </div>
          </div>
      </div>
          
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        <md-button class="md-primary" @click="updateModalInfo(modalInfo.reservation)">Save</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="flightDialog" :style="[dialogDimensions]" v-if="actualFlightPack" :md-fullscreen="false" class="free-dialog">
      <md-dialog-title>Reservation <span class="up-text-primary ">{{actualFlightPack.readableId}} </span>
        <div class="md-caption">Flight details ({{ getJobTypeName(actualFlightPack.jobType) }})</div>
      </md-dialog-title>
      <md-divider class=" mb-4">
      </md-divider>
      <div class="modal-up-content">
        <CRow  class="mb-2">
            <CCol sm="12">
                <div>Matched flight</div>
                <div class="md-title">
                    <span v-if="actualFlightPack.icao != null && actualFlightPack.icao != ''">
                        {{ actualFlightPack.icao }}
                    </span>
                    <span v-if="actualFlightPack.icao != null && actualFlightPack.icao != '' && actualFlightPack.iata != null && actualFlightPack.iata != ''">/</span>
                    <span v-if="actualFlightPack.iata != null && actualFlightPack.iata != ''">
                        {{ actualFlightPack.iata }}
                    </span>

                    {{actualFlightPack.number}}
                </div>
                
            </CCol>
        </CRow>
        <CRow v-if="actualFlightPack.schedule" class="mb-4">
            
            <CCol :sm="actualFlightPack.diverted ? 4 : 6">
                <md-icon class="mr-2">flight_takeoff</md-icon>
                <div class="md-subheading mb-1">Departure</div>
                <div class="md-title up-text-primary">{{actualFlightPack.schedule.origin}} <span v-if="actualFlightPack.originAirport.iata">/ {{actualFlightPack.originAirport.iata }}</span></div>
                <div class="md-body-1 text-magic-ellipsis">{{actualFlightPack.originAirport.name }}</div>
                <div class="md-caption">{{actualFlightPack.originAirport.city }} / {{actualFlightPack.originAirport.country }}</div>
                <div v-if="actualFlightPack.live">
                  <md-divider class="mt-1 mb-2"></md-divider>
                  <span class="md-caption mr-2">Actual status</span>
                  <md-chip v-if="actualFlightPack.live.actualdeparturetime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actualdeparturetime == 0" class="mini uplimo-orange">On land</md-chip> 
                  <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
              
                  
                  <div v-if="actualFlightPack.live.actualdeparturetime == 0" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ actualFlightPack.live.filed_departuretime | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.live.filed_departuretime > 0 && actualFlightPack.live.actualdeparturetime != -1" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.live.actualdeparturetime | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                </div>

                <div v-if="actualFlightPack.divertedLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                  <span class="md-caption mr-2">Actual status</span>
                  <md-chip v-if="actualFlightPack.divertedLive.actualdeparturetime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actualdeparturetime == 0" class="mini uplimo-orange">On land</md-chip> 
                  <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
              
                  
                  <div v-if="actualFlightPack.divertedLive.actualdeparturetime == 0" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ actualFlightPack.divertedLive.filed_departuretime | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedLive.filed_departuretime > 0 && actualFlightPack.divertedLive.actualdeparturetime != -1" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedLive.actualdeparturetime | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                </div>
                
                <md-divider class="mb-2 mt-2"></md-divider>
                <span class="md-caption">Schedule</span>
                <div class="md-subheading">{{ actualFlightPack.schedule.departuretime | moment('timezone', actualFlightPack.originAirport.tz , 'YYYY-MM-DD') }}</div>
                <div class="md-subheading">{{ actualFlightPack.schedule.departuretime | moment('timezone', actualFlightPack.originAirport.tz , 'hh:mm A (HH:mm [HS])')  }}</div>
                <div class="md-caption">Timezone: {{actualFlightPack.originAirport.tz }}</div>
                <md-divider class="mb-2 mt-2"></md-divider>
                <CRow v-if="actualFlightPack.info" >
                    <CCol sm="12">
                        <div>Terminal: {{actualFlightPack.info.terminal_orig != '' ? actualFlightPack.info.terminal_orig : '-' }}</div>
                        <div>Gate:  {{actualFlightPack.info.gate_orig != '' ? actualFlightPack.info.gate_orig : '-' }}</div>
                    </CCol>
                </CRow>
                

            </CCol>
            <CCol :sm="actualFlightPack.diverted ? 4 : 6">
                <md-icon class="mr-2">flight_land</md-icon>
                <div class="md-subheading mb-1">{{ actualFlightPack.diverted ? 'Diverted arrival' : 'Arrival'}}</div>

                <div class="md-title up-text-primary" :class="{'up-line-through': actualFlightPack.diverted  }">{{actualFlightPack.schedule.destination}} <span v-if="actualFlightPack.destinationAirport.iata">/ {{actualFlightPack.destinationAirport.iata }}</span></div>
                <div class="md-title up-text-primary" v-if="actualFlightPack.divertedDestinationAirport">{{actualFlightPack.divertedLive.destination}} <span v-if="actualFlightPack.divertedDestinationAirport.iata">/ {{actualFlightPack.divertedDestinationAirport.iata }}</span></div>

                <div class="md-body-1 text-magic-ellipsis" :class="{'up-line-through': actualFlightPack.diverted  }">{{actualFlightPack.destinationAirport.name }}</div>
                <div class="md-body-1 text-magic-ellipsis" v-if="actualFlightPack.diverted">{{actualFlightPack.divertedDestinationAirport.name }}</div>

                <div class="md-caption" :class="{'up-line-through': actualFlightPack.diverted }" >{{actualFlightPack.destinationAirport.city }} / {{actualFlightPack.destinationAirport.country }}</div>
                <div class="md-caption"  v-if="actualFlightPack.diverted">{{actualFlightPack.divertedDestinationAirport.city }} / {{actualFlightPack.divertedDestinationAirport.country }}</div>
                
                 
                 <div v-if="actualFlightPack.live">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.live.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actualarrivaltime == 0 && actualFlightPack.live.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.live.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="actualFlightPack.live.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.live.estimatedarrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.live.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.live.actualarrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>
                 <div v-if="actualFlightPack.divertedLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.divertedLive.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actualarrivaltime == 0 && actualFlightPack.divertedLive.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedLive.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="actualFlightPack.divertedLive.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.divertedLive.estimatedarrivaltime | moment('timezone', actualFlightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedLive.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedLive.actualarrivaltime | moment('timezone', actualFlightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>

                <md-divider class="mb-2 mt-2"></md-divider>
                <span class="md-caption">Schedule</span>
                <div class="md-subheading">{{ actualFlightPack.schedule.arrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'YYYY-MM-DD') }}</div>
                <div class="md-subheading">{{ actualFlightPack.schedule.arrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A (HH:mm [HS])') }}</div>
                <div class="md-caption">Timezone: {{actualFlightPack.destinationAirport.tz }}</div>
                <md-divider class="mb-2 mt-2"></md-divider>
                <CRow v-if="actualFlightPack.info" >
                    <CCol sm="12">
                        <div>Terminal: {{actualFlightPack.info.terminal_dest != '' ? actualFlightPack.info.terminal_dest : '-' }}</div>
                        <div>Gate:  {{actualFlightPack.info.gate_dest != '' ? actualFlightPack.info.gate_dest : '-' }}</div>
                        <div>Bag claim:  {{actualFlightPack.info.bag_claim != '' ? actualFlightPack.info.bag_claim : '-' }}</div>
                    </CCol>
                </CRow>
                
            </CCol>
            <CCol v-if="actualFlightPack.diverted" sm="4">
                <md-icon class="mr-2">flight_land</md-icon>
                <div class="md-subheading mb-1">{{ actualFlightPack.diverted ? 'Original arrival' : 'Arrival'}}</div>

                <div class="md-title up-text-primary" >{{actualFlightPack.schedule.destination}} <span v-if="actualFlightPack.destinationAirport.iata">/ {{actualFlightPack.destinationAirport.iata }}</span></div>

                <div class="md-body-1 text-magic-ellipsis">{{actualFlightPack.destinationAirport.name }}</div>

                <div class="md-caption">{{actualFlightPack.destinationAirport.city }} / {{actualFlightPack.destinationAirport.country }}</div>
                
                 
                 <div v-if="actualFlightPack.divertedToScheduledDestinationLive">
                  <md-divider class="mt-1 mb-2"></md-divider>
                
                  <span class="md-caption mr-2">
                    Actual status
                  </span>
                  
              
                  <md-chip v-if="actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime == 0 && actualFlightPack.divertedToScheduledDestinationLive.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                  <md-chip v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
            
                
                  <div v-if="actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ actualFlightPack.divertedToScheduledDestinationLive.estimatedarrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div v-else-if="actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ actualFlightPack.divertedToScheduledDestinationLive.actualarrivaltime | moment('timezone', actualFlightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                  <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ actualFlightPack.lastLiveUpdate | moment('timezone', actualFlightPack.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                 </div>
                

                
                
            </CCol>
        </CRow>
        
       
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="flightDialog = false">Close</md-button>
       
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="bulk.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog">
     
      <md-dialog-title>
        {{bulk.title}}
      </md-dialog-title>
      <md-divider class=" mb-4">
      </md-divider>
      <div class="modal-up-content text-center" v-if="bulk.busy">
        <md-progress-spinner class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" ></md-progress-spinner>
      </div>
      
      <div class="modal-up-content" v-if="!bulk.busy">
        <div v-if="!bulk.available">
            <div class="md-subheading">
              <span>{{ bulk.error.message }}
              </span>
              <span class="up-text-confirmed">Paid</span> or <span class="up-text-caution">Partially paid</span>
              <p>Please refund or uncheck to continue bulk delete.</p>
            </div>
            <ul class="pl-0 mt-3">
              <li class="md-display-1 d-inline-block" v-for="(item, index) in bulk.deniedIds" v-bind:key="'denied-' + item">
                {{ bulk.deniedIds.length - 1 == index ? item : bulk.deniedIds.length == 1 ? item : item + ', ' }}  
              </li>
            </ul>
        </div>
        <div v-else>
            <div class="md-subheading">The following Ids will be deleted. This action can not be undone</div>
            <span class="md-title" v-for="(selelectedItem, index) in alternateToolBar.items" v-bind:key="'selelectedItem-' + selelectedItem.id">
                    {{ alternateToolBar.items.length - 1 == index ? selelectedItem.readableId : alternateToolBar.count == 1 ? selelectedItem.readableId : selelectedItem.readableId + ',' }}  
            </span>
            <div class="md-display-1 mt-3" :class="{'up-text-confirmed' : bulk.unlocked , 'up-text-cancelled': !bulk.unlocked}">  
              <md-icon v-if="bulk.available" class="md-icon-40" :class="{'up-text-confirmed' : bulk.unlocked , 'up-text-cancelled': !bulk.unlocked}">{{ bulk.unlocked ? 'lock_open' : 'lock_outline' }}</md-icon>
              {{ bulk.unlockCode}}
            </div>
            <md-field>
              <label>Type above code to unlock </label>
              <md-input v-model="bulk.unlockTry" @keyup="checkLock"></md-input>
            </md-field>
        </div>
      </div>
      <md-dialog-actions v-if="!bulk.busy">
        <md-button @click="bulk.active = false">Close</md-button>
        <md-button class="md-accent md-raised" @click="fireBulk" :disabled="!bulk.unlocked">{{ bulk.action }}</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="map.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog" >
      <md-dialog-title v-if="map.item">
        Reservation <span class="up-text-primary">{{map.item.readableId}}</span> route
         <div class="md-subheading" v-if="map.item">Trip distance: <span class="up-text-secondary">{{ map.item.miles}}</span> miles</div>
        
      </md-dialog-title>
       <md-divider class=" mb-4">
      </md-divider>
      <div class="modal-up-content text-center">
        <CRow >
          <CCol sm="12" md="12" lg="12" xl="7">
             
              <GmapMap :zoom="12"  style="width:500px;height:600px"  ref="map"  :center="{lat:map.item ? map.item.pu.coordinates.lat : 0,lng: map.item ? map.item.pu.coordinates.lng : 0}" >
                  <DirectionsRenderer
                      travelMode="DRIVING"
                      :origin="origin"
                      :destination="destination"
                      :waypoints="computedWaypoints"
                      ref="road" />
              </GmapMap>
          </CCol>
        </CRow>

      </div>
      <md-dialog-actions>
        <md-button @click="closeMapDialog">Close</md-button>
       
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="driverAssignment.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog" >
      <md-dialog-title >
          Driver assignment
      </md-dialog-title>
       <md-divider class=" mb-4">
      </md-divider>
      <div class="modal-up-content text-center">
        <div v-if="driverAssignment.actualRes && driverAssignment.actualRes.offers && driverAssignment.actualRes.offers.length > 0">
          <md-table md-card class="mb-4" @md-selected="onOfferSelected" v-model="driverAssignment.actualRes.offers">
            <md-table-toolbar class="md-primary" >
              <h1 class="md-title text-left">Active offers</h1>
            </md-table-toolbar>
            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
              <div class="md-toolbar-section-start">{{ count }} {{ count == 1 ? 'offer' : 'offers' }} selected</div>

              <div class="md-toolbar-section-end">
                
                <!--md-button class="md-raised md-primary" v-if="count === 1">Assign job</md-button-->
                <md-button 
                    class="md-raised md-accent mr-0" 
                    @click="deleteOffers()"
                    :disabled="driverAssignment.busy" 
                >
                    Delete {{ count == 1 ? 'offer' : 'offers' }}
                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                </md-button>
                <md-button 
                    class="md-raised md-primary mr-0 ml-2" 
                    v-if="statuses[driverAssignment.selectedOffers[0].status] === 'Accepted' && driverAssignment.selectedOffers.length === 1 "
                    :disabled="driverAssignment.busy" 
                    @click="assignDriver()"
                >
                    Assign driver
                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                </md-button>
                
              </div>
            </md-table-toolbar>
            <md-table-row>
            
              <md-table-head>Email</md-table-head>
              <md-table-head>Code</md-table-head>
              <md-table-head>Status</md-table-head>
              <md-table-head>Community</md-table-head>
              
            </md-table-row>
              <md-table-row 
                slot="md-table-row" slot-scope="{ item }"
                class="text-left"
                md-selectable="multiple" md-auto-select>
              
              <md-table-cell md-label="Email">{{ item.email}}</md-table-cell>
              <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Status">{{ statuses[item.status]}}</md-table-cell>
              <md-table-cell md-label="Community">{{ item.community ? item.community.name : '-'}}</md-table-cell>
              
            
            </md-table-row>
          </md-table>  
          <md-divider class="mb-4"></md-divider>
        </div>
        <md-empty-state
              v-else
            class="md-accent"
            md-icon="list"
            md-label="No offers made yet"
            md-description="Add offers below to start driver assignment.">
          </md-empty-state>

        <div v-if="driverAssignment.actualRes && statuses[driverAssignment.actualRes.driverStatus] !== 'Assigned'">
          <md-toolbar class="md-dense">
            <h1 class="md-title text-left">Add offers</h1>
          </md-toolbar>
          <CRow v-if="driverAssignment.newOffer">
            <CCol sm="12">              
              <md-tabs md-alignment="right">
                <md-tab id="tab-home" md-label="All drivers list" md-icon="list">
                  <md-table v-if="driverAssignment.filteredLinkedDrivers.length > 0" v-model="driverAssignment.filteredLinkedDrivers" md-card @md-selected="onSelectAllDriverList">
                    
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                      <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'driver' : 'drivers' }} selected</div>
  
                      <div class="md-toolbar-section-end">
                        <md-button 
                            class="md-primary md-raised" 
                            @click="sendOffer()"
                            :disabled="driverAssignment.busy" 
                            
                            >
                            {{ driverAssignment.busy ? 'Sending offer' : 'Send offer' }}
                            <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                        </md-button>
                      </div>
                    </md-table-toolbar>
  
                    <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select>
                      <md-table-cell md-label="Code" md-sort-by="code" class="text-left">{{ item.code }}</md-table-cell>
                      <md-table-cell md-label="Email" md-sort-by="email" class="text-left">{{ item.email }}</md-table-cell>
                      <md-table-cell md-label="Areas" class="text-left">
                        <ul class="pl-0 mb-0">
                          <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index">
                            {{ area.formatted_address }}
                          </li>
                        </ul>
                      </md-table-cell>
                      <md-table-cell md-label="Fleet" class="text-left">
                        <ul class="pl-0 mb-0">
                          <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index">
                            {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
  
                          </li>
                        </ul>
                      </md-table-cell>
                      
                    </md-table-row>
                  </md-table>
                  <md-empty-state
                     v-else
                    class="md-accent"
                    md-icon="car_crash"
                    md-label="No more available drivers"
                    md-description="There no more possible owner operators or company drivers to offer.">
                  </md-empty-state>
                 
                </md-tab>
                <md-tab id="tab-pages" md-label="Communities" md-icon="groups_3">
                  <md-table v-model="driverAssignment.communities" md-card @md-selected="onSelectCommunity">
                    
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                      <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'community' : 'communities'}} selected</div>
  
                      <div class="md-toolbar-section-end">
                        <md-button class="md-primary md-raised" @click="sendCommunitiesOffers" :disabled="driverAssignment.busy">
                         
                          {{ driverAssignment.busy ? 'Sending offers' : 'Send offers' }}
                            <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                        </md-button>
                      </div>
                    </md-table-toolbar>
  
                    <md-table-row 
                      slot="md-table-row" slot-scope="{ item }" 
                       md-selectable="multiple" 
                       md-auto-select 
                       :md-disabled="driverAssignment.actualRes.offeredCommunities.includes(item.id)">
                      <md-table-cell md-label="Name" class="text-left">
                        {{ item.name }}
                      </md-table-cell>

                      <md-table-cell md-label="Status" class="text-left">
                        {{ driverAssignment.actualRes.offeredCommunities.includes(item.id) ? 'Already used' : 'Available to offer'}}
                      </md-table-cell>
                     
                      
                      
                    </md-table-row>
                  </md-table>
                </md-tab>
                <md-tab id="tab-posts" md-label="Custom search" md-icon="travel_explore">
                  
                  <md-toolbar class="md-dense">
                    <div class="md-toolbar-row">
                      <div class="md-toolbar-section-start">
                        <div class="ml-0 md-title">Driver filter</div>
                      </div>
  
                      <div class="md-toolbar-section-end">
                        <md-button class="md-raised md-dense md-primary" @click="performQuery()" :disabled="driverAssignment.searching">
                          Apply driver filter
                        </md-button>
  
                        <md-button class="md-icon-button">
                          <md-icon>more_vert</md-icon>
                        </md-button>
                      </div>
                    </div>
  
                    <div class="md-toolbar-row">
                      <CContainer>
  
                        <CRow>
                          <CCol md="6">
                            <md-field :class="{'md-invalid':getValidationClass('vehicleType')}">
                              <label>Vehicle type</label>
                              <md-select v-model="driverAssignment.vehicleType.id" :disabled="driverAssignment.searching">
                                  <md-option v-for="vehicleType in baseVehicleTypes" :value="vehicleType.id" v-bind:key="'filter-vehicle-' + vehicleType.id">{{vehicleType.name}}</md-option>
                              </md-select>
                              <span class="md-error" v-if="!$v.driverAssignment.vehicleType.id.required">Field is required</span>
                            </md-field>
                            
                          </CCol>
                          <CCol md="6">
                            <md-field :class="{'md-focused':driverAssignment.zoneInputFocused,'md-invalid':getValidationClass('fencesList')}">
                              <label>Type zone</label>
                              
                              
                              
                              <gmap-autocomplete
                                  ref="autocomplete"
                                  placeholder=""
                                  @place_changed="setFence($event)" class="pu-autocomplete"
                                  @blur="resetCursor()"
                                  @focus="locateCursor()"
                              
                                  :disabled="driverAssignment.searching"
                                  
                                  :types="mapTypes"
                                  >
                              </gmap-autocomplete>
                              <!--span class="md-helper-text">{{zoneQuery ? 'Type place, city, state, country' : 'e.g. COA-1003, companyname@mail.com'}}</span>
                              <span class="md-error" v-if="!$v.searchTerm.required">Field is required</span-->
                              <span class="md-error" v-if="!$v.coveredArea.fencesList.required">Field is required</span>
                            </md-field>
    
                          </CCol>
                        </CRow>
                      </CContainer>
                    </div>
                  </md-toolbar>
                  <md-progress-bar class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" v-if="driverAssignment.searching"></md-progress-bar>
                  <md-table v-if="driverAssignment.queryResults.length > 0 && !driverAssignment.searching" v-model="driverAssignment.queryResults" @md-selected="onSelectCustomSearch">
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                      <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'driver' : 'drivers'}} selected</div>
  
                      <div class="md-toolbar-section-end">
                        <md-button class="md-primary md-raised">
                          Send offer
                        </md-button>
                      </div>
                    </md-table-toolbar>
                    <md-table-row 
                          slot="md-table-row" 
                          slot-scope="{ item }" 
                          :md-disabled="item.exists" md-selectable="multiple" md-auto-select>
                          <md-table-cell md-label="Name" md-sort-by="auth.displayName">
                              {{ item.auth.displayName }}
                          </md-table-cell>
                          <md-table-cell md-label="Email" md-sort-by="auth.email">
                              {{ item.auth.email }}
                              <md-chip v-if="item.exists" class="md-primary mt-2">Already linked</md-chip>
                          </md-table-cell>
                          <md-table-cell md-label="Phones">
                              <ul class="table-inner-list">
                                  <template v-for="profile in item.profiles" >
                                      <li v-for="phone in profile.data.phones" v-bind:key="phone">
                                          {{ phone }}
                                      </li>
                                  </template>
                                  
                              </ul>
                          </md-table-cell>
                          <md-table-cell md-label="Areas">
                              <ul class="table-inner-list">
                                  <template v-for="profile in item.profiles" >
                                      <li v-for="matchedArea in profile.matchedAreas" v-bind:key="matchedArea.name">
                                          {{ matchedArea.name }}
                                      </li>
                                  </template>
                                  
                              </ul>
                          </md-table-cell>
                          <md-table-cell  md-label="Role" md-sort-by="auth.customClaims.role">{{item.auth.customClaims.role}}</md-table-cell>
                          <md-table-cell  md-label="Code" md-sort-by="auth.customClaims.code">{{item.auth.customClaims.code}}</md-table-cell>
                          <md-table-cell  md-label="Matched partner profiles" >{{item.profiles.length}}</md-table-cell>
                          <md-table-cell md-label="Action">
                          <md-button class="md-raised" @click="viewRefMap(item)" v-if="!zonePreview.showMap">
                              <md-icon class="mr-2">map</md-icon>
                              View area map</md-button>
                              <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                              <md-icon class="mr-2">map</md-icon>
                              Hide area map</md-button>
                          
                          </md-table-cell>
                      </md-table-row>
                  </md-table>
                </md-tab>
              </md-tabs>
            </CCol>
          </CRow>
        </div>        
          
      </div>
      <md-dialog-actions>
        <md-button @click="driverAssignment.active = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>


    <!--md-bottom-bar md-type="fixed" class="uplimo-fixed-bottom" ref="bottomBar"
              :style="[dialogDimensions]">
     
      <md-button class="md-icon-button md-raised md-primary" @click="scrollLeft">
        <md-icon>navigate_before</md-icon>
      </md-button>
       <md-button class="md-icon-button md-raised md-primary" @click="scrollRight">
        <md-icon>navigate_next</md-icon>
      </md-button>
    </md-bottom-bar-->
   
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import Vuetable from 'vuetable-2'
import CTableWrapper from '../base/Table'
//import VueTimepicker from 'vue2-timepicker'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import FieldsDef from "./FieldsDef";
import firebase from '../../Firebase'
import axios from "./../../helpers/axios";
import newAxios from "axios"

import DirectionsRenderer from './../maps/DirectionsRenderer';
import moment from 'moment'
import moment_timezone from "moment-timezone";
import { validationMixin } from 'vuelidate';

import * as VueGoogleMaps from 'vue2-google-maps'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    //return items.filter(item => toLower(item.readableId).includes(toLower(term)))
    return items.filter(item => {
     
      return  toLower(item.readableId).includes(toLower(term)) || 
              toLower(item.passenger.phone).includes(toLower(term)) || 
              toLower(item.passenger.firstName).includes(toLower(term)) || 
              toLower(item.passenger.lastName).includes(toLower(term)) || 
              toLower(item.passenger.email).includes(toLower(term));
    })
  }

  return items
}


export default {
  name: 'Reservations',
  components: { CTableWrapper, Vuetable, VuetablePagination, DirectionsRenderer,VueTimepicker },
  mixins: [validationMixin],
  data() {
    return {
      rowsPerPage: 5,
      test: '1680318000600',
      map: {
        title: null,
        active: false,
        item: null,
        
      },
      bulk: {
        title: null,
        available: false,
        active:false,
        error: {
          title: null,
          message: null
        },
        confirmed: false,
        action: null,
        deniedIds: [],
        unlockCode: null,
        unlockTry: null,
        unlocked: false,
        busy: false

      },
      alternateToolBar: {
        active: false,
        count: 0,
        items: [],
        
      },
       
      actualFlightPack:null,
      flightDialog: false,
      isBusy:true,
      // filters: {
      //   fromDate: null,
      //   toDate: null,
      //   queryFromDate: null,
      //   queryToDate: null,
      //   rowsQty:10,
      //   search:null,
      //   companyProfile:null,
      //   perPage: 30,
      //   page: 1,
      //   sort: null,
      //   sortOrder: null,
      //   preset: 'today',
      //   searchById: false,
      //   goToID: null
      // },
      
      modalInfo: {
        type: null,
        resId: null,
        daytime: {
          day: null,
          time: null
        },
        passenger: {
          firstName:null,
          lastName:null,
          email: null,
          phone:null,
          qty:null,
          luggage:null
        },
        reservation: null
      },
      dialogLeft: 0,
      showDialog:false,
      showSnackbar: false,
      snackMessage: null,
      duration: 4000,
      isInfinity: false,
      
      fields:FieldsDef,
      reservationStatuses: [],
      driverStatuses: [],
      paymentStatuses: [],
      tripStatuses: [],
      //reservations: [],
      reservations: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      dispatchers: [],
      addons: [],
      vehicleTypes: [],
      baseVehicleTypes: [],
      companyProfiles: [],
      jobTypes: [],
      ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
      dispatchRef:  this.$firebase.firestore().collection('dispatchers'),
      vehicleRef:  this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
      jobTypesRef:  this.$firebase.firestore().collection('job-types'),
      statusesRef: this.$firebase.firestore().collection('statuses'),
      addonsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
      css: {
        table: {
          tableWrapper: 'up-table-wrapper',
          tableClass: 'up-table',
        }
      },
      masterTableStyle:{ height: '600px'},
      tableFixedColumns: {
        data: null,
        show: false
      },
      tableContentHeight: null,
      fixedColShadowed: false,
      unsub: null,
      openedFromDate: false,
      openedToDate: false,
      driverAssignment: {
        auxModal:false,
        actualRes: null,
        busy: false,
        active: false,
        direct: false,
        newOffer: true,
        linkedDrivers: [],
        companyDrivers: [],
        filteredLinkedDrivers: [],
        communities: [],
        selectedDriver: null,
        selectedDriverText: null,
        selectedCommunity: null,
        queryResults: [],
        zoneInputFocused: false,
        vehicleType: {
          id: null
        },
        searchTerm: null,
        searching: false,
        actualReservationId: null,
        selectedDrivers: [],
        selectedCommunities: [],
        selectedCustomSearch: [],
        selectedOffers: [],

        


      },
      mapTypes: [
          'political'
          // 'country', 
          // 'administrative_area_level_1', 
          // 'administrative_area_level_2',
          // 'administrative_area_level_3',
          // 'locality',
          // 'sublocality_level_1',
      ],
      coveredArea: {
          paths: [
              // [
              //     { lat: 25.774, lng: -80.19 },
              //     { lat: 18.466, lng: -66.118 },
              //     { lat: 32.321, lng: -64.757 }
              // ],
              // [
              //     { lat: 23.774, lng: -80.19 },
              //     { lat: 12.466, lng: -66.118 },
              //     { lat: 28.321, lng: -64.757 }
              // ]
          ],
          zoom:4,
          coordinates: { 
              lat: 24.886, lng: -70.268 
          },
          fencesList: []

      },
      geofence: {
          name: null,
          code:null,
          path: [],
          zoom:7,
          center: {lat: 1.38, lng: 103.8},
      },
      zonePreview: {
          showMap:false,
          searched: {
              geometry: {
                  lat: null,
                  lng: null
              },
              paths: [],
              show: true

          },
          matched: [],
          showMatched: true,
          user: null,
          profiles: []
      },
      dateLoaded: false,
      firedFromPreset: false
    }
  },
  computed: {
    origin() {
        if (this.map.item) {
          if (!this.map.item.pu) return null;
          return { query: this.map.item.pu.coordinates.lat + ',' + this.map.item.pu.coordinates.lng };
        } else {
          return null
        }

       
    },
    destination() {
        if(this.map.item){
            if (!this.map.item.do) return null;
            return { query: this.map.item.do.coordinates.lat + ',' + this.map.item.do.coordinates.lng };
        } else {
          return null
        }
        
    },
    computedWaypoints() {
        if(this.map.item){

          if (this.map.item.waypoints.length == 0 ) return null;
          const total = [];
          this.map.item.waypoints.forEach((waypoint,index) => {
              if(!waypoint.stop){
                  //total.push(null);
              } else {
                  total.push({
                      stopover:true,
                      location: !waypoint.stop ? null : waypoint.stop.location
                  })

                  
              }
              
          });
          return total;

        } else {
          return null
        }
       
        
    }, 
    dialogDimensions () {
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    statuses() {
      return this.$store.state.statuses;
    },
    user() {
      return this.$store.state.user;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    fromDateString:{
      get(){
      
        return String(this.filters.fromDate)
      },
      set(newFromDate) {
        //
        console.log('computed fromDateString');
        this.$store.commit('setDateFilter', ['fromDate',parseInt(newFromDate)])
        this.$store.commit('setDateFilter', ['preset','custom'])
        this.$store.commit('setDateFilter', ['page',1])
        this.fireQuery()
        //this.$store.commit('filters.fromDate',newFromDate)
        //this.filters.fromDate = parseInt(newFromDate)
        //return newFromDate
      }
    },
    toDateString:{
      get(){
      
        return String(this.filters.toDate)
      },
      set(newToDate) {
        console.log('computed toDateString');
        this.$store.commit('setDateFilter', ['toDate',parseInt(newToDate)])
        this.$store.commit('setDateFilter', ['preset','custom'])
        this.$store.commit('setDateFilter', ['page',1])
        this.fireQuery()
        //this.filters.toDate = parseInt(newToDate)
        //return newToDate
      }
    },
    filters() {
      return this.$store.state.filters;
    },
    filteredReservationsList: {
      get(){
       
        if(this.filters.search) {
          let result =  searchByName(this.reservations.mdData, this.filters.search)
          this.$nextTick(function(){
            this.updateTableHeight()
            this.updateTableFixedColumnsData()
            if(window.document.querySelectorAll('.up-table')[0]) {
              window.document.querySelectorAll('.up-table')[0].addEventListener('scroll', this.onTableScrollHorizontal)
            }
            if( window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0]) {
             window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0].addEventListener('scroll', this.onTableScrollVertical)
            }
          })
          return result
        } else {
          let result = this.reservations.mdData
          this.$nextTick(function(){
            this.updateTableHeight()
            this.updateTableFixedColumnsData()
             if(window.document.querySelectorAll('.up-table')[0]) {
                window.document.querySelectorAll('.up-table')[0].addEventListener('scroll', this.onTableScrollHorizontal)
             }
            if( window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0]) {
              window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0].addEventListener('scroll', this.onTableScrollVertical)
            }
          })
          return result
        }
        
      },
      set(newList){
        return newList
      }
      
    },

    
    
    
  },
  created() {
      window.addEventListener('resize', this.myEventHandler);
      
      //this.$refs.masterTable.addEventListener('scroll', this.onTableScrollHorizontal);
       this.obtainViewResources();
      
  },
  destroyed() {
        if(this.unsub) {
          
          this.unsub()
        }
        window.removeEventListener('resize', this.myEventHandler);
        this.$store.commit('restoreBrowserTime')
        //this.$refs.masterTable.removeEventListener('scroll', this.onTableScrollHorizontal);
  },
  mounted () {
    
    // ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
    // vehicleRef:  this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
    // addonsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
    this.$store.commit('centerDialog',window.innerWidth)
    
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    '$store.state.sidebarMinimize': function() {
      this.$store.commit('centerDialog',window.innerWidth)
    },
    '$store.state.sidebarShow': function(){
      this.$store.commit('centerDialog',window.innerWidth)
    },
    // 'filters.fromDate': {
    //   handler: function(val,oldVal) {
    //     //updatePagination()
    //     //this.fireFilters()
    //   }
    // },
    // 'filters.toDate': {
    //   handler: function(val,oldVal) {
    //     //this.fireFilters()
    //   }
    // },
    'filters.fromDate': {
      //this.fireFilters()
      handler(val,oldVal) {

           
            if(this.dateLoaded) {
              
              let dayChanged = this.isDiffDay(val,oldVal)
              
              if(dayChanged){
                //this.filters.preset = 'custom'
                //this.$store.commit('set', ['filters.preset','custom' ])
              }
              //this.openedFromDate = false
            }
            
         
        // this.onFromDatepickerClosed()
      }
      
    },
    
    'filters.toDate': {
      //this.fireFilters()
       handler(val,oldVal) {


            if(this.dateLoaded) {
              let dayChanged = this.isDiffDay(val,oldVal)
              
             
              if(dayChanged){
                //this.filters.preset = 'custom'
                //this.$store.commit('set', ['filters.preset','custom' ])
              }
              //this.openedToDate = false
            }
            
         
        // this.onFromDatepickerClosed()
      }
      
    },
    'filters.perPage': {
      handler(val,oldVal){
        //this.fireQuery()
      }
    },
    'filters.preset': {

      handler(val,oldVal){
        console.log('wathc filters.preset');
        this.preset(val)
        
      }
    },
    'filters.companyProfile': {
      handler(val,oldVal){
        console.log('wathc filters.companyProfile');
        this.$store.commit('setDateFilter', ['page',1])
        //this.preset(this.filters.preset)
        this.fireQuery()
      }
    },
    'driverAssignment.direct': {
      handler(val,oldVal){
        this.driverAssignment.newOffer = !val
      }
    },
    'driverAssignment.newOffer': {
      handler(val,oldVal){
        this.driverAssignment.direct = !val
      }
    },
    'map.item': function() {
      // if(!this.map.item.pu) {
      //   this.map.origin = null
      // } else {
      //    this.map.origin = { query: this.map.item.pu.coordinates.lat + ',' + this.map.item.pu.coordinates.lng };
      // }

      // if(!this.map.item.do) {
      //   this.map.destination = null
      // } else {
      //    this.map.destination = { query: this.map.item.do.coordinates.lat + ',' + this.map.item.do.coordinates.lng };
      // }
    }
    // computedWaypoints() {
    //     if (!this.map.item) return null;
    //     const total = [];
    //     this.map.item.waypoints.forEach((waypoint,index) => {
    //         if(!waypoint.stop){
    //             //total.push(null);
    //         } else {
    //             total.push({
    //                 stopover:true,
    //                 location: !waypoint.stop ? null : waypoint.stop.location
    //             })
    //         }
    //     });
    //     return total;
    // }, 
  },
  methods: {
    
    statusClass(className) {
      let slug
      if(className) {
        slug = className.toLowerCase().replace(" ","-")
      }
      return slug
    },
    fromPickerInput(value) {
      
    },
    toPickerInput(value) {
     
    },
    onOfferSelected(items) {
      console.log(items)
      this.driverAssignment.selectedOffers = items
    },
    openAuxAssignModal(res){
      this.driverAssignment.auxModal = true
      this.driverAssignment.actualRes = res
    },
    assignDriver() {
     
      let data = { 
        offerToAssign: this.driverAssignment.selectedOffers[0],
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;

      axios.patch('reservations/offers/assign-driver', data).then(response =>{
          this.driverAssignment.busy = false
          th.snackMessage = 'Driver successfully assigned';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

      


      
    },
    async getResOffers(id) {
      let th = this ;
      let uid ;
      switch(this.user.data.role) {
        case 'company-admin':
            uid = this.user.data.uid
          break;
        case 'dispatcher':

          break;
      }

      var reservationsRef = this.$firebase.firestore().collection('users').doc(uid).collection('reservations').doc(id).collection('offers')

      return new Promise((resolve, reject) => { 
        reservationsRef.onSnapshot(
          (querySnapshot) => {
              let offers = []
              querySnapshot.forEach(async (doc) => {
                  let dataToSave = doc.data();
                  dataToSave.id = doc.id
                  offers.push(dataToSave);
              })
              resolve(offers)
          }
          , (error) => { 
            console.log(error)
            reject(error)
          })
      })

      

    },
    async startDriverAssignment(res) {

      let offers = await this.getResOffers(res.id)
      this.driverAssignment.actualReservationId = res.id
      this.driverAssignment.actualRes = res
      this.driverAssignment.actualRes.offers = offers
      let th = this

      let linkedDrivers = []
      let companyDrivers = []

      linkedDrivers = this.driverAssignment.linkedDrivers.filter((driver) => {
        let matches = [];
        for (let i = 0; i < th.driverAssignment.actualRes.offers.length; i++) {
          console.log(th.driverAssignment.actualRes.offers[i].code);
          console.log(driver.code);
          

          th.driverAssignment.actualRes.offers[i].code != driver.code ? matches.push(false) : matches.push(true)
        }
        console.log('matches', matches)
        console.log('driver', driver)
        if(matches.includes(true)) {
         
        } else {
          return driver
        }
      })

      companyDrivers = this.driverAssignment.companyDrivers.filter((driver) => {
        let matches = [];
        for (let i = 0; i < th.driverAssignment.actualRes.offers.length; i++) {
          console.log(th.driverAssignment.actualRes.offers[i].code);
          console.log(driver.profiles[0].code);
          

          th.driverAssignment.actualRes.offers[i].code != driver.profiles[0].code ? matches.push(false) : matches.push(true)
        }
        console.log('matches', matches)
        console.log('driver', driver)
        if(matches.includes(true)) {
         
        } else {
          let result =  driver.profiles[0]
          return result
        }
      })

      let filteredInfoCompanyDrivers = []
      for (let i = 0; i < companyDrivers.length; i++) {
        filteredInfoCompanyDrivers.push(companyDrivers[i].profiles[0])
        
      }
      this.driverAssignment.filteredLinkedDrivers = [].concat(linkedDrivers, filteredInfoCompanyDrivers);
      

      this.driverAssignment.active = true
    },
    deleteOffers() {
      let data = { 
        offers: this.driverAssignment.selectedOffers ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;
      axios.patch('reservations/offers/delete', data).then(response =>{
          this.driverAssignment.busy = false
          th.snackMessage = 'Offer successfully deleted';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

    },
    sendOffer() {
      let data = { 
        driversToOffer: this.driverAssignment.selectedDrivers ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId

      }

      this.driverAssignment.busy = true
      let th = this;
      axios.patch('reservations/offers/send', data).then(response =>{
          this.driverAssignment.busy = false
          th.snackMessage = 'Offer sent successfully';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })

    },
    sendCommunitiesOffers() {
      let data = {
        communitiesToOffer: this.driverAssignment.selectedCommunities ,
        userReservationTenantUid: this.user.data.uid,
        reservationId:  this.driverAssignment.actualReservationId
      }

      this.driverAssignment.busy = true
      let th = this;
      axios.patch('reservations/offers/communities/send', data).then(response =>{
          this.driverAssignment.busy = false
          th.snackMessage = 'Offers sent successfully';
          th.showSnackbar = true;
          th.resetDriverAssignment()
      }).catch(e => {
          console.log(e);
          this.driverAssignment.busy = false
          th.resetDriverAssignment()
      })
    },

    resetDriverAssignment() {
        this.driverAssignment.busy =  false,
        this.driverAssignment.active =  false,
        this.driverAssignment.direct =  false,
        this.driverAssignment.newOffer =  true,
        this.driverAssignment.selectedDriver =  null,
        this.driverAssignment.selectedDriverText =  null,
        this.driverAssignment.selectedCommunity =  null,
        this.driverAssignment.queryResults =  [],
        this.driverAssignment.zoneInputFocused =  false,
        this.driverAssignment.vehicleType =  {
          id : null
        }
        this.driverAssignment.searchTerm =  null,
        this.driverAssignment.searching =  false,
        this.driverAssignment.actualReservationId =  null
    },
    getValidationClass (fieldName) {
        let field = null;

        
        if(fieldName === 'vehicleType') {
            field = this.$v.driverAssignment.vehicleType.id;
        } else if(fieldName === 'fencesList') {
          field = this.$v.coveredArea.fencesList;

        } else {

            field = this.$v[fieldName];
        }
        
        
        
        if (field) {
            //return true
            return field.$invalid && field.$dirty
        }
    },
    validateCustomSearch(){
        let vehicleType, fencesList;
        
        vehicleType = this.$v.driverAssignment.vehicleType.id.$invalid;
        fencesList = this.$v.coveredArea.fencesList.$invalid;

        
        return !vehicleType && !fencesList ? true : false ;


    },
    performQuery() {
      if(this.validateCustomSearch()) {
        this.locateCursor()
        let data = {
            requiredFence: this.coveredArea.fencesList[0],
            requiredPaths: [],
            roleToGet: 'driver',
            subroleToGet: 'owner-operator',
            vehicleTypeBase: this.driverAssignment.vehicleType.id
        }
        
        for (let i = 0; i < this.coveredArea.fencesList[0].indexes.length; i++) {
            data.requiredPaths.push(this.coveredArea.paths[this.coveredArea.fencesList[0].indexes[i]])
            
        }

        this.zonePreview.searched = {
            geometry: {
                lat: data.requiredFence.geometry.lat,
                lng: data.requiredFence.geometry.lng
            },
            paths: data.requiredPaths,
            show: true,
            formatted_address: data.requiredFence.formatted_address
        }

        let th = this
        //th.coveredArea.fencesList = []
        //th.coveredArea.fencesList[0].indexes = []
        //this.coveredArea.paths = []
        this.driverAssignment.searching = true;
        axios.patch('users/search-by-zone', data).then(response =>{
            th.activeQueryListType = 'zone'
            let results = response.data.users
            
            th.driverAssignment.queryResults = results ;
            th.driverAssignment.searching = false;
        }).catch(e => {
            console.log(e);
            th.driverAssignment.searching = false;
        })


              

          
          
      } else {
          this.$v.$touch()
      }
    },
    resetCursor(){
        if(this.$refs.autocomplete.$el.value == '') {
            this.driverAssignment.zoneInputFocused = false
        }
        
    
    },
    async getBoundarie(lat, lon) {
        let params = {
            apiKey: '3f5d675290ce41e08917bc9f40516382',
            geometry: 'geometry_10000',
            lat: lat,
            lon: lon

        }
        return new Promise((resolve, reject) => {
            newAxios.create({
                headers: {},                    
            }).get('https://api.geoapify.com/v1/boundaries/part-of',
            {params: params})
            .then(response =>{
                resolve(response.data)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    async setFence(place) {
        this.queryResults = []
        this.coveredArea.fencesList = []
        let newFence = {
            formatted_address:place.formatted_address,
            indexes: [],
            address_components: place.address_components,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }
        }

        this.geofence.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        
        let placeLevel = place.address_components[0].types[0]

        newFence.name = place.formatted_address
        // Google naming 

        // country
        // administrative_area_level_1
        // locality
        // sublocality_level_1

        let boundarie = await this.getBoundarie(this.geofence.center.lat, this.geofence.center.lng)
        this.coveredArea.coordinates.lat = this.geofence.center.lat
        this.coveredArea.coordinates.lng = this.geofence.center.lng
        this.coveredArea.zoom = 10
        let categoryToLook = null;
        let alternativeCategoryToLook = null;
        let secondAlternativeCategoryToLook = null;
        switch(placeLevel) {
            case 'country':
                //categoryToLook = "administrative.country_part_level"
                categoryToLook = "administrative.country_level"
                break;
            case 'administrative_area_level_1':
                //categoryToLook = "administrative.state_level"
                //categoryToLook = "administrative.county_level"
                categoryToLook = "administrative.country_part_level"
                break;
            case 'administrative_area_level_2':
                //categoryToLook = "administrative.state_level"
                categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.district_level"
                break;
            case 'locality':
                //categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.city_level"
                categoryToLook = "administrative.state_level"
                alternativeCategoryToLook = "administrative.district_level"
                // ithaca, newy york : syracuse ny
                secondAlternativeCategoryToLook = "administrative.city_level"
                    // ex New York , New York
                // ex New York , New York
                break;
            case 'sublocality_level_1':
                categoryToLook = "administrative.city_level"
                // ex. Manhattan, Bronx, Queens
                break;
            case 'neighborhood':
                //categoryToLook = "administrative.city_level"
                this.areaValidation.message = 'Search a place larger than a neighborhood'
                break;
            
        }
        let finalFeature = null
        if(categoryToLook || alternativeCategoryToLook || secondAlternativeCategoryToLook) {
            for (let i = 0; i < boundarie.features.length; i++) {
                if(
                    boundarie.features[i].properties.categories[1] === categoryToLook
                    || boundarie.features[i].properties.categories[1] === alternativeCategoryToLook
                    || boundarie.features[i].properties.categories[1] === secondAlternativeCategoryToLook
                    ) 
                {
                    finalFeature = boundarie.features[i]
                }
            }

        } else {
            this.areaValidation.valid = false;

        }

        if(finalFeature) {
            if(finalFeature.geometry.type === "Polygon") {
                let pathToSave = []

                for (let i = 0; i < finalFeature.geometry.coordinates[0].length; i++) {
                    pathToSave.push({
                        lng: finalFeature.geometry.coordinates[0][i][0],
                        lat: finalFeature.geometry.coordinates[0][i][1],
                    })
                    
                    
                }
                let newIndex = this.coveredArea.paths.push(pathToSave) - 1
                newFence.indexes.push(newIndex)
            } else if(finalFeature.geometry.type === "MultiPolygon") {
                for (let i = 0; i < finalFeature.geometry.coordinates.length; i++) {
                    let pathToSave = []
                    for (let j = 0; j < finalFeature.geometry.coordinates[i][0].length; j++) {
                        pathToSave.push({
                            lng: finalFeature.geometry.coordinates[i][0][j][0],
                            lat: finalFeature.geometry.coordinates[i][0][j][1],
                        })
                        
                        
                    }
                    let newIndex =  this.coveredArea.paths.push(pathToSave) - 1
                    newFence.indexes.push(newIndex)
                    
                }
            }
            this.coveredArea.fencesList.push(newFence)
        }

        
        

        
    },
    locateCursor(){
        this.$refs.autocomplete.$el.focus()
        this.driverAssignment.zoneInputFocused = true
    },
    onSelectAllDriverList(selected) {
      console.log(selected)
      this.driverAssignment.selectedDrivers = selected ;

    },
    onSelectCommunity(selected) {
      console.log(selected)
      this.driverAssignment.selectedCommunities = selected ;
    },
    onSelectCustomSearch(selected) {
      console.log(selected)
      this.driverAssignment.selectedCustomSearch = selected ;
    },
    setDirectDriver(item) {
      this.driverAssignment.selectedDriver = item
      this.driverAssignment.selectedDriverText = item.email + ' (' +  item.code + ')'
    },
    directDriverAssignmentChanged(searchTerm) {
        // this.resetFlightPack()
        // this.airlines = new Promise(resolve => {
        //     window.setTimeout(() => {
        //         if (!searchTerm) {
        //             let aux = this.airlinesList.slice(0, 10);
        //             resolve(aux);
        //             //resolve(this.airlinesList)
        //         } else {
        //             const term = searchTerm.toLowerCase()
        //             console.log(term);
        //             resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                        
        //         }
        //     }, 200)
        // })
        
    },
    focusedDriverDirectAssignment(ev){
        //this.resetFlightPack()
        
        ev.target.value = ""
        this.driverAssignment.selectedDriver = null
        this.driverAssignment.selectedDriverText = null
       
    },
    fireCheck() {
      return new Promise((resolve, reject) => {
         axios.put('check-flight-status-globally').then(response =>{
              resolve()
          }).catch(e => {
              console.log(e);
              
              reject()
          })
      }) 
    },
    closeMapDialog() {
      this.map.active = false;
      this.map = {
        title: null,
        active: false,
        item: null,
        
      }

    },
    openMapDialog(item) {
      
      let th = this
      this.map.active = true
      
      this.$nextTick(() => {
        if(this.$refs.map) {
          this.$refs.map.$mapPromise.then((map) => {
            th.map.item = item
          })
        }
        
      })
      // this.$refs.map.$mapPromise.then((map) => {
      //   th.map.item = item
      // })
      //setTimeout(function(){
        
      //},3000)
      

      // this.$nextTick(() => {
        
      //   this.map.origin = { query: item.pu.coordinates.lat + ',' + item.pu.coordinates.lng }
      //   this.map.destination = { query: item.do.coordinates.lat + ',' + item.do.coordinates.lng }

      //   if (item.waypoints.length == 0 )  {
      //     this.map.waypoints = null
      //   } else {

      //     const total = [];
      //     item.waypoints.forEach((waypoint,index) => {
      //         if(!waypoint.stop){
      //             //total.push(null);
      //         } else {
      //             total.push({
      //                 stopover:true,
      //                 location: !waypoint.stop ? null : waypoint.stop.location
      //             })

                  
      //         }
              
      //     });
      //   this.map.waypoints = total;

      //   }
        
      //   this.map.title = 'Trip route' 
      // })
      
    },
    checkPaidReservations() {
        let deleteAvailable = []
        this.bulk.deniedIds = []
        for (let i = 0; i < this.alternateToolBar.items.length; i++) {
            
            let statusName = this.getCompleteStatus(this.alternateToolBar.items[i].paymentStatus).name
            if(statusName == 'Paid' || statusName == 'Partially paid'){
                deleteAvailable.push(false)
                this.bulk.deniedIds.push(this.alternateToolBar.items[i].readableId)
            } else {
               deleteAvailable.push(true)
            }
          
        }
        
        return !deleteAvailable.includes(false)
    },
    async fireBulk() {
      this.bulk.busy = true;
      let th = this
      
      const batch = firebase.firestore().batch()

      for (let i = 0; i < this.alternateToolBar.items.length; i++) {
        let ref = firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations').doc(this.alternateToolBar.items[i].id)
        batch.delete(ref);
      }
      await batch.commit();
      th.bulk.busy = false;
      th.alternateToolBar = {
        active: false,
        count: 0,
        items: [],
      }
      th.snackMessage = 'Bulk action successful';
      th.showSnackbar = true;
      th.resetBulk()
    },
    checkLock() {
      this.bulk.unlockCode === this.bulk.unlockTry ? this.bulk.unlocked = true : this.bulk.unlocked = false
    },
    resetBulk(){
      this.bulk = {
        title: null,
        available: false,
        active:false,
        error: {
          title: null,
          message: null
        },
        confirmed: false,
        action: null,
        deniedIds: [],
        unlockCode: null,
        unlockTry: null,
        unlocked: false,
        busy: false

      }
    },
    doBulk(action) {
      this.resetBulk()
      let deleteAvailable = this.checkPaidReservations();
      this.bulk.available = deleteAvailable ;
      this.bulk.title = 'Bulk ' + action
      this.bulk.action = action
      switch(action) {
        case 'delete':
          if(!this.bulk.available){
            let text = this.bulk.deniedIds.length == 1 ? 'its' : 'their'
            this.bulk.error.message = 'The following IDs can not be deleted due to ' + text  + ' payment status'
          }
          break;
      }

      if(this.bulk.available) {
        this.bulk.unlockCode = moment().tz(this.timezone).format('HHmm')
      }
      this.bulk.active = true
      

    },
    checkboxSelected(item){
      
      let showToolbar = false ;
      let count = 0;
      let selectedItems = [];
      for (let i = 0; i < this.filteredReservationsList.length; i++) {
       
        if(this.filteredReservationsList[i].checked) {
          showToolbar = true
          count++
          selectedItems.push(this.filteredReservationsList[i]);
        }
        
      }
       
      this.alternateToolBar.active = showToolbar
      this.alternateToolBar.count = count
      this.alternateToolBar.items = selectedItems
     
    },
    onFromFocusIn(e) {
      //this.openedFromDate = true
      // var fromPicker = document.querySelectorAll('.md-datepicker-date-select')[0]
      
      // fromPicker.addEventListener("click", function() {
      // }, false);
      // setTimeout(function() {
      //   fromPicker.click(); 
      // }, 1000);

    },
    onFromFocusOut(e) {
     
    },
    onToFocusIn(e) {
     
      //this.openedToDate = true
    },
    onToFocusOut(e) {
    
      
    },
    onFromDatepickerClosed(){
      // if(!this.isBusy) {
      //   this.$refs.toDatepicker.showDialog = true
      // } 
      
    },
    async searchId(){
       this.isBusy = true
        let th = this ;
        let data = {
           user: this.user.data.uid,
           id: this.filters.goToID
        }
        return new Promise((resolve, reject) => {
            axios.get('reservations/go-to-id', { params: data }).then(response =>{
              resolve()
            }).catch(e => {
                console.log(e);
                this.isBusy = false
                reject()
            })
        }) 
    },
    async preset(name){
      this.isBusy = true
      let timezone ;
      let companyProfile;
      if(this.filters.companyProfile) {
        if(this.companyProfiles.length == 0) {
           let zone_name =  moment.tz.guess();
           let browserZone = moment.tz(zone_name).zoneAbbr() 
           timezone = zone_name
           companyProfile = null
         } else {
           for (let i = 0; i < this.companyProfiles.length; i++) {
             if(this.companyProfiles[i].id  ===  this.filters.companyProfile) {
               timezone = this.companyProfiles[i].timeZone
               companyProfile = this.companyProfiles[i]
             }
             
           }
         }
      } else {
          let zone_name =  moment.tz.guess();
          let browserZone = moment.tz(zone_name).zoneAbbr() 
          timezone = zone_name
          companyProfile = null
      }

      let options = {
        user: this.user.data.uid,
        fromDate:null ,
        toDate:null,
        companyProfile: companyProfile,
        page:this.filters.page,
        pageSize:this.filters.perPage,
        sort:null,
        sortOrder:null,
        //rowsQty:10,
        search:this.filters.search,
      }

      let from ;
      let to;
      switch(name) {
        case 'today':
          from = moment().tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'yesterday':
          from = moment().subtract(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().subtract(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'tomorrow':
          from = moment().add(1,'day').tz(timezone).set({hour:0,minute:0,second:0})
          to =  moment().add(1,'day').tz(timezone).set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break;
        case 'this month':
          from = moment().tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
         case 'previous month':
          from = moment().add(-1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().add(-1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'next month':
          from = moment().add(1, 'months').tz(timezone).startOf('month').set({hour:0,minute:0,second:0})
          to  = moment().add(1, 'months').tz(timezone).endOf('month').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'this week':
          from = moment().tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
        case 'last week':
          from = moment().subtract(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().subtract(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break
          case 'next week':
          from = moment().add(1, 'weeks').tz(timezone).startOf('isoWeek').set({hour:0,minute:0,second:0})
          to  = moment().add(1, 'weeks').tz(timezone).endOf('isoWeek').set({hour:23,minute:59,second:59})
          options.fromDate = from.unix()
          options.toDate = to.unix()
          break

      }
      

      // Parse for date
      //this.filters.fromDate = parseInt(from.format('x'))
      //this.filters.toDate = parseInt(to.format('x'))
      if(name != 'custom') {
        await this.fireQuery(options)
      }
      


      this.isBusy = false

    },
    
    async fireQuery(options){
      console.log('options', options);
      
      //this.isBusy = true
      let th = this ;
      let timezone 
      let companyProfile;
      let zone_name =  moment.tz.guess();
      if(th.filters.companyProfile) {
        if(this.companyProfiles.length == 0) {
          
          let browserZone = moment.tz(zone_name).zoneAbbr() 
          timezone = zone_name
          companyProfile = null
        } else {
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              timezone = this.companyProfiles[i].timeZone
              companyProfile = this.companyProfiles[i]
            }
            
          }
        }
      } else {
        timezone = zone_name
        companyProfile = null
      }
  
      this.$store.commit('set', ['timezone',timezone ])
      this.$store.commit('set', ['utc',moment.tz(timezone).utcOffset() ])
      
      let data = {}

      if(!options) {
        
        //  D M YYYY
        let fromYear = parseInt(moment(this.filters.fromDate).format('YYYY'))
        let fromMonth = parseInt(moment(this.filters.fromDate).format('M')) - 1 
        let fromDate = parseInt(moment(this.filters.fromDate).format('D'))
        let toYear = parseInt(moment(this.filters.toDate).format('YYYY'))
        let toMonth = parseInt(moment(this.filters.toDate).format('M')) - 1
        let toDate = parseInt(moment(this.filters.toDate).format('D'))

        let start = { year: fromYear , month: fromMonth , date: fromDate ,hour:0,minute:0,second:0 }
        let end = { year: toYear , month: toMonth, date: toDate ,hour:23,minute:59,second:49 }
        
        var fd = this.filters.fromDate ? moment().tz(timezone).set(start) : moment().tz(timezone).set({hour:0,minute:0,second:0});
        var td = this.filters.toDate ? moment().tz(timezone).set(end) :  moment().tz(timezone).set({hour:23,minute:59,second:59});

        if(!this.filters.fromDate) {
          //this.filters.fromDate = parseInt(fd.format('x'))
        }
        
        if(!this.filters.toDate) {
          //this.filters.toDate = parseInt(fd.format('x'))
        }
        
        //page, pageSize, sort, sortOrder
        data = {  
                user: this.user.data.uid,
                fromDate:fd.unix() ,
                toDate: td.unix(),
                companyProfile: companyProfile,
                page:this.filters.page,
                pageSize:this.filters.perPage,
                sort:this.filters.sort,
                sortOrder:this.filters.sortOrder,
                //rowsQty:10,
                search:this.filters.search,
                
              
        }

      } else {
        data = options
      }

      switch(this.user.data.role) {
        case 'company-admin':
            data.resOwnerUid = this.user.data.uid
           
          break;
        case 'dispatcher':

          break;
      }

      data.requesterRole = this.user.data.role
      data.requesterEmail = this.user.data.email
       
      data.page = this.filters.page 
      data.last = this.filters.last
      data.first = this.filters.first
      data.direction = this.filters.direction

      if(this.filters.page === 1) {

      } else {
        
      }

       
      // Parse for date picker

      let fakeFromOrigin = moment.unix(data.fromDate).tz(timezone)
      let fakeToOrigin = moment.unix(data.toDate).tz(timezone)

      let fakeDatepickerDate_fromYear = parseInt(fakeFromOrigin.format('YYYY'))
      let fakeDatepickerDate_fromMonth = parseInt(fakeFromOrigin.format('M')) - 1 
      let fakeDatepickerDate_fromDate = parseInt(fakeFromOrigin.format('D'))
      let fakeDatepickerDate_toYear = parseInt(fakeToOrigin.format('YYYY'))
      let fakeDatepickerDate_toMonth = parseInt(fakeToOrigin.format('M')) - 1
      let fakeDatepickerDate_toDate = parseInt(fakeToOrigin.format('D'))
      
      let fakeDatepickerDate_start = { year: fakeDatepickerDate_fromYear , month: fakeDatepickerDate_fromMonth , date: fakeDatepickerDate_fromDate ,hour:0,minute:0,second:0 }
      let fakeDatepickerDate_end = { year: fakeDatepickerDate_toYear , month: fakeDatepickerDate_toMonth, date: fakeDatepickerDate_toDate ,hour:23,minute:59,second:49 }
      
      var fake_fd =  moment().tz(zone_name).set(fakeDatepickerDate_start) ;
      var fake_td =  moment().tz(zone_name).set(fakeDatepickerDate_end) ;
      
      this.$store.commit('setDateFilter', ['fromDate',parseInt(fake_fd.format('x'))])
      this.$store.commit('setDateFilter', ['toDate',parseInt(fake_td.format('x'))])
      this.$store.commit('setDateFilter', ['sort','day'])

      
      
    
      // var reservationsRef = this.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').orderBy(th.filters.sort)
      
      // if(th.filters.sort) {
      //   reservationsRef = reservationsRef.orderBy(th.filters.sort)

      // }

    //   if(th.filters.searchById &&  th.filters.goToID) {
    //     reservationsRef = reservationsRef.where("readableId", "==", parseInt(th.filters.goToID) )
    //   } else {
    //     if(data.companyProfile) {
    //       reservationsRef = reservationsRef.where("companyProfile.id", "==",data.companyProfile.id )
    //     }                                          

    //     if(data.fromDate) {
    //         reservationsRef = reservationsRef.where("day", ">=",data.fromDate )
    //     }

    //     if(data.toDate) {
    //         reservationsRef = reservationsRef.where("day", "<=",data.toDate )
    //     }
    //   }
 
    //  if(this.filters.page === 1) {
    //     reservationsRef = reservationsRef.limit(this.filters.perPage)

    //  } else {
        
    //     if(th.filters.last) {
    //       reservationsRef = reservationsRef.startAfter(th.filters.last).limit(this.filters.perPage)

    //     } else { 

    //     }
    //  }


     


     

      if(th.unsub) {       
        th.unsub()
      } 


      console.log('filters to send', this.filters)
      console.log('data to Send',data);
      this.cancel();
      let axiosSource = newAxios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };

      return new Promise((resolve, reject) => {    //Send token to your backend via HTTPS
                //...
         axios.get('reservations/filter', { params: data,  cancelToken: axiosSource.token  }).then(response =>{
            console.log(response);
            th.reservations.mdData = response.data.results
            th.reservations.mdCount = response.data.totalResults

            if(response.data.results.length > 0) {
              th.$store.commit('setDateFilter', ['first',response.data.results[0].id ])
              th.$store.commit('setDateFilter', ['last',response.data.results[response.data.results.length - 1].id ])

            }

            resolve()
        }).catch(e => {
            console.log(e);
            reject()
        })
           
      }) 

      
      /*
      return new Promise((resolve, reject) => {
    
        th.unsub = reservationsRef.onSnapshot(
          (querySnapshot) => {
            console.log('querySnapshot.docs.length',querySnapshot.docs.length);
            const last = querySnapshot.docs[querySnapshot.docs.length - 1];
            this.$store.commit('setDateFilter', ['last',last])
            th.reservations.mdData = []
            querySnapshot.forEach(async (doc) => {
                let dataToSave = doc.data();

                dataToSave.id = doc.id

                // let offers = await doc.ref.collection('offers').get()
                // dataToSave.offers = []

                // offers.forEach((offer) => {
                //   dataToSave.offers.push(offer.data())
                // })

                // console.log('offers',offers)

                dataToSave.checked = false;
                let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
                  

                let timeDiff = timePack.timezoneOffsetDif
                // Get UTC time to add or substract timezone difference
                let utcRealDateTime = moment.unix(dataToSave.day)

                if(timePack.viewerTimezonePosition == 'right') {
                    timeDiff = timeDiff * -1
                }

                let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                  
                //th.selectedData.datetime = timeToShow
                //dataToSave.day = timeToShow
                  
                th.reservations.mdData.push(dataToSave);
            })

            console.log('th.filters.page',th.filters.page)
            console.log('th.filters.perPage',th.filters.perPage)
            console.log('th.reservations.mdData',th.reservations.mdData)
            th.reservations.mdCount = th.reservations.mdData.length
            th.reservations.mdPage = th.filters.page
            
            // th.isBusy = false
            resolve()
          }
          , (error) => { 
            console.log(error)
            th.isBusy = false
            reject(error)
          })
      
      
      })
      
      */
      
        
    
    },
    cancel() {
        if (this.request) this.request.cancel('New request called');
    },
    async fireFilters() {
      let th = this;
      return new Promise((resolve, reject) => {
        var statusId = null ;
        if(th.$route.params.status){
          for (let i = 0; i < th.reservationStatuses.length; i++) {
            var aux = th.$route.params.status.charAt(0).toUpperCase() + th.$route.params.status.slice(1).replace("-"," ");
            if(th.reservationStatuses[i].name == aux){
                statusId = th.reservationStatuses[i].id
            }
          }

          th.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').where("reservationStatus","==",statusId).orderBy("day", "asc").onSnapshot((querySnapshot) => {
            th.reservations = [];
            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              th.reservations.push(dataToSave);
              
              
            });
            resolve()
          });

        } else {
          let timezone ;
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              timezone = this.companyProfiles[i].timeZone
            }
            
          }
          var fd = moment().tz(timezone).set({hour:0,minute:0,second:0});
          var td = moment().tz(timezone).set({hour:23,minute:59,second:59});
         
          // import { collection, query, where } from "firebase/firestore";
          // const citiesRef = collection(db, "cities");

          // // Create a query against the collection.
          // const q = query(citiesRef, where("state", "==", "CA"));
          
          if(th.filters.companyProfile && this.filters.fromDate && this.filters.toDate) {
            //reservationsRef
          }

          const reservationsRef = th.$firebase.firestore().collection('users')
                                  .doc(th.user.data.uid)
                                  .collection('reservations')
                                  .where("day", ">=",parseInt(fd.format('X')) )
                            .where("day", "<=", parseInt(td.format('X')) )
  
                          .orderBy("day", "asc")
                          .onSnapshot((querySnapshot) => {
                              th.reservations.mdData = [];

                              querySnapshot.forEach((doc) => {
                                let dataToSave = doc.data();
                                dataToSave.id = doc.id ;
                                //dataToSave.miles = dataToSave.miles.split(" mi")[0]
                                dataToSave.checked = false;
                                //dataToSave.day = dataToSave.day.toString();
                                let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
                                

                                let timeDiff = timePack.timezoneOffsetDif
                                // Get UTC time to add or substract timezone difference
                                let utcRealDateTime = moment.unix(dataToSave.day)

                                if(timePack.viewerTimezonePosition == 'right') {
                                    timeDiff = timeDiff * -1
                                }

                                let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                                
                                //th.selectedData.datetime = timeToShow
                                dataToSave.day = timeToShow
                                
                                th.reservations.mdData.push(dataToSave);
                                
                                
                                
                              });
                              resolve()
            
                          });
         

          

        }
      })
    },
    async updatePagination(page, pageSize, sort, sortOrder){
      console.log('updatePagination')
      console.log(page, pageSize, sort, sortOrder)
      console.log('page seted before change', this.filters.page)

      if(page > 1) {
          let direction = null
          page < this.filters.page ? direction = 'prev' : direction = 'next'

          this.$store.commit('setDateFilter', ['direction',direction])
      } else if( page == 0) {
        this.$store.commit('setDateFilter', ['direction',null])
      } else {
        this.$store.commit('setDateFilter', ['direction',null])
      }
      this.$store.commit('setDateFilter', ['page',page])
      this.$store.commit('setDateFilter', ['perPage',pageSize])
      this.$store.commit('setDateFilter', ['sort',sort])
      await this.fireQuery()

     
    },
    openFlightDialog(item){
      this.actualFlightPack = item.flightPack ;
      this.actualFlightPack.readableId = item.readableId
      this.actualFlightPack.jobType = item.jobType.jobType
      this.actualFlightPack.companyProfile = item.companyProfile
      this.flightDialog = true
      //this.getFlightInfo(item.flightPack, item.id)
    },
    getCompleteStatus(id) {
        for (let i = 0; i < this.paymentStatuses.length; i++) {
            if(this.paymentStatuses[i].id === id) {
                return this.paymentStatuses[i]
            }
            
        }
    },

    checkTimeToGo(date) {
      var startTime = moment();
      var endTime = moment.unix(date);
    
      //var hoursDiff = endTime.diff(startTime, 'hours');
    
      var minutesDiff = endTime.diff(startTime, 'minutes');
    
      //var secondsDiff = endTime.diff(startTime, 'seconds');

      var ms = endTime.diff(startTime);
      var d = moment.duration(ms);
      //var s = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
      let className ;
      if(minutesDiff >= 180) {
        className = 'white-pu'
      } else if(minutesDiff >= 60 ) {
        className = 'yellow-pu'
      } else if(minutesDiff > 0) {
        className = 'red-pu'
      }

      
     
      let payload ;
      if(d >= 0 ) {
        payload = '<div class="time-to-go ' + className + '">' + d.days() + ' D, ' + d.hours() + ' H, ' + d.minutes() + ' M' + ' to PU' + '</div>';
        //return 'Days:' + d.days() + ' , Hours:' + d.hours() + ' , Minutes:' + d.minutes() + ' to pick up';
      } else {
        // Here goes logic with trip status , on location, dropped off etc
        className = 'green-pu'
        className = 'grey-pu'
        if(minutesDiff < 0 &&  minutesDiff >= -60) {
          payload = '<div class="time-to-go ' + className + '">' + 'Picked up' + '</div>';
          //payload = '<div class="' + className + '">' + d.days() + ' days, ' + d.hours() + ' h and ' + d.minutes() + ' min' + ' to PU' + '</div>';
        } else {
          payload = ''
        }
       
      }
      payload = '<div class="text-uppercase time-to-go ' + className + '">' + endTime.fromNow() + '</div>'
      return payload ;
      //return ''
       //moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    async getLinkedUsers(requestedRole) {
      let th = this
      let ref 
      switch(this.user.data.role) {
        case 'company-admin':
        ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid).
              collection('linked-users')
          break;
        default:
        ref = firebase
            .firestore()
            .collection('drivers')
            .doc(this.user.data.email).
            collection('linked-users')
          break;
      }
      return new Promise((resolve, reject) => {
        th.driverAssignment.linkedDrivers = []
        ref.where('role','==',requestedRole).get()
          .then(function(snapShot){
            snapShot.forEach((doc) => {
              let dataToSave = doc.data()
              dataToSave.id = doc.id
              th.driverAssignment.linkedDrivers.push(dataToSave)
            });

           
            resolve()
          
          }).catch(function(error) {
             
              reject()
          });
        
            
      });
    },
    async getCommunities(requestedRole) {
      let th = this
      let ref 
      switch(this.user.data.role) {
        case 'company-admin':
        ref = firebase
              .firestore()
              .collection('users')
              .doc(this.user.data.uid).
              collection('communities')
          break;
        default:
        ref = firebase
            .firestore()
            .collection('drivers')
            .doc(this.user.data.email).
            collection('communities')
          break;
      }
      return new Promise((resolve, reject) => {
        th.driverAssignment.communities = []
        ref.where('role','==',requestedRole).get()
          .then(function(snapShot){
            snapShot.forEach((doc) => {
              let dataToSave = doc.data()
              dataToSave.id = doc.id
              th.driverAssignment.communities.push(dataToSave)
            });

           
            resolve()
          
          }).catch(function(error) {
             
              reject()
          });
        
            
      });
    },
    async getCompanyDrivers(){
        let th = this ;
        console.log(th.user);
        let data = {  
              ownerEmail: this.user.data.email,
              roleToGet: 'driver' ,
              ownerUid: this.user.data.uid
        }
        
          
        axios.get('drivers', { params: data }).then(response =>{
          th.driverAssignment.companyDrivers = response.data.users ;
          th.isBusy = false;
        }).catch(e => {
          console.log(e);
            th.isBusy = false;
        })
    },
    async obtainViewResources() {
      this.isBusy = true
      await this.getStatuses()
      // await this.getDispatchers()
      if(this.user.data.role === 'company-admin') {

        await this.getCompanyProfiles()
        await this.getLinkedUsers('driver')
        await this.getCommunities('driver')
        await this.getCompanyDrivers()
      }
      await this.getAddons()
      await this.getVehicleTypes()
      await this.getBaseVehicleTypes()
      await this.getJobTypes()
      //await this.filerByStatus()
      await this.updatePagination(1, this.filters.perPage)
      this.isBusy = false;
      // this.$nextTick(function(){
      //   this.updateTableHeight()
      //   this.updateTableFixedColumnsData()
      //   window.document.querySelectorAll('.up-table')[0].addEventListener('scroll', this.onTableScrollHorizontal)
      //   window.document.querySelectorAll('body.uplimo-body .md-table.md-theme-default .md-table-content')[0].addEventListener('scroll', this.onTableScrollVertical)
      // })
      this.dateLoaded = true
      

    },
    async updateReservation(reservation){
      let th = this;
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations').doc(reservation.id).update(reservation)
          .then(function(){
              th.snackMessage = 'Reservation updated';
              th.isBusy = false;
              th.showSnackbar = true;
              resolve()
          
          }).catch(function(error) {
              th.snackMessage = 'Ooopss something went wrong';
              th.isBusy = false;
              th.showSnackbar = true;
              console.log("Error getting document:", error);
              reject()
          });
        
            
      });
 
    },
    async filerByStatus(){
      await this.getStatuses()
      let th = this;
      return new Promise((resolve, reject) => {
        var statusId = null ;
        if(th.$route.params.status){
          for (let i = 0; i < th.reservationStatuses.length; i++) {
            var aux = th.$route.params.status.charAt(0).toUpperCase() + th.$route.params.status.slice(1).replace("-"," ");
            if(th.reservationStatuses[i].name == aux){
                statusId = th.reservationStatuses[i].id
            }
          }

          th.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').where("reservationStatus","==",statusId).orderBy("day", "asc").onSnapshot((querySnapshot) => {
            th.reservations = [];
            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              th.reservations.push(dataToSave);
              
              
            });
            resolve()
          });

        } else {
          let timezone ;
          for (let i = 0; i < this.companyProfiles.length; i++) {
            if(this.companyProfiles[i].id  ===  th.filters.companyProfile) {
              timezone = this.companyProfiles[i].timeZone
            }
            
          }
          var fd = moment().tz(timezone).set({hour:0,minute:0,second:0});
          var td = moment().tz(timezone).set({hour:23,minute:59,second:59});
         
          this.filters.fromDate = parseInt(fd.format('x'))
          this.filters.toDate = parseInt(fd.format('x'))


          th.$firebase.firestore().collection('users')
                                  .doc(th.user.data.uid)
                                  .collection('reservations')
                                  .where("day", ">=",parseInt(fd.format('X')) )
                                  .where("day", "<=", parseInt(td.format('X')) )
                                  .orderBy("day", "asc")
                                  .onSnapshot((querySnapshot) => {
            th.reservations.mdData = [];

            querySnapshot.forEach((doc) => {
              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              //dataToSave.miles = dataToSave.miles.split(" mi")[0]
              dataToSave.checked = false;
              //dataToSave.day = dataToSave.day.toString();
              let timePack = th.flowTimeZone(dataToSave.companyProfile.timeZone);
              

              let timeDiff = timePack.timezoneOffsetDif
              // Get UTC time to add or substract timezone difference
              let utcRealDateTime = moment.unix(dataToSave.day)

              if(timePack.viewerTimezonePosition == 'right') {
                  timeDiff = timeDiff * -1
              }

              let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
              
              //th.selectedData.datetime = timeToShow
              dataToSave.day = timeToShow
              
              th.reservations.mdData.push(dataToSave);
              
              
              
            });
            resolve()
            
          });

          th.reservations.mdCount = th.reservations.mdData.length
         

          

        }
      })
        
    
    },
    async getStatuses() {
        ///////
        let th = this;
        return new Promise((resolve, reject) => {
            th.statusesRef.orderBy('name').onSnapshot((querySnapshot) => {
                th.reservationStatuses =  []
                th.driverStatuses =  []
                th.paymentStatuses =  []
                th.tripStatuses =  []
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    switch(dataToSave.type) {
                        case 'reservation':
                            th.reservationStatuses.push(dataToSave);
                            break;
                        case 'driver':
                            th.driverStatuses.push(dataToSave);
                            break;
                        case 'payment':
                            th.paymentStatuses.push(dataToSave);
                            break;
                        case 'trip':
                            th.tripStatuses.push(dataToSave) ;
                            break; 
                        
                    }
                    
                    
                });
                resolve()
            })
            
        }) 

        
    },
    getJobTypeName(jobTypeId) {
        for (let i = 0; i < this.jobTypes.length; i++) {
            if(this.jobTypes[i].id == jobTypeId ) {
                return this.jobTypes[i].name ;
            }   
        }
    },
    async getJobTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                th.jobTypes = [];
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.jobTypes.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    async getBaseVehicleTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                th.baseVehicleTypes = [];
                querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                th.baseVehicleTypes.push(dataToSave);
                

                });    
                
                resolve()
                
            });
        })  
    },
    async getVehicleTypes() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                th.vehicleTypes = [];
                querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                th.vehicleTypes.push(dataToSave);
                

                });    
                
                resolve()
                
            });
        })  
    },
    async getDispatchers(){
        let th = this ;

        let data = {  
                ownerEmail: this.$store.getters.user.data.email,
                roleToGet: 'dispatcher'
        }

        
        return new Promise((resolve, reject) => {
            th.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                //Send token to your backend via HTTPS
                //...
                axios.get('dispatchers', { params: data }).then(response =>{
                    th.dispatchers = response.data.users ;
                    resolve()
                }).catch(e => {
                    //console.log(e);
                    reject()
                })
            }).catch(function(error) {
                //Handle error
            });
        }) 

    
    },
    async getAddons() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('add-ons').onSnapshot((querySnapshot) => {
                th.addOns = [];
                querySnapshot.forEach((doc) => {
                    
                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.addOns.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    async getCompanyProfiles() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(th.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                th.companyProfiles = [];
                querySnapshot.forEach((doc) => {
                    
                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    if(dataToSave.default) {
                      
                     
              

                      th.$store.commit('set', ['timezone', dataToSave.timeZone ])
                      th.$store.commit('set', ['utc',moment.tz(dataToSave.timeZone).utcOffset() ])
                      //th.$store.commit('set', ['time',moment.tz(dataToSave.timeZone).format('hh:mm A (HH:mm [HS])') ])
                      //th.$store.commit('set', ['date',moment.tz(dataToSave.timeZone).format('dddd, MMM Do YYYY') ])
                      //$store.commit('toggleSidebarMobile')
                      if(th.filters.companyProfile === null) {
                         th.filters.companyProfile = dataToSave.id
                      }
                     
                    }

                    th.companyProfiles.push(dataToSave);
                    
                });
                resolve()
            });
        })  
    },
    updateTime() {
        var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
        this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
        
    },
    flowTimeZone(timeZone) {
        let timePack = {
          timezoneOffsetDif: null,
          viewerTimezonePosition: null
        }

        var tz = moment.tz.guess();
        var dbaTZoffset = moment.tz(timeZone).utcOffset()

        var creatorTZoffset = moment.tz(tz).utcOffset()


        if(creatorTZoffset > dbaTZoffset) {
            // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
            // Moscu 180 (dba) // nuevadelih (creator) 300
            timePack.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;
            timePack.viewerTimezonePosition = 'right';
            // -180 -  -240 = 60
            // 300 - 180 = 120
        } else if(dbaTZoffset > creatorTZoffset) {

            // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
            // Moscu 180 (creator) // nuevadelih (dba) 300

            timePack.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
            timePack.viewerTimezonePosition = 'left';
            // -240 - -180 = 
            // 180 - 300 = -120
        } else {
            timePack.timezoneOffsetDif = 0;
            timePack.viewerTimezonePosition = 'equal';
        }

        return timePack;




    },
    roundNumber(number) {
          number = number.toString(); //If it's not already a String
          if(number.indexOf(".") != -1) {
              number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
          } 
          
          return number
      },
    scrollLeft() {
      var table = this.$refs.masterTable.$el ;
      table.scrollLeft -= 300;
    },
    scrollRight() {
     
      var table = this.$refs.masterTable.$el ;
      //var content = table.querySelectorAll('.md-scrollbar')[0];
      table.scrollLeft += 300;
      //table.scrollTo(900,0);
      
      
    },
    setWidth() {
      // if (this.MdTable.fixedHeader) {
      //   var cellSelector = 'md-table-cell';
      //   var thEls = this.getChildNodesBySelector(this.$el.parentNode, 'md-table-head');
      //   var tdEls = this.MdTable.contentEl.querySelectorAll('tr:first-child .' + cellSelector);
      //   var nodeIndex = this.getNodeIndex(thEls, this.$el);

      //   this.width = tdEls[nodeIndex].offsetWidth;
      // }
    },
    async fireGlobalCheck() {
         let data = {
             test: true
         }
         axios.put('check-flight-status-globally/'

            ).then(response =>{
            }).catch(e => {
                // this.reservation.flightInfo = null;
                // this.loadingFlight = false ;
                console.log(e);
                // this.serverError = false ;
            })  

    },
    async getFlightInfo(flightPack, resId){
        let th = this;
        return new Promise((resolve, reject) => {
            let data = {
                flightNumber: flightPack.schedule.ident,
                departuretime: flightPack.schedule.departuretime,
                resId: resId,
                user: th.user.data.uid
            }

            axios.post('live-flight-info/' , data

            ).then(response =>{
                
                
                if(response.data.result) {
                  let flight = response.data.result.flights[0]
                   
                }

                // Si actualarrivaltime es 0 esta en el aire
                // estimated arrival time (hora estimada de aterrizaje)


                // Si actualdeparturetime es 0 no depego
                // estimated departure time (hora estimada de aterrizaje)


                // if(callback.FlightInfoExResult) {
                
                        
                // }
                resolve()
            }).catch(e => {
                // this.reservation.flightInfo = null;
                // this.loadingFlight = false ;
                console.log(e);
                reject()
                // this.serverError = false ;
            })  

            
          
        })  
    },
    myEventHandler(e) {
        // your code for handling resize...
        this.$store.commit('centerDialog',window.innerWidth) 
        this.updateTableHeight()
        ////
    },
    updateTableHeight(){
      // Master table content element
      let toolbarHeight = this.$refs.masterToolbar.$el.offsetHeight ;
      //let bottombarHeight = this.$refs.bottomBar.$el.offsetHeight ;
      if(this.$refs.masterTable) {
        let tableHeaderHeight = this.$refs.masterTable.$children[0].$children[0].$el.offsetHeight
     
        let tableHeight = this.$window.innerHeight - (56 + toolbarHeight + tableHeaderHeight /* + bottombarHeight */ + 10) 
        

        this.$refs.masterTable.$children[0].$children[1].$el.style.height = tableHeight + "px"
        this.$refs.masterTable.$children[0].$children[1].$el.style.maxHeight = tableHeight + "px"
        this.tableContentHeight = tableHeight

      } else {
        this.tableContentHeight = null
      }
      
      
    },
    
    paymentStatus(statusId) {
      for (let i = 0; i < this.paymentStatuses.length; i++) {
        if(this.paymentStatuses[i].id === statusId) {
          if(this.paymentStatuses[i].name === 'Partially paid') {
            return 'partially-paid'
          } else {
            return this.paymentStatuses[i].name.toLowerCase();
          }
          
        }
      }
    },
    isSameDay(dateA, dateB){
      let a = moment.unix(dateA).format("YYYY-MM-DD") ;
      let p = moment.unix(dateB).format("YYYY-MM-DD") ;
      //moment('2010-01-01').isSame('2010-02-01', 'day'); 
      return moment(a).isSame(p,'day')
      
    },
    isDiffDay(actualDate, previousDate){
      let a = moment.unix(actualDate).format("YYYY-MM-DD") ;
      let p = moment.unix(previousDate).format("YYYY-MM-DD") ;
      //moment('2010-01-01').isSame('2010-02-01', 'day'); 
      return !moment(a).isSame(p,'day')
      
    },
    resetModalInfo(){
      this.showDialog = false;
      this.modalInfo.daytime.day = null;
      this.modalInfo.daytime.time = null;
      this.modalInfo.resId = null;
      this.modalInfo.reservation = null ;
      this.modalInfo.passenger.email = null ;
      this.modalInfo.passenger.phone = null ;
      this.modalInfo.passenger.qty = null ;
      this.modalInfo.passenger.luggage = null ;


    },
    updateModalInfo(item) {
      item.day = this.modalInfo.daytime.day;
      item.time = this.modalInfo.daytime.time;
      this.updateReservation(item);
      

    },
    openModal(fieldType,item) {
      this.modalInfo.type = fieldType ;
      this.modalInfo.resId = item.readableId;
      switch(fieldType){
        case 'daytime':
          let formattedDate = moment.unix(item.day).format("YYYY-MM-DD HH:mm") ;
          this.modalInfo.daytime.day = item.day;
          //this.modalInfo.daytime.day = moment.unix(item.day).format("YYYY-MM-DD HH:mm");
          this.modalInfo.daytime.time = item.time;
          
          this.modalInfo.reservation = item;
        break;
        case 'passenger':
          this.modalInfo.passenger.firstName = item.passenger.firstName ;
          this.modalInfo.passenger.lastName = item.passenger.lastName ;
          this.modalInfo.passenger.email = item.passenger.email ;
          this.modalInfo.passenger.phone = item.passenger.phone ;
          this.modalInfo.passenger.qty = item.passenger.qty ;
          this.modalInfo.passenger.luggage = item.passenger.luggage ;
        
        break;
      }
      this.showDialog = true;
    },
    
    onSelect (items) {
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
    getShuffledUsersData () {
      return this.shuffleArray(usersData.slice(0))
    },
    editOrView(item) {
      this.$router.push({path: `/reservations/${item.id}`})
    },
    rowClicked (item, index) {
      this.$router.push({path: `/reservations/${item.id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    newReservation(){
      this.$router.push({path: `/reservations/new`})
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      /*
      if (this.reservations.length < 1) return;

      let local = this.reservations;
      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
      */
    },
    updateTableFixedColumnsData(){
      if(this.$refs.masterTable) {
        var table = this.$refs.masterTable.$el ;

        let firstColumns = [] 
        var content = table.querySelectorAll('table tbody tr')
        
        if(content.length > 0){
          for (let i = 0; i < content.length; i++) {
            let row = {};
            
            row.height = content[i].offsetHeight;
            let dateRow = content[i].classList.contains('blue-back');
            let dateWidth = content[i].querySelectorAll('.time-info')
            
            row.columns = {
              first : dateRow ? null : content[i].children[1].offsetWidth,
              second: dateRow ? null : dateWidth[0].offsetWidth + 35,
              id: dateRow ? null : content[i].children[1].innerText,
              date: dateRow ? content[i].children[0].innerText : content[i].dataset.datetime,
              timezone: dateRow ? null : content[i].dataset.timezone,
              realid: dateRow ? null : content[i].dataset.realid,
            }
            firstColumns.push(row);
           
            
          }
        }
        this.tableFixedColumns.data = firstColumns
      } else {
         this.tableFixedColumns.data = []
      }
      // this.$nextTick(function(){
      //   window.document.querySelectorAll('.fixed-columns-content')[0].addEventListener('scroll', this.onFixedColumnVertical)
      // })
      

    },
    onTableScrollHorizontal() {
        
        var table = this.$refs.masterTable.$el ;
        if(table.scrollLeft > 20 ) {
          this.fixedColShadowed = true;
          
        } else {
          this.fixedColShadowed = false;
          
        }

        if(table.scrollLeft > this.tableFixedColumns.data[0].columns.first ) {
         
          // var content = table.querySelectorAll('.md-table-content')[0].scrollTop  
         
          
          
          this.tableFixedColumns.show = true;
          this.$nextTick(function(){
             this.$refs.fixedColumnsContent.scrollTop = this.$refs.masterTable.$children[0].$children[1].$el.scrollTop
          })
         
        } else {
          this.tableFixedColumns.show = false;
        }
    },
    onTableScrollVertical(ev) {
      if(this.$refs.fixedColumnsContent) {
        this.$refs.fixedColumnsContent.scrollTop = ev.target.scrollTop
      }
    },
    onFixedColumnVertical(ev) {
      
      //this.$refs.masterTable.$children[0].$children[1].$el.scrollTop = ev.target.scrollTop
     
    }
  },
  validations: { 
      
      driverAssignment: {

        
        vehicleType: {
          id: {
            required
          }
        }
      },
      coveredArea: {
          fencesList: {
            minLength: minLength(1),
            required,
          }
      }
      
  }
}
</script>

<style  lang="scss">
    @import "./../../assets/scss/variables";
    .md-snackbar {
      z-index: 3000;
    }
    .md-table-pagination {
      position:absolute;
      right:0;
      margin-top: 20px;
      border-top:0px;
    }

    .md-table-pagination .md-field {
      margin: 0px 24px 0 32px!important;
    }

    .assigned {
      background-color: $assigned-to!important;
    }

    .unassigned {
      background-color: $unassigned!important;
    }
    .quoted {
        background-color: $quoted!important;
    }

    .offered {
      background-color: $offered!important;
    }

    .taken {
      background-color: $taken!important;
    }

    
    .confirmed {
        background-color: $confirmed!important;
    } 

    .unconfirmed {
        background-color: $unconfirmed!important;
    } 

    .late-concellation{
        background-color: $late-cancellation!important;
    } 

   .cancelled {
        background-color: $cancelled!important;
    }

    
</style>
