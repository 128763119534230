<template>
    <div>
        <div v-show="isBusy">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
        <div v-show="!isBusy">   
            <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="reservation && reservation.readableId">Reservation #{{reservation.readableId}}<b> (<md-icon>person</md-icon> {{reservation.passenger.firstName}} {{reservation.passenger.lastName}} / <md-icon>phone_iphone</md-icon> {{reservation.passenger.phone}})</b></h3>
            
                
                <!--md-button class="md-primary">Save vehicle type</md-button-->
                <md-button class="md-primary md-raised" @click="fireUpdate" :disabled="isBusy">Save</md-button>
            </md-toolbar>
            
            <md-tabs 
                class="fixed-tab-selector"
                md-alignment="left" 
                @md-changed="mainTabChanged" 
                ref="mainTabs" 
                :md-active-tab="mainActiveTab" 
                

                v-if="reservation"
                
                > 
                <template slot="md-tab" slot-scope="{ tab }"> 
                    {{ tab.label }} 
                    <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
                    <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
                </template>
                <md-tab id="date-time-location" md-label="Date, time &amp; location" :md-template-data="{ badge: tabs.dateTimeLocation.errors }">
                    <md-card class="uplimo-card">
                        <md-card-header>
                            <div class="md-title">Date, time &amp; location</div>
                        </md-card-header>
                        <md-card-content>
                            <CRow>
                                <CCol sm="12" md="12" lg="12" xl="6">
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('day')">
                                                <VueCtkDateTimePicker 
                                                    v-model="selectedData.datetime" 
                                                    :dark="true" 
                                                    :inline="false" 
                                                    :formatted="'llll (HH:mm [HS])'"
                                                    :format="'YYYY-MM-DD hh:mm a'"
                                                    :output-format="'X'"
                                                    :color="'#448aff'"
                                                    :button-color="'#448aff'"	
                                                    :minute-interval="5"
                                                    id="ul-timepicker"
                                                />
                                            <span class="md-error" v-if="!$v.reservation.day.required">Date &amp; time are required</span>
                                        
                                            

                                            <span class="md-helper-text" v-if="reservation.companyProfile">
                                                Selected date and time below will be applied to  <b>{{reservation.companyProfile.name}}</b> timezone <b>{{reservation.companyProfile.timeZone}}</b> <br><b>Actual date time {{companyActualDateTime}}</b>
                                        </span>

                                            </md-field>
                                        </CCol>
                                        
                                        
                                    </CRow>
                                    <CRow class="mt-4" v-if="reservation.vehicleType && reservation.vehicleType.rates.hourlyEnabled && reservation.jobType && reservation.jobType.type == 'Hourly'">
                                        <CCol sm="12">
                                            <md-field>
                                                <label for="hours">hours</label>
                                                <md-select v-model="reservation.time" name="hours" id="hours">
                                                    <md-option value="1">1</md-option>
                                                    <md-option value="2">2</md-option>
                                                    <md-option value="3">3</md-option>
                                                    <md-option value="4">4</md-option>
                                                    <md-option value="5">5</md-option>
                                                    <md-option value="6">6</md-option>
                                                    <md-option value="7">7</md-option>
                                                    <md-option value="8">8</md-option>
                                                    <md-option value="9">9</md-option>
                                                    <md-option value="10">10</md-option>
                                                    <md-option value="11">11</md-option>
                                                    <md-option value="12">12</md-option>
                                                </md-select>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow class="mt-5"> 
                                        <CCol sm="8">
                                            <md-field :class="getValidationClass('pu')" class="md-focused">
                                                <label>Pick up</label>
                                                <gmap-autocomplete
                                                    :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                    placeholder="Pick up"
                                                    @place_changed="setPU" class="pu-autocomplete"
                                                    @change="getSelectedAddress('pu',$event.target)"
                                                    ref="pu"
                                                >
                                                </gmap-autocomplete>
                                                <!--md-button class="md-icon-button" @click="usePlace" >
                                                    <md-icon>add</md-icon>
                                                </md-button-->
                                                <span class="md-error" v-if="!$v.reservation.pu.required">Pick up is required</span>
                                            </md-field>

                                        </CCol>

                                            <CCol sm="4">
                                                <md-field>
                                                <label>Zip code</label>
                                                <md-input v-model="reservation.pu.postalCode" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-button class="md-raised stop-button" @click="addStop"><md-icon>add_location_alt</md-icon>Add stop</md-button>
                                            <!--div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`"-->
                                            <div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`">
                                                <div class="row">
                                                    <div :class="{ 'col-sm-8': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-6': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <!--md-field :class="focusedItem == index ? 'md-focused' : ''"-->
                                                        <md-field class="md-focused">
                                                            <label>{{'Stop #' + (index + 1)}}</label>
                                                            <gmap-autocomplete
                                                                :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                                :placeholder="''"
                                                                @place_changed="setWP" class="pu-autocomplete"
                                                                @change="autocompleteUser(index,$event.target)"
                                                                @focus="focusedLocation(index)"
                                                                @blur="unfocusLocation(index)"
                                                                v-on:input="inputWithValue(index,$event.target.value)"
                                                                ref="stopwaypoint"
                                                                
                                                            >
                                                            </gmap-autocomplete>
                                                            
                                                        </md-field>
                                                    </div>
                                                    <div class="stop-zip-delete-container"
                                                        v-if="reservation.jobType && reservation.jobType.type == 'Distance'" 
                                                       :class="{ 'col-sm-0': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <md-field>
                                                            <label>Waiting Time (min)</label>
                                                            <md-input v-model="waypoint.waitingTime" type="number" step="15"></md-input>
                                                            
                                                        </md-field>
                                                    </div>
                                                    <div class="col-sm-2 stop-zip-delete-container"
                                                    :class="{ 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-2': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <md-field>
                                                            <label>Zip code</label>
                                                            <md-input v-model="waypoint.postalCode" readonly></md-input>
                                                            
                                                        </md-field>
                                                    </div>
                                                    
                                                    <div class="col-sm-1 stop-zip-delete-container">
                                                        <md-button class="md-icon-button" @click="removeWaypoint(index)">
                                                            <md-icon>delete</md-icon>
                                                        </md-button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </CCol>
                                        

                                    
                                    </CRow>
                                        <CRow>
                                        <CCol sm="8">
                                            <md-field :class="getValidationClass('do')" class="md-focused">
                                                <label>Drop off</label>
                                                <gmap-autocomplete
                                                    placeholder="Drop off"
                                                    @place_changed="setDO" class="pu-autocomplete"
                                                    ref="do"
                                                    @change="getSelectedAddress('do',$event.target)"
                                                >
                                                </gmap-autocomplete>
                                                <span class="md-error" v-if="!$v.reservation.do.required">Drop off is required</span>
                                                
                                            </md-field>
                                            </CCol>

                                            <CCol sm="4">
                                                <md-field>
                                                <label>Zip code</label>
                                                <md-input v-model="reservation.do.postalCode" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        
                                        <CCol sm="12" >
                                            <md-autocomplete 
                                                    
                                                    :md-options="airlines" 
                                                    :md-open-on-focus="false" 
                                                    :md-dense="true"
                                                    @focusin.native="focusedAirlines($event)"
                                                    @md-changed="getAirlines"
                                                    @md-selected="setAirline"
                                                    :value="reservation.flightAirline"
                                                    aria-autocomplete="off"
                                                    :class="getValidationClass('flightAirline')"
                                                    ref="airlineAutocomplete"
                                                    >
                                                <label>Airline</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item }">
                                                     <span class="airline-code">
                                                        <b v-if="item.icao != '' && item.iata != ''">{{ item.icao }} - {{item.iata}}</b>
                                                        <b v-if="item.icao == '' && item.iata != ''">{{item.iata}}</b>
                                                        <b v-if="item.icao != '' && item.iata == ''">{{item.icao}}</b>
                                                    </span> 
                                                    <span> {{ item.name }}</span>
                                                    
                                                </template>
                                                <template slot="md-autocomplete-empty" slot-scope="{ term }"> No matches found for <b>{{ term }}</b> </template>
                                                <span class="md-error" v-if="!$v.reservation.flightAirline.required">Flight Airline is required</span>
                                            </md-autocomplete>
                                            <div v-if="reservation && reservation.flightAirline">
                                                Selected airline <b>
                                                    <span v-if="reservation.flightIcao">{{reservation.flightIcao}}</span>
                                                    {{ reservation.flightIcao && reservation.flightIata ? ' / ' : '' }}
                                                    <span v-if="reservation.flightIata">{{reservation.flightIata}}</span>
                                                </b>
                                            </div> 
                                            <div v-else>No airline selected</div>
                                        </CCol>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('flightNumber')">
                                                <label>Flight #</label>
                                                <md-input v-model="reservation.flightNumber" v-number-only @keyup="initFlightCheck"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.flightNumber.required">Flight is required</span>
                                                <span class="md-error" v-else-if="!$v.reservation.flightNumber.serverError">Flight is invalid</span>
                                                <md-progress-spinner v-show="loadingFlight" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <div v-if="reservation.flightPack.noMatch" class="md-elevation-4 p-3">
                                        <div class="md-layout md-alignment-center-center">
                                            <div class="md-layout-item md-size-15">
                                                <md-icon class="md-size-2x  md-accent">airplanemode_inactive</md-icon>
                                            </div>
                                            <div class="md-layout-item">
                                                <div class="md-title"><b>{{reservation.flightIcao != '' && reservation.flightIcao != null ? reservation.flightIcao : reservation.flightIata != null ? reservation.flightIata : ''}}  {{ reservation.flightNumber}}</b></div>
                                                <div class="md-subheading">No matches found</div>
                                                <p class="md-body-2 mb-0">Searched flight code  has not been founded. Please doublecheck date, time, location, airline and flight number</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-if="reservation.flightPack.schedule" class="md-elevation-4 p-3 mb-4">
                                        <CRow  class="mb-2">
                                            <CCol sm="12">
                                                <div>Matched flight</div>
                                                <div class="md-title">
                                                    <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != ''">
                                                        {{ reservation.flightPack.icao }}
                                                    </span>
                                                    <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != '' && reservation.flightPack.iata != null && reservation.flightPack.iata != ''">/</span>
                                                    <span v-if="reservation.flightPack.iata != null && reservation.flightPack.iata != ''">
                                                        {{ reservation.flightPack.iata }}
                                                    </span>

                                                    {{reservation.flightPack.number}}
                                                </div>
                                                
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="reservation.flightPack.schedule">
                                            
                                            <CCol :sm="reservation.flightPack.diverted ? 4 : 6">
                                                <md-icon class="mr-2">flight_takeoff</md-icon>
                                                <div class="md-subheading mb-1">Departure</div>
                                                <div class="md-title up-text-primary">{{reservation.flightPack.schedule.origin}} <span v-if="reservation.flightPack.originAirport.iata">/ {{reservation.flightPack.originAirport.iata }}</span></div>
                                                <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.originAirport.name }}</div>
                                                <div class="md-caption">{{reservation.flightPack.originAirport.city }} / {{reservation.flightPack.originAirport.country }}</div>
                                                <div v-if="reservation.flightPack.live">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    <span class="md-caption mr-2">Actual status</span>
                                                    <md-chip v-if="reservation.flightPack.live.actualdeparturetime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actualdeparturetime == 0" class="mini uplimo-orange">On land</md-chip> 
                                                    <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                                                
                                                    
                                                    <div v-if="reservation.flightPack.live.actualdeparturetime == 0" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ reservation.flightPack.live.filed_departuretime | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.live.filed_departuretime > 0 && reservation.flightPack.live.actualdeparturetime == -1" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.live.actualdeparturetime | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>



                                                <div v-if="reservation.flightPack.divertedLive">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    <span class="md-caption mr-2">Actual status</span>
                                                    <md-chip v-if="reservation.flightPack.divertedLive.actualdeparturetime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actualdeparturetime == 0" class="mini uplimo-orange">On land</md-chip> 
                                                    <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                                                
                                                    
                                                    <div v-if="reservation.flightPack.divertedLive.actualdeparturetime == 0" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ reservation.flightPack.divertedLive.filed_departuretime | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.divertedLive.filed_departuretime > 0 && reservation.flightPack.divertedLive.actualdeparturetime != -1" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedLive.actualdeparturetime | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>


                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <span class="md-caption">Schedule</span>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.departuretime | moment('timezone', reservation.flightPack.originAirport.tz , 'YYYY-MM-DD') }}</div>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.departuretime | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A (HH:mm [HS])')  }}</div>
                                                <div class="md-caption">Timezone: {{reservation.flightPack.originAirport.tz }}</div>
                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <CRow v-if="reservation.flightPack.info" >
                                                    <CCol sm="12">
                                                        <div>Terminal: {{reservation.flightPack.info.terminal_orig != '' ? reservation.flightPack.info.terminal_orig : '-' }}</div>
                                                        <div>Gate:  {{reservation.flightPack.info.gate_orig != '' ? reservation.flightPack.info.gate_orig : '-' }}</div>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            <CCol :sm="reservation.flightPack.diverted ? 4 : 6">
                                                <md-icon class="mr-2">flight_land</md-icon>
                                                <div class="md-subheading mb-1">Arrival</div>
                                                

                                                <div class="md-title up-text-primary" :class="{'up-line-through': reservation.flightPack.diverted  }">{{reservation.flightPack.schedule.destination}} <span v-if="reservation.flightPack.destinationAirport.iata">/ {{reservation.flightPack.destinationAirport.iata }}</span></div>
                                                <div class="md-title up-text-primary" v-if="reservation.flightPack.divertedDestinationAirport">{{reservation.flightPack.divertedLive.destination}} <span v-if="reservation.flightPack.divertedDestinationAirport.iata">/ {{reservation.flightPack.divertedDestinationAirport.iata }}</span></div>

                                                <div class="md-body-1 text-magic-ellipsis" :class="{'up-line-through': reservation.flightPack.diverted  }">{{reservation.flightPack.destinationAirport.name }}</div>
                                                <div class="md-body-1 text-magic-ellipsis" v-if="reservation.flightPack.diverted">{{reservation.flightPack.divertedDestinationAirport.name }}</div>

                                                <div class="md-caption" :class="{'up-line-through': reservation.flightPack.diverted }" >{{reservation.flightPack.destinationAirport.city }} / {{reservation.flightPack.destinationAirport.country }}</div>
                                                <div class="md-caption"  v-if="reservation.flightPack.diverted">{{reservation.flightPack.divertedDestinationAirport.city }} / {{reservation.flightPack.divertedDestinationAirport.country }}</div>


                                                 <div v-if="reservation.flightPack.live">
                                                <md-divider class="mt-1 mb-2"></md-divider>
                                                
                                                    <span class="md-caption mr-2">
                                                        Actual status
                                                    </span>
                                                    
                                            
                                                    <md-chip v-if="reservation.flightPack.live.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actualarrivaltime == 0 && reservation.flightPack.live.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
                                            
                                                
                                                    <div v-if="reservation.flightPack.live.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.live.estimatedarrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.live.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.live.actualarrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>


                                                <div v-if="reservation.flightPack.divertedLive">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    
                                                    <span class="md-caption mr-2">
                                                        Actual status
                                                    </span>
                                                    
                                                
                                                    <md-chip v-if="reservation.flightPack.divertedLive.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actualarrivaltime == 0 && reservation.flightPack.divertedLive.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
                                                
                                                    
                                                    <div v-if="reservation.flightPack.divertedLive.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.divertedLive.estimatedarrivaltime | moment('timezone', reservation.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.divertedLive.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedLive.actualarrivaltime | moment('timezone', reservation.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>

                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                 <span class="md-caption">Schedule</span>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.arrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'YYYY-MM-DD') }}</div>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.arrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A (HH:mm [HS])') }}</div>
                                                <div class="md-caption">Timezone: {{reservation.flightPack.destinationAirport.tz }}</div>
                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <CRow v-if="reservation.flightPack.info" >
                                                    <CCol sm="12">
                                                        <div>Terminal: {{reservation.flightPack.info.terminal_dest != '' ? reservation.flightPack.info.terminal_dest : '-' }}</div>
                                                        <div>Gate:  {{reservation.flightPack.info.gate_dest != '' ? reservation.flightPack.info.gate_dest : '-' }}</div>
                                                        <div>Bag claim:  {{reservation.flightPack.info.bag_claim != '' ? reservation.flightPack.info.bag_claim : '-' }}</div>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            


                                            <CCol v-if="reservation.flightPack.diverted" sm="4">
                                                <md-icon class="mr-2">flight_land</md-icon>
                                                <div class="md-subheading mb-1">{{ reservation.flightPack.diverted ? 'Original arrival' : 'Arrival'}}</div>

                                                <div class="md-title up-text-primary" >{{reservation.flightPack.schedule.destination}} <span v-if="reservation.flightPack.destinationAirport.iata">/ {{reservation.flightPack.destinationAirport.iata }}</span></div>

                                                <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.destinationAirport.name }}</div>

                                                <div class="md-caption">{{reservation.flightPack.destinationAirport.city }} / {{reservation.flightPack.destinationAirport.country }}</div>
                                                
                                                
                                                <div v-if="reservation.flightPack.divertedToScheduledDestinationLive">
                                                <md-divider class="mt-1 mb-2"></md-divider>
                                                
                                                <span class="md-caption mr-2">
                                                    Actual status
                                                </span>
                                                
                                            
                                                <md-chip v-if="reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime == -1" class="mini uplimo-red">Cancelled</md-chip> 
                                                <md-chip v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime == 0 && reservation.flightPack.divertedToScheduledDestinationLive.actualdeparturetime > 0 " class="mini uplimo-yellow">On air</md-chip> 
                                                <md-chip v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime > 0" class="mini uplimo-green">Landed</md-chip> 
                                            
                                                
                                                <div v-if="reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime == 0" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.divertedToScheduledDestinationLive.estimatedarrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                <div v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedToScheduledDestinationLive.actualarrivaltime | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>
                                                

                                                
                                                
                                            </CCol>






                                        </CRow>
                                    </div>                                    
                                    <CRow v-if="reservation.flightInfo">
                                        <CCol sm="12">
                                            <md-table class="flight-table">
                                                <md-table-row>
                                                    <md-table-head>Airline</md-table-head>
                                                    <md-table-head>Flight</md-table-head>
                                                    <md-table-head>Origin</md-table-head>
                                                    <md-table-head>Destination</md-table-head>
                                                
                                                    <md-table-head>Day</md-table-head>
                                                    
                                                    <md-table-head v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">ETD</md-table-head>
                                                    <md-table-head v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">ETA</md-table-head>
                                                </md-table-row>

                                                <md-table-row>
                                                    <md-table-cell>{{reservation.flightAirline}}</md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.ident}}</md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.originAirport}} <br><b>{{ reservation.flightInfo.origin }}</b></md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.destinationAirport}} <br> <b>{{ reservation.flightInfo.destination }}</b></md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>

                                                    <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>
                                                </md-table-row>
                                            </md-table>
                                        </CCol>    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="6">
                                            <FeeCalculator 
                                                :addons="feeCalculatorData.addOns"
                                                :promoCodes="reservation.promoCodes"
                                                :pu="reservation.pu" 
                                                :do="reservation.do" 
                                                :datetime="reservation.day"
                                                :jobType="feeCalculatorData.jobType" 
                                                :companyProfile="reservation.companyProfile"
                                                :vehicleType="feeCalculatorData.vehicleType" 
                                                :overviewPath="feeCalculatorData.overviewPath"
                                                :extras="selectedData.extras"
                                                :calculatedDistance="reservation.miles"
                                                :waypoints="reservation.waypoints"
                                                @priceTable="printPriceTable"
                                                :rideDetailsOverridedData="overridedRateData"
                                                ref="feeCalculator" 
                                                /> 
                                            <!--md-field>
                                                <label>Price</label>
                                                <md-input v-model="reservation.price"></md-input>
                                            </md-field-->
                                        </CCol>
                                        <CCol sm="6">
                                            
                                            <md-field>
                                                <label>Miles</label>
                                                <md-input v-model="reservation.miles" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field>
                                                <label>Promo code</label>
                                                <md-input v-model="feeCalculatorData.promoCode"></md-input>
                                                
                                                <md-progress-spinner v-show="loadingPromoCode" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                            </md-field>
                                            <md-button class="md-raised stop-button" @click="addPromoCode">Add code</md-button>
                                            
                                            <md-list v-if="reservation.promoCodes && reservation.promoCodes.length > 0">
                                                <md-subheader>Codes to check</md-subheader>
                                                <md-list-item v-for="promoCode in reservation.promoCodes" v-bind:key="'codes-to-check-' + promoCode">{{promoCode}}</md-list-item>
                                            </md-list>  
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="reservation.vehicleType 
                                                && 
                                                (
                                                    ( reservation.vehicleType.rates.extras.childSeat.enabled 
                                                && reservation.vehicleType.rates.extras.childSeat.price )
                                                || ( reservation.vehicleType.rates.extras.accessible.enabled 
                                                && reservation.vehicleType.rates.extras.accessible.price )
                                                || ( reservation.vehicleType.rates.extras.meetAndGreet.enabled 
                                                && reservation.vehicleType.rates.extras.meetAndGreet.price)
                                                )">
                                        <CCol sm="12">
                                            <div class="extras-box mt-2">
                                                <div class="ex-title">Extras</div>
                                                <CRow>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.childSeat.enabled && reservation.vehicleType.rates.extras.childSeat.price">
                                                        <md-icon  class="special-icon super" v-bind:class="{ 'active-icon': reservation.childSeat }">child_friendly</md-icon> 
                                                        <md-switch v-model="reservation.childSeat">Childseat</md-switch>
                                                    </CCol>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.accessible.enabled && reservation.vehicleType.rates.extras.accessible.price">
                                                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.accessible }">accessible</md-icon> 
                                                        <md-switch v-model="reservation.accessible">Accessible</md-switch>
                                                    </CCol>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.meetAndGreet.enabled && reservation.vehicleType.rates.extras.meetAndGreet.price">
                                                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.meetAndGreet }">face</md-icon> 
                                                        <md-switch v-model="reservation.meetAndGreet">Meet and greet</md-switch>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    
                                </CCol>
                                
                                <CCol sm="12" md="12" lg="12" xl="6">
                                    <GmapMap :center="{lat:coordinates.lat,lng:coordinates.lng}" :zoom="4" style="width:100%;height:600px">
                                        <Gmap-Marker v-for="(marker, index) in markers"
                                            :key="index"
                                            :position="marker.position"
                                            ></Gmap-Marker>
                                        

                                        <DirectionsRenderer
                                            travelMode="DRIVING"
                                            :origin="origin"
                                            :destination="destination"
                                            :waypoints="computedWaypoints"
                                            @updated-miles="updatedMiles"
                                            @overview-path="overviewPath"
                                            ref="road" />
                                    </GmapMap>
                                </CCol>
                            
                            </CRow>
                        </md-card-content>
                    </md-card>
                </md-tab>
                <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
                    <CRow>
                        <CCol sm="12" lg="6">
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">General</div>
                                </md-card-header>
                                <md-card-content>
                                    <md-field :class="getValidationClass('companyProfile')">
                                        <md-select v-model="selectedData.companyProfile" name="company_profile" id="company-profile" placeholder="Company" >
                                            <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.reservation.companyProfile.required">Company is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('jobType')">
                                        <md-select v-model="selectedData.jobType" name="vehicle_type" id="job-type" placeholder="Job Type" >
                                            <md-option v-for="serviceType in serviceTypes"  :value="serviceType.id" v-bind:key="serviceType.id">{{serviceType.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.reservation.jobType.required">Job Type is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('vehicleType')">
                                        <md-select  v-model="selectedData.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" :disabled="!$v.reservation.jobType.required">
                                            <md-option v-for="vehicleType in filteredVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.reservation.vehicleType.required">Vehicle Type is required</span>
                                    </md-field>
                                    
                                    
                                    
                                    <CRow>
                                        <CCol sm="6">
                                            <md-field :class="getValidationClass('qty')">
                                                <label># Passengers</label>
                                                <md-input v-model="reservation.passenger.qty" type="number" :max="selectedData.passengers" ></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.qty.required">Passenger quantity is required</span>
                                                <span class="md-error" v-if="!$v.reservation.passenger.qty.between">Number must be between 1 and {{selectedData.passengers}}</span>
                                            </md-field>
                                            
                                        </CCol>
                                        <CCol sm="6">
                                            <md-field :class="getValidationClass('luggage')">
                                                <label># Luggage</label>
                                                <md-input v-model="reservation.passenger.luggage" type="number" :max="selectedData.luggage" ></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.luggage.required">luggage quantity is required</span>
                                                <span class="md-error" v-if="!$v.reservation.passenger.luggage.between">Number must be between 0 and {{selectedData.luggage}}</span>

                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    
                                    <CRow>
                                        <CCol sm="6">
                                            <md-field>
                                                <label>Notes for passenger</label>
                                                <md-textarea v-model="reservation.notes[0].text" md-autogrow md-counter="300"></md-textarea>
                                            </md-field>
                                            
                                            
                                        </CCol>
                                        <CCol sm="6">
                                            <md-field>
                                                <label>Notes for driver</label>
                                                <md-textarea v-model="reservation.notes[1].text" md-autogrow md-counter="300"></md-textarea>
                                            </md-field>
                                            
                                        </CCol>
                                        
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field>
                                                <label>Notes for all</label>
                                                <md-textarea v-model="reservation.notes[2].text" md-autogrow md-counter="300"></md-textarea>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                            
                                        
                                </md-card-content>
                            </md-card>
                        </CCol>
                                
                    </CRow>
                </md-tab>
                
                <md-tab id="dispatchment-info" md-label="Dispatchment" :md-template-data="{ badge: tabs.dispatchmentInfo.errors }">
                    <CRow>
                        <CCol sm="12" lg="6">
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Dispatchment info</div>
                                </md-card-header>
                                <md-card-content>
                                    <CRow>
                                        <CCol sm="12">
                                            <!--md-field :class="getValidationClass('dispatcher')">
                                                <md-select v-model="selectedData.dispatcher" name="dispatcher" id="dispatcher" placeholder="Dispatcher">
                                                    <md-option v-for="dispatcher in dispatchers" :value="dispatcher.uid" v-bind:key="dispatcher.uid">{{dispatcher.displayName}}</md-option>
                                                </md-select>
                                                    <span class="md-error" v-if="!$v.reservation.dispatcher.required">Dispatcher is required</span>
                                            </md-field-->
                                            <!--md-autocomplete :value="reservation.dispatcher" :md-options="dispatchers" :class="getValidationClass('dispatcher')" @md-selected="setDispatcher">
                                                <label>Dispatcher</label>
                                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                    <span> {{ item.displayName }}</span> 
                                                    
                                                </template>
                                                <span class="md-error" v-if="!$v.reservation.dispatcher.required">Dispatcher is required</span>
                                            </md-autocomplete-->
                                            
                                        </CCol>
                                    
                                        <CCol sm="12">        
                                            <md-field>
                                                <label>How long in advance (minutes)</label>
                                                <md-input v-model="reservation.publishAnticipation" type="number"></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field>
                                                <label>Driver assignations status</label>
                                                <md-select v-model="reservation.driverStatus" name="driver_status" id="driver_status" placeholder="Driver assignation status">
                                                    <md-option v-for="status in driverStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                                                </md-select>
                                            </md-field>
                                            <md-field>
                                                <label>Driver amount ($)</label>
                                                <md-input v-model="reservation.driverAmount" type="number"></md-input>
                                            </md-field>
                                            <md-field :class="getValidationClass('reservationStatus')">
                                                <label>Reservation status</label>
                                                <md-select v-model="reservation.reservationStatus" name="reservation_status" id="reservation_status" placeholder="Reservation status">
                                                    <md-option v-for="status in reservationStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                                                </md-select>
                                                <span class="md-error" v-if="!$v.reservation.reservationStatus.required">Reservation status required</span>
                                            </md-field>
                                            <md-field >
                                                <label>Trip status</label>
                                                <md-select v-model="reservation.tripStatus">
                                                    <md-option v-for="tripStatus in tripStatuses" :value="tripStatus.id" v-bind:key="tripStatus.id">{{tripStatus.name}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                </md-card-content>
                            </md-card>
                        </CCol>
                                
                    </CRow>
                </md-tab>
                <md-tab id="addons" md-label="Add ons" :md-template-data="{ badge: tabs.addons.errors }">
                    <CRow>
                        <CCol sm="12" lg="6">
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Addons</div>
                                </md-card-header>
                                <md-card-content>
                                    <md-field v-if="reservation.vehicleType && reservation.vehicleType.rates.addonsAvailable">
                                        
                                        <md-table>
                                            <md-table-row>
                                                <md-table-head>Name</md-table-head>
                                                <md-table-head>Qty</md-table-head>
                                                <md-table-head>Price</md-table-head>
                                                <md-table-head>Subtotal</md-table-head>
                                            </md-table-row>
                                            <template v-for="addon in reservation.vehicleType.rates.addons">
                                                <md-table-row v-if="addon.enabled && addon.price" v-bind:key="'reservation-' + addon.id">

                                                    <md-table-cell>{{addon.name}}
                                                    </md-table-cell>

                                                    <md-table-cell>
                                                        <md-field >
                                                            <label>Addon quantity</label>
                                                            <md-input type="number" v-model="addon.qty" min="0"></md-input>
                                                        </md-field>
                                                    </md-table-cell>

                                                    <md-table-cell>{{addon.price}}
                                                    </md-table-cell>
                                                    
                                                    <md-table-cell>
                                                        {{ addon.qty ? parseFloat(addon.qty) * parseFloat(addon.price) : 0}}
                                                    </md-table-cell>

                                                </md-table-row>
                                                
                                            </template>
                                        </md-table>
                                        
                                    </md-field>
                                    <md-empty-state
                                        v-else
                                        class="mt-4 md-primary"
                                        md-rounded
                                        md-icon="sentiment_dissatisfied"
                                        md-label="No addons available"
                                        md-description="No addons available for selected vehicle">
                                    </md-empty-state>
                                </md-card-content>
                            </md-card>
                        </CCol>
                    
                    </CRow>
                </md-tab>
                <md-tab id="rates-and-taxes" md-label="Rates and taxes" :md-template-data="{ badge: tabs.ratesAndTaxes.errors }" >
                    <md-empty-state
                        v-if="!rateData"
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="request_quote"
                        md-label="Calculated rates"
                        md-description="Rate detail will be displayed here when required information is completed">
                    </md-empty-state>
                    
                    <md-card class="uplimo-card" v-if="rateData">
                        <md-card-header>
                            <div class="md-title">Rate details</div>
                        </md-card-header>
                        <md-card-content>
                            <md-table>
                                <md-table-row>
                                    <md-table-head>Reference</md-table-head>
                                    <md-table-head class="text-right">Units, minutes or %</md-table-head>
                                    <md-table-head class="text-right">$ cost per unit</md-table-head>
                                    <md-table-head class="text-right">$ Subtotal No Tax</md-table-head>
                                    <md-table-head class="text-right">$ Subtotal + Tax</md-table-head>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Ride related</md-table-cell>
                                    
                                </md-table-row>
                                <md-table-row v-if="rateData.activeFlatRate">
                                    <md-table-cell>{{rateData.activeFlatRate.name}} (Flat rate)</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">
                                         <md-field>
                                            <md-input v-model="rateData.activeFlatRate.price" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.activeFlatRate.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="reservation.vehicleType && rateData.miniumRate && !rateData.activeFlatRate">
                                    <md-table-cell>Minium rate</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">
                                         <md-field>
                                            <md-input v-model="rateData.rates.miniumRate" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.miniumRate}}</md-table-cell>
                                </md-table-row>
                                <template v-if="!rateData.activeFlatRate && !rateData.miniumRate">
                                    <md-table-row v-for="(rate,index) in rateData.rateSpread" v-bind:key="index">
                                        <md-table-cell v-if="rate.base"> Base rate <b>(The first {{rateData.rates.base.includedMiles}} miles)</b></md-table-cell>
                                        <md-table-cell v-else>Miles</md-table-cell>

                                        <md-table-cell class="text-right"> {{ rate.base ? '1' : roundNumber(rate.milesQty)}}</md-table-cell>
                                        <md-table-cell class="text-right">
                                            
                                            <md-field v-if="rate.base">
                                                <md-input v-model="rate.rate" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                            </md-field>
                                            <md-field v-else>
                                                 <md-input v-model="rate.ratePerMile" type="number" @change="rate.rate = rate.ratePerMile * rate.milesQty; updateRideRelatedSubtotal()"></md-input>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rate.rate) }}</md-table-cell>    
                                    </md-table-row>
                                </template>
                                
                                
                                
                                <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                    <md-table-cell>Stops</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.stops.length}}</md-table-cell>
                                    <md-table-cell class="text-right">
                                        
                                        <md-field>
                                            <md-input v-model="rateData.rates.stop" type="number" @change="rateData.subtotals.stops = rateData.stops.length * rateData.rates.stop; updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.subtotals.stops}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                    <md-table-cell>Total waiting time on stops</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.subtotals.waitingTimeMinutesTotal}}</md-table-cell>
                                    <md-table-cell class="text-right">
                                        <md-field>
                                            <md-input :value="roundNumber(rateData.rates.waitingTime / 60)" type="number" @change="rateData.subtotals.waitingTimePriceTotal = $event.target.value * rateData.subtotals.waitingTimeMinutesTotal; updateRideRelatedSubtotal()"></md-input>
                                            <span class="md-helper-text">Per minute rate</span>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{roundNumber(rateData.subtotals.waitingTimePriceTotal)}}</md-table-cell>
                                </md-table-row>

                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterRideDetails)}}</b></md-table-cell>
                                </md-table-row>

                                <!-- Increases and decreases here -->
                                <template v-if="rateData.validOverrides.length > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Increases and decreases</md-table-cell>
                                        
                                    </md-table-row>
                                    <template  >
                                        <md-table-row v-for="override in rateData.validOverrides" v-bind:key="override.id">
                                            <md-table-cell>{{override.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.value + '%' : 1}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                            <md-table-cell class="text-right">0.00</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterOverrides)}}</b></md-table-cell>
                                    </md-table-row>
                            
                                </template>

                                <!-- Bonus and discounts here -->
                                <template v-if="rateData.validPromoCodes.length > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Bonus and discounts</md-table-cell>
                                        
                                    </md-table-row>
                                    <template  >
                                        <md-table-row v-for="promoCode in rateData.validPromoCodes" v-bind:key="promoCode.id">
                                            <md-table-cell>{{promoCode.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.value + '%' : 1}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                            <md-table-cell class="text-right">0.00</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterDiscount)}}</b></md-table-cell>
                                    </md-table-row>
                            
                                </template>

                                <!-- Applied on base tax here -->
                                <template v-if="rateData.subtotals.appliedOnBase">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Applied on base</md-table-cell>
                                    </md-table-row>
                                    <template v-for="tax in rateData.subtotals.onBaseTaxList">
                                        <md-table-row v-if="tax.type === 'percentage' && tax.percentageType === 'base'" v-bind:key="tax.id">
                                            <md-table-cell>{{tax.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100)}}</md-table-cell>
                                            <md-table-cell class="text-right">{{!tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                            <md-table-cell class="text-right">{{tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable)}}</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseTaxable)}}</b></md-table-cell>
                                    </md-table-row>

                                </template>

                                <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Addons and miscellaneous</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template v-if="rateData.addons && rateData.addons.length > 0">
                                    <md-table-row v-for="addon in rateData.addons" v-bind:key="addon.id">
                                        <md-table-cell>{{addon.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.qty}}</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.price}}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.price * addon.qty}}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row v-if="rateData.extras.accessible">
                                    <md-table-cell>Accessible</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.extras.childSeat">
                                    <md-table-cell>Child Seat</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.extras.meetAndGreet">
                                    <md-table-cell>Meet and Greet</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                                </md-table-row>
                                <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable ) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterAddonsTaxable )}}</b></md-table-cell>
                                    </md-table-row>
                                </template>

                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Tolls and fees</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <template v-for="tax in rateData.taxes">
                                    <md-table-row  v-if="tax.type === 'fixed'" v-bind:key="tax.id">
                                        <md-table-cell>{{tax.name}}</md-table-cell>
                                        <md-table-cell class="text-right">1</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{!tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                    </md-table-row>
                                    </template>
                                </template>
                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesNoTaxable) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesTaxable) }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                            
                            
                                <template>
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Applied on total tax</md-table-cell>
                                    </md-table-row>
                                    <md-table-row>
                                        <md-table-cell>Sale tax</md-table-cell>
                                        <md-table-cell class="text-right">{{ reservation.companyProfile.saleTax }}%</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template >
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterSaleTaxNoTaxable) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterSaleTaxTaxable)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row>
                                    <md-table-cell>Credit card fee</md-table-cell>
                                    <md-table-cell class="text-right">{{ reservation.companyProfile.creditCardFee }}%</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}} / {{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}}</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                                </md-table-row>
                                <template>
                                    <md-table-row class="final-rows uplimo-blue">
                                        <md-table-cell><b>Subtotals</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.lastNoTaxable)}}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.lastTaxable)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <template>
                                    <md-table-row class="final-rows uplimo-blue">
                                        <md-table-cell><b>Total</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                               <template>
                                    <md-table-row class="final-rows uplimo-green">
                                        <md-table-cell><b>Paid</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ totalTransactionsPaidAmount }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <template>
                                    <md-table-row class="final-rows uplimo-yellow">
                                        <md-table-cell><b>Balance</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <!--template>
                                    <md-table-row class="final-rows uplimo-red">
                                        <md-table-cell><b>Collect Balance</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                    </md-table-row>
                                </template-->
                            </md-table>
                            <md-button class="md-primary md-raised full-width-button" @click="openPaymentDialog()" :disabled="roundNumber(rateData.price - totalTransactionsPaidAmount) == 0">Collect balance <b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-button>
                        </md-card-content>
                    </md-card>             
                </md-tab>
                <md-tab id="passenger" md-label="Passenger" :md-template-data="{ badge: tabs.passenger.errors }">
                    <CRow>
                        <CCol sm="12" md="12" lg="6">
                            <CRow>
                                <CCol sm="12">
                                    <md-card class="uplimo-card">
                                        <md-card-header>
                                            <div class="md-title">Passengers</div>
                                        </md-card-header>

                                        <md-card-content>
                                            <md-field :class="getValidationClass('firstName')">
                                                <label>Passenger´s first name</label>
                                                <md-input v-model="reservation.passenger.firstName"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.firstName.required">First name is required</span>
                                            </md-field>
                                            <md-field :class="getValidationClass('lastName')">
                                                <label>Passenger´s last name</label>
                                                <md-input v-model="reservation.passenger.lastName"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.lastName.required">Last name is required</span>
                                            </md-field>
                                            <md-field :class="getValidationClass('email')">
                                                <label>Email address</label>
                                                <md-input v-model="reservation.passenger.email"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.email.required">Email is required</span>
                                                <span class="md-error" v-if="!$v.reservation.passenger.email.email">Invalid email</span>
                                            </md-field>
                                            <md-field :class="getValidationClass('phone')">
                                                <label>Passenger´s phone</label>
                                                <md-input type="tel" v-model="reservation.passenger.phone"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.phone.required">Phone is required</span>
                                            </md-field>
                                            <md-field>
                                                <label>Additional phone</label>
                                                <md-input type="tel" v-model="reservation.passenger.additionalPhone"></md-input>
                                            </md-field>
                                            
                                        </md-card-content>
                                    </md-card>
                                    
                                </CCol>
                                <CCol sm="12">
                                    
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </md-tab>
                <md-tab id="payment-method" md-label="Payment" :md-template-data="{ badge: tabs.paymentMethod.errors }">
                    
                   
                    <CRow>
                        <CCol sm="12" md="12" lg="8" >
                             <md-card class="uplimo-card" v-if="reservation.transactions">
                                <md-card-header>
                                    <div class="md-title">Payment history</div>
                                    <div class="mt-4">Payment Status: 
                                        
                                       <span class="bubble ml-2 paid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Paid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                       <span class="bubble ml-2 unpaid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Unpaid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                       <span class="bubble ml-2 partially-paid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Partially paid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                        
                                       
                                    
                                    </div>
                                     <!--md-field v-show="payment.paymentMethod === 'cash' || payment.paymentMethod === 'check'">
                        <label>Payment status</label>
                        <md-select v-model="reservation.paymentStatus" name="payment_status" id="payment_status" placeholder="Payment status">
                            <md-option v-for="status in paymentStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                        </md-select>
                    </md-field-->
                                </md-card-header>

                                <md-card-content>
                                    <md-empty-state
                                        v-if="reservation.transactions.length == 0"
                                        class="md-primary"
                                        md-icon="receipt"
                                        md-label="No payments were made"
                                        md-description="Select your payment method and submit payment">
                                    </md-empty-state>
                                    <md-table
                                        v-if="reservation.transactions.length > 0"
                                    >
                                        <md-table-row>
                                            <md-table-head>Id</md-table-head>
                                            <md-table-head>Datetime</md-table-head>
                                            <md-table-head>Method</md-table-head>
                                            <md-table-head>Amount</md-table-head>
                                            <md-table-head>Status</md-table-head>
                                            <md-table-head>Creator</md-table-head>
                                            <md-table-head>Action</md-table-head>
                                        </md-table-row>
                                        <md-table-row v-for="(transaction, index) in reservation.transactions" v-bind:key="index + '-transaction'">
                                            <md-table-cell>TR-{{index + 1}}</md-table-cell>
                                            <md-table-cell>{{ transaction.datetime | moment("LLLL") }} </md-table-cell>
                                            <md-table-cell>{{transaction.type}}</md-table-cell>
                                            <md-table-cell class="text-right"><span class="md-subheading">{{transaction.amount}}</span></md-table-cell>
                                            <md-table-cell>
                                                
                                                <md-chip class="mini uplimo-green" v-if="transaction.status == 'SUCCESS' && transaction.action == 'collect'">Collected</md-chip>
                                                <md-chip class="mini uplimo-red" v-if="transaction.status == 'SUCCESS'  && !transaction.partial && transaction.action == 'refund'">Refunded</md-chip>
                                                <md-chip class="mini uplimo-red" v-if="transaction.status == 'SUCCESS' && transaction.refunded">Refunded</md-chip>
                                                <md-chip class="mini uplimo-yellow" v-if="transaction.status == 'SUCCESS' && transaction.partial && transaction.action == 'refund'">Partial refund</md-chip>
                                                <md-chip class="mini  uplimo-yellow" v-if="transaction.status == 'SUCCESS' && transaction.partiallyRefunded">Partially refunded</md-chip>
                                               
                                                
                                            </md-table-cell>
                                            <md-table-cell>{{transaction.creator.email}}</md-table-cell>
                                            <md-table-cell>
                                                <!--md-button class="md-raised full-width-button" v-if="transaction.type === 'cash'" @click="refundPayment(transaction.type, 'TR-' + (index + 1))">Delete</md-button-->
                                                <md-button class="md-raised md-primary full-width-button" v-if="transaction.action === 'collect' && !transaction.refunded" @click="openRefundDialog(transaction,'TR-' + (index + 1))">Refund</md-button>
                                                <md-button class="full-width-button md-accent" :disabled="true" v-if="transaction.action === 'refund'">No possible action</md-button>

                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </md-card-content>
                            </md-card>
                            
                        </CCol>
                        <CCol sm="12" md="12" lg="4" >
                            <md-card class="uplimo-card" v-if="rateData">
                                <md-card-header>
                                    <div class="md-title">Rate resume</div>
                                </md-card-header>

                                <md-card-content>
                                    <md-table>
                                        <template>
                                            <md-table-row class="final-rows uplimo-blue">
                                                <md-table-cell><b>Total</b></md-table-cell>
                                               
                                                <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                         <template>
                                            <md-table-row class="final-rows uplimo-green">
                                                <md-table-cell><b>Paid</b></md-table-cell>
                                               
                                                <md-table-cell class="text-right"><b>{{ roundNumber(totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                        <template>
                                            <md-table-row class="final-rows uplimo-yellow">
                                                <md-table-cell><b>Balance</b></md-table-cell>
                                                
                                                <md-table-cell class="text-right"><b>{{ roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                        <!--template>
                                            <md-table-row class="final-rows uplimo-red">
                                                <md-table-cell><b>Collect Balance</b></md-table-cell>
                                                <md-table-cell class="text-right">-</md-table-cell>
                                                <md-table-cell class="text-right">-</md-table-cell>
                                                <md-table-cell class="text-right">-   
                                                </md-table-cell>
                                                <md-table-cell class="text-right"><b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template-->
                                    </md-table>
                                     <md-button class="md-primary md-raised full-width-button" @click="openPaymentDialog()" :disabled="roundNumber(rateData.price - totalTransactionsPaidAmount) == 0">Collect balance <b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-button>

                                </md-card-content>
                            </md-card>
                        </CCol>
                        
                    </CRow>
                </md-tab>
                <md-tab id="emails" md-label="Emails" :md-template-data="{ badge: tabs.emails.errors }">
                    <md-toolbar>
                        <div class="md-toolbar-row">
                            <h3 class="md-title flex-1">Email notifications actions</h3>
                            <!--md-button class="md-raised">Start notification scheduling</md-button-->
                        </div>
                    </md-toolbar >
                    <md-toolbar md-elevation="0" class="mb-3">
                        <md-button class="md-raised" @click="openMailingModal('Confirmation')">Confirmation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Receipt')">Receipt</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Cancellation')">Cancellation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Refund')">Refund</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Driver assignment request')">Driver assignment request</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Driver assignation')">Driver assignation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Quotation')">Quotation</md-button>
                    </md-toolbar>
                    
                    <md-empty-state
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="schedule_send"
                        md-label="Email log"
                        md-description="All sent emails related to this reservation will be displayed here."
                        v-if="reservation.mails.length == 0"
                    >
                    </md-empty-state>
                    <md-table md-card v-else>
                        <md-table-toolbar>
                            <h2 class="md-subheading">Sent notifications</h2>
                        </md-table-toolbar>
                        <md-table-row>
                            
                            <md-table-head>Mail type</md-table-head>
                            <md-table-head>Recipient type</md-table-head>
                            <md-table-head>To</md-table-head>
                            <md-table-head>Subject</md-table-head>
                            <md-table-head>Status</md-table-head>
                            <md-table-head >Date</md-table-head>
                        </md-table-row>

                        <md-table-row v-for="(mail,index) in reservation.mails" v-bind:key="'mail' + index">
                            
                            <md-table-cell class="text-capitalize">{{mail.mailType}}</md-table-cell>
                            <md-table-cell class="text-capitalize">{{mail.recipientType}}</md-table-cell>
                            <md-table-cell>{{mail.to}}</md-table-cell>
                            <md-table-cell>{{mail.message.subject}}</md-table-cell>
                            <md-table-cell>{{mail.delivery.state}}</md-table-cell>
                            <md-table-cell>{{ mail.delivery.endTime.seconds | moment("LLLL (HH:mm [HS])") }}</md-table-cell>
                            
                        </md-table-row>
                    </md-table>
                </md-tab>
                <md-tab id="sms" md-label="SMS" :md-template-data="{ badge: tabs.sms.errors }">
                    <md-toolbar>
                        <div class="md-toolbar-row">
                            <h3 class="md-title flex-1">SMS notifications actions</h3>
                            <!--md-button class="md-raised">Start notification scheduling</md-button-->
                        </div>
                    </md-toolbar >
                    <md-toolbar md-elevation="0" class="mb-3">
                        <md-button class="md-raised" @click="openSMSModal('Confirmation')">Confirmation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Receipt')">Receipt</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Cancellation')">Cancellation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Refund')">Refund</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Driver assignment request')">Driver assignment request</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Driver assignation')">Driver assignation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Quotation')">Quotation</md-button>
                    </md-toolbar>
                    
                    <md-empty-state
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="schedule_send"
                        md-label="SMS log"
                        md-description="All sent SMS related to this reservation will be displayed here."
                        v-if="reservation.sms.length == 0"
                    >
                    </md-empty-state>
                    <md-table md-card v-else>
                        <md-table-toolbar>
                            <h2 class="md-subheading">Sent notifications</h2>
                        </md-table-toolbar>
                        <md-table-row>
                            
                            <md-table-head>Sms type</md-table-head>
                            <md-table-head>Recipient type</md-table-head>
                            <md-table-head>To</md-table-head>
                            <md-table-head>Text</md-table-head>
                            <md-table-head>Status</md-table-head>
                            <md-table-head >Date</md-table-head>
                        </md-table-row>

                        <md-table-row v-for="(sms,index) in reservation.sms" v-bind:key="'mail' + index">
                            
                            <md-table-cell class="text-capitalize">{{sms.smsType}}</md-table-cell>
                            <md-table-cell class="text-capitalize">{{sms.recipientType}}</md-table-cell>
                            <md-table-cell>{{sms.to}}</md-table-cell>
                            <md-table-cell>{{sms.text}}</md-table-cell>
                            <md-table-cell>{{sms.delivery.state}}</md-table-cell>
                            <md-table-cell>{{ sms.delivery.endTime | moment("LLLL (HH:mm [HS])") }}</md-table-cell>
                            
                        </md-table-row>
                    </md-table>
                </md-tab>
            </md-tabs>
            <!--md-dialog-confirm
                :style="[dialogDimensions]"
                :md-active.sync="confirmDialog"
                md-title="Do you want to leave without saving?"
                md-content="All changes made will not be save"
                md-confirm-text="Leave without saving"
                md-cancel-text="Continue editing"
                @md-cancel="onCancel"
                @md-confirm="onConfirm" /-->
            <md-dialog
                :style="[dialogDimensions]"
                :md-active.sync="confirmDialog"
            >
                <md-dialog-title>What would you like to do ?</md-dialog-title>
                <md-dialog-content>
                    <p>You made changes in this reservation. Choose your action </p>
                    <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                    <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                    <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save &amp; exit</md-button>
                </md-dialog-content>

                
                
            </md-dialog>
            <md-dialog :md-active.sync="showDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="true">
                <md-dialog-title class="md-body-1">{{mailingInfo.title}} <div class="md-caption">Mail config</div></md-dialog-title>
                <!-- Content -->
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-list >
                        <md-subheader>Recipients</md-subheader>
                        <md-list-item v-if="mailingInfo.recipients.passenger.active">
                            <md-checkbox v-model="mailingInfo.recipients.passenger.send"  />
                            <div class="md-list-item-text">
                                <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                <span class="md-caption">Passenger</span>
                            </div>
                            
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.owner.active">
                            <md-checkbox v-model="mailingInfo.recipients.owner.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.companyProfile ? reservation.companyProfile.mailList : 'Owner email'}}</span>
                                <span class="md-caption">Owner</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.driver.active">
                            <md-checkbox v-model="mailingInfo.recipients.driver.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver email'}}</span>
                                <span class="md-caption">Driver</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.user.active">
                            <md-checkbox v-model="mailingInfo.recipients.user.send" />
                            <div class="md-list-item-text">
                                <span>{{user.data ? user.data.email : ''}} <b> (me)</b></span>
                                <span class="md-caption">User</span>
                            </div>
                        </md-list-item>
                    </md-list>
                    
                    
                </div>   
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>Mail Subject</label>
                        <md-input v-model="mailingInfo.subject"></md-input>
                    </md-field>
                </div>
                <md-divider class="mb-3"></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>Mail body</label>
                        <md-textarea v-model="mailingInfo.body"></md-textarea>
                    </md-field>
                </div>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showDialog = false; isBusy = false">Close</md-button>
                    <md-button class="md-primary" @click="fireNotifications">Send</md-button>
                </md-dialog-actions>
            </md-dialog>
            <md-dialog :md-active.sync="showSMSDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="true">
                <md-dialog-title class="md-body-1">{{smsInfo.title}} <div class="md-caption">SMS config</div></md-dialog-title>
                <!-- Content -->
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-list >
                        <md-subheader>Recipients</md-subheader>
                        <md-list-item v-if="smsInfo.recipients.passenger.active">
                            <md-checkbox v-model="smsInfo.recipients.passenger.send"  />
                            <div class="md-list-item-text">
                                <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger phone'}}</span>
                                <span class="md-caption">Passenger</span>
                            </div>
                            
                        </md-list-item>
                        <md-list-item v-if="smsInfo.recipients.owner.active">
                            <md-checkbox v-model="smsInfo.recipients.owner.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.companyProfile ? reservation.companyProfile.smsPhoneTarget : 'Owner email'}}</span>
                                <span class="md-caption">Owner</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="smsInfo.recipients.driver.active">
                            <md-checkbox v-model="smsInfo.recipients.driver.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver email'}}</span>
                                <span class="md-caption">Driver</span>
                            </div>
                        </md-list-item>
                       
                    </md-list>
                    
                    
                </div>   
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>SMS text</label>
                        <md-input v-model="smsInfo.text"></md-input>
                    </md-field>
                </div>
                <md-divider class="mb-3"></md-divider>
               
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showSMSDialog = false; isBusy = false">Close</md-button>
                    <md-button class="md-primary" @click="fireSMSNotifications">Send</md-button>
                </md-dialog-actions>
            </md-dialog>
            <md-dialog :md-active.sync="transactionDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="false" class="free-dialog">
               
                <div v-if="submitingPayment">
                    <div class="up-modal-content md-alignment-center-center mt-4 mb-4 text-center">
                        <p class="md-title">Submitting Payment</p>
                        <md-progress-spinner class="md-accent mt-3" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
                   
                   
                <md-dialog-title class="md-body-1" v-if="!submitingPayment">{{smsInfo.title}} <div class="md-caption">Payment method</div></md-dialog-title>
                <!-- Content -->
                <md-divider v-if="!submitingPayment"></md-divider>
                <div class="up-modal-content" v-if="!submitingPayment">
                
                    <div class="amount-to-pay" v-show="reservation.price">
                        <div class="title">Amount to pay</div>
                        <div class="amount">$ {{reservation.price}}</div>
                    </div>
                    
                    <!--md-field v-show="payment.paymentMethod === 'cash' || payment.paymentMethod === 'check'">
                        <label>Payment status</label>
                        <md-select v-model="reservation.paymentStatus" name="payment_status" id="payment_status" placeholder="Payment status">
                            <md-option v-for="status in paymentStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                        </md-select>
                    </md-field-->
                    
                    <div class="row">
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="credit-card" @change="paymentMethodChanged">Credit card</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="cash" @change="paymentMethodChanged">Cash</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="check" @change="paymentMethodChanged">Check</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="invoice" @change="paymentMethodChanged">Invoice</md-radio>
                        </div>
                    </div>
                    
                    
                    
                    <md-field v-if="payment.paymentMethod == 'credit-card'">
                    
                        <label>Merchant accounts</label>
                        <md-select v-model="payment.merchantAccount" name="merchant_account" id="merchant_account" placeholder="Merchant account">
                            <md-option v-for="merchant in merchantAccounts" :value="merchant.id" v-bind:key="merchant.id">{{merchant.name}}</md-option>
                        </md-select>
                    </md-field>
                    <md-tabs id="payment-method" ref="tabs"  :md-active-tab="paymentMethodActiveTab" :md-dynamic-height="true" @md-changed="tabChanged">
                        <md-tab id="cash" md-label="cash">
                            <md-field :class="getValidationClass('paidAmount')">
                                <md-icon>account_balance_wallet</md-icon>
                                <label>Amount to collect</label>
                                <md-input 
                                        min="10"
                                        type="number"
                                        v-model="payment.cash.paidAmount"   
                                ></md-input>
                                <span class="md-error" v-if="!$v.payment.cash.paidAmount.required">Amount to collect is required</span>
                                <span class="md-error" v-if="!$v.payment.cash.paidAmount.minValue">Min transaction value is 1</span>
                            </md-field>
                        </md-tab>
                        <md-tab id="credit-card" md-label="Credit card">
                            <md-field :class="getValidationClass('cardToPayAmount')">
                                <label>Amount to collect</label>
                                <md-input 
                                        type="number"
                                        v-model="cardToPayAmount"   
                                ></md-input>
                                <span class="md-error" v-if="!$v.cardToPayAmount.required">Amount to collect is required</span>
                                <span class="md-error" v-if="!$v.cardToPayAmount.minValue">Min transaction value is 1</span>
                            </md-field>
                            <vue-paycard 
                                :value-fields="payment.cardFields" 
                                :input-fields="inputFields" 
                                :is-card-number-masked="false" />
                            <md-field :class="getValidationClass('cardNumber')" :md-counter="false" style="margin-top:40px">
                                <md-icon>credit_card</md-icon>
                                <label>Credit card number</label>
                                <md-input  type="tel"
                                        :value="payment.cardFields.cardNumber"
                                        @keyup="changeNumber"
                                        data-card-field
                                        autocomplete="off"
                                        :id="inputFields.cardNumber"
                                        :maxlength="cardNumberMaxLength"
                                        
                                        
                                ></md-input>
                                <span class="md-error" v-if="!$v.payment.cardFields.cardNumber.required">Card number is required</span>
                            </md-field>

                            <md-field :class="getValidationClass('cardName')">
                                <md-icon>person_outline</md-icon>
                                <label>Name on card</label>
                                <md-input  type="text"
                                        :id="inputFields.cardName"
                                            title="Name on card"
                                            v-letter-only
                                            :value="payment.cardFields.cardName"
                                            @keyup="changeName"
                                            data-card-field
                                            autocomplete="off"
                                        
                                        
                                ></md-input>
                                <span class="md-error" v-if="!$v.payment.cardFields.cardName.required">Card name is required</span>
                            </md-field>
                            <div class="row">
                                <div class="col-sm-4">
                                    <md-field class="expiration-month" :class="[getValidationClass('cardMonth'),getValidationClass('cardYear')]">
                                        <md-icon>event</md-icon>
                                        <label>Expiration date</label>
                                        <md-select md-dense 
                                                    :id="inputFields.cardMonth"
                                                    aria-label="Card Month"
                                                    title="Month"
                                                    v-model="payment.cardFields.cardMonth"
                                                    data-card-field
                                                    
                                        >
                                            <md-option value disabled selected>Month</md-option>
                                            <md-option
                                                v-bind:value="n < 10 ? '0' + n : n"
                                                v-for="n in 12"
                                                v-bind:disabled="n < minCardMonth"
                                                v-bind:key="n"
                                            >{{generateMonthValue(n)}}</md-option>
                                            
                                        </md-select>
                                        <md-select md-dense 
                                                    :id="inputFields.cardYear"
                                                    aria-label="Card year"
                                                    title="Year"
                                                    v-model="payment.cardFields.cardYear"
                                                    data-card-field
                                                    
                                        >
                                            <md-option value disabled selected>Year</md-option>
                                            <md-option
                                                v-bind:value="$index + minCardYear"
                                                v-for="(n, $index) in 12"
                                                v-bind:key="n"
                                            >{{$index + minCardYear}}</md-option>
                                            
                                        </md-select>
                                        <span class="md-error" v-if="!$v.payment.cardFields.cardMonth.required || !$v.payment.cardFields.cardYear.required">Card expiration is required</span>
                                        
                                    </md-field>
                                </div>
                                <div class="col-sm-4">
                                    <md-field :md-counter="false" :class="getValidationClass('cardCvv')">
                                        <md-icon>https</md-icon>
                                        <label>CVV</label>
                                        <md-input 
                                                    type="tel"
                                                    title="CVV"
                                                    v-number-only
                                                    :id="inputFields.cardCvv"
                                                    maxlength="4"
                                                    :value="payment.cardFields.cardCvv"
                                                    @keyup="changeCvv"
                                                    data-card-field
                                                    autocomplete="off"
                                                
                                                
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.cardFields.cardCvv.required">Card CVV is required</span>
                                    </md-field>
                                </div>
                                <div class="col-sm-4">
                                    <md-field :class="getValidationClass('billZip')">
                                        <md-icon>home</md-icon>
                                        <label>Bill Zip code</label>
                                        <md-input 
                                                type="tel"
                                                title="Zip code"
                                                v-number-only
                                                v-model="payment.cardFields.billZip"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.cardFields.billZip.required">Billing ZIP is required</span>
                                    </md-field>
                                </div>
                            </div>
                            <md-field :class="getValidationClass('billingAddress')">
                                <md-icon>map</md-icon>
                                <label>Billing address</label>
                                <md-input 
                                        title="Billing address"
                                        v-model="payment.cardFields.billingAddress"
                                ></md-input>
                                <span class="md-error" v-if="!$v.payment.cardFields.billingAddress.required">Billing address is required</span>
                            </md-field>
                        </md-tab>
                        <!-- Check    -Company Name, -Address, -Routing #, -Account # -->
                        <md-tab id="check" md-label="check">
                            <div class="row">
                                <div class="col-sm-12">
                                    <md-field :class="getValidationClass('check.companyName')">
                                        <md-icon>business</md-icon>
                                        <label>Company name</label>
                                        <md-input 
                                                v-model="payment.check.companyName"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.companyName.required">Company name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.address')">
                                        <md-icon>room</md-icon>
                                        <label>Address</label>
                                        <md-input 
                                                v-model="payment.check.address"   
                                        ></md-input>
                                    <span class="md-error" v-if="!$v.payment.check.address.required">Address is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.routing')">
                                        <md-icon>account_balance</md-icon>
                                        <label>Routing #</label>
                                        <md-input 
                                                v-model="payment.check.routing"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.routing.required">Routing # is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.account')">
                                        <md-icon >account_balance</md-icon>
                                        <label>Account #</label>
                                        <md-input 
                                                v-model="payment.check.account"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.account.required">Account # is required</span>
                                    </md-field>

                                </div>
                            </div>
                        
                        </md-tab>
                        <md-tab id="invoice" md-label="invoice">
                            <div class="row">
                                <div class="col-sm-12">
                                    <md-field :class="getValidationClass('invoice.companyName')">
                                        <md-icon>business</md-icon>
                                        <label>Company name</label>
                                        <md-input 
                                                v-model="payment.invoice.companyName"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.companyName.required">Company name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.address')">
                                        <md-icon>room</md-icon>
                                        <label>Address</label>
                                        <md-input 
                                                v-model="payment.invoice.address"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.address.required">Address is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.ein')">
                                        <md-icon>account_balance</md-icon>
                                        <label>EIN #</label>
                                        <md-input 
                                                v-model="payment.invoice.ein"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.ein.required">EIN is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.email')">
                                        <md-icon>mail</md-icon>
                                        <label>Email</label>
                                        <md-input 
                                                v-model="payment.invoice.email"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.email.required">Email is required</span>
                                    </md-field>

                                </div>
                            </div>
                        </md-tab>
                    </md-tabs>
                   
                    
                </div>    
                    
                <md-dialog-actions class="fixed-bottom-dialog" v-if="!submitingPayment">
                    <md-button class="md-primary md-raised full-width-button" @click="submitPayment">Submit payment</md-button>
                    <md-button class="md-raised full-width-button" @click="transactionDialog = false">Close</md-button>
                </md-dialog-actions> 
                
               
            </md-dialog>
            <md-dialog :md-active.sync="refundDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="false" class="free-dialog">
                 <div v-if="submitingRefund">
                    <div class="up-modal-content md-alignment-center-center mt-4 mb-4 text-center">
                        <p class="md-title">Submitting refund</p>
                        <md-progress-spinner class="md-accent mt-3" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
                <md-dialog-title class="md-title" v-if="!submitingRefund"><b>{{refund.actualId}}</b> Refund details</md-dialog-title>
                <div class="up-modal-content" v-if="!submitingRefund && refund.actualTransaction">
                    
                         <md-field :class="getValidationClass('refund.partial')">
                            <label for="refund-type">Refund type</label>
                            <md-select v-model="refund.partial" name="refund-type" id="refund-type" :disabled="refund.actualTransaction.partiallyRefunded">
                                <md-option :value="true">Partial</md-option>
                                <md-option :value="false">Total</md-option>
                                
                            </md-select>
                            <span class="md-error" v-if="!$v.refund.partial.required">Refund type is required</span>

                        </md-field>
                         <md-field :class="getValidationClass('refund.amount')">
                            <label>Amount to refund {{refund.partial ? '(Partial)': '(Total)'}}</label>
                            <md-input v-model="refund.amount" type="number" step="5" :disabled="!refund.partial"></md-input>
                            <span class="md-error" v-if="!$v.refund.amount.required">Amount is required</span>
                            <span class="md-error" v-if="!$v.refund.amount.minValue">Min refund value is 1</span>
                            <span class="md-error" v-if="!$v.refund.amount.maxValue">Max value is {{refund.maxPossibleValue}}</span>
                            
                        </md-field>
                        <md-button class="md-primary md-raised full-width-button" @click="refundPayment(refund.actualTransaction.type,refund.actualId,refund.actualTransaction)">Refund</md-button>
                   
                </div>
            </md-dialog>
            <md-snackbar md-position="center" :md-duration="snackbar.isInfinity ? Infinity : snackbar.duration" :md-active.sync="snackbar.show" md-persistent>
                <span>{{ snackbar.message }}</span>
                <md-button class="md-primary" @click="snackbar.show = false">Ok</md-button>
            </md-snackbar>
            <!--transition name="fade">
                <div class="bottom-el" :style="[dialogDimensions]" v-if="rateData && $refs.mainTabs.activeTab == 'rates-and-taxes'"> 
                    <md-bottom-bar>
                        <md-toolbar class="md-dense md-primary" md-elevation="1" v-if="rateData">
                            <h3 class="md-body-2 mb-0" style="flex: 1">Grand total </h3>
                            <h4 class="mb-0">${{roundNumber(rateData.price)}}</h4>
                        </md-toolbar>
                    </md-bottom-bar>
                </div>
            </transition-->  
        </div>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import DirectionsRenderer from './../maps/DirectionsRenderer';
import { validationMixin } from 'vuelidate'
import FeeCalculator from './FeeCalculator.vue';
import firebase from '../../Firebase'
import * as VueGoogleMaps from 'vue2-google-maps'
import {
    between,
    required,
    email,
    minLength,
    //maxLength,
    minValue,
    maxValue,
    requiredIf
} from 'vuelidate/lib/validators'
import { brandSet } from '@coreui/icons';
import { VuePaycard } from 'vue-paycard'

import axios from "axios"
import axiosApi from "./../../helpers/axios";
import moment from 'moment'
import moment_timezone from "moment-timezone";
import Vue from 'vue';

export default {
    name: 'Reservation',
    mixins: [validationMixin],
    components: {
        DirectionsRenderer,
        VueTimepicker,
        VuePaycard,
        FeeCalculator
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                event.target.value = event.target.value.replace(/[^0-9]/g, '')
                if (event.charCode >= 48 && event.charCode <= 57) {
                    return true
                }
                event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                if (event.charCode >= 48 && event.charCode <= 57) {
                    event.preventDefault()
                }
                return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    data() {
        return {
            possibleFlights: [],
            refund: {
                partial: false,
                amount: null,
                actualTransaction: null,
                actualId: null,
                maxPossibleValue: null
            },
            submitingRefund:false,
            refundDialog: false,
            transactionDialog: false,
            totalTransactionsPaidAmount: 0,
            submitingPayment: false,
            cardToPayAmount:null,
            resReadedFirstSnap: false,
            snackbar: {
                show: false,
                duration: 4000,
                isInfinity: false,
                message:null

            }, 
            notificationPack: {
                types: []
            },
            mailTypes: [
                'confirmation',
                'receipt',
                'cancellation',
                'driverAssignation',
                'driverAssignmentRequest',
                'refund',
                'quotation'
            ],
            mailingInfo: {
                title: null,
                recipients: {
                    passenger: {
                        send: false,
                        active: false
                    },
                    driver: {
                        send: false,
                        active: false
                    },
                    owner: {
                        send: false,
                        active: false
                    },
                    user: {
                        send: false,
                        active: false
                    },
                    others: []
                },
                subject: null,
                body: null,
            },
            smsInfo: {
                title: null,
                recipients: {
                    passenger: {
                        send: false,
                        active: false
                    },
                    driver: {
                        send: false,
                        active: false
                    },
                    owner: {
                        send: false,
                        active: false
                    },
                    user: {
                        send: false,
                        active: false
                    },
                    others: []
                },
                text: null,
                body: null,
            },
            isBusy: true,
            companyActualDateTime: null,
            timezoneOffsetDif: null,
            viewerTimezonePosition: null,
            overridedRateData:null,
            rateData: null,
            mainActiveTab: 'date-time-location',
            //mainActiveTab: 'payment-method',
            paymentMethodActiveTab: 'credit-card',
            tabs: {
                general: {
                    errors: null,
                },
                dateTimeLocation: {
                    errors: null,
                },
                dispatchmentInfo: {
                    errors: null,
                },
                addons: {
                    errors: null,
                },
                ratesAndTaxes: {
                    errors: null,
                },
                passenger: {
                    errors: null,
                },
                paymentMethod: {
                    errors: null,
                },  
                emails: {
                    errors: null,
                },
                sms: {
                    errors: null,
                }
            },
            filteredVehicleTypes: [],
            filteredAddons:[],
            selectedData: {
                companyProfile: null,
                datetime:null,
                jobType: null,
                vehicleType: undefined,
                passengers:null,
                luggage:null,
                dispatcher:null,
                addOns: [],
                extras: {
                    childSeat: null,
                    accessible: null,
                    meetAndGreet: null
                }
            },
            feeCalculatorData: {
                jobType: {
                    name: null,
                    type: null
                },
                vehicleType: {
                    
                },
                overviewPath:[],
                addOns:[],
                extras: null,
                promoCode:null
                
            },
            loadingFlight: false,
            loadingPromoCode: false,
            serverError: true,
            airlines: [],
            airlinesList: this.$airlines,
            airportsList: this.$airports,
            inputFields: {
                cardNumber: 'v-card-number',
                cardName: 'v-card-name',
                cardMonth: 'v-card-month',
                cardYear: 'v-card-year',
                cardCvv: 'v-card-cvv'
            },
            minCardYear: new Date().getFullYear(),
            mainCardNumber: '',
            cardNumberMaxLength: 19,
            dialogLeft: 0,
            step: "general",
            labelWrittenItems: [],
            labelFocusedItems: [],
            waypointsPack: [],
            activeStopIndex: null,
            actualStopAddress: null,
           
            date: new Date(),
            dispatchers: [],
            companyProfiles: [],
            vehicleTypes: [],
            addOns: [],
            jobTypes: [],
            serviceTypes: [],
            reservationStatuses: [],
            driverStatuses: [],
            paymentStatuses: [],
            tripStatuses: [],
            merchantAccounts: [],
            payment: {
                cash: {
                    paidAmount: null
                },
                invoice: {
                    companyName: null,
                    address: null,
                    ein: null,
                    email: null
                },
                check: {
                    companyName: null,
                    address: null,
                    routing: null,
                    account: null
                },
                cardFields: {
                    cardName: '',
                    cardNumber: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                    billZip: '',
                    billingAddress: '',
                },
                merchantAccount: null,
                paymentMethod: 'credit-card'
            },
            //reservation: {
                // passenger: {
                //     firstName: '',
                //     lastName:'',
                //     email:'',
                //     phone:'',
                //     additionalPhone: '',
                //     qty: null,
                //     luggage:null
                // },
                // do: {
                //     formatted_address: '',
                //     postalCode: null,
                //     coordinates: {
                //         lat: null,
                //         lng: null
                //     }
                // },
                // pu: {
                //     formatted_address: '',
                //     postalCode: null,
                //     coordinates: {
                //         lat: null,
                //         lng: null
                //     }
                // },
                // waypoints: [],
            //} ,
            unsub:null,
            reservation: null,
            ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
            //dispatchRef: this.$firebase.firestore().collection('dispatchers'),
            vehicleRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
            jobTypesRef:  this.$firebase.firestore().collection('job-types'),
            statusesRef: this.$firebase.firestore().collection('statuses'),
            addOnsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
            lastIdRef: this.$firebase.firestore().collection('res-last-id'),
            lastId: null,
            coordinates: {
                lat: 37.09024,
                lng:-95.712891
            },
            markers: [],
            showDialog: false,
            showSMSDialog: false,
            isEditing: false,
            confirmDialog: false,
            goTo: null,
            confirmLeave: false
            // start: "",
            // end: "",
           
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
    },
    beforeRouteLeave(to, from, next) {
   
        if (this.isEditing && !this.confirmLeave) {
            this.confirmDialog = true;
            console.log(to);
            this.goTo = to;
            return 
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
    beforeDestroy() {
       window.removeEventListener("beforeunload", this.preventNav);
    },
    mounted(){
        this.$store.commit('centerDialog',window.innerWidth)
        this.getAirlines();
        //this.obtainViewResources();
    },
    watch: {

        cardYear () {
            if (this.payment.cardFields.cardMonth < this.minCardMonth) {
                this.payment.cardFields.cardMonth = ''
            }
        },
        'reservation': {
            handler: function(val, oldVal) {
                
                if(val && oldVal && val.transactions.length == oldVal.transactions.length) {
                    
                    this.isEditing = true 
                }
                
            },
            deep:true
        },
        'selectedData.datetime': {
            handler: function(val,oldVal) {

                if(oldVal != null) {
                    // let localDateTime = moment.unix(val) ;
                    // console.log('localDateTime')
                    // console.log(localDateTime.format('LLLL'))
                    // let utcRealDateTime = localDateTime.utcOffset(this.timezoneOffsetDif).format()
                    // let utcCutOffset;

                    // console.log('this.timezoneOffsetDif');
                    // console.log(this.timezoneOffsetDif);
                    // if(this.timezoneOffsetDif === 0) {
                    //     utcCutOffset = utcRealDateTime
                    // } else {
                    //     utcCutOffset = utcRealDateTime.split('+')[0] + '+00:00'
                    // }

                    // console.log('utcCutOffset');
                    // console.log(utcCutOffset);

                    // let dbaDateTime = moment.utc(utcCutOffset);
                    // console.log('dbaDateTime')
                    // console.log(dbaDateTime.format('LLLL Z'))
                    // this.reservation.day = parseInt(dbaDateTime.format('X'))
                    let localDateTime = moment.unix(val) ;
                    let datePack = {
                        date: parseInt(localDateTime.format('D')),
                        month: parseInt(localDateTime.format('M')) - 1,
                        year:parseInt(localDateTime.format('YYYY')),
                        hour:parseInt(localDateTime.format('H')),
                        minute:parseInt(localDateTime.format('m')),
                    }

                    let companyProfileTime = moment().tz(this.reservation.companyProfile.timeZone).set(datePack)
                    let dbaTime = companyProfileTime.unix()
                   
                    this.reservation.day = dbaTime



                }
               
            }

        },
        'reservation.childSeat': {
            handler: function(val,oldVal) {
                this.selectedData.extras.childSeat = val;
            }
        },
        'reservation.accessible': {
            handler: function(val,oldVal) {
                this.selectedData.extras.accessible = val;
            }
        },
        'reservation.meetAndGreet': {
            handler: function(val,oldVal) {
                this.selectedData.extras.meetAndGreet = val;

            }
        },
        
        'selectedData.jobType': {
            handler: function(val,oldVal) {
                
                if(oldVal != null) {
                    this.rateData = null;
                    this.filteredVehicleTypes = [];
                    this.reservation.vehicleType = null;
                    this.selectedData.vehicleType = null;
                    this.feeCalculatorData.vehicleType = null;
                    for (let i = 0; i < this.serviceTypes.length; i++) {
                        if(this.serviceTypes[i].id === val) {
                                this.feeCalculatorData.jobType = this.serviceTypes[i]
                                this.reservation.jobType = this.serviceTypes[i]
                                //this.serviceTypes[i].vehicleType
                                for (let j = 0; j < this.serviceTypes[i].vehicleType.length; j++) {
                                    for (let k = 0; k < this.vehicleTypes.length; k++) {
                                        if(this.serviceTypes[i].vehicleType[j] == this.vehicleTypes[k].id ) {
                                            this.filteredVehicleTypes.push(this.vehicleTypes[k]);
                                        }
                                        
                                    }
                                    
                                }
                        }
                    }
                     this.validateAllSteps()
                } else {
                    

                }
               
            }   
        },
        'selectedData.companyProfile': {
            handler: function(val,oldVal) {
                for (let i = 0; i < this.companyProfiles.length; i++) {
                   if(this.companyProfiles[i].id === val) {
                        this.reservation.companyProfile = this.companyProfiles[i]
                        this.updateTime()
                        this.flowTimeZone();
                   }
                }
            }   
        },
        'selectedData.vehicleType': {
            handler: function(val,oldVal) {
                
                if(oldVal !== undefined) {
                    this.reservation.addOns = []
                    
                    for (let i = 0; i < this.vehicleTypes.length; i++) {
                        if(this.vehicleTypes[i].id === val) {
                                this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
                                this.reservation.vehicleType = this.vehicleTypes[i]
                                this.selectedData.passengers = this.vehicleTypes[i].passengers
                                this.selectedData.luggage = this.vehicleTypes[i].luggage


                                /////
                                let counter = 0;
                                if(this.reservation.vehicleType.rates.addons){
                                    for (let i = 0; i < this.reservation.vehicleType.rates.addons.length; i++) {
                                        if(this.reservation.vehicleType.rates.addons[i].enabled && this.reservation.vehicleType.rates.addons[i].price) {
                                            counter++;
                                        }
                                        
                                    }
                                }
                                console.log('Counter',counter);
                            
                                counter > 0 ? this.reservation.vehicleType.rates.addonsAvailable = true : this.reservation.vehicleType.rates.addonsAvailable = false
                                /////
                        }
                    }
                } else {
                    // for (let i = 0; i < this.vehicleTypes.length; i++) {
                    //     if(this.vehicleTypes[i].id === val) {
                    //             this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
                    //             this.reservation.vehicleType = this.vehicleTypes[i]
                    //             this.selectedData.passengers = this.vehicleTypes[i].passengers
                    //             this.selectedData.luggage = this.vehicleTypes[i].luggage
                    //     }
                    // }
                }
            }   
        },
        'selectedData.dispatcher': {
            handler: function(val,oldVal) {
                
                for (let i = 0; i < this.dispatchers.length; i++) {
                   if(this.dispatchers[i].uid === val) {
                        this.reservation.dispatcher = this.dispatchers[i]
                       
                   }
                }
            }   
        },
        'selectedData.addOns': {
            handler: function(val,oldVal) {
                console.log(val);
                let addOns = [];
                for (let i = 0; i < val.length; i++) {
                    
                    for (let j = 0; j < this.reservation.vehicleType.rates.addons.length; j++) {
                        if(val[i] == this.reservation.vehicleType.rates.addons[j].id) {
                            addOns.push(this.reservation.vehicleType.rates.addons[j]);
                        }
                        
                    }
                }
                this.reservation.addOns = addOns ;
                this.feeCalculatorData.addOns = addOns ;
                
            }   
        },
        'reservation.vehicleType.rates.addons' : {
            handler: function(val,oldVal) {
                
                
                if(val !== undefined) {
                    let addOns = [];
                    for (let i = 0; i < val.length; i++) {
                        if(parseFloat(val[i].qty) > 0) {
                            addOns.push(val[i])
                        }
                    }
                    this.reservation.addOns = addOns ;
                    this.feeCalculatorData.addOns = addOns ;
                }

            },
            deep: true
        },
        'reservation.day': {
            handler: function(){
                //this.initFlightCheck()
            }
        },

        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        },
        'refund.partial': {
            handler: function(val, oldVal){
                if(val) {
                    this.refund.amount = null;
                } else {
                    this.refund.amount = this.refund.actualTransaction.amount;
                }
            }
        },
        'refund.amount': {
            handler: function(val, oldVal){
                if(parseFloat(val) ===  parseFloat(this.refund.actualTransaction.amount)) {
                    this.refund.partial = false;
                }
            }
        }
        
        
    },
    methods: {
        validateRefund() {
            this.tabs.paymentMethod.errors = 0;
            let amount,partial;

            amount = this.$v.refund.amount.$invalid;
            partial = this.$v.refund.partial.$invalid;


            amount ? this.tabs.paymentMethod.errors++ : false;
            partial ? this.tabs.paymentMethod.errors++ : false;
            return !amount && !partial? true : false ;

        },
        openRefundDialog(transaction, id){

            this.refund.actualTransaction = transaction
            this.refund.amount = this.refund.partial ? null : this.refund.actualTransaction.amount
            this.refund.partial = this.refund.actualTransaction.partiallyRefunded
            let actualRefundAddintion = 0
            if(this.refund.actualTransaction.refunds) {
                for (let i = 0; i < this.refund.actualTransaction.refunds.length; i++) {
                    actualRefundAddintion += parseFloat(this.refund.actualTransaction.refunds[i])
                }
            }
            
            this.refund.maxPossibleValue = parseFloat(this.roundNumber(parseFloat(this.refund.actualTransaction.amount) -  actualRefundAddintion))
            this.refund.actualId = id;
            this.refundDialog = true
        },
        getCompleteStatus(id) {
            for (let i = 0; i < this.paymentStatuses.length; i++) {
                if(this.paymentStatuses[i].id === id) {
                    return this.paymentStatuses[i]
                }
                
            }
        },
        validateTransaction(){
            this.tabs.paymentMethod.errors = 0;
            switch(this.payment.paymentMethod) {
                case 'credit-card':
                    let cardNumber,cardName,cardCvv,cardMonth,cardYear,billZip,billingAddress,cardToPayAmount ;
                    cardToPayAmount = this.$v.cardToPayAmount.$invalid;
                    cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                    cardName = this.$v.payment.cardFields.cardName.$invalid;
                    cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                    cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                    cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                    billZip = this.$v.payment.cardFields.billZip.$invalid;
                    billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;

                    cardNumber ? this.tabs.paymentMethod.errors++ : false;
                    cardName ? this.tabs.paymentMethod.errors++ : false;
                    cardCvv ? this.tabs.paymentMethod.errors++ : false;
                    cardMonth ? this.tabs.paymentMethod.errors++ : false;
                    cardYear ? this.tabs.paymentMethod.errors++ : false;
                    billZip ? this.tabs.paymentMethod.errors++ : false;
                    billingAddress ? this.tabs.paymentMethod.errors++ : false;
                    cardToPayAmount ? this.tabs.paymentMethod.errors++ : false;

                    return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && !cardToPayAmount? true : false ;
                    
                case 'cash':
                    let paidAmount;
                    paidAmount = this.$v.payment.cash.paidAmount.$invalid;
                    

                    paidAmount ? this.tabs.paymentMethod.errors++ : false;
                    return !paidAmount ? true : false ;
                case 'check':
                    let companyName,address,routing,account;
                    companyName = this.$v.payment.check.companyName.$invalid;
                    address = this.$v.payment.check.address.$invalid;
                    routing = this.$v.payment.check.routing.$invalid;
                    account = this.$v.payment.check.account.$invalid;

                    companyName ? this.tabs.paymentMethod.errors++ : false;
                    address ? this.tabs.paymentMethod.errors++ : false;
                    routing ? this.tabs.paymentMethod.errors++ : false;
                    account ? this.tabs.paymentMethod.errors++ : false;
                        

                    return !companyName && !address && !routing && !account ? true : false ;
                        
                case 'invoice':
                    let companyNameInv,addressInv,ein,email;
                    companyNameInv = this.$v.payment.invoice.companyName.$invalid;
                    addressInv = this.$v.payment.invoice.address.$invalid;
                    ein = this.$v.payment.invoice.ein.$invalid;
                    email = this.$v.payment.invoice.email.$invalid;

                    companyNameInv ? this.tabs.paymentMethod.errors++ : false;
                    addressInv ? this.tabs.paymentMethod.errors++ : false;
                    ein ? this.tabs.paymentMethod.errors++ : false;
                    email ? this.tabs.paymentMethod.errors++ : false;
                        

                    return !companyNameInv && !addressInv && !ein && !email  ? true : false ;
            }        
        },
        openPaymentDialog() {
            this.payment.cash.paidAmount = this.roundNumber(this.rateData.price - this.totalTransactionsPaidAmount)
            this.cardToPayAmount = this.roundNumber(this.rateData.price - this.totalTransactionsPaidAmount)
            this.transactionDialog = true
        },
        switchMainTabs(tab) {
            console.log(tab)
            this.$refs.mainTabs.activeTab = tab;
            this.$window.scrollTo(0,0)
        },
        async getMerchantAccounts() {
            var params = {
                user: this.user.data.uid
            }
            let th = this;
            return new Promise((resolve, reject) => {
                axiosApi.get('active-merchant-accounts/' + th.user.data.uid).then(response =>{
                    console.log(response);
                    th.merchantAccounts = response.data.accounts ;
                    for (let i = 0; i <  th.merchantAccounts.length; i++) {
                        if(th.merchantAccounts[i].default) {
                            th.payment.merchantAccount = th.merchantAccounts[i].id
                            th.reservation.gateway = th.merchantAccounts[i].gateway
                        }
                        
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
        async refundPayment(type, refundedId,refundedTransaction){
            if(this.validateRefund()) {

            
                this.submitingRefund = true

                // const {
                //     type,
                //     user,
                //     resId,
                //     refundedId,
                //     creator,
                //     totalTransactionsPaidAmount,
                //     targetPrice,
                //     amountToRefund,
                //     partial,
                // } = req.body;


                let data = {
                    user: this.user.data.uid,
                    creator: {
                        email: this.user.data.email,
                        role: this.user.data.role,
                        displayName: this.user.data.displayName,
                    },
                    resId: this.$route.params.id,
                    totalTransactionsPaidAmount: this.totalTransactionsPaidAmount,
                    targetPrice: this.roundNumber(this.rateData.price),
                    type: type,
                    refundedId:refundedId,
                    refundedTransaction:refundedTransaction,
                    amountToRefund: this.roundNumber(this.refund.amount),
                    partial: this.refund.partial
                }

                if(type === 'credit card') {
                    data.gateway = this.refund.actualTransaction.merchantId
                    data.transactionId = this.refund.actualTransaction.callback.transactionId
                }

                //     amountToRefund,
                //     partial,

                

                let th = this;

                //return new Promise((resolve, reject) => {
                axiosApi.post('refund-payment',data).then(response =>{
                    console.log(response);
                    //th.reservation.transactions.push(response.data.transaction)
                    th.refundDialog = false
                    th.snackbar.message = 'Transaction refunded correctly'
                    th.snackbar.show = true
                    th.submitingRefund = false
                    th.isEditing = false
                    
                    
                    //resolve()
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    th.refundDialog = false
                    th.snackbar.message = 'Transaction refund failed'
                    th.snackbar.show = true
                    console.log(e);
                    th.submitingRefund = false
                    th.isEditing = false
                    //reject()
                }) 
            } else {
                this.$v.refund.$touch()
            }


        },
        async submitPayment() {
            if(this.validateTransaction()) {
                console.log('valid transaction')
                
                this.submitingPayment = true
                let data = {
                    user: this.user.data.uid,
                    creator: {
                        email: this.user.data.email,
                        role: this.user.data.role,
                        displayName: this.user.data.displayName,
                    },
                    resId: this.$route.params.id,
                    transaction: {},
                    merchantId: this.payment.merchantAccount,
                    totalTransactionsPaidAmount: this.totalTransactionsPaidAmount,
                    targetPrice: this.roundNumber(this.rateData.price)
                } ;
                switch (this.payment.paymentMethod) {
                    case 'cash':
                        data.transaction = {
                            amount : this.payment.cash.paidAmount,
                            type: 'cash'
                        }
                        break;
                    case 'credit-card':
                        data.transaction = {
                            cardFields: this.payment.cardFields,
                            amount: this.cardToPayAmount,
                            type: 'paypal'
                        }

                        break;

                }
                let th = this;

                //return new Promise((resolve, reject) => {
                axiosApi.post('submit-payment',data).then(response =>{
                    console.log(response);
                    //th.reservation.transactions.push(response.data.transaction)
                    th.transactionDialog = false
                    th.snackbar.message = 'Transaction submited correctly'
                    th.snackbar.show = true
                    th.submitingPayment = false
                    th.isEditing = false
                    
                    
                    //resolve()
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    th.transactionDialog = false
                    th.snackbar.message = 'Transaction failed'
                    th.snackbar.show = true
                    console.log(e);
                    th.submitingPayment = false
                    th.isEditing = false
                    //reject()
                }) 
                // }).catch(e => {
                //     console.log(e);
                //     reject()
                // })
                

                
                //this.reservation.transactions.push(transaction)
                
                
            } else {
                console.log('Not valid transaction')
                this.$v.payment.$touch();
                this.$v.cardToPayAmount.$touch();
            }
           
            
            //this.$v.payment.$touch()
            
        },
        resetTabErrors() {
            for(var key in this.tabs) {
                //console.log(key);
                this.tabs[key].errors = null
            }
        },
        onCancel() {
            this.confirmDialog = false
        },
        onConfirm() {
            this.confirmLeave = true
            this.$router.push({path: this.goTo.path})
        },
        preventNav(event) {
            console.log(event)
            if (!this.isEditing) return
            event.preventDefault()
            event.returnValue = ""
        },
        openSMSModal(type) {
            this.smsInfo.title = type;
            switch(type) {
                case 'Confirmation':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' created'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Receipt':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' receipt'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Cancellation':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' has been cancelled'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Refund':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' refund'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Driver assignation':
                    this.smsInfo.text = 'New job - Reservation #' + this.reservation.readableId + ' driver confirmation'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Driver assignment request':
                    this.smsInfo.text = 'Confirmation request for reservation #' + this.reservation.readableId + ' .'
                    this.smsInfo.recipients.passenger.active = false;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Quotation':
                    this.smsInfo.text = 'Quotation #' + this.reservation.readableId + ' '
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
            }
            
            this.showSMSDialog = true
        },
        openMailingModal(type) {
            this.mailingInfo.title = type;

            switch(type) {
                case 'Confirmation':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' created'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Receipt':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' receipt'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Cancellation':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' has been cancelled'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Refund':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' refund'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Driver assignation':
                    this.mailingInfo.subject = 'New job - Reservation #' + this.reservation.readableId + ' driver confirmation'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Driver assignment request':
                    this.mailingInfo.subject = 'Confirmation request for reservation #' + this.reservation.readableId + ' .'
                    this.mailingInfo.recipients.passenger.active = false;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Quotation':
                    this.mailingInfo.subject = 'Quotation #' + this.reservation.readableId + ' '
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
            }
            
            this.showDialog = true
        },
        fireNotifications(){
            this.sendMail(this.mailingInfo.title)
        },
        fireSMSNotifications(){
            this.sendSMS(this.smsInfo.title)
        },
        sendSMS(type) {
            let resId = this.$route.params.id
            let reservation = this.reservation
            let th = this;
            let data = [];
            for (const property in this.smsInfo.recipients) {
                let to = null;
                let sms = null;
                if( property != 'others' 
                    && this.smsInfo.recipients[property].send === true 
                    && this.smsInfo.recipients[property].active === true) 
                {
                    
                    sms = {
                        smsType: type,
                        recipientType: property,
                        reservation: resId,
                        user: th.user.data.uid,
                        to: null,
                        text: th.smsInfo.text,
                            
                    }

                    switch (property) {
                        case 'passenger':
                            to = reservation.passenger.phone;
                            break;
                        case 'driver':
                            to = reservation.assignedDriver;
                            break;
                        case 'owner':
                            to = reservation.companyProfile.smsPhoneTarget;
                            break;
                        case 'user':
                            to = th.user.data.uid;
                            break;
                         
                    }
                    sms.to = to ;
                    data.push(sms)
                    
                } else {

                }
                 
                
            }

            console.log(data);
            if(data.length > 0) {
                 axiosApi.post('sms',data).then(response =>{
                    console.log(response);
                    this.snackbar.message = 'Notifications queued correctly'
                    this.showSMSDialog = false
                    this.snackbar.show = true
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    this.snackbar.message = 'Notifications queued failed'
                    this.showSMSDialog = false
                    this.snackbar.show = true
                    console.log(e);
                }) 
            }
        },
        sendMail(type){
            let resId = this.$route.params.id
            let reservation = this.reservation
            let th = this;
            let data = [];
            
            for (const property in this.mailingInfo.recipients) {
                let to = null;
                let mail = null;
                if( property != 'others' 
                    && this.mailingInfo.recipients[property].send === true 
                    && this.mailingInfo.recipients[property].active === true) 
                {
                    mail = {
                        mailType: type,
                        recipientType: property,
                        reservation: resId,
                        user: th.user.data.uid,
                        to: null,
                        message: {
                            subject: th.mailingInfo.subject,
                            html: th.mailingInfo.body,
                        }
                    }

                    switch (property) {
                        case 'passenger':
                            to = reservation.passenger.email;
                            break;
                        case 'driver':
                            to = reservation.assignedDriver;
                            break;
                        case 'owner':
                            to = reservation.companyProfile.mailList;
                            break;
                        case 'user':
                            to = th.user.data.uid;
                            break;
                         
                    }
                    mail.to = to ;
                    data.push(mail)
                    
                } else {

                }
                 
                
            }

            console.log(data);
            if(data.length > 0) {
                 axiosApi.post('mail',data).then(response =>{
                    console.log(response);
                    this.snackbar.message = 'Notifications queued correctly'
                    this.showDialog = false
                    this.snackbar.show = true
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    this.snackbar.message = 'Notifications queued failed'
                    this.showDialog = false
                    this.snackbar.show = true
                    console.log(e);
                }) 
            }

          

       
        },
        updateTime() {
            var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
            this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
           
        },
        flowTimeZone() {
            //console.log(this.reservation.companyProfile.timeZone);
            var tz = moment.tz.guess();
            //console.log(tz);
            var dbaTZoffset = moment.tz(this.reservation.companyProfile.timeZone).utcOffset()
            //console.log(dbaTZoffset);

            var creatorTZoffset = moment.tz(tz).utcOffset()
            //console.log(creatorTZoffset);


            if(creatorTZoffset > dbaTZoffset) {
                // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
                // Moscu 180 (dba) // nuevadelih (creator) 300
                this.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;
                this.viewerTimezonePosition = 'right';
                // -180 -  -240 = 60
                // 300 - 180 = 120
            } else if(dbaTZoffset > creatorTZoffset) {

                // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
                // Moscu 180 (creator) // nuevadelih (dba) 300

                this.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
                this.viewerTimezonePosition = 'left';
                // -240 - -180 = 
                // 180 - 300 = -120
            } else {
                this.timezoneOffsetDif = 0;
                this.viewerTimezonePosition = 'equal';
            }




        },
        addPromoCode(){
            if(this.feeCalculatorData.promoCode != '' && this.feeCalculatorData.promoCode && !this.reservation.promoCodes.includes(this.feeCalculatorData.promoCode)) {
                this.reservation.promoCodes.push(this.feeCalculatorData.promoCode)
                this.feeCalculatorData.promoCode = null;
            }
            
        },
        roundNumber(number) {
            number = number.toString(); //If it's not already a String
            if(number.indexOf(".") != -1) {
                number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
            } 
            
            return number
        },
        updateRideRelatedSubtotal(){
           // v-if="rateData.activeFlatRate"
           // v-if="reservation.vehicleType && rateData.miniumRate && !rateData.activeFlatRate"
            let addition = 0;

            if(this.rateData.activeFlatRate) {
                addition += parseFloat(this.rateData.activeFlatRate.price)
            }

            if(this.reservation.vehicleType && this.rateData.miniumRate && !this.rateData.activeFlatRate){
                addition += parseFloat(this.rateData.rates.miniumRate) ;
            }

            if(!this.rateData.activeFlatRate && !this.rateData.miniumRate) {
                this.rateData.rateSpread.forEach(rate => {
                    addition += parseFloat(rate.rate)
                });
            }

            if(this.rateData.stops && this.rateData.stops.length > 0) {
               addition +=  this.rateData.subtotals.stops ;
               addition +=  this.rateData.subtotals.waitingTimePriceTotal
               

            }

            this.rateData.subtotals.afterRideDetails = addition

            let overrided = this.rateData ;
            console.log(overrided);
            this.overridedRateData =  overrided;
            var calculator = this.$refs.feeCalculator ;  
            this.$nextTick(() => {
                calculator.validateRequiredFields()
            })

           
        },
        printPriceTable(data){
            console.log('Price table data',data)
            
            
            this.rateData = data;
            this.reservation.generatedPrice = data.price
            this.reservation.rateData = data;
            this.updatePaymentStatusLocally()
            
        },
        updatePaymentStatusLocally() {
           

            let totalPaid = parseFloat(this.roundNumber(this.totalTransactionsPaidAmount))
            let targetPrice = parseFloat(this.roundNumber(this.rateData.price))

            if(totalPaid == 0) {
                //unpaid
                this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            } else if(totalPaid < targetPrice) {
                //-Partially paid
                this.reservation.paymentStatus =  '5y2pmSkkrYBpWmQCGMKT'
            } else if(totalPaid >= targetPrice) {
                //-Totally paid
                this.reservation.paymentStatus = 'dF6isActI3wRNckjAXiw'
            } else {
                //unpaid
                this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            }


            // if(this.roundNumber(this.totalTransactionsPaidAmount)  >= this.roundNumber(this.rateData.price) ){
            //     // Paid
            //     this.reservation.paymentStatus = 'dF6isActI3wRNckjAXiw'
                
            // } else if(this.roundNumber(this.totalTransactionsPaidAmount) > 0 && this.roundNumber(this.totalTransactionsPaidAmount) < this.roundNumber(this.rateData.price) ) {
            //     // Partially paid
            //      this.reservation.paymentStatus = '5y2pmSkkrYBpWmQCGMKT'
            // } else {
            //     // Unpaid
            //     this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            // }

           
        },
        mainTabChanged(tabId) {
            
            if(this.$refs.tabs && tabId == 'payment-method'){
                this.paymentMethodActiveTab = this.payment.paymentMethod
               
            }                     
        },
        paymentMethodChanged(val){
            this.paymentMethodActiveTab = val
        },
        jobTypeChanged(event){
            console.log(event);
        },
        
        tabChanged(tab) {
            //console.log(tab);
        },
        getJobTypeName(jobTypeId) {
            for (let i = 0; i < this.jobTypes.length; i++) {
                if(this.jobTypes[i].id == jobTypeId ) {
                    return this.jobTypes[i].name ;
                }   
            }
        },
         resetFlightPack() {
            if(this.reservation) {
                this.reservation.flightPack =  {
                                icao: null,
                                iata: null,
                                schedule: null,
                                info: null,
                                number:null,
                                noMatch: false,
                                originAirport: null,
                                destinationAirport: null,

                            }
            }
            
        },
        async initFlightCheck() {


            this.resetFlightPack()
           
             if(
                ( this.reservation.day 
                 && this.reservation.flightNumber 
                 && this.reservation.flightNumber.length >= 2 
                ) 
                && 
                (
                 this.reservation.flightIcao 
                 ||
                 this.reservation.flightIata 
                ) 
             ){
                this.loadingFlight = true ;
                this.reservation.flightNumber= this.reservation.flightNumber.replace(/\s/g,'')
                console.log('start')
                console.log(this.reservation.flightNumber)
                const schedules = await this.getFlightSchedules()
                console.log(schedules)
                console.log(this.reservation.flightNumber)
                console.log('end')
                // // if(this.possibleFlights.length > 0){
                // //      await this.getAirlineFlightInfo()
                // // }

                
             }     
            
            this.loadingFlight = false ;
        },
        
        async getFlightInfo(){
            let th = this;
            return new Promise((resolve, reject) => {
                let data = {
                    flightNumber: th.reservation.flightCode + th.reservation.flightNumber,
                    departuretime: th.possibleFlights[0].departuretime
                }

                axiosApi.get('get-flight-info/' , { params: data }

                ).then(response =>{
                   
                    let callback = JSON.parse(response.data.body)
                    console.log(callback)
                    if(callback.FlightInfoExResult) {
                        console.log('actual departure time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualdeparturetime).utc().format('LLLL'))
                        console.log('estimated arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].estimatedarrivaltime).utc().format('LLLL'))
                        console.log('actual arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualarrivaltime).utc().format('LLLL'))
                        // origin
                        // originCity
                        // originName

                        // destination
                        // destinationCity
                        // destinationName          
                    }
                    resolve()
                }).catch(e => {
                    // this.reservation.flightInfo = null;
                    // this.loadingFlight = false ;
                    console.log(e);
                    reject()
                    // this.serverError = false ;
                })  

                
              
            })  
        },
       async getFlightSchedules() {
            /*
            Si pone **From Airport** 2021-02-19 2 PM
            Pick up > ETA
           

            Si pone **To Airport** 2021-02-19 2 PM
            DO < ETD
            */
            let th = this;
            return new Promise((resolve, reject) => {
                if(this.reservation.flightNumber && this.reservation.day) {
                    this.possibleFlights = []
                    
                    let qty = 5;
                    //let flight = this.reservation.flightCode + this.reservation.flightNumber
                    let code = this.reservation.flightIata ?  this.reservation.flightIata  : this.reservation.flightIcao ? this.reservation.flightIcao : null;
                    let flight = code + this.reservation.flightNumber
                    
                    let flightAirline = code
                    let flighNumber = this.reservation.flightNumber
                    //let startDate = moment.unix(this.reservation.day).hour(0).minute(0).unix(); 
                    //let endDate = moment.unix(this.reservation.day).hour(23).minute(59).unix() ;



                    // Set departure time range to return

                    let startDate = null
                    let endDate = null
                    var jobTypeName = this.getJobTypeName(this.reservation.jobType.jobType)
                    if(jobTypeName == 'From airport') {
                        console.log('From airport')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix()
                        endDate = moment.unix(this.reservation.day).unix()
                        
                    } else if(jobTypeName == 'To Airport') {
                        console.log('To airport')
                        startDate = moment.unix(this.reservation.day).subtract(2,'h').unix()
                        endDate = moment.unix(this.reservation.day).add(12,'h').unix()
                    } else {
                        console.log('Not service type selected')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix()
                        endDate = moment.unix(this.reservation.day).unix()
                    }    
                    

                    // console.log(startDate);
                    // console.log(endDate);
                    this.cancel();
                    let axiosSource = axios.CancelToken.source();
                    this.request = { cancel: axiosSource.cancel };
                    
                    axiosApi.get('flights-schedules/' 
                            + startDate + '/'
                            + endDate + '/'
                            + flightAirline + '/'
                            + this.reservation.flightIcao + '/'
                            + this.reservation.flightIata + '/'
                            + flighNumber + '/'
                            + qty + '/'
                            + this.reservation.day
                            ,
                            { cancelToken: axiosSource.token }
                    ).then(response =>{

                        console.log(response)
                        
                        var payload = response;
                        
                            if(payload.data.flight) {
                                this.reservation.flightPack.noMatch = false
                                this.reservation.flightPack = payload.data.flight
                                let originAirport = this.getAirportInfo(this.reservation.flightPack.schedule.origin)
                                let destinationAirport = this.getAirportInfo(this.reservation.flightPack.schedule.destination)
                                            
                                this.reservation.flightPack.originAirport = originAirport;
                                this.reservation.flightPack.destinationAirport = destinationAirport;
                            } else {
                                this.reservation.flightPack =  {
                                    icao: null,
                                    iata: null,
                                    schedule: null,
                                    info: null,
                                    number:null,
                                    originAirport: null,
                                    destinationAirport: null

                                }

                                this.reservation.flightPack.noMatch = true
                                
                            }
                        


                        
                        resolve()
                        
                    }).catch(e => {
                        this.reservation.flightInfo = null;
                        //this.loadingFlight = false ;
                        
                        this.serverError = false ;
                        console.log(e)
                        //reject({message: 'cancelled request'})
                    }) 
                       
                } else {
                    this.serverError = false ;
                    this.loadingFlight = false ;
                     resolve()
                }
            })
        },
        cancel() {
            if (this.request) this.request.cancel('New request called');

        },
        setDispatcher(item) {
            //this.reservation.dispatcher = item;
            this.reservation.dispatcher = {
                displayName: item.displayName,
                email: item.email,
                customClaims: item.customClaims
            }
        },
        setAirline(item){
            

            
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;


            this.reservation.flightAirline = item.name ;
            this.reservation.flightIcao = item.icao;
            this.reservation.flightIata = item.iata;
            this.$nextTick(() => {
                 this.$refs.airlineAutocomplete.showMenu = false
            })
            this.initFlightCheck();
        },
        getAirportInfo(icao) {
          
           let payload; 
           for (const airport in this.airportsList) {
               if (Object.hasOwnProperty.call(this.airportsList, airport)) {
                   const element = this.airportsList[airport];
                   
                   if(element.icao == icao) {
                       payload = element;
                   }
                   
               }
           }
           
           return payload;
    
        },
        focusedAirlines(ev){
            //console.log('Focused airlines')
            //this.resetFlightPack()
            //console.log(this.$refs.airlineAutocomplete);
            console.log(ev)
            
            ev.target.value = ""
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;
            this.getAirlines()
        },
        getAirlines(searchTerm) {
            
            this.resetFlightPack()
            this.airlines = new Promise(resolve => {
                window.setTimeout(() => {
                    if (!searchTerm) {
                        let aux = this.airlinesList.slice(0, 10);
                        resolve(aux);
                        //resolve(this.airlinesList)
                    } else {
                        const term = searchTerm.toLowerCase()
                        console.log(term);
                        resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                            
                    }
                }, 200)
            })
            
        },
        changeName (e) {
            this.payment.cardFields.cardName = e.target.value
            this.$emit('input-card-name', this.payment.cardFields.cardName)
        },
        changeNumber (e) {
            this.payment.cardFields.cardNumber = e.target.value
            const value = this.payment.cardFields.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            } else if (/^62[0-9]\d*/.test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{6})/, '$1 ').replace(/(\d{6}) (\d{7})/, '$1 $2 ').replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ').replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4')
                this.cardNumberMaxLength = 21
            } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line
            if (e.inputType == 'deleteContentBackward') {
                const lastChar = this.payment.cardFields.cardNumber.substring(this.payment.cardFields.cardNumber.length, this.payment.cardFields.cardNumber.length - 1)
                // eslint-disable-next-line
                if (lastChar == ' ') { this.payment.cardFields.cardNumber = this.payment.cardFields.cardNumber.substring(0, this.payment.cardFields.cardNumber.length - 1) }
            }
            this.$emit('input-card-number', this.payment.cardFields.cardNumber)
        },
        changeMonth () {
            this.$emit('input-card-month', this.payment.cardFields.cardMonth)
        },
        changeYear () {
            this.$emit('input-card-year', this.payment.cardFields.cardYear)
        },
        changeCvv (e) {
            this.payment.cardFields.cardCvv = e.target.value
            this.$emit('input-card-cvv', this.payment.cardFields.cardCvv)
        },
        generateMonthValue (n) {
            return n < 10 ? `0${n}` : n
        },
        toggleMask () {
            this.isCardNumberMasked = !this.isCardNumberMasked
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            } else {
                this.unMaskCardNumber()
            }
        },
        maskCardNumber () {
            this.payment.cardFields.cardNumberNotMask = this.payment.cardFields.cardNumber
            this.mainCardNumber = this.payment.cardFields.cardNumber
            const arr = this.payment.cardFields.cardNumber.split('')
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== '') {
                arr[index] = '*'
                }
            })
            this.payment.cardFields.cardNumber = arr.join('')
        },
        unMaskCardNumber () {
            this.payment.cardFields.cardNumber = this.mainCardNumber
        },
        ////
        stepChanged(id)
        {
            this.step = id;
        },
        myEventHandler(e) {
            // your code for handling resize...
            this.$store.commit('centerDialog',window.innerWidth)
        },
        
        
        async saveAndExit() {
            this.isBusy = true;
            if(this.validateAllSteps()){
                await this.update()
                this.onConfirm()
            }
            this.isBusy = false;
        },
        async fireUpdate() {
            this.isBusy = true;
            if(this.validateAllSteps()){
                await this.update()
            }
            this.isBusy = false;
        },
        validateAllSteps(){
            let validSteps = [];
            let firstCorrectableStep = undefined;
            let steps = ['general','date-time-location','dispatchment-info','addons','rates-and-taxes','passenger','payment-method','emails'] ;
            for (let i = 0; i < steps.length; i++) {
                if(this.validateStep(steps[i])) {
                    validSteps.push(true)
                } else {
                    validSteps.push(false)
                    if(firstCorrectableStep === undefined) {
                        firstCorrectableStep = steps[i] ;
                    }
                }
                      
            }
            if(validSteps.includes(false)) {
                console.log('At least one false step')
                console.log('firstCorrectableStep',firstCorrectableStep);
                this.$refs.mainTabs.activeTab = firstCorrectableStep
                //this.mainActiveTab = 'general' ;
                this.$v.reservation.$touch();
                this.isBusy = false;
                return false;
            } else {
                console.log('All steps are valid')
                return true;
                
            }

            
        },
        async update() {
            this.isBusy = true;
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations').doc(this.$route.params.id).update(th.reservation)
                .then(function(){
                    console.log('Updated');
                    th.snackbar.message = 'Reservation updated'
                    th.resetTabErrors()
                    //th.isBusy = false;
                    th.snackbar.show = true
                    th.isEditing = false 
                    resolve()
                
                }).catch(function(error) {
                    th.snackbar.message = 'Ooopss something went wrong'
                    //th.isBusy = false;
                    th.snackbar.show = true
                    console.log("Error getting document:", error);
                    reject()
                });
            })                      
            
        },
        validateStep(step){
            // General
            // Date, time & location
            // Dispatchment
            // add ons
            // Rates and taxes
            // passenger
            // payment
            // emails
            
            switch(step) {
                case 'general':
                    let companyProfile,jobType,vehicleType,qty,lugagge ;
                    companyProfile = this.$v.reservation.companyProfile.$invalid;
                    jobType = this.$v.reservation.jobType.$invalid;
                    vehicleType = this.$v.reservation.vehicleType.$invalid;
                    qty = this.$v.reservation.passenger.qty.$invalid;
                    lugagge = this.$v.reservation.passenger.luggage.$invalid;
                    this.tabs.general.errors = 0 ;
                    companyProfile ? this.tabs.general.errors++ : false;
                    jobType ? this.tabs.general.errors++ : false;
                    vehicleType ? this.tabs.general.errors++ : false;
                    qty ? this.tabs.general.errors++ : false;
                    lugagge ? this.tabs.general.errors++ : false;
                    
                    return !companyProfile && !jobType && !vehicleType && !qty && !lugagge ? true : false ;
                
                case 'date-time-location':
                    let d,e,f ;
                    d = this.$v.reservation.day.$invalid;
                    e = this.$v.reservation.pu.formatted_address.$invalid;
                    f = this.$v.reservation.do.formatted_address.$invalid;
                    this.tabs.dateTimeLocation.errors = 0;
                    d ? this.tabs.dateTimeLocation.errors++ : false;
                    e ? this.tabs.dateTimeLocation.errors++ : false;
                    f ? this.tabs.dateTimeLocation.errors++ : false;
                    
                    return !d && !e ? true : false ;

                case 'dispatchment-info':
                    let reservationStatus;
                    reservationStatus = this.$v.reservation.reservationStatus.$invalid;
                    this.tabs.dispatchmentInfo.errors = 0;
                    reservationStatus ? this.tabs.dispatchmentInfo.errors++ : false;
                    return  !reservationStatus ? true : false ;

                case 'addons':
                    this.tabs.addons.errors = 0;
                    return true
                    
                case 'rates-and-taxes':
                    this.tabs.ratesAndTaxes.errors = 0;
                    return true
                case 'passenger':
                    let firstName,lastName,passengerEmail,phone ;
                    firstName = this.$v.reservation.passenger.firstName.$invalid;
                    lastName = this.$v.reservation.passenger.lastName.$invalid;
                    passengerEmail = this.$v.reservation.passenger.email.$invalid;
                    phone = this.$v.reservation.passenger.phone.$invalid;

                    this.tabs.passenger.errors = 0;
                    firstName ? this.tabs.passenger.errors++ : false;
                    lastName ? this.tabs.passenger.errors++ : false;
                    passengerEmail ? this.tabs.passenger.errors++ : false;
                    phone ? this.tabs.passenger.errors++ : false;

                    let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
                    return validPassenger
                    break;
                case 'payment-method':
                    this.tabs.paymentMethod.errors = 0;
                    switch(this.payment.paymentMethod) {
                            case 'credit-card':
                                // let cardNumber,cardName,cardCvv,cardMonth,cardYear,billZip,billingAddress ;
                                // cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                                // cardName = this.$v.payment.cardFields.cardName.$invalid;
                                // cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                                // cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                                // cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                                // billZip = this.$v.payment.cardFields.billZip.$invalid;
                                // billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;

                                // cardNumber ? this.tabs.paymentMethod.errors++ : false;
                                // cardName ? this.tabs.paymentMethod.errors++ : false;
                                // cardCvv ? this.tabs.paymentMethod.errors++ : false;
                                // cardMonth ? this.tabs.paymentMethod.errors++ : false;
                                // cardYear ? this.tabs.paymentMethod.errors++ : false;
                                // billZip ? this.tabs.paymentMethod.errors++ : false;
                                // billingAddress ? this.tabs.paymentMethod.errors++ : false;

                                // return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && validPassenger? true : false ;
                                return true;
                                
                            case 'cash':
                                // let paidAmount;
                                // paidAmount = this.$v.payment.cash.paidAmount.$invalid;

                                // paidAmount ? this.tabs.paymentMethod.errors++ : false;
                                // return !paidAmount ? true : false ;
                                return true;
                            case 'check':
                                // let companyName,address,routing,account;
                                // companyName = this.$v.payment.check.companyName.$invalid;
                                // address = this.$v.payment.check.address.$invalid;
                                // routing = this.$v.payment.check.routing.$invalid;
                                // account = this.$v.payment.check.account.$invalid;

                                // companyName ? this.tabs.paymentMethod.errors++ : false;
                                // address ? this.tabs.paymentMethod.errors++ : false;
                                // routing ? this.tabs.paymentMethod.errors++ : false;
                                // account ? this.tabs.paymentMethod.errors++ : false;
                                    

                                // return !companyName && !address && !routing && !account && validPassenger ? true : false ;
                                return true;
                                    
                            case 'invoice':
                                // let companyNameInv,addressInv,ein,email;
                                // companyNameInv = this.$v.payment.invoice.companyName.$invalid;
                                // addressInv = this.$v.payment.invoice.address.$invalid;
                                // ein = this.$v.payment.invoice.ein.$invalid;
                                // email = this.$v.payment.invoice.email.$invalid;

                                // companyNameInv ? this.tabs.paymentMethod.errors++ : false;
                                // addressInv ? this.tabs.paymentMethod.errors++ : false;
                                // ein ? this.tabs.paymentMethod.errors++ : false;
                                // email ? this.tabs.paymentMethod.errors++ : false;
                                    

                                // return !companyNameInv && !addressInv && !ein && !email && validPassenger ? true : false ;
                                return true;
                    }        
                    break;
                    case 'emails':
                        this.tabs.emails.errors = 0;
                        return true;
                    
            }                    
        },
        buttonContinueOn() {
            if(this.step == "general"){
                return true;
                //return this.firstStepOk == true ? true : false ;
                
            } else if(this.step == "locations") {
                return true;
                //return this.publicacion.fotos.length > 0 ? true : false ;
            }
        },
        getValidationClass (fieldName) {
            let field = null;
            if(
                fieldName == 'qty' 
                || fieldName == 'luggage' 
                || fieldName == 'firstName' 
                || fieldName == 'lastName' 
                || fieldName == 'email' 
                || fieldName == 'phone') {

                field = this.$v.reservation.passenger[fieldName]
            } else if (
                 fieldName == 'cardNumber' 
                || fieldName == 'cardName' 
                || fieldName == 'cardCvv' 
                || fieldName == 'cardMonth' 
                || fieldName == 'cardYear' 
                || fieldName == 'billZip' 
                || fieldName == 'billingAddress' 
            ) {
                field = this.$v.payment.cardFields[fieldName]
            } else if(
                fieldName == 'check.companyName' 
                || fieldName == 'check.address' 
                || fieldName == 'check.routing' 
                || fieldName == 'check.account' 
            ){
                let fieldSplit = fieldName.split('check.')[1];
                
                field = this.$v.payment.check[fieldSplit]
            } else if(
                fieldName == 'invoice.companyName' 
                || fieldName == 'invoice.address' 
                || fieldName == 'invoice.ein' 
                || fieldName == 'invoice.email' 
            ){
                let fieldSplit = fieldName.split('invoice.')[1];
                field = this.$v.payment.invoice[fieldSplit]
            } else if(fieldName == 'paidAmount' ){
                field = this.$v.payment.cash[fieldName]
            } else if(fieldName == 'cardToPayAmount' ){
                field = this.$v[fieldName]
            } else if (fieldName == 'refund.amount' || fieldName == 'refund.partial') {
                let fieldSplit = fieldName.split('refund.')[1];
                field = this.$v.refund[fieldSplit]
            } else {
                field = this.$v.reservation[fieldName]
            }
            

            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm () {
            this.$v.$reset()
            // this.email = null
            // this.password = null
            // this.displayName = null
            // this.role = null
            
        },
        validateReservation () {
            this.$v.$touch()

            if (!this.$v.$invalid) {
               // Save reservation
               console.log('Valid');
            }
        },
        inputWithValue(index,value){
            console.log('Input with value');
            console.log(value);
            let incomingIndex = this.labelWrittenItems.indexOf(index);
            if(value.length > 0) {
                if (incomingIndex > -1) {

                } else {
                    this.labelWrittenItems.push(index) ;
                }
                
                //this.focusedItem = index ;
            } else {
                
                if (incomingIndex > -1) {
                    this.labelWrittenItems.splice(incomingIndex, 1);
                }
            }
        },
        unfocusLocation(index) {
            //this.labelActiveItems[index].focused = null ;
            let incomingIndex = this.labelFocusedItems.indexOf(index);
            if (incomingIndex > -1) {
                this.labelFocusedItems.splice(incomingIndex, 1);
            }
        },
        focusedLocation(index){
            console.log(index);
            console.log('focusedLocation')
            this.labelFocusedItems.push(index) ;
        },
        autocompleteUser(index,address) {
            console.log(index)
            this.activeStopIndex = index;
            let th = this;
            setTimeout(function(){
                console.log(address.value);
                th.actualStopAddress = address.value ;
                //th.reservation.waypoints[index].formatted_address = address.value
            },0)
        },
        showStatusModal(){
            this.showDialog = true;
        },
        setDescription(description) {
            this.description = description;
        },
        saveWaypoint(index) {
            //this.reservation.waypoints[this.activeStopIndex] = this.waypointsPack[this.activeStopIndex] ;
        },
        removeWaypoint(key){
           console.log(key);
            this.reservation.waypoints.splice(key, 1);
        },
        addStop(){ 
            // let waypoint = {
            //     stopover: true,
            //     location: null
            // }
            // this.reservation.waypoints.push({
            //     stopover: true,
            //     location: null
            // })
            //this.waypointsPack.push({});
            this.reservation.waypoints.push({});
            
        },
        setWP(place) {
            console.log('Place changed');
            
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            let wp = {
                    //formatted_address: place.formatted_address,
                    postalCode: postal,
                    coordinates: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    },
                    stop: {
                        location: place.geometry.location.lat() + ',' + place.geometry.location.lng(),
                        stopover: true
                    },
                    formatted_address : this.actualStopAddress
                    
            }

            //this.reservation.waypoints[this.activeStopIndex] = wp;
            this.reservation.waypoints.splice(this.activeStopIndex, 1, wp);
           

            
        },
        getSelectedAddress(type,address) {
           let th = this;
           setTimeout(function(){
               console.log(address.value);
                th.reservation[type].formatted_address = address.value
           },0)
            
        },
        setPU(place) {

            //this.reservation.pu = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.reservation.pu.postalCode = postal;
            this.reservation.pu.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };
        },
        setDO(place) {

            //this.reservation.do = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.reservation.do.postalCode = postal;
            this.reservation.do.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };
        },
        updatedMiles(miles) {
            this.reservation.miles = miles;
        },
        overviewPath(path) {
            this.feeCalculatorData.overviewPath = path;
            
        },
        add(){
            console.log('Add method')
        },
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.vehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getServiceTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('service-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.serviceTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.serviceTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getAddons() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('add-ons').onSnapshot((querySnapshot) => {
                    th.addOns = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        th.addOns.push(dataToSave);
                        
                    });
                    resolve()
                });
            })  
        },
        async getCompanyProfiles() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                    th.companyProfiles = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        
                        th.companyProfiles.push(dataToSave);
                        
                    });
                    resolve()
                });
            })  
        },
        async getJobTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                    th.jobTypes = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        th.jobTypes.push(dataToSave);
                        
                    });
                    resolve()
                });
            })  
        },
        async getStatuses() {
            ///////
            let th = this;
            return new Promise((resolve, reject) => {
                th.statusesRef.orderBy('name').onSnapshot((querySnapshot) => {
                    th.reservationStatuses =  []
                    th.driverStatuses =  []
                    th.paymentStatuses =  []
                    th.tripStatuses =  []
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        switch(dataToSave.type) {
                            case 'reservation':
                                th.reservationStatuses.push(dataToSave);
                                break;
                            case 'driver':
                                th.driverStatuses.push(dataToSave);
                                break;
                            case 'payment':
                                th.paymentStatuses.push(dataToSave);
                                break;
                            case 'trip':
                                th.tripStatuses.push(dataToSave) ;
                                break; 
                            
                        }
                        
                        
                    });
                    resolve()
                })
                
            }) 

            
        },
        async getDispatchers(){
            let th = this ;

            let data = {  
                    ownerEmail: this.$store.getters.user.data.email,
                    roleToGet: 'dispatcher'
            }

            
            return new Promise((resolve, reject) => {
                th.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                    //Send token to your backend via HTTPS
                    //...
                    axiosApi.get('dispatchers', { params: data }).then(response =>{
                        th.dispatchers = response.data.users ;
                        resolve()
                    }).catch(e => {
                        console.log(e);
                        reject()
                    })
                }).catch(function(error) {
                    //Handle error
                });
            }) 

        
        },
        async initPU() {
            
            let data = this.reservation.pu.coordinates ;
            this.reservation.pu.coordinates = null;
            this.reservation.pu.coordinates = data;

            
            // const geocoder =  new window.google.maps.Geocoder();
            
            // return new Promise((resolve, reject) => {
            //     console.log(geocoder);

            //     geocoder
            //     .geocode({ location: th.reservation.pu.coordinates })
            //     .then((response) => {
            //         th.setPU(response.results[0]);
            //         resolve();
            //     })
            //     .catch((e) => window.alert("Geocoder failed due to: " + e));
                                
            // }) 
            
            
        },

        async initDO() {

            let data = this.reservation.do.coordinates ;
            this.reservation.do.coordinates = null;
            this.reservation.do.coordinates = data;



            // let th = this;
            // const geocoder =  new window.google.maps.Geocoder();
           
            // return new Promise((resolve, reject) => {
                
            //     geocoder
            //     .geocode({ location: th.reservation.do.coordinates })
            //     .then((response) => {
            //         th.setDO(response.results[0]);
            //         resolve();
            //     })
            //     .catch((e) => window.alert("Geocoder failed due to: " + e));
            // }) 
           
        },
        reorderTransactions(transactions) {
            transactions.sort(function (a, b) {
                if (a.datetime > b.datetime) {
                    return 1;
                }
                if (a.datetime < b.datetime) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        },
        updateTotalPaidAmount(transactions){
            this.reorderTransactions(transactions)
            let totalPaid = 0;

            for (let j = 0; j < transactions.length; j++) {
                if(transactions[j].status === 'SUCCESS') {
                    totalPaid += parseFloat(transactions[j].amount)
                }
                
            }
            this.totalTransactionsPaidAmount = totalPaid

            return totalPaid


        },
        async getReservation() {
            let th = this;
            return new Promise((resolve, reject) => {
                th.unsub = firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').doc(th.$route.params.id)
                     .onSnapshot(doc => {
                        
                        if (doc.exists && !th.resReadedFirstSnap) {
                            th.reservation = doc.data();
                            
                            if(th.reservation.paymentStatus != 'dF6isActI3wRNckjAXiw') {
                                // Not approved payment
                                th.payment.cardFields =  {
                                    cardName: '',
                                    cardNumber: '',
                                    cardMonth: '',
                                    cardYear: '',
                                    cardCvv: '',
                                    billZip: '',
                                    billingAddress: '',
                                }
                            }
                             this.$store.commit('set', ['timezone',th.reservation.companyProfile.timeZone ])
                            this.$store.commit('set', ['utc',moment.tz(th.reservation.companyProfile.timeZone).utcOffset() ])
                            //this.$store.commit('set', ['time',moment.tz(th.reservation.companyProfile.timeZone).format('hh:mm A (HH:mm [HS])') ])
                            //this.$store.commit('set', ['date',moment.tz(th.reservation.companyProfile.timeZone).format('dddd DD-MM-YYYY') ])
                            th.selectedData.companyProfile = th.reservation.companyProfile.id
                            th.selectedData.jobType = th.reservation.jobType.id
                            th.selectedData.vehicleType = th.reservation.vehicleType.id
                            //th.selectedData.dispatcher = th.reservation.dispatcher.uid
                            th.selectedData.passengers = th.reservation.vehicleType.luggage
                            th.selectedData.luggage = th.reservation.vehicleType.luggage
                            
                            th.feeCalculatorData.vehicleType = th.reservation.vehicleType


                            let counter = 0;
                            if(th.reservation.vehicleType.rates.addons){
                                for (let i = 0; i < th.reservation.vehicleType.rates.addons.length; i++) {
                                    if(th.reservation.vehicleType.rates.addons[i].enabled && th.reservation.vehicleType.rates.addons[i].price) {
                                        counter++;
                                    }
                                    
                                }
                            }
                           
                            counter > 0 ? th.reservation.vehicleType.rates.addonsAvailable = true : th.reservation.vehicleType.rates.addonsAvailable = false

                            // feeCalculatorData.jobType
                            // feeCalculatorData.vehicleType

                            for (let i = 0; i < th.serviceTypes.length; i++) {
                                if(th.serviceTypes[i].id === th.selectedData.jobType) {
                                        th.feeCalculatorData.jobType = th.serviceTypes[i]
                                        th.reservation.jobType = th.serviceTypes[i]
                                        //th.serviceTypes[i].vehicleType
                                        for (let j = 0; j < th.serviceTypes[i].vehicleType.length; j++) {
                                            for (let k = 0; k < th.vehicleTypes.length; k++) {
                                                if(th.serviceTypes[i].vehicleType[j] == th.vehicleTypes[k].id ) {
                                                    th.filteredVehicleTypes.push(th.vehicleTypes[k]);
                                                }
                                                
                                            }
                                            
                                        }
                                }
                            }

                            th.updateTime()
                            th.flowTimeZone();

                            let timeDiff = th.timezoneOffsetDif
                            th.reservation.day = parseInt(th.reservation.day)
                            // Get UTC time to add or substract timezone difference
                            let utcRealDateTime = moment.unix(th.reservation.day)

                            if(th.viewerTimezonePosition == 'right') {
                                timeDiff = timeDiff * -1
                            }

                            let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                            th.selectedData.datetime = timeToShow
                           
                            th.rateData = th.reservation.rateData;
                            

                            
                            th.updateTotalPaidAmount(th.reservation.transactions)

                            th.$nextTick(() => {
                                console.log('this.$refs.pu');
                                console.log(th.$refs.pu);
                                if(!th.resReadedFirstSnap) {
                                    th.$refs.pu.$el.value = th.reservation.pu.formatted_address
                                    th.$refs.do.$el.value = th.reservation.do.formatted_address

                                    if(th.reservation.waypoints.length > 0) {
                                        for (let m = 0; m < th.reservation.waypoints.length; m++) {
                                            th.$refs.stopwaypoint[m].$el.value = th.reservation.waypoints[m].formatted_address
                                        }
                                    }
                                    th.resReadedFirstSnap = true;
                                }
                                
                                resolve()

                            });
                          
                            

                            //th.$refs.pu.$refs.input.value= 'Hola mundo'
                            
                            
                        } else if(doc.exists && th.resReadedFirstSnap) {
                           console.log('Mails, SMS, or transactions changed --------')
                           th.reservation.mails = doc.data().mails ; 
                           th.reservation.transactions = doc.data().transactions ; 
                           th.updateTotalPaidAmount(th.reservation.transactions)
                           th.updatePaymentStatusLocally()
                        } else {
                            // doc.data() will be undefined in this case
                            resolve()
                            console.log("No such document!");
                        }
                        // ...
                    }, err => {
                         console.log(`Encountered error: ${err}`);
                         reject()
                    });

                    // .get()
                    // .then(function(doc) {
                    //     if (doc.exists) {
                    //         console.log("Document data:", doc.data());
                    //         th.reservation = doc.data();
                            
                           
                    //         th.selectedData.companyProfile = th.reservation.companyProfile.id
                    //         th.selectedData.jobType = th.reservation.jobType.id
                    //         th.selectedData.vehicleType = th.reservation.vehicleType.id
                    //         //th.selectedData.dispatcher = th.reservation.dispatcher.uid
                    //         th.selectedData.passengers = th.reservation.vehicleType.luggage
                    //         th.selectedData.luggage = th.reservation.vehicleType.luggage
                            
                    //         th.feeCalculatorData.vehicleType = th.reservation.vehicleType


                    //         let counter = 0;
                    //         if(th.reservation.vehicleType.rates.addons){
                    //             for (let i = 0; i < th.reservation.vehicleType.rates.addons.length; i++) {
                    //                 if(th.reservation.vehicleType.rates.addons[i].enabled && th.reservation.vehicleType.rates.addons[i].price) {
                    //                     counter++;
                    //                 }
                                    
                    //             }
                    //         }
                    //         console.log('Counter',counter);
                           
                    //         counter > 0 ? th.reservation.vehicleType.rates.addonsAvailable = true : th.reservation.vehicleType.rates.addonsAvailable = false

                    //         // feeCalculatorData.jobType
                    //         // feeCalculatorData.vehicleType

                    //         for (let i = 0; i < th.serviceTypes.length; i++) {
                    //             if(th.serviceTypes[i].id === th.selectedData.jobType) {
                    //                     th.feeCalculatorData.jobType = th.serviceTypes[i]
                    //                     th.reservation.jobType = th.serviceTypes[i]
                    //                     //th.serviceTypes[i].vehicleType
                    //                     for (let j = 0; j < th.serviceTypes[i].vehicleType.length; j++) {
                    //                         for (let k = 0; k < th.vehicleTypes.length; k++) {
                    //                             if(th.serviceTypes[i].vehicleType[j] == th.vehicleTypes[k].id ) {
                    //                                 th.filteredVehicleTypes.push(th.vehicleTypes[k]);
                    //                             }
                                                
                    //                         }
                                            
                    //                     }
                    //             }
                    //         }

                    //         th.updateTime()
                    //         th.flowTimeZone();

                    //         let timeDiff = th.timezoneOffsetDif
                    //         // Get UTC time to add or substract timezone difference
                    //         let utcRealDateTime = moment.unix(th.reservation.day)

                    //         if(th.viewerTimezonePosition == 'right') {
                    //             timeDiff = timeDiff * -1
                    //         }

                    //         let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                    //         th.selectedData.datetime = timeToShow
                           
                    //         th.rateData = th.reservation.rateData;
                    //         th.$nextTick(() => {
                    //             console.log('this.$refs.pu');
                    //             console.log(th.$refs.pu);
                    //             th.$refs.pu.$el.value = th.reservation.pu.formatted_address
                    //             th.$refs.do.$el.value = th.reservation.do.formatted_address

                    //             if(th.reservation.waypoints.length > 0) {
                    //                 for (let m = 0; m < th.reservation.waypoints.length; m++) {
                    //                     th.$refs.stopwaypoint[m].$el.value = th.reservation.waypoints[m].formatted_address
                    //                 }
                    //             }

                    //             resolve()

                    //         });
                          
                            

                    //         //th.$refs.pu.$refs.input.value= 'Hola mundo'
                            
                            
                    //     } else {
                    //         // doc.data() will be undefined in this case
                    //         resolve()
                    //         console.log("No such document!");
                    //     }
                        
                    // }).catch(function(error) {
                    //     console.log("Error getting document:", error);
                    //     reject()
                    // });
            })  
        },
        async obtainViewResources() {
            this.isBusy = true;
            await this.getVehicleTypes()
            await this.getServiceTypes()
            await this.getAddons()
            await this.getCompanyProfiles()
            await this.getJobTypes()
            await this.getStatuses()
            await this.getDispatchers()
            
            
            await this.getReservation()
            await this.getMerchantAccounts()
            await this.initPU()
            await this.initDO()
            this.isEditing = false
            this.isBusy = false;
            this.$nextTick(()=>{

                this.fixInitialTabHeight()
            })
            // this.mainActiveTab = 'general'
            // this.mainActiveTab = 'date-time-location'
            console.log('obtainViewResources end');
        },
        fixInitialTabHeight() {
            console.log(this.$refs.mainTabs)

            let finalHeight = this.$refs.mainTabs.$el.querySelectorAll('#date-time-location')[0].clientHeight
            let mdTabsContent = this.$refs.mainTabs.$el.querySelectorAll('.md-tabs-content')[0]
            mdTabsContent.style.height = finalHeight + 'px'
            
            
        }
       
    },
    computed: {
        maxPossibleRefundVal() {
            return this.refund.maxPossibleValue
        },
        minCardMonth () {
            if (this.payment.cardFields.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
        
        origin() {
            if (!this.reservation.pu) return null;
            return { query: this.reservation.pu.coordinates.lat + ',' + this.reservation.pu.coordinates.lng };
        },
        destination() {
            if (!this.reservation.do) return null;
            return { query: this.reservation.do.coordinates.lat + ',' + this.reservation.do.coordinates.lng };
        },
        computedWaypoints() {
            if (this.reservation.waypoints.length == 0 ) return null;
            const total = [];
            this.reservation.waypoints.forEach((waypoint,index) => {
                if(!waypoint.stop){
                    //total.push(null);
                } else {
                    total.push({
                        stopover:true,
                        location: !waypoint.stop ? null : waypoint.stop.location
                    })

                   
                }
                
            });
            return total;
            
        }, 
        dialogDimensions () {
            console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
        user() {
            return this.$store.state.user;
        }
        
       
    },
    beforeCreate() {
        
    },
    created () {
        window.addEventListener("resize", this.myEventHandler);
        let th = this ;
       
        //this.obtainViewResources();
        this.obtainViewResources();
        

    },
    
    //validations: {
    validations() {    
        return {
            refund: {
                amount: {
                    minValue: minValue(1),
                    required,
                    maxValue: maxValue(this.refund.maxPossibleValue)
                    // maxValue: maxValue(function(val){
                    //     console.log(val)
                    //     return false
                    //     //return this.refund.maxPossibleValue
                    // })
                
                },
                partial: {
                    required
                }
            },
            //paymentMethod: null,
            cardToPayAmount: {
                required: requiredIf(function(payment){
                    return payment.paymentMethod == 'credit-card' ? false : true ;
                }),
                minValue: minValue(1),
            },
            payment: {
                cardFields: {
                    cardName: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardNumber: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardMonth: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardYear: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardCvv: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    billZip: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    billingAddress: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"  && this.reservation.paymentStatus != null))  ? false : true ;

                        })
                    }
                },
                cash: {
                    paidAmount: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'cash' ? false : true ;
                        }),
                        minValue: minValue(1),
                    }
                },
                invoice: {
                    companyName: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    address: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    ein: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    email: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    }
                },
                check: {
                    companyName: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    address: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    routing: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    account: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    }
                },
            },
            reservation: {
                
                passenger: {
                    qty: {
                        required,
                        between (value) {
                            return between(1, this.selectedData.passengers)(value)
                        }
                    },
                    luggage: {
                        required,
                        between (value) {
                            return between(0, this.selectedData.luggage)(value)
                        }
                    },
                    firstName: {
                        required
                    },
                    lastName:{
                        //required
                    },
                    email:{
                        //required,
                        email
                    },
                    phone:{
                        required
                    }
                },
                jobType: {
                    required
                },
                companyProfile: {
                    required
                },
                vehicleType: {
                    required
                },
                addOns: {
                    //required
                },
                dispatcher: {
                    //required
                },
                reservationStatus: {
                    required
                },
                day: {
                    required
                },
                pu: {
                    formatted_address: {
                        required
                    }
                },
                do: {
                    formatted_address: {
                        required
                    }
                },
                flightNumber: {
                    required: requiredIf(function(reservation){
                        
                        if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                            return true
                        } else {
                            return false
                        }
                        
                    }),
                    serverError () { return this.serverError }
                },
                flightAirline: {
                    required: requiredIf(function(reservation){
                        
                        if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                            return true
                        } else {
                            return false
                        }
                        
                    })
                },
                
            }
        }
        
    } , 
        
    //},
    
    destroyed() {
        
        this.unsub()
        window.removeEventListener("resize", this.myEventHandler);
        this.$store.commit('restoreBrowserTime')
    },
}
</script>  

<style >
  .badge {
    width: 19px;
    height: 19px;
    line-height:19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }

  .badge.md-primary {
      background-color: rgb(119, 207, 119);
  }

  .badge.md-primary .md-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    font-size: 12px!important;
    color:white!important;

  }

  .text-decoration-line-through .md-table-head-label {
      text-decoration: line-through;
  }

  

  
</style>