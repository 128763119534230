<template>
    <div>
        <div v-show="isBusy">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
            <md-dialog :md-active.sync="sending" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog" :md-backdrop="false" >
                <md-dialog-title>
                    Upload status
                </md-dialog-title>
                <div class="modal-up-content text-center">
                    <md-list v-if="upload.uploadedDocs.length > 0">
                        <md-list-item v-for="(docFile,index) in upload.uploadedDocs" v-bind:key="docFile + index">
                            <md-icon>done</md-icon>
                            <span class="md-list-item-text">{{docFile}}</span>
                        </md-list-item>
                    </md-list>
                    <div>
                        <md-divider class="mb-4"></md-divider>
                        <p class="md-subheading">{{upload.currentTitle}}</p>
                        <md-progress-bar class="mb-2" md-mode="determinate" :md-value="upload.currentFileProgress" v-show="upload.active"></md-progress-bar>
                        <div class="md-caption mb-4" v-show="upload.active">{{upload.actualBytes}} uploaded of {{upload.totalBytes}} bytes</div>
                    </div>
                </div>
            </md-dialog>
        </div>
        <div v-show="!isBusy" v-if="driver">
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="driver.general.name || driver.general.lastname">{{driver.general.name}} {{driver.general.lastname}}</h3>
                <h3 class="md-title" style="flex: 1" v-else>Driver name</h3>
            
                
                <!--md-button class="md-primary">Save vehicle type</md-button-->
                <div class="flex-right mr-2">
                   <md-button class="md-raised md-accent full-width-button" @click="fireDeleteDialog">Delete driver</md-button>
                </div>
                <div class="flex-right">
                    <md-badge md-content="i" md-dense md-position="bottom" v-if="isEdited">
                        <md-tooltip md-direction="bottom">Changes were made, click  to save</md-tooltip>
                        <md-button class="md-primary md-raised mr-0" :class="{'pulse yellow': isEdited}" @click="fireUpdate" :disabled="isBusy || !isEdited ">
                            Update driver
                        </md-button>
                    </md-badge>     
                    <md-button v-else class="md-primary md-raised mr-0" :class="{'pulse yellow': isEdited}" @click="fireUpdate" :disabled="isBusy || !isEdited ">
                            Update driver
                    </md-button>
                    
                </div>
                
            </md-toolbar>
            <form enctype="multipart/form-data">
                <md-steppers @md-changed="stepChanged" class="md-elevation-4 display-pdf-fix" :md-active-step="step" md-vertical>
                    <md-step id="general" md-label="General" :md-error="tabs.general.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.general.errors == 0">  
                            
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <CRow>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('name')">
                                            <label for="name">Name</label>
                                            <md-input name="name" id="name" autocomplete="given-name" v-model="driver.general.name" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.driver.general.name.required">Name is required</span>
                                            
                                        </md-field>
                                    </CCol>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('lastname')">
                                            <label for="last-name">Last Name</label>
                                            <md-input name="last-name" id="last-name" autocomplete="given-name" v-model="driver.general.lastname" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.driver.general.lastname.required">Lastname is required</span>
                                            
                                        </md-field>
                                    </CCol>
                                </CRow>
                                <md-field :class="getValidationClass('email')">
                                    <label for="email">Email</label>
                                    <md-input type="email" name="email" id="email" autocomplete="off" v-model="driver.general.email" :disabled="true" />
                                    <span class="md-error" v-if="!$v.driver.general.email.required">The email is required</span>
                                    <span class="md-error" v-else-if="!$v.driver.general.email.email">Invalid email</span>
                                </md-field>
                                <md-field>
                                    <label for="phone">Cell Phone</label>
                                    <md-input name="phone" id="phone" autocomplete="given-name" v-model="driver.general.phone" :disabled="sending" />
                                    <!--span class="md-error" v-if="!$v.driver.general.phone.required">Phone is required</span-->
                                    
                                </md-field>
                                <md-field :class="getValidationClass('displayName')">
                                    <label for="display-name">Display Name</label>
                                    <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="driver.general.displayName" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.driver.general.displayName.required">Display name is required</span>
                                    <span class="md-error" v-else-if="!$v.driver.general.displayName.minlength">Invalid first name</span>
                                </md-field>
                                <!--md-field :class="getValidationClass('password')">
                                    <label for="password">Password</label>
                                    <md-input type="password" name="password" id="password" autocomplete="given-name" v-model="driver.general.password" :disabled="sending" />
                                    <span class="md-error" v-if="!$v.driver.general.password.required">Password is required</span>
                                </md-field-->
                            </CCol>
                        </CRow>
                    
                            
                        <md-button class="ml-0 md-raised" @click="step = 'fleet'">Next step</md-button>
                        
                    </md-step>
                    <md-step id="coveredArea" md-label="Covered Area" :md-error="tabs.coveredArea.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.coveredArea.errors == 0"> 
                        <CRow>
                            <CCol sm="12" lg="6">
                                <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Covered area</div>
                                </md-card-header>
                                <md-card-content>
                                    <CRow>
                                        <CCol sm="12" md="12" lg="12" xl="12">
                                            
                                            
                                            <GmapMap :center="{lat:driver.coveredArea.coordinates.lat,lng:driver.coveredArea.coordinates.lng}" :zoom="driver.coveredArea.zoom" style="width:100%;height:600px" ref="fenceMap">
                                                <gmap-polygon v-for="(path,index) in areaPaths" v-bind:key="'new-path' + index" :paths="path.path" :editable="false" @paths_changed="updateEdited($event,path.existant,path.path,index)">
                                                </gmap-polygon>
                                            </GmapMap>
                                        </CCol>
                                    </CRow>
                                    
                                </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" lg="6">
                                    <md-card class="uplimo-card">
                                        <md-card-header>
                                            <div class="md-title">Areas list</div>
                                        </md-card-header>
                                        <md-card-content>
                                            <md-field :class="{'md-focused':inputFocused,'md-invalid': !areaValidation.valid}">
                                                <gmap-autocomplete
                                                    ref="autocomplete"
                                                    placeholder="Type country, state, city here to add new fence"
                                                    @place_changed="setFence($event)" class="pu-autocomplete"
                                                    @blur="resetCursor()"
                                                    @focus="locateCursor()"
                                                    :types="mapTypes"
                                                    >
                                                </gmap-autocomplete>
                                                <span class="md-error">{{ areaValidation.message }}</span>
                                            </md-field>
                                            <div v-if="areas.length == 0 ">
                                                <md-empty-state
                                                    md-icon="add_location"
                                                    md-label="Add your first fence to determine covered area"
                                                    md-description="You can type a place name and then edit resultant fence">
                                                    
                                                </md-empty-state>
                                                <md-button class="md-primary md-raised full-width-button" @click="locateCursor()">Add fence</md-button>

                                            </div>
                                            <div v-else>
                                                <md-table >
                                                    <md-table-row>
                                                        <md-table-head>Name</md-table-head>
                                                    
                                                        <md-table-head>Action</md-table-head>
                                                        
                                                    </md-table-row>

                                                    <md-table-row v-for="(area,index) in areas" v-bind:key="area.id">
                                                        <md-table-cell>{{area.formatted_address}}</md-table-cell>
                                                        <md-table-cell>
                                                            <md-button class="md-raised ml-0" @click="removeFence(area,index,area.newArea)">Remove</md-button>
                                                        </md-table-cell>
                                                    
                                                        
                                                    </md-table-row>
                                                    <!--md-table-row v-for="(fence,index) in driver.coveredArea.fencesList" v-bind:key="fence.formatted_address">
                                                        <md-table-cell>{{fence.formatted_address}}</md-table-cell>
                                                        <md-table-cell>
                                                            <md-button class="md-raised ml-0" @click="removeFence(fence,index,true)">Remove</md-button>
                                                        </md-table-cell>
                                                    
                                                        
                                                    </md-table-row-->
                                                </md-table>
                                                <md-button class="mt-3 md-primary md-raised full-width-button" @click="locateCursor()">Add new area</md-button>
                                            </div>
                                        </md-card-content>
                                    </md-card>
                            </CCol>
                        </CRow> 
                    </md-step>
                    <md-step id="fleet" md-label="Fleet assignment" :md-error="tabs.fleet.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.fleet.errors == 0">
                        <div v-if="vehicles.length === 0">
                            <md-empty-state
                                md-icon="drive_eta"
                                md-label="Add new vehicles"
                                md-description="Add new vehicles to your fleet to assing to your drivers">
                                <md-button class="md-primary md-raised">Add new vehicle</md-button>
                            </md-empty-state>
                        </div>
                        <div v-else>
                            <CRow >
                                <CCol sm="12" md="12" lg="4" xl="4">
                                    <md-field>
                                        <label for="fleet">Fleet</label>
                                        <md-select v-model="driver.vehicles" name="fleet" id="fleet" multiple @md-selected="vehicleSelected()">
                                            <md-option v-for="vehicle in vehicles" v-bind:key="vehicle.id" :value="vehicle.id" >{{vehicle.mainInfo.brand}} {{vehicle.mainInfo.carModel}} ({{vehicle.mainInfo.plate}})</md-option>
                                        </md-select>
                                        
                                        <!--span class="md-error" v-if="!$v.driver.vehicles.required">Assing at least one vehicle for this driver</span-->
                                                
                                    </md-field>
                                </CCol>
                            </CRow>
                            
                            <CRow>
                                <CCol sm="12" md="12" lg="4" xl="4" v-for="vehicle in selectedFleet" v-bind:key="vehicle.id">
                                    <md-card class="mb-4">
                                        <md-card-media v-if="vehicle.pictures.length > 0 ">
                                            <img :src="vehicle.pictures[0]" :alt="vehicle.name">
                                        </md-card-media>
                                        <md-card-header>
                                            <div class="md-subheading">{{vehicle.mainInfo.brand}} {{vehicle.mainInfo.carModel}}</div>
                                            <div class="md-subhead">Plate {{vehicle.mainInfo.plate}}</div>
                                        </md-card-header>
                                        
                                        
                                    </md-card>
                                </CCol>

                            </CRow>
                            <md-button class="md-raised" @click="step = 'general'">Previous step</md-button>
                            <md-button class="md-raised" @click="step = 'dmv'">Next step</md-button>
                            
                        </div>
                        
                    </md-step>
                    <md-step id="dmv" md-label="DMV" :md-error="tabs.dmv.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.dmv.errors == 0">
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-datepicker name="driver-license" id="driver-license-expiration" v-model="driver.dmv.expiration" :disabled="sending">
                                    <label for="driver-license-expiration">DMV driver license expiration</label>
                                    <!--span class="md-error" v-if="!$v.driver.dmv.expiration.required">Driver license expiration is required</span-->
                                </md-datepicker>
                                
                                <md-field >
                                    <label for="driver-license-picture">DMV driver license picture</label>
                                    <md-file name="driver-license" id="driver-license-picture" v-model="driver.dmv.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'dmv')"/>
                                    <!--span class="md-error" v-if="!$v.driver.dmv.driverLicensePicture.required">Driver license picture is required</span-->
                                </md-field>
  
                            </CCol>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">
                                            <md-icon v-if="!driver.dmv.driverLicensePreview && !driver.dmv.storage" class="md-size-5x">badge</md-icon>
                                            <div class="doc-asset picture-asset">

                                                <!--div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)" v-if="driver.dmv.pdf">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.dmv.storage"    />
                                                </div-->

                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)" v-if="driver.dmv.pdf && driver.dmv.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.dmv.driverLicensePreview"    />
                                                </div>
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.dmv.storage, driver.dmv.image, driver.dmv.pdf)" v-else-if="driver.dmv.storage != null && !driver.dmv.driverLicensePreview && !driver.dmv.deleted">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.dmv.storage"    :loaded="pdfLoaded()"/>
                                                </div>

                                                <!--div class="img-preview-holder"  v-if="driver.dmv.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="driver.dmv.image"
                                                        @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>

                                                    <img :src="driver.dmv.driverLicensePreview ? driver.dmv.driverLicensePreview : ''" alt="" />
                                                </div-->


                                                <div class="img-preview-holder"  v-if="driver.dmv.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="driver.dmv.image && driver.dmv.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', driver.dmv.driverLicensePreview, driver.dmv.image, driver.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <div 
                                                        class="doc-overlay"
                                                        v-else-if="driver.dmv.image && driver.dmv.storage != null && !driver.dmv.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', driver.dmv.storage, driver.dmv.image, driver.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>

                                                    <img class="ml-0 mt-0" :src="this.driver.dmv.driverLicensePreview ? this.driver.dmv.driverLicensePreview : this.driver.dmv.storage" alt="" />
                                                    <!--img class="ml-0 mt-0" :src="this.driver.dmv.storage ? this.driver.dmv.storage : ''" alt="" /-->

                                                </div>
                                            </div>
                                            <!--img v-else :src="this.driver.dmv.driverLicensePreview" alt=""-->
                                        </md-card-media>
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ driver.dmv.driverLicense ? driver.dmv.driverLicense : 'DMV driver license'}}</span>
                                            <span class="md-subhead" v-if="driver.dmv.expiration">{{ driver.dmv.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>

                                        <!--md-card-actions>
                                            <md-button>Neither this</md-button>
                                            <md-button>Or this</md-button>
                                        </md-card-actions-->
                                    
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                        <md-button class="md-raised" @click="step = 'fleet'">Previous step</md-button>
                        <md-button class="md-raised" @click="step = 'tlc'">Next step</md-button>
                        
                    </md-step> 
                    <md-step id="tlc" md-label="TLC" :md-error="tabs.tlc.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.tlc.errors == 0">
                        <CRow>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-datepicker  name="tlc-driver-license-expiration" id="tlc-driver-license-expiration" v-model="driver.tlc.expiration" :disabled="sending">
                                    <label for="tlc-driver-license-expiration">TLC driver license expiration</label>
                                    <!--span class="md-error" v-if="!$v.driver.tlc.expiration.required">TLC Driver license expiration is required</span-->
                                </md-datepicker>

                                <md-field >
                                    <label for="tlc-driver-license-picture">TLC driver license picture</label>
                                    <md-file name="tlc-driver-license-picture" id="tlc-driver-license-picture" v-model="driver.tlc.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'tlc')"/>
                                    <!--span class="md-error" v-if="!$v.driver.tlc.driverLicensePicture.required">TLC driver license picture is required</span-->
                                </md-field>
                                
                            </CCol>
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">

                                            <!--img v-if="this.driver.tlc.driverLicensePreview" :src="this.driver.tlc.driverLicensePreview" alt="">
                                            <img v-else-if="this.driver.tlc.storage" :src="this.driver.tlc.storage" alt="">
                                            <md-icon v-else class="md-size-5x">badge</md-icon-->
                                            <div class="doc-asset picture-asset">

                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.tlc.driverLicensePreview, driver.tlc.image, driver.tlc.pdf)" v-if="driver.tlc.pdf && driver.tlc.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.tlc.driverLicensePreview"    />
                                                </div>
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', driver.tlc.storage, driver.tlc.image, driver.tlc.pdf)" v-else-if="driver.tlc.storage != null && !driver.tlc.driverLicensePreview && !driver.tlc.deleted">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="driver.tlc.storage"    :loaded="pdfLoaded()"/>
                                                </div>
                                                <div class="img-preview-holder"  v-if="driver.tlc.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="driver.tlc.image && driver.tlc.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', driver.tlc.driverLicensePreview, driver.tlc.image, driver.tlc.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <div 
                                                        class="doc-overlay"
                                                        v-else-if="driver.tlc.image && driver.tlc.storage != null && !driver.tlc.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', driver.tlc.storage, driver.tlc.image, driver.tlc.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>

                                                    <img class="ml-0 mt-0" :src="this.driver.tlc.driverLicensePreview ? this.driver.tlc.driverLicensePreview : this.driver.tlc.storage" alt="" />
                                                    <!--img class="ml-0 mt-0" :src="this.driver.tlc.storage ? this.driver.tlc.storage : ''" alt="" /-->

                                                </div>
                                            </div>
                                        </md-card-media>
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ driver.tlc.driverLicense ? driver.tlc.driverLicense : 'TLC driver license'}}</span>
                                            <span class="md-subhead" v-if="driver.tlc.expiration">{{ driver.tlc.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>

                                        <!--md-card-actions>
                                            <md-button>Neither this</md-button>
                                            <md-button>Or this</md-button>
                                        </md-card-actions-->
                                    
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                        <md-button class="md-raised" @click="step = 'dmv'">Previous step</md-button>
                        <md-button class="md-raised" @click="step = 'other-licenses'">Next step</md-button>
                    </md-step>
                    <md-step id="otherLicenses" md-label="Other licenses" :md-error="tabs.otherLicenses.errors > 0 ? 'This step needs attention' : null" :md-done="tabs.otherLicenses.errors == 0">
                        <md-empty-state
                            v-if="driver.otherLicenses.length === 0"
                            
                            md-icon="badge"
                            md-label="Custom licenses and permissions"
                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                        </md-empty-state>
                        <CRow v-else>
                            <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in driver.otherLicenses" v-bind:key="'otherLicenses-' + index ">
                            
                                <md-card class="uplimo-card" >
                                    <!--md-card-header >
                                            <div class="md-title">FH1</div>
                                    </md-card-header-->
                                    <md-card-content class="listed-doc-card">
                                        <div class="doc-asset">

                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                            </div>
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                            </div>

                                        
                                            <div class="img-preview-holder"  v-if="license.attachment.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="license.attachment.image && license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <div 
                                                    class="doc-overlay"
                                                    v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>

                                                <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                                <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />

                                            </div>
                                            
                                        </div>
                                    
                                        <div class="doc-data">
                                            <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field :class="getValidationClass('otherLicenses.name',index)">
                                                        <label>Name</label>
                                                        <md-input v-model="license.name"></md-input>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].name.required">Name is required</span>
                                                    </md-field>
                                                    <md-field :class="getValidationClass('otherLicenses.file',index)">
                                                        <label >Attachment (Image or PDF)</label>
                                                        <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].attachment.file.required">Attachment is required</span>
                                                    </md-field>
                                                    <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" :class="getValidationClass('otherLicenses.expiration',index)">
                                                        <label>Expiration Date</label>
                                                        <span class="md-error" v-if="!$v.driver.otherLicenses.$each[index].expiration.required">Expiration Date is required</span>
                                                    </md-datepicker>
                                                    <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                                    <md-button class="md-raised md-dense full-width-button" @click="removeLicense(index)">Remove license</md-button>
                                                </CCol>
                                                
                                            </CRow>
                                            
                                            

                                        </div>  
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                    <md-card-header >
                                    </md-card-header>
                                    <md-card-content >
                                        <md-empty-state
                                
                                            
                                            md-icon="badge"
                                            md-label="Add new"
                                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                                        </md-empty-state>
                                    </md-card-content>
                                </md-card>
                            </CCol>

                        </CRow>
                        <md-button class="md-raised" @click="step = 'tlc'">Previous step</md-button>
                    </md-step> 
                    
                
                </md-steppers>
            </form>
            <md-dialog :md-active.sync="docDialog.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog"  >
                <md-dialog-title v-if="docDialog.name">
                    {{ docDialog.name}}
                    
                </md-dialog-title>
                <md-divider class=" mb-4">
                </md-divider>
                <div class="modal-up-content text-center">
                    <img :src="docDialog.src" alt="" v-if="docDialog.image">


                    <md-button :disabled="docDialog.page == 1" @click="prevPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Prev</md-button>
                    <md-button :disabled="docDialog.page == docDialog.pageCount" @click="nextPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Next</md-button>

                    <div class="popup-pdf-holder" v-if="docDialog.pdf">
                        <vue-pdf-embed :source="docDialog.src"   :page="docDialog.page" ref="masterPdf" :width="pdfWidth"/>
                    </div>
                    
                </div>
                <md-dialog-actions>
                    <md-button @click="docDialog.active = false">Close</md-button>
                
                </md-dialog-actions>
            </md-dialog>
            <md-dialog
                :style="[dialogDimensions]"
                :md-active.sync="confirmDialog"
            >
                <md-dialog-title>What would you like to do ?</md-dialog-title>
                <md-dialog-content>
                    <p>You made changes in this driver. Choose your action </p>
                    <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                    <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                    <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save &amp; exit</md-button>
                </md-dialog-content>

                
                
            </md-dialog>
            <md-dialog :md-active.sync="deleteDialog.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog">
     
                <md-dialog-title>
                    {{deleteDialog.title}}
                </md-dialog-title>
                <md-divider class=" mb-4">
                </md-divider>
                <div class="modal-up-content text-center" v-if="deleteDialog.busy">
                    <md-progress-spinner class="md-accent mb-3" :md-stroke="4" md-mode="indeterminate" ></md-progress-spinner>
                </div>
                
                <div class="modal-up-content" v-if="!deleteDialog.busy">
                    
                    <div class="md-display-1 mt-3" :class="{'up-text-confirmed' : deleteDialog.unlocked , 'up-text-cancelled': !deleteDialog.unlocked}">  
                    <md-icon  class="md-icon-40" :class="{'up-text-confirmed' : deleteDialog.unlocked , 'up-text-cancelled': !deleteDialog.unlocked}">{{ deleteDialog.unlocked ? 'lock_open' : 'lock_outline' }}</md-icon>
                    {{ deleteDialog.unlockCode}}
                    </div>
                    <md-field>
                    <label>Type above code to unlock </label>
                    <md-input v-model="deleteDialog.unlockTry" @keyup="checkLock"></md-input>
                    </md-field>
                </div>
                <md-dialog-actions v-if="!deleteDialog.busy">
                    <md-button @click="deleteDialog.active = false">Close</md-button>
                    <md-button class="md-accent md-raised" @click="destroy" :disabled="!deleteDialog.unlocked">Delete driver</md-button>
                </md-dialog-actions>
            </md-dialog>
            <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
        
        </div>
    </div>
</template>

<script>

import moment from 'moment'
import moment_timezone from "moment-timezone";
import firebase from '../../Firebase'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import imageCompression from "browser-image-compression"
import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength,
    requiredIf
  } from 'vuelidate/lib/validators'

import axios from "./../../helpers/axios"
import axiosInstance from 'axios'

export default {
  name: 'CompanyDriver',
  components:{VuePdfEmbed},
  mixins: [validationMixin],
  data() {
    return {
        deleteDialog: {
            title: 'Are you sure you want to delete this driver?',
            available: false,
            active:false,
            error: {
            title: null,
            message: null
            },
            confirmed: false,
        
            
            unlockCode: null,
            unlockTry: null,
            unlocked: false,
            busy: false

        },
        upload: {
            currentTitle: '',
            totalProgress: 0,
            uploadedDocs: [],
            currentFileProgress: 0,
            actualBytes: 0,
            totalBytes: 0,
            active: false
        },
        isDeleting: false,
        isEdited: false,
        isBusy: true,
        tabs: {
            general: {
                errors: null,
            },
            fleet: {
                errors: null,
            },
            coveredArea: {
                errors: null,
            },
            dmv: {
                errors: null,
            },
            tlc: {
                errors: null,
            },
            otherLicenses: {
                errors: null
            }
        },
        docDialog: {
            name: null,
            active: false,
            src: null,
            pdf: false,
            image: false,
            pageCount: null,
            page: 1

        },
        dialogLeft:0,
        role: '',
        userSaved: false,
        sending: false,
        lastUser: null,
        step: "general",
        vehicles: [],
        removedLicenses: [],
        driver: null,
        confirmDialog: false,
        goTo: null,
        confirmLeave: false,
        areas:[],
        inputFocused: false,
        areaValidation: {
            message: '',
            valid: true
        },
        mapTypes: ['political'],
        geofence: {
            name: null,
            code:null,
            path: [],
            zoom:7,
            center: {lat: 1.38, lng: 103.8},
        },
        removedAreas: [],
      
    }
  },
  watch: {
    'areas': {
        handler(val,oldVal) {
            
            if(oldVal != null) {
                
                this.validateAllSteps(false)
                this.isEdited = true;
            }
        },
        deep: true

    },
    'driver': {
        handler(val,oldVal) {
            console.log('val',val)
            console.log('oldVal',oldVal)
            if(oldVal != null) {
                console.log('Driver watch')
                this.isEdited = true;
            }
        },
        deep: true

    },
    '$store.state.sidebarMinimize': function() {
        this.$store.commit('centerDialog',window.innerWidth)
    },
    '$store.state.sidebarShow': function(){
        this.$store.commit('centerDialog',window.innerWidth)
    },
  },
  computed: {
    areaPaths() {
        console.log('computing')
        let paths = []
        

        

        const areas = this.areas.map( (area,index) => {
            let pathsToSave = []
            for (const path in area.paths) {
                if (Object.hasOwnProperty.call(area.paths, path)) {
                    const element = area.paths[path];
                    let dataToSave = {
                        existant: true,
                        path: null
                    }
                    dataToSave.path = element
                    console.log(element)
                    pathsToSave.push(dataToSave)
                   
                    
                }
            }
            return pathsToSave
        })

        for (let j = 0; j <  areas.length; j++) {
            
            paths = paths.concat(areas[j])
        }


        
        
        return paths
    },
    user() {
      return this.$store.state.user;
    } ,
    selectedFleet: function() {
      let th = this
      return this.driver.vehicles.map(function(i) {
        for (let j = 0; j < th.vehicles.length; j++) {
            if(th.vehicles[j].id == i) {
                return th.vehicles[j]
            }
            
        }
        
      });
    },
    dialogDimensions () {
        console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    dialogHeight() {
        console.log(window)
        console.log(window.innerHeight)
        
        return {
            height: window.innerHeight - this.headerHeight + 'px',
            top: this.headerHeight + 'px'
            //height: this.$store.state.sidebarNavHeight + 'px'
        }
    },
    pdfWidth () {
        return  (window.innerWidth - this.dialogLeft) * 0.9 
        
    },
    headerHeight() {
        return this.$store.state.headerHeight;
    },
  },
  methods: {
    removeFence(area,index,newArea) {
        console.log(area)
        console.log(index)
        console.log(newArea)

        if(newArea) {
            // for (let i = 0; i < area.indexes.length; i++) {
            //     this.companyProfile.coveredArea.paths.splice(area.indexes[i],1)
                
            // }

            this.areas.splice(index,1)
            this.$refs.autocomplete.$el.focus()
            this.$refs.autocomplete.$el.blur()
        } else {
            this.removedAreas.push(area)
            this.areas.splice(index,1)
        }
        
        
    },
    async setFence(place) {
        console.log(place)
        let newFence = {
            formatted_address:place.formatted_address,
            indexes: [],
            address_components: place.address_components,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            },
            paths: [],
            newArea: true
        }
        this.geofence.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        let placeLevel = place.address_components[0].types[0]
        console.log('placeLevel',placeLevel)

        newFence.name = place.formatted_address
        // Google naming 

        // country
        // administrative_area_level_1
        // locality
        // sublocality_level_1

        let boundarie = await this.getBoundarie(this.geofence.center.lat, this.geofence.center.lng)
        this.driver.coveredArea.coordinates.lat = this.geofence.center.lat
        this.driver.coveredArea.coordinates.lng = this.geofence.center.lng
        this.driver.coveredArea.zoom = 10
        let categoryToLook = null;
        let alternativeCategoryToLook = null;
        let secondAlternativeCategoryToLook = null;
        switch(placeLevel) {
            case 'country':
                //categoryToLook = "administrative.country_part_level"
                categoryToLook = "administrative.country_level"
                break;
            case 'administrative_area_level_1':
                //categoryToLook = "administrative.state_level"
                //categoryToLook = "administrative.county_level"
                categoryToLook = "administrative.country_part_level"
                break;
            case 'administrative_area_level_2':
                //categoryToLook = "administrative.state_level"
                categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.district_level"
                break;
            case 'locality':
                //categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.city_level"
                categoryToLook = "administrative.state_level"
                alternativeCategoryToLook = "administrative.district_level"
                // ithaca, newy york : syracuse ny
                secondAlternativeCategoryToLook = "administrative.city_level"
                    // ex New York , New York
                // ex New York , New York
                break;
            case 'sublocality_level_1':
                categoryToLook = "administrative.city_level"
                // ex. Manhattan, Bronx, Queens
                break;
            case 'neighborhood':
                //categoryToLook = "administrative.city_level"
                this.areaValidation.message = 'Search a place larger than a neighborhood'
                break;
            
        }
        let finalFeature = null
        if(categoryToLook || alternativeCategoryToLook || secondAlternativeCategoryToLook) {
            for (let i = 0; i < boundarie.features.length; i++) {
                if(
                    boundarie.features[i].properties.categories[1] === categoryToLook
                    || boundarie.features[i].properties.categories[1] === alternativeCategoryToLook
                    || boundarie.features[i].properties.categories[1] === secondAlternativeCategoryToLook
                    ) 
                {
                    finalFeature = boundarie.features[i]
                }
            }

        } else {
            this.areaValidation.valid = false;

        }
        console.log('finalFeature',finalFeature)

        if(finalFeature) {
            if(finalFeature.geometry.type === "Polygon") {
                console.log("Polygon")
                let pathToSave = []

                for (let i = 0; i < finalFeature.geometry.coordinates[0].length; i++) {
                    pathToSave.push({
                        lng: finalFeature.geometry.coordinates[0][i][0],
                        lat: finalFeature.geometry.coordinates[0][i][1],
                    })
                    
                    
                }
                //let newIndex = this.driver.coveredArea.paths.push(pathToSave) - 1
                let newIndex = newFence.paths.push(pathToSave) - 1
                newFence.indexes.push(newIndex)
            } else if(finalFeature.geometry.type === "MultiPolygon") {
                console.log("MultiPolygon")
                for (let i = 0; i < finalFeature.geometry.coordinates.length; i++) {
                    let pathToSave = []
                    for (let j = 0; j < finalFeature.geometry.coordinates[i][0].length; j++) {
                        pathToSave.push({
                            lng: finalFeature.geometry.coordinates[i][0][j][0],
                            lat: finalFeature.geometry.coordinates[i][0][j][1],
                        })
                        
                        
                    }
                    //let newIndex =  this.driver.coveredArea.paths.push(pathToSave) - 1
                    let newIndex =  newFence.paths.push(pathToSave) - 1
                    newFence.indexes.push(newIndex)
                    
                }
            }
            //this.driver.coveredArea.fencesList.push(newFence)
            this.areas.push(newFence)
        }

        
        

        // Boundaries API naming 
        
        // "administrative.country_part_level"
        // "administrative.state_level"
        // "administrative.county_level"
        // "administrative.city_level"

        //Get the boundaries of the Map.
        
        var bounds = this.$refs.fenceMap.$mapObject.getBounds();
        this.$refs.fenceMap.fitBounds(bounds)

        this.$refs.autocomplete.$el.value = ''

        //this.$nextTick(() => {
           
        //})
        
        this.$refs.autocomplete.$el.focus()
        this.$refs.autocomplete.$el.blur()
        //Center map and adjust Zoom based on the position of all markers.
        // map.setCenter(latlngbounds.getCenter());
        // map.fitBounds(latlngbounds);

        
    },
    async getBoundarie(lat, lon) {
        let params = {
            apiKey: '3f5d675290ce41e08917bc9f40516382',
            geometry: 'geometry_10000',
            //geometry: 'point',
            boundary: 'political',
            lat: lat,
            lon: lon

        }
        return new Promise((resolve, reject) => {
            axiosInstance.create({
                headers: {},                    
            }).get('https://api.geoapify.com/v1/boundaries/part-of',
            {params: params})
            .then(response =>{
                console.log(response)
                resolve(response.data)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    updateEdited(mvcArray,existant,actualPath,index) {
        let paths = [];
        for (let i=0; i<mvcArray.getLength(); i++) {
            let path = [];
            for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
            }
            paths.push(path);
        }

        if(existant) {
            this.areas[index].path = paths[0]
            this.areas[index].edited = true
        } else {
            this.driver.coveredArea.paths[index] = paths[0]
        }
        
        
    },
    resetCursor(){
        this.inputFocused = false
    },
    locateCursor(){
        this.$refs.autocomplete.$el.focus()
        this.inputFocused = true
    },
    checkLock() {
      this.deleteDialog.unlockCode === this.deleteDialog.unlockTry ? this.deleteDialog.unlocked = true : this.deleteDialog.unlocked = false
    },
    fireDeleteDialog() {
        this.deleteDialog.active = true
    },
    async removeEntireFolder() {
        let email = this.user.data.email ;
        let urlList = [];


        let dmv = this.driver.dmv.storage

        if(dmv) {
            urlList.push(dmv)
        }

        let tlc = this.driver.tlc.storage

        if(tlc) {
            urlList.push(tlc)
        }
       
        
        for (let i = 0; i < this.driver.otherLicenses.length; i++) {
            let url = this.driver.otherLicenses[i].attachment.storage;
            if(url) {
                //await this.removeFileFromStorage(url)
                urlList.push(url)
            }
        }

        

        console.log(urlList)


        /////
        const promises = urlList.map((file, index) => {
            console.log(file);
            let fileRef = this.$firebase.storage().refFromURL(file);
            return fileRef.delete()
              .then(() => {
                console.log('deleted successfully');
              })
              .catch((err) => {
                console.log(err);
              });
        })
        return Promise.all(promises)
        .then(() => {
            console.log('Files deleted');
            
        })
        .catch((err) => alert(err.code));
        /////


        // await bucket.deleteFiles({
        //     prefix: `${folderName}/`
        // });
    },
    async saveAndExit() {
        // this.isBusy = true;
        // if(this.validateAllSteps()){
        //     await this.update()
        //     this.onConfirm()
        // }
        // this.isBusy = false;
       
        this.fireUpdate()
    },
    onCancel() {
        this.confirmDialog = false
    },
    onConfirm() {
        this.confirmLeave = true
        this.$router.push({path: this.goTo.path})
    },
    preventNav(event) {
        console.log(event)
        if (!this.isEdited) return
        event.preventDefault()
        event.returnValue = ""
    },
    async imagesLoaded(){
        let th = this
        var resources = [];
        // const images = this.vehicle.pictures.map(imageSrc => {
        //     return new Promise((resolve, reject) => {
        //         const img = new Image();
        //         img.src = imageSrc;
        //         img.onload = resolve;
        //         img.onerror = reject;
        //     });
        // });
    

        // var assetBox = [];
        // for (const doc in this.vehicle.documents) {
        //     if (Object.hasOwnProperty.call(this.vehicle.documents, doc)) {
        //         const element = this.vehicle.documents[doc];
        //         if(element.storage) {
        //             assetBox.push(
        //                 new Promise((resolve, reject) => {
                            
        //                     const resource = new Image()
        //                     resource.src = element.storage
        //                     resource.onload = resolve;
        //                     resource.onerror = reject;
        //                 })
        //             )
        //         }
        //     }
        // }
        // console.log('assetBox',assetBox)

        Promise.all(resources).then(() => { 
            this.$nextTick(() => {
                //this.isBusy = false;
                console.log('Resoureces loaded')
            })
            
        }).catch(error => {
            console.error("Some image(s) failed loading!");
            console.error(error.message)
           
        });
    },
    async getDriver() {
        let th = this;
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('drivers').doc(this.$route.params.id).get()
            .then(function(doc) {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                   
                    let dataToShow = doc.data()

                    if(!dataToShow.coveredArea) {
                        dataToShow.coveredArea = {
                            coordinates: {
                                lat: 40,
                                lng: -73
                            },
                            zoom: 10
                        }
                    }
                    if(dataToShow.dmv.expiration) {

                        dataToShow.dmv.expiration = parseInt(moment.unix(dataToShow.dmv.expiration).format('x'))
                    }

                    if(dataToShow.tlc.expiration) {
                        
                        dataToShow.tlc.expiration = parseInt(moment.unix(dataToShow.tlc.expiration).format('x'))
                    }
                    if(dataToShow.otherLicenses.length > 0) {
                        for (let i = 0; i < dataToShow.otherLicenses.length; i++) {
                            dataToShow.otherLicenses[i].expiration = parseInt(moment.unix(dataToShow.otherLicenses[i].expiration).format('x'))
                            
                        }
                    }
                    th.driver = dataToShow;
                    th.deleteDialog.unlockCode = th.driver.general.email
                    
                    
                    //th.isBusy = false
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
                resolve()
            }).catch(function(error) {
                console.log("Error getting document:", error);
                reject()
            });

        }) 
    },
    pdfLoaded(call){
        //console.log(call)
    },
    async uploadDocumentsAttachments() {
        let email = this.user.data.email ;
        let th = this;
       
        
        const promises = this.driver.otherLicenses.map( async (license, index) => {

            if(license.changed) {

                return new Promise(async (resolve, reject) => {
                    let url = license.attachment.storage;
                    if(url) {
                        await this.removeFileFromStorage(url)
                    }
                    let timestamp = moment().format('x')
                    this.upload.active = true
                    const compressedFile = license.attachment.image ? await this.compressImage(license.attachment.file) : license.attachment.file
                    th.upload.currentFileProgress = 0
                    th.upload.currentTitle = `Currently uploading ${license.name}`
                    let now = moment().format('x')
                    let destination = `drivers/`+ th.driver.general.email +`/other-licenses/${license.name + now}`
                    //`users/`+ email +`/fleet/${hash}/other-licenses/${license.name}`



                    let storage = await this.uploadDocAttachment(destination, compressedFile)
                    console.log(storage)
                   
                        
                    license.attachment.storage = storage
                    license.attachment.preview = null

                    delete license.attachment.file
                    delete license.changed
                    this.upload.active = false
                    resolve()
                    
                    

                })


            }

            if(license.deleted && license.changed === null) {
                return new Promise(async (resolve, reject) => {
                    let url = license.attachment.storage;
                    console.log(url)
                    let removedFile = await this.removeFileFromStorage(url)
                    removedFile.then(() => {
                        
                        resolve()
                    })
                    .catch((err) => {
                        console.log(err);
                        reject()
                    });


                })
               
            }
            // this.upload.uploadedDocs.push(`${type.toUpperCase()} driver license`)
        })

        if(this.removedLicenses.length > 0){
            for (let i = 0; i < this.removedLicenses.length; i++) {
                
                if(this.removedLicenses[i].deleted && this.removedLicenses[i].changed === null) {
                    let url = this.removedLicenses[i].attachment.storage;
                    console.log(url)
                    await this.removeFileFromStorage(url)
                
                }
            }

        }

        return Promise.all(promises)
        .then((uploadedMediaList) => {
            console.log(uploadedMediaList, 'all');
            
        })
        .catch((err) => alert(err.code));

       
    },

    async removeFileFromStorage(url) {
        let th = this
        return new Promise((resolve, reject) => {
            let pictureRef = this.$firebase.storage().refFromURL(url);
            pictureRef.delete()
              .then(() => {
                console.log('deleted successfully');
                resolve()
              })
              .catch((err) => {
                console.log(err);
                reject()
              });
        })
    },
    async compressImage(file) {
       
       
       return new Promise(async (resolve, reject) => {
       
           const imageFile = file;
           console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
           console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);     


           const options = {
               maxSizeMB: 1,
               maxWidthOrHeight: 1200,
               useWebWorker: true
           }

           
           
           imageCompression(imageFile, options)
           .then(function (compressedFile) {
               console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
               console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

               resolve(compressedFile); // write your own logic
           })
           .catch(function (error) {
               reject(error)
           });
       });
           
           
         
           //await uploadToServer(compressedFile); // write your own logic
      
   },
    async uploadDocAttachment(destination, file) {
        let email = this.user.data.email ;
        let th = this;
        return new Promise((resolve, reject) => {

            const storageRef =  this.$firebase.storage().ref()
                                .child(destination)
                                .put(file);

            storageRef.on(`state_changed`,
                snapshot => {
                    //this.driver[type].driverLicenseUploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    //license.uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    th.upload.currentFileProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    th.upload.actualBytes = snapshot.bytesTransferred
                    th.upload.totalBytes = snapshot.totalBytes
                }, 
                error => {
                    console.log(error.message)
                    reject(error);
                },
                async () => {
                    const imgURL = await storageRef.snapshot.ref.getDownloadURL();
                    console.log("uploaded image: " + imgURL);
                    

                    // this.driver.otherLicenses[k].attachment.storage = uploadedMediaList[k]
                    // let dataToSave = this.driver.otherLicenses[k]
                    // dataToSave.attachment.preview = null
                    // delete dataToSave.attachment.file

                    //this.upload.uploadedDocs.push(`${license.name} driver license`)

                    resolve(imgURL)
                    

                }
            );

           
        })
    },
    
    async updateAttachments() {
        let email = this.user.data.email ;

        var dmv = this.driver.dmv
        console.log(dmv)
        if(this.driver.dmv.changed) {
            this.upload.currentTitle = `Updating DMV`;
            let url = this.driver.dmv.storage;
            console.log(url)
            if(url) {
                await this.removeFileFromStorage(url)
            }
           
            let type = 'dmv'
            //let destination = `users/`+ email +`/fleet/${this.$route.params.id}/documents/${doc}/${element.file.name}`
            let destination = `drivers/${this.driver.general.email}/${type}-driver-license/${this.driver[type].driverLicensePicture}`

            
            let dmv = await this.uploadLicense('dmv')

            //   let storage = await this.uploadDocAttachment(destination, this.driver.dmv.file)
            //   console.log(storage)
            //   this.vehicle.documents[doc].storage = storage
        }

        if(this.driver.dmv.deleted && this.driver.dmv.changed === null) {
            this.upload.currentTitle = `Deleting DMV`;
            let url = this.driver.dmv.storage;
            console.log(url)
            if(url) {
                await this.removeFileFromStorage(url)
            }
            
        //   this.vehicle.documents[doc].storage = null
        }


        var  tlc = this.driver.tlc
        console.log(tlc)
        if(this.driver.tlc.changed) {
            this.upload.currentTitle = `Updating TLC`;
            let url = this.driver.tlc.storage;
            console.log(url)
            if(url) {
                await this.removeFileFromStorage(url)
            }
            let type = 'tlc'
            //let destination = `users/`+ email +`/fleet/${this.$route.params.id}/documents/${doc}/${element.file.name}`
            let destination = `drivers/${this.driver.general.email}/${type}-driver-license/${this.driver[type].driverLicensePicture}`

            
            let tlc = await this.uploadLicense('tlc')

            //   let storage = await this.uploadDocAttachment(destination, this.driver.tlc.file)
            //   console.log(storage)
            //   this.vehicle.documents[doc].storage = storage
        }

        if(this.driver.tlc.deleted && this.driver.tlc.changed === null) {
            this.upload.currentTitle = `Deleting DMV`;
            let url = this.driver.tlc.storage;
            console.log(url)
            if(url) {
                await this.removeFileFromStorage(url)
            }
            
        //   this.vehicle.documents[doc].storage = null
        }

        /*
        for (let i = 0; i < this.otherLicenses.length; i++) {
        
            if(this.otherLicenses[i].changed) {
                let url = this.otherLicenses[i].attachment.storage;
                if(url) {
                    await this.removeFileFromStorage(url)
                }
                let timestamp = moment().format('x')
                let destination = `users/`+ email +`/fleet/${this.$route.params.id}/other-licenses/${timestamp + this.otherLicenses[i].name + this.otherLicenses[i].attachment.file.name }`
                //`users/`+ email +`/fleet/${hash}/other-licenses/${this.otherLicenses[i].name}`
                let storage = await this.uploadDocAttachment(destination, this.otherLicenses[i].attachment.file)
                console.log(storage)
                this.otherLicenses[i].attachment.storage = storage
                this.otherLicenses[i].attachment.preview = null

                delete this.otherLicenses[i].attachment.file
                delete this.otherLicenses[i].changed

                


            }

            if(this.otherLicenses[i].deleted && this.otherLicenses[i].changed === null) {
                let url = this.otherLicenses[i].attachment.storage;
                console.log(url)
                await this.removeFileFromStorage(url)
               
            }
        }

        if(this.removedLicenses.length > 0){
            for (let i = 0; i < this.removedLicenses.length; i++) {
                
                if(this.removedLicenses[i].deleted && this.removedLicenses[i].changed === null) {
                    let url = this.removedLicenses[i].attachment.storage;
                    console.log(url)
                    await this.removeFileFromStorage(url)
                
                }
            }

        }
        */

    },
    async UserProfile() {
        let th = this

        let displayName ;
        if(!this.driver.general.displayName || this.driver.general.displayName === '') {
            displayName = this.driver.general.name
        } else {
            displayName = this.driver.general.displayName
        }
        let data = {
            uid: this.driver.general.uid,
            properties: {
                displayName: displayName
            }
        }
        return new Promise((resolve, reject) => {
            axios.post('drivers/update-user-profile',data).then(response =>{
                console.log(response);
                
                resolve(response)
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                
                reject(e)
                
            }) 
        })
    },
    async firestoreUpdate(){
        let dataToSend = this.driver
        dataToSend.dmv.expiration = parseInt(moment(this.driver.dmv.expiration).format('X'))
        dataToSend.tlc.expiration = parseInt(moment(this.driver.tlc.expiration).format('X'))
        if(this.driver.otherLicenses.length > 0) {
            for (let i = 0; i < this.driver.otherLicenses.length; i++) {
                dataToSend.otherLicenses[i].expiration = parseInt(moment(this.driver.otherLicenses[i].expiration).format('X'))
                
            }
        }

        let displayName ;
        if(!this.driver.general.displayName || this.driver.general.displayName === '') {
            displayName = this.driver.general.name
        } else {
            displayName = this.driver.general.displayName
        }

        dataToSend.general.displayName = displayName
        return new Promise((resolve,reject) => {
            firebase.firestore().collection('drivers').doc(this.driver.general.email).update(dataToSend)
            .then(function(){
                resolve()
            }).catch(function(error) {
                console.log("Error getting document:", error);
                reject()
            });
        })
    },
    async fireUpdate(){
        let th = this;
        if(this.validateAllSteps()) {
            this.isBusy = true;
            this.confirmDialog = false
            this.confirmLeave = true
            this.sending = true;
            console.log('Fire Update')
            // console.log(this.$v)
            this.upload.currentTitle = 'Starting update'
            var userRecord
            if(this.$v.driver.general.displayName.$dirty) {
                this.upload.currentTitle = 'Updating user profile'
                userRecord = await this.UserProfile()
            }
            
            await this.updateAttachments();
            await this.uploadDocumentsAttachments()
            this.upload.currentTitle = `Updating database`;


            await this.deleteRemovedAreas()
            let polygons = this.driver.coveredArea.paths
            let fencesList = this.driver.coveredArea.fencesList
            let newDocId = this.$route.params.id
            delete this.driver.coveredArea.paths
            delete this.driver.coveredArea.fencesList
            await this.createPolygonsAreas(newDocId)
            await this.updateEditedAreas()



            await this.firestoreUpdate()
            th.$router.push({path: `/drivers/company-drivers`})
            //await this.getAreas()
            this.isBusy = false;



        }

       

    },
    async deleteRemovedAreas() {
        let th = this
        const promises = this.removedAreas.map(async (area, index) => {
            return firebase
            .firestore()
            .collection('drivers')
            .doc(th.$route.params.id)
            .collection('areas')
            .doc(area.id)
            .delete()
        })

        return Promise.all(promises)
        .then((callback) => {
            console.log(callback, 'callback');
            
        })
        .catch((err) => alert(err));

    },
    async createPolygonsAreas(id) {
        let th = this
        const promises = this.areas.map( async (fence, index) => {


            if(fence.newArea) {
                
                return new Promise((resolve, reject) => {
                    
                    console.log(fence)
                    let pathsToSave = {}
                    for (let j = 0; j < fence.paths.length; j++) {
                        pathsToSave[j] = fence.paths[j]
                        
                    }
    
                    console.log(pathsToSave)
                    fence.paths = pathsToSave
                    delete fence.newArea
                    firebase.
                    firestore()
                    .collection('drivers')
                    .doc(th.$route.params.id)
                    .collection('areas')
                    .add(fence)
                    .then(function(docRef) {
                        console.log("Tutorial created with ID: ", docRef.id);
                        resolve()
                    })
                    .catch(function(error) {
                        console.error("Error adding Tutorial: ", error);
                        reject()
                    });
                    
    
                    
                    
                })
            }

        })

        return Promise.all(promises)
        .then(() => {
            console.log('Promises full filled')
        })
        .catch((err) => {
            console.log(err)
        });
    },
    async updateEditedAreas() {
        let th = this
        const promises = this.areas.map( async (area, index) => {
            if(area.edited) {
                return new Promise((resolve, reject) => {
                    console.log(area)
                    let pathsToSave = {}
                    for (let j = 0; j < area.paths.length; j++) {
                        pathsToSave[j] = area.paths[j]
                        
                    }
    
                    console.log(pathsToSave)
                    area.paths = pathsToSave

                    delete area.edited
                    firebase.
                    firestore()
                    .collection('drivers')
                    .doc(th.$route.params.id)
                    .collection('areas')
                    .doc(area.id)
                    .update(area)
                    .then(function(docRef) {
                        console.log(docRef);
                        resolve()
                    })
                    .catch(function(error) {
                        console.error("Error adding Tutorial: ", error);
                        reject()
                    });

                })

            }
            
        })

        return Promise.all(promises)
        .then(() => {
            console.log('Promises full filled')
        })
        .catch((err) => {
            console.log(err)
        });
    },
    
    openDocDialog(name,src,image,pdf) {
        this.docDialog.page = 1
        this.docDialog.name = name
        this.docDialog.src = src
        
        this.docDialog.image = image
        this.docDialog.pdf = pdf
        this.docDialog.active = true
        this.$nextTick(() => {
            
            let pdf = this.$refs.masterPdf
            
            
            if(pdf) {
                this.docDialog.pageCount = this.$refs.masterPdf.pageCount
            }
            this.$store.commit('centerDialog',window.innerWidth)
        })
        
    },
    docAttachLicenseChanged(e, index) {
        console.log(e);
        console.log(e.target.files[0].type);
        this.driver.otherLicenses[index].changed = true;

        if(e.target.files[0].type == `application/pdf`) {
            this.driver.otherLicenses[index].attachment.pdf = true
            this.driver.otherLicenses[index].attachment.image = false
        } else {
            this.driver.otherLicenses[index].attachment.pdf = false
            this.driver.otherLicenses[index].attachment.image = true
        }

        this.driver.otherLicenses[index].attachment.file = e.target.files[0]
        this.driver.otherLicenses[index].attachment.preview = URL.createObjectURL(e.target.files[0]);
        
    },
    checkTimeToGo(date) {
        var startTime = moment();
        var endTime = moment(date); 
        var minutesDiff = endTime.diff(startTime, 'minutes');
        var daysDiff = endTime.diff(startTime, 'days');
        var ms = endTime.diff(startTime);
        var d = moment.duration(ms);
        let className ;
        
        if(daysDiff < 0  || minutesDiff < 0) {
            className = 'up-text-cancelled'
        } else if(daysDiff >= 0 && daysDiff <= 30) {
            className = 'up-text-caution'
        } else if(daysDiff > 30) {
            className = 'up-text-confirmed'
        }

        let payload ; 
        let text = minutesDiff >= 0 ? 'Expires ' : 'Expired '  
        payload = '<div class="text-uppercase time-to-go ' + className + '">'+ text + endTime.fromNow() + '</div>'
        return payload ;
        
    },
    createLicense(){
        let license = {
            name: null,
            expiration: null,
            attachment: {
                preview: null,
                file:null,
                name: null,
                pdf: false,
                image: false
            }
        }

        this.driver.otherLicenses.push(license)
    },
    removeLicense(index) {
        if(this.driver.otherLicenses[index].attachment.storage) {
            this.driver.otherLicenses[index].changed = null
            this.driver.otherLicenses[index].deleted = true
            this.removedLicenses.push(this.driver.otherLicenses[index])
            this.driver.otherLicenses.splice(index, 1);
        } else {
            this.driver.otherLicenses.splice(index, 1);
        }
    },
    vehicleSelected() {
    },
    async uploadLicense(type) {
        let th = this;
        return new Promise((resolve, reject) => {
            console.log("Uploading image ...");
            this.upload.active = true
            console.log(type);
            this.driver[type].storage = null;        
            const storageRef = this.$firebase.storage().ref()
                                .child(`drivers/${this.driver.general.email}/${type}-driver-license/${this.driver[type].driverLicensePicture}`)
                                .put(this.driver[type].file);

            storageRef.on(`state_changed`,
                snapshot => {
                    this.driver[type].driverLicenseUploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    th.upload.currentFileProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    th.upload.actualBytes = snapshot.bytesTransferred
                    th.upload.totalBytes = snapshot.totalBytes
                }, 
                error => {
                    console.log(error.message)
                    reject(error);
                },
                async () => {
                    const imgURL = await storageRef.snapshot.ref.getDownloadURL();
                    console.log("uploaded image: " + imgURL);
                    this.driver[type].storage = imgURL;
                    delete this.driver[type].file;
                    delete this.driver[type].changed;
                    this.driver[type].driverLicensePreview = null;
                    this.driver[type].driverLicenseUploadProgress = 0;
                    this.upload.active = false
                    resolve(imgURL);

                }
            );
        });
    },
    imageChanged(e, type) {
        this.driver[type].changed = true
        if(e.target.files[0].type == 'application/pdf') {
            this.driver[type].pdf = true
            this.driver[type].image = false
        } else {
            this.driver[type].pdf = false
            this.driver[type].image = true  
        }
        this.driver[type].file = e.target.files[0]
        this.driver[type].driverLicensePreview = URL.createObjectURL(e.target.files[0]);
    },
    nextOrSave(){
        console.log(this.step);
        console.log(this.$v);
        switch(this.step) {
            case 'general':
                
                if(this.validateStep(this.step)){
                    this.step = 'dmv'
                } else {
                    this.$v.driver.$touch()
                    // this.$v.driver.general.name.$touch();
                    // this.$v.driver.general.lastname.$touch();
                    // this.$v.driver.general.email.$touch();
                    // this.$v.driver.general.displayName.$touch();
                    // this.$v.driver.general.password.$touch();
                }
                break
            case 'fleet':
                if(this.validateStep(this.step)){
                    // Save
                    this.step = 'dmv'
                } else {
                    this.$v.driver.vehicles.$touch();
                    //this.$v.driver.tlc.driverLicensePicture.$touch();
                }
            break
            case 'dmv':
                if(this.validateStep(this.step)){
                    this.step = 'tlc'
                } else {
                    console.log(false);
                    this.$v.driver.dmv.expiration.$touch();
                    this.$v.driver.dmv.driverLicensePicture.$touch();
                }
                break
            case 'tlc':
                if(this.validateStep(this.step)){
                    // Save
                } else {
                    this.$v.driver.tlc.expiration.$touch();
                    this.$v.driver.tlc.driverLicensePicture.$touch();
                }
                break
            
        }
    },
    validateAllSteps(){
        let validSteps = [];
        let firstCorrectableStep = undefined;
        let steps = ['general','fleet','dmv','tlc','otherLicenses'] ;
        for (let i = 0; i < steps.length; i++) {
            if(this.validateStep(steps[i])) {
                validSteps.push(true)
            } else {
                validSteps.push(false)
                if(firstCorrectableStep === undefined) {
                    firstCorrectableStep = steps[i] ;
                }
            }
                    
        }
        
        if(validSteps.includes(false)) {
            
            this.step = firstCorrectableStep
            //this.mainActiveTab = 'general' ;
            this.$v.$touch();
            return false;
        } else {
            this.$v.$touch();
            return true
            
            //this.add();
            //this.saveVehicle()
        }

        
    },
    validateStep(step){
        switch(step) {
            case 'general':
                this.tabs.general.errors = 0 ;
                let name,lastname,email,displayName,password,phone ;
                name = this.$v.driver.general.name.$invalid;
                lastname = this.$v.driver.general.lastname.$invalid;
                //phone = this.$v.driver.general.phone.$invalid;
                email = this.$v.driver.general.email.$invalid;
                //displayName = this.$v.driver.general.displayName.$invalid;
                //password = this.$v.driver.general.password.$invalid;

                name ? this.tabs.general.errors++ : false;
                lastname ? this.tabs.general.errors++ : false;
                email ? this.tabs.general.errors++ : false;
                //phone ? this.tabs.general.errors++ : false;
                //displayName ? this.tabs.general.errors++ : false;
                //password ? this.tabs.general.errors++ : false;


                return !name && !lastname && !email ? true : false ;
            
            case 'fleet':
                let vehicles;
                this.tabs.fleet.errors = 0 ;

                // vehicles = this.$v.driver.vehicles.$invalid

                // vehicles ? this.tabs.fleet.errors++ : false;

                // return !vehicles ? true : false
                return true
                
            case 'dmv':
                let driverLicense, expiration, driverLicensePicture ;
                this.tabs.dmv.errors = 0 ;


                // expiration = this.$v.driver.dmv.expiration.$invalid;
                // driverLicensePicture = this.$v.driver.dmv.driverLicensePicture.$invalid;

                // expiration ? this.tabs.dmv.errors++ : false;
                // driverLicensePicture ? this.tabs.dmv.errors++ : false;


                // return !driverLicense && !expiration && !driverLicensePicture ? true : false ;
                return true
                
            case 'tlc':
                let tlcDriverLicense, tlcDriverLicenseExpiration, tlcDriverLicensePicture ;
                this.tabs.tlc.errors = 0 ;


                // tlcDriverLicenseExpiration = this.$v.driver.tlc.expiration.$invalid;
                // tlcDriverLicensePicture = this.$v.driver.tlc.driverLicensePicture.$invalid;

                // tlcDriverLicenseExpiration ? this.tabs.tlc.errors++ : false;
                // tlcDriverLicensePicture ? this.tabs.tlc.errors++ : false;

                // return !tlcDriverLicense && !tlcDriverLicenseExpiration && !tlcDriverLicensePicture ? true : false ;
                return true
            case 'otherLicenses':
                this.tabs.otherLicenses.errors = 0 ;
                let otherErrors = []
                for (let i = 0; i < this.driver.otherLicenses.length; i++) {
                    
                    let licName,expiration,file;
                    licName = this.$v.driver.otherLicenses.$each[i].name.$invalid;
                    expiration = this.$v.driver.otherLicenses.$each[i].expiration.$invalid;
                    file = this.$v.driver.otherLicenses.$each[i].attachment.file.$invalid;

                    licName ? this.tabs.otherLicenses.errors++ : false;
                    expiration ? this.tabs.otherLicenses.errors++ : false;
                    file ? this.tabs.otherLicenses.errors++ : false;

                    !licName && !expiration && !file  ? otherErrors.push(true) : otherErrors.push(false) ;
                    
                }

                

                return otherErrors.includes(false) ? false : true ;
                
        }
    },
    stepChanged(id)
    {
        this.validateAllSteps()
        this.step = id;
       
    },
    getValidationClass (fieldName,index) {
        let field = null;
        //const field = this.$v[fieldName]
        if(
            fieldName == 'email' 
            || fieldName == 'displayName' 
            || fieldName == 'password' 
            || fieldName == 'phone' 
            || fieldName == 'name' 
            || fieldName == 'lastname' 
            ) {
            // General Step fields
            field = this.$v.driver.general[fieldName]
        } else if(fieldName == 'fleet') {
            
            field = this.$v.driver.vehicles
        } else if (
            fieldName == 'dmv.driverLicense' 
            || fieldName == 'dmv.expiration' 
            || fieldName == 'dmv.driverLicensePicture' 
            ) {
            let fieldSplit = fieldName.split('dmv.')[1];
            field = this.$v.driver.dmv[fieldSplit]
        } else if (
            fieldName == 'tlc.driverLicense' 
            || fieldName == 'tlc.expiration' 
            || fieldName == 'tlc.driverLicensePicture' 
        ) {
            let fieldSplit = fieldName.split('tlc.')[1];
            field = this.$v.driver.tlc[fieldSplit]
        } else if(
            fieldName === 'otherLicenses.name'
            || fieldName === 'otherLicenses.file'
            || fieldName === 'otherLicenses.expiration'

        
        ) {
            
            let fieldSplit = fieldName.split('otherLicenses.')[1];
            if(fieldSplit == 'file') {
                field = this.$v.driver.otherLicenses.$each[index].attachment[fieldSplit]
            } else {
                field = this.$v.driver.otherLicenses.$each[index][fieldSplit]
            }
            
        }

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    clearForm () {
        this.$v.$reset()
        this.driver = null;
        this.role = null
        
    },
    async obtainViewResources(){
        this.isBusy = true
        await this.getFleet()
        await this.getDriver()
        await this.getAreas()
        //await this.imagesLoaded()
        
        this.isBusy = false;
        console.log('not more busy')
       
        
    },
    async getAreas(){
        let th = this
        return new Promise((resolve, reject) => {
            const snapshot = firebase
                            .firestore()
                            .collection('drivers')
                            .doc(this.$route.params.id)
                            .collection('areas')
                            .get().then(snapshot => {
                                th.areas = [];
                                
                                snapshot.forEach((doc) => {
                                    console.log(doc)
                                    let dataToSave = doc.data();
                                    dataToSave.id = doc.id ;
                                    th.areas.push(dataToSave);
                                    

                                });
                                resolve()
                            })
            
        }) 
    },
    async getFleet(){
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet').orderBy("order").onSnapshot((querySnapshot) => {
                this.vehicles = [];
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    this.vehicles.push(dataToSave);
                    

                });
                this.total = this.vehicles.length;
                resolve()
            
           
            
            
            });
        }) 
    },
    async deleteDocument(){

        return new Promise((resolve, reject) => {
            firebase.firestore().collection('drivers').doc(this.$route.params.id).delete()
            .then(function(){
                console.log('Deleted');
                resolve()
            }).catch(function(error) {
                console.log("Error getting document:", error);
                reject()
            });
        })
    },
    async destroy() {
        this.confirmLeave = true;
        this.deleteDialog.active = false; 
        this.isBusy = true ;
        await this.removeEntireFolder()
        let th = this;
        await this.deleteDocument()
        await this.deleteUser()

        this.$router.push({path: `/drivers/company-drivers`})
        
    },
    async deleteUser() {
        let th = this;
        let data = {
            uid: this.driver.general.uid,
             
        }
        return new Promise((resolve, reject) => {
            axios.delete('users/' + this.driver.general.uid ).then(response =>{
                console.log(response);
                
                resolve(response)
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                
                reject(e)
                
            }) 
        });
    },
    myEventHandler(e) {
        // your code for handling resize...
        console.log('resized')
        this.$store.commit('centerDialog',window.innerWidth)
    },
    
   
   

  },
  created () {

    this.obtainViewResources()
    window.addEventListener("resize", this.myEventHandler);
    
  },
  mounted(){
    console.log('Mounted')
  },
  validations: {
    areas: {
        required
    },
    driver: {
        general: {
            name: {
                required
            },
            lastname: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
            // password: {
            //     required,
            //     minLength: minLength(6)
            // },
            displayName: {
                required,
                minLength: minLength(3)
            }
        },
        vehicles: {
            required,
            minLength: minLength(1),
        },
        dmv: {
              
              expiration: {
                  required
              },
              driverLicensePicture: {
                  required
              }
        },
        tlc: {
           
            expiration: {
                required
            },
            driverLicensePicture: {
                required
            }
        },
        otherLicenses: {
            $each: {
                name: {
                    required
                },
                expiration: {
                    required
                },
                attachment: {
                    //preview: null,
                    file:{
                        required: requiredIf(function(value){
                            
                            return value.storage ? false : true 
                            //return this.otherLicenses[0].attachment.storage ? false : true 
                            
                         })
                    },
                    //pdf: false,
                    //image: false
                }
            }
        }
    }
  },
  beforeRouteLeave(to, from, next) {

    if (this.isEdited && !this.confirmLeave) {
        this.confirmDialog = true;
        console.log(to);
        this.goTo = to;
        return 
        if (!window.confirm("Leave without saving?")) {
            return;
        }
    }
    next();
    
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  destroyed() {
        
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>