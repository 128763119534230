import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
Vue.use(Vuex)
import moment from 'moment'
import moment_timezone from "moment-timezone";

var zone_name =  moment.tz.guess();
var timezone = moment.tz(zone_name).zoneName() 
var utc = moment.tz(zone_name).utcOffset()


var from = parseInt(moment().tz(timezone).set({hour:0,minute:0,second:0}).format('X'))
var to =  parseInt(moment().tz(timezone).set({hour:23,minute:59,second:59}).format('X'))
//var time = moment().format('hh:mm A (HH:mm [HS])')
//var date =  moment().format('dddd, MMM Do YYYY')


const state = {

  refresh_token: "",
  access_token: "",
  loggedInUser: {},
  isAuthenticated: false,

  dialogLeft: 0,
  dialogWidth:0,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  sidebarNavHeight: 0,
  headerHeight:0,
  user: {
    loggedIn: false,
    data: null
  },
  snackbar: {
    show: false,
    position: 'center',
    duration: 4000,
    isInfinity: false
  },
  timezone: timezone,
  utc: utc,
  //time: time,
  //date: date,
  statuses: {
    '2eHTRDPnLGfafpyspaLy': 'Cancelled',
    '5E6evdRSaYGebn0QyTEr': 'Offered',
    '5y2pmSkkrYBpWmQCGMKT': 'Partially paid',
    'EjzXPazPlo7b2WO1LvdH': 'Dropped off',
    'Eni0aM7IqPYqG7ZYCPBn': 'Quoted',
    'GyMszTAcBIYXgbsv3eLQ': 'Picked up',
    'IPKFsEucb8kjJueVEJbX': 'Unpaid',
    'JcX77ZF7l2ydDCTMsQiw': 'Confirmed',
    'YSJGDWBb47imDaIUcmMr': 'Assigned',
    'atIamyJfP8XDbTfNuPNP': 'On the way',
    'b2UwM5EsIfoOvt9clkoA': 'Unassigned',
    'dF6isActI3wRNckjAXiw': 'Paid',
    'ezxqWUGWSa7w0jsZJJLk': 'Unconfirmed',
    'ppoEFhV1VL98AQhUBInf': 'On location',
    'upNRT5qFbkXmjQDn7mtW': 'Late cancellation',
    'y3lWmMTGW7eiP72DSN0F': 'Taken',
    'BzmeahZDs7Fon0kP0R60': 'Accepted'
  },
  filters: { 
    fromDate: from,
    toDate: to,
    queryFromDate: null,
    queryToDate: null,
    rowsQty:10,
    search:null,
    companyProfile:null,
    perPage: 10,
    page: 1,
    sort: 'day',
    sortOrder: null,
    preset: 'today',
    searchById: false,
    goToID: null,
    last: null,
    first: null,
    direction: null
  },

}


const mutations = {
  centerDialog: function(state, innerWidth){
      
      if(state.sidebarShow === 'responsive' ) {
      //     if(window.innerWidth >= 992) {
      //         if(!this.$store.state.sidebarMinimize) {
      //             this.dialogLeft = 256 
      //         } else {
      //             this.dialogLeft = 56
      //         }
      //     } else {
      //     this.dialogLeft = 0
      
      //     }
       if(innerWidth >= 992) {
        state.dialogLeft = 190
        state.dialogWidth = innerWidth - 190
       
       } else {
        state.dialogLeft = 0
        state.dialogWidth = innerWidth 
       }
        
          
      } else {
        state.dialogLeft = 0
        state.dialogWidth = innerWidth
      //     if(this.$store.state.sidebarShow === false) {
      //         this.dialogLeft = 0
      //     } else {
      //         if(this.$store.state.sidebarMinimize) {
      //             this.dialogLeft = 256
      //         } else {
      //             this.dialogLeft = 256
      //         }

      //     }
          
      }
  },
  setRefreshToken: function(state, refreshToken) {
    state.refresh_token = refreshToken;
  },
  setAccessToken: function(state, accessToken) {
    state.access_token = accessToken;
  },

  // sets state with user information and toggles 
  // isAuthenticated from false to true
  setLoggedInUser: function(state, user) {
    // SET_USER
    // SET_LOGGED_IN
    
    state.loggedInUser = user;
    state.isAuthenticated = true;
  },
  // delete all auth and user information from the state
  clearUserData: function(state) {
    state.refresh_token = "";
    state.access_token = "";
    state.user = {
      loggedIn: false,
      data: null
    }
    // state.loggedInUser = {};
    // state.isAuthenticated = false;
  },
  restoreBrowserTime (state) {
    let zone_name =  moment.tz.guess();
    state.timezone = zone_name
    state.utc = moment.tz(zone_name).utcOffset()
    //state.time = moment().format('hh:mm A (HH:mm [HS])')
   
    //state.date = moment().format('dddd, MMM Do YYYY')
    //state.date = moment().format('dddd DD-MM-YYYY')
  },
  toggleSidebarDesktop (state) {
    
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setDateFilter (state, [variable, value]) {
    state.filters[variable] = value
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  }
}

const actions = {
  logIn: async ({ commit, dispatch }, payload) => {
    const loginUrl = "v1/auth/jwt/create/";
    try {
      await axios.post(loginUrl, payload).then(response => {
        if (response.status === 200) {
          commit("setRefreshToken", response.data.refresh);
          commit("setAccessToken", response.data.access);
          dispatch("fetchUser");
          // redirect to the home page
          router.push({ name: "home" });
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
  refreshToken: async ({ state, commit }) => {
    const refreshUrl = "auth/jwt/refresh/";

      try {

        // await Vue.prototype.$firebase
        // .auth()
        // .currentUser.getIdToken(true)
        // .then(function(idToken) {
          
        //   commit("setAccessToken", idToken);
        // })
        // .catch((e) => {
        //   console.log(e);
        // }); 

        const token = await Vue.prototype.$firebase
        .auth()
        .currentUser.getIdToken(true)
        commit("setAccessToken", token);

      // await axios
      //   .post(refreshUrl, { refresh: state.refresh_token })
      //   .then(response => {
      //     if (response.status === 200) {
      //       commit("setAccessToken", response.data.access);
      //     }
      //   });
    } catch (e) {
      console.log(e.response);
    }
  },
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    
    if (user) {
      commit("setRefreshToken", user.refreshToken);
      commit("setAccessToken", user.token);
      commit("SET_USER", {
        // displayName: user.displayName,
        // email: user.email,
        // refreshToken: user.refreshToken,
        // uid: user.uid
        displayName: user.claims.name,
        email: user.claims.email,
        refreshToken: user.refreshToken,
        token: user.token,
        uid: user.claims.user_id,
        role: user.claims.role,
        subrole: user.claims.subrole,
        ownerEmail: user.claims.ownerEmail,
        ownerUid: user.claims.ownerUid,
        code: user.claims.code,

      });
    } else {
      commit("SET_USER", null);
    }
  }
}

const getters = {
  user(state){
    return state.user
  },
  accessToken: state => state.access_token,
  refreshToken: state => state.refresh_token
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.getJSON(key),
      setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: false })
    })
  ]
})