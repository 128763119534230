<template>
  <div>
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar>
    <div v-else>
      <md-table v-if="offers.length > 0">
        <md-table-row>
          
            <md-table-head>Id</md-table-head>
            <md-table-head>Day</md-table-head>
            <md-table-head>PU / ST / DO</md-table-head>
            <md-table-head>Miles</md-table-head>
            <md-table-head>Vehicle</md-table-head>
            <md-table-head>Amount to get</md-table-head>
            <md-table-head>Status</md-table-head>
            <md-table-head>Actions</md-table-head>
          
        </md-table-row>
        <md-table-row v-for="offer in offers" v-bind:key="offer.id">
          <md-table-cell>
            {{ offer.readableId }}
          </md-table-cell>
          <md-table-cell>
            <div class="md-layout">
              <div class="md-layout-item">
                <div class="white-space-no-wrap">
                  <span class="time-info">
                  {{ parseInt(offer.day) | moment('timezone', offer.companyProfile.timeZone , 'MMMM Do YYYY') }} <br>
                    <b >{{ parseInt(offer.day) | moment('timezone', offer.companyProfile.timeZone , 'hh:mm A (HH:mm [HS])') }}</b>
                    </span>
                
                </div>
                <div class="white-space-no-wrap mt-2">
                  <span class="up-chip  up-text-confirmed mb-1 mr-2">Time Zone {{ offer.companyProfile.timeZone }}</span>
                  <span class="up-chip  up-text-secondary mb-1">UTC {{ parseInt(offer.day) | moment('timezone', offer.companyProfile.timeZone , 'Z') }}</span>
                </div>
          
                  
                <div class="mt-2" v-html="checkTimeToGo(parseInt(offer.day))"></div>
                
              </div>
              
            </div>
          </md-table-cell>

          <md-table-cell>
            <div class="md-layout md-alignment-center-left">
              <div class="md-layout-item flex-1">
                <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200">
                  <b>PU:</b> {{offer.pu.formatted_address}}
                  <md-tooltip md-direction="top" md-delay="500"><b>PU:</b> {{offer.pu.formatted_address}}</md-tooltip>
                </div>
                <div class="location-text no-wrap mb-2 text-magic-ellipsis max-width-200" v-if="offer.waypoints.length > 0">
                    <div v-for="(waypoint,index) in offer.waypoints" class="location-text no-wrap" v-bind:key="'waypoint' + index">
                      <b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}
                      <md-tooltip md-direction="top" md-delay="500"><b>ST#{{index +1 }}:</b> {{waypoint.formatted_address}}</md-tooltip>
                    </div>
                </div>
                <div class="location-text no-wrap text-magic-ellipsis max-width-200">
                  <b>DO:</b> {{offer.do.formatted_address}}
                    <md-tooltip md-direction="top" md-delay="500"><b>DO:</b> {{offer.do.formatted_address}}</md-tooltip>
                </div>
              </div>
              <div class="md-layout-item flex-none pl-2">
                <md-button @click="openMapDialog(offer)" class="min-width-55">
                  <md-icon>route</md-icon>
                    <md-tooltip md-direction="top" md-delay="1000">View map</md-tooltip>
                </md-button>
              </div>
            </div>
                 
          </md-table-cell>

          <md-table-cell>
            {{ offer.miles }}
          </md-table-cell>
          <md-table-cell>
            {{ offer.vehicleType.type }}
          </md-table-cell>
          <md-table-cell>
            {{ offer.amountToGet }}
          </md-table-cell>
          <md-table-cell>
            <div class="white-space-no-wrap">

              {{  statuses[offer.status] === 'Offered' ? 'Offered by company (waiting for your action)' : ''   }}
              {{  statuses[offer.status] === 'Accepted' ? 'Offer accepted (waitin for company approval)' : ''   }}
            </div>
          </md-table-cell>
          <md-table-cell>
            <md-button 
                class="ml-0 md-raised md-primary" 
                @click="acceptOffer(offer)"
                v-if="statuses[offer.status] === 'Offered'"
               
            >Accept</md-button>
            <md-button 
                class="ml-0 md-raised md-accent" 
                @click="rejectOffer(offer)"
                v-if="statuses[offer.status] === 'Accepted'"
               
            >Reject</md-button>

            <!--md-button class="md-raised">Dismiss</md-button-->
          </md-table-cell>
        </md-table-row>
      </md-table>
      
      <md-empty-state
       v-else
       class="mt-4"
        md-rounded
        md-icon="access_time"
        md-label="Up to date"
        md-description="Offer will be display here.">
      </md-empty-state>
      
      <md-dialog :md-active.sync="map.active" :style="[dialogDimensions]" :md-fullscreen="false" class="free-dialog" >
        <md-dialog-title v-if="map.item">
          Reservation <span class="up-text-primary">{{map.item.readableId}}</span> route
          <div class="md-subheading" v-if="map.item">Trip distance: <span class="up-text-secondary">{{ map.item.miles}}</span> miles</div>
          
        </md-dialog-title>
        <md-divider class=" mb-4">
        </md-divider>
        <div class="modal-up-content text-center">
          <CRow >
            <CCol sm="12" md="12" lg="12" xl="7">
              
                <GmapMap :zoom="12"  style="width:500px;height:600px"  ref="map"  :center="{lat:map.item ? map.item.pu.coordinates.lat : 0,lng: map.item ? map.item.pu.coordinates.lng : 0}" >
                    <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="origin"
                        :destination="destination"
                        :waypoints="computedWaypoints"
                        ref="road" />
                </GmapMap>
            </CCol>
          </CRow>

        </div>
        <md-dialog-actions>
          <md-button @click="closeMapDialog">Close</md-button>
        
        </md-dialog-actions>
      </md-dialog>
    </div>
    <md-snackbar md-position="center" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackMessage}}</span>
      <md-button class="md-primary" @click="showSnackbar = false">Ok</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import firebase from '../../Firebase'
import CTableWrapper from '../base/Table'
import VueTimepicker from 'vue2-timepicker'
import moment from 'moment'
import moment_timezone from "moment-timezone";
import DirectionsRenderer from './../maps/DirectionsRenderer';
import axios from "./../../helpers/axios";

export default {
  name: 'Offers',
  components: { CTableWrapper, VueTimepicker , DirectionsRenderer},
  data() {
    return {
      isBusy: true,
      offers: [],
      dispatchers: [],
      vehicleTypes: [],
      jobTypes: [],
     
      dispatchRef:  firebase.firestore().collection('dispatchers'),
      vehicleRef:  firebase.firestore().collection('vehicle-types'),
      jobTypesRef:  firebase.firestore().collection('job-types'),
      //items: usersData,
      fields: [
        {key: 'day', label: 'Day'},
        {key: 'pu', label: 'PU'},
        {key: 'do', label: 'DO'},
        {key: 'miles', label: 'Miles'},
        {key: 'price', label: 'Price'},
        {key: 'vehicleType', label: 'Vehicle Type'},
       
      ],
      activePage: 1,
      columns: ['day','pu','do','miles','price','vehicleType','edit'],
      options: {

      },
      map: {
        title: null,
        active: false,
        item: null,
        
      },
      showSnackbar: false,
      snackMessage: null,
      duration: 4000,
      isInfinity: false,
     
    }
  },
  created () {
    this.obtainViewResources();

    window.addEventListener('resize', this.myEventHandler);
    
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler);
    this.$store.commit('restoreBrowserTime')
  },
  mounted() {
    this.$store.commit('centerDialog',window.innerWidth)
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    rejectOffer(offer) {
      let data = {
        resId: offer.reservationId,
        driverOfferId: offer.id,
        driverEmail: this.user.data.email,
        reservationTenantUid: offer.reservationTenantUid,
        resOfferId: offer.resOfferId
      }
      let th = this;
      this.isBusy = true
      console.log(data)
      axios.patch('reservations/offers/reject', data).then(response =>{
          console.log(response);
          
          th.snackMessage = 'Offer rejected successfully';
          th.isBusy = false
          th.showSnackbar = true;
        

      }).catch(e => {
          console.log(e);
          th.isBusy = false
          
          
      })
    },
    acceptOffer(offer) {
      let data = {
        resId: offer.reservationId,
        driverOfferId: offer.id,
        driverEmail: this.user.data.email,
        reservationTenantUid: offer.reservationTenantUid,
        resOfferId: offer.resOfferId
      }
      let th = this;
      this.isBusy = true
      console.log(data)
      axios.patch('reservations/offers/accept', data).then(response =>{
          console.log(response);
          
          th.snackMessage = 'Offer accepted successfully';
          th.isBusy = false
          th.showSnackbar = true;
        

      }).catch(e => {
          console.log(e);
          th.isBusy = false
          
          
      })
    },
    myEventHandler(e) {
        // your code for handling resize...
        this.$store.commit('centerDialog',window.innerWidth) 
        ////
    },
    openMapDialog(item) {
      
      let th = this
      this.map.active = true
      
      this.$nextTick(() => {
        if(this.$refs.map) {
          this.$refs.map.$mapPromise.then((map) => {
            th.map.item = item
          })
        }
        
      })
      
      
    },
    closeMapDialog() {
      this.map.active = false;
      this.map = {
        title: null,
        active: false,
        item: null,
        
      }

    },
    checkTimeToGo(date) {
      var startTime = moment();
      var endTime = moment.unix(date);
    
      //var hoursDiff = endTime.diff(startTime, 'hours');
    
      var minutesDiff = endTime.diff(startTime, 'minutes');
    
      //var secondsDiff = endTime.diff(startTime, 'seconds');

      var ms = endTime.diff(startTime);
      var d = moment.duration(ms);
      //var s = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
      let className ;
      if(minutesDiff >= 180) {
        className = 'white-pu'
      } else if(minutesDiff >= 60 ) {
        className = 'yellow-pu'
      } else if(minutesDiff > 0) {
        className = 'red-pu'
      }

      
     
      let payload ;
      if(d >= 0 ) {
        payload = '<div class="time-to-go ' + className + '">' + d.days() + ' D, ' + d.hours() + ' H, ' + d.minutes() + ' M' + ' to PU' + '</div>';
        //return 'Days:' + d.days() + ' , Hours:' + d.hours() + ' , Minutes:' + d.minutes() + ' to pick up';
      } else {
        // Here goes logic with trip status , on location, dropped off etc
        className = 'green-pu'
        className = 'grey-pu'
        if(minutesDiff < 0 &&  minutesDiff >= -60) {
          payload = '<div class="time-to-go ' + className + '">' + 'Picked up' + '</div>';
          //payload = '<div class="' + className + '">' + d.days() + ' days, ' + d.hours() + ' h and ' + d.minutes() + ' min' + ' to PU' + '</div>';
        } else {
          payload = ''
        }
       
      }
      payload = '<div class="text-uppercase time-to-go ' + className + '">' + endTime.fromNow() + '</div>'
      return payload ;
      //return ''
       //moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    async obtainViewResources() {
      this.isBusy = true
      await this.getOffers()
      this.isBusy = false;
    },
    async getOffers() {
        ///////
        let th = this;
        return new Promise((resolve, reject) => {
            let ref = firebase.firestore().collection('drivers').doc(this.user.data.email).collection('offers')
            ref.onSnapshot((querySnapshot) => {
                th.offers =  []
                
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.offers.push(dataToSave)
                });
                resolve()
            })
            
        }) 

        
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },

    getShuffledUsersData () {
      return this.shuffleArray(usersData.slice(0))
    },
    rowClicked (item, index) {
      console.log(item);
      this.$router.push({path: `offers/${item.id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    newOffer(){
      this.$router.push({path: `offers/new`})
    }
  },
  computed: {
    origin() {
        if (this.map.item) {
          if (!this.map.item.pu) return null;
          return { query: this.map.item.pu.coordinates.lat + ',' + this.map.item.pu.coordinates.lng };
        } else {
          return null
        }

       
    },
    destination() {
        if(this.map.item){
            if (!this.map.item.do) return null;
            return { query: this.map.item.do.coordinates.lat + ',' + this.map.item.do.coordinates.lng };
        } else {
          return null
        }
        
    },
    computedWaypoints() {
        if(this.map.item){

          if (this.map.item.waypoints.length == 0 ) return null;
          const total = [];
          this.map.item.waypoints.forEach((waypoint,index) => {
              if(!waypoint.stop){
                  //total.push(null);
              } else {
                  total.push({
                      stopover:true,
                      location: !waypoint.stop ? null : waypoint.stop.location
                  })

                  
              }
              
          });
          return total;

        } else {
          return null
        }
       
        
    }, 
    dialogDimensions () {
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
    },
    user() {
      return this.$store.state.user;
    },
    statuses() {
      return this.$store.state.statuses;
    },
  }
}
</script>