<template>
  <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
  <div v-else>
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="companyProfile.name">{{companyProfile.name}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>New company profile</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update company profile</md-button>
      </md-toolbar>
      <md-tabs class="md-transparent" md-alignment="left" @md-changed="mainTabChanged" ref="mainTabs" :md-active-tab="mainActiveTab"> 
          <template slot="md-tab" slot-scope="{ tab }"> 
              {{ tab.label }} 
              <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
              <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
          </template>
          <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
            <CRow>
              <CCol sm="12" lg="6">
                <md-card class="uplimo-card">
                  <md-card-header>
                      <div class="md-title">General</div>
                  </md-card-header>
                  <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('name')">
                                <label>Name</label>
                                <md-input v-model="companyProfile.name"></md-input>
                                  <span class="md-error" v-if="!$v.companyProfile.name.required">Name is required</span>
                            </md-field>
                        </CCol>
                    </CRow>
                    
                    <CRow>
                          <CCol sm="12">
                              <md-field :class="getValidationClass('address')">
                                  <label for="address">Company address</label>
                                  <md-input name="address" id="address" autocomplete="given-name" v-model="companyProfile.address" :disabled="sending" />
                                  <span class="md-error" v-if="!$v.companyProfile.address.required">Address is required</span>
                              </md-field>
                          </CCol>
                      </CRow>
                      
                      <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('phones')" class="no-line">
                                <md-chips class="md-primary" v-model="companyProfile.phones" md-placeholder="Add company telephone" :md-auto-insert="true">
                                    <label>Company phones</label>
                                </md-chips>
                                <span class="md-error" v-if="!$v.companyProfile.phones.required">Add at least one phone</span>
                            </md-field>
                            
                        </CCol>
                    </CRow>
                        <CRow>
                        <CCol sm="12">
                            <md-autocomplete v-model="companyProfile.timeZone" :md-options="timeZones" md-dense :class="getValidationClass('timeZone')">
                                <label>Timezones</label>
                                <span class="md-error" v-if="!$v.companyProfile.timeZone.required">Timezone is required</span>
                            </md-autocomplete>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="6">
                            <md-switch v-model="companyProfile.active">Active</md-switch>
                        </CCol>
                        <CCol sm="6">
                            <md-switch v-model="companyProfile.default">Default</md-switch>
                        </CCol>
                    </CRow>
                      
                      <CRow>
                          <CCol sm="12">
                              <img :src="companyProfile.logo[0]" alt="">
                          </CCol>
                      </CRow>
                      <CRow >
                          <CCol sm="12">
                            <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete company profile</md-button>
                        </CCol>
                    </CRow>
                  </md-card-content>
                </md-card>
              </CCol>
            </CRow>
          </md-tab>
          <md-tab id="coveredArea" md-label="Covered Area" :md-template-data="{ badge: tabs.coveredArea.errors }">
            <CRow>
              <CCol sm="12" lg="6">
                <md-card class="uplimo-card">
                  <md-card-header>
                      <div class="md-title">Covered areas</div>
                  </md-card-header>
                  <md-card-content>
                     <CRow>
                          <CCol sm="12" lg="12">
                              
                              <GmapMap ref="fenceMap" :center="{lat:companyProfile.coveredArea.coordinates.lat,lng:companyProfile.coveredArea.coordinates.lng}" :zoom="companyProfile.coveredArea.zoom" style="width:100%;height:600px">
                                    <!--gmap-polygon v-for="(area,index) in areas" v-bind:key="'path' + index" :paths="area.path" :editable="true" @paths_changed="updateEdited($event,true,area.path,index)">
                                    </gmap-polygon>
                                     <gmap-polygon v-for="(path,index) in companyProfile.coveredArea.paths" v-bind:key="'new-path' + index" :paths="path" :editable="true" @paths_changed="updateEdited($event,false,path,index)">
                                    </gmap-polygon-->

                                    <gmap-polygon v-for="(path,index) in areaPaths" v-bind:key="'new-path' + index" :paths="path.path" :editable="false" @paths_changed="updateEdited($event,path.existant,path.path,index)">
                                    </gmap-polygon>
                              </GmapMap>
                          </CCol>
                          
                      </CRow>
                  </md-card-content>
                </md-card>
              </CCol>
              <CCol sm="12" lg="6">
                    <md-card class="uplimo-card">
                        <md-card-header>
                            <div class="md-title">Areas list</div>
                        </md-card-header>
                        <md-card-content>
                            <md-field :class="{'md-focused':inputFocused,'md-invalid': !areaValidation.valid}">
                                <gmap-autocomplete
                                    ref="autocomplete"
                                    placeholder="Type country, state, city here to add new fence"
                                    @place_changed="setFence($event)" class="pu-autocomplete"
                                    @blur="resetCursor()"
                                    @focus="locateCursor()"
                                    :types="mapTypes"
                                    >
                                </gmap-autocomplete>
                                <span class="md-error">{{ areaValidation.message }}</span>
                            </md-field>
                            <div v-if="areas.length == 0 && companyProfile.coveredArea.fencesList.length == 0">
                                <md-empty-state
                                    md-icon="add_location"
                                    md-label="Add your first fence to determine covered area"
                                    md-description="You can type a place name and then edit resultant fence">
                                    
                                </md-empty-state>
                                <md-button class="md-primary md-raised full-width-button" @click="locateCursor()">Add fence</md-button>

                            </div>
                            <div v-else>
                                <md-table >
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                    
                                        <md-table-head>Action</md-table-head>
                                        
                                    </md-table-row>

                                    <md-table-row v-for="(area,index) in areas" v-bind:key="area.id">
                                        <md-table-cell>{{area.formatted_address}}</md-table-cell>
                                        <md-table-cell>
                                            <md-button class="md-raised ml-0" @click="removeFence(area,index,area.newArea)">Remove</md-button>
                                        </md-table-cell>
                                    
                                        
                                    </md-table-row>
                                    <!--md-table-row v-for="(fence,index) in companyProfile.coveredArea.fencesList" v-bind:key="fence.formatted_address">
                                        <md-table-cell>{{fence.formatted_address}}</md-table-cell>
                                        <md-table-cell>
                                            <md-button class="md-raised ml-0" @click="removeFence(fence,index,true)">Remove</md-button>
                                        </md-table-cell>
                                    
                                        
                                    </md-table-row-->
                                </md-table>
                                <md-button class="mt-3 md-primary md-raised full-width-button" @click="locateCursor()">Add new area</md-button>
                            </div>
                        </md-card-content>
                    </md-card>
              </CCol>
            </CRow>
          </md-tab>
          <md-tab id="taxes" md-label="Taxes" :md-template-data="{ badge: tabs.taxes.errors }">

            <CRow>
              <CCol sm="12" lg="6">
                <md-card class="uplimo-card">
                  <md-card-header>
                      <div class="md-title">Taxes</div>
                  </md-card-header>
                  <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('saleTax')">
                                <label for="sale-tax">Sale tax</label>
                                <span class="md-prefix">%</span>
                                <md-input name="sale-tax" id="sale-tax" type="number" v-model="companyProfile.saleTax" :disabled="sending" />
                                <span class="md-error" v-if="!$v.companyProfile.saleTax.required">Sale tax is required</span>
                            </md-field>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" >
                            <md-field :class="getValidationClass('creditCardFee')">
                                <label for="credit-card-fee">Credit card fee</label>
                                <span class="md-prefix">%</span>
                                <md-input name="credit-card-fee" id="credit-card-fee" type="number" v-model="companyProfile.creditCardFee" :disabled="sending" />
                                <span class="md-error" v-if="!$v.companyProfile.creditCardFee.required">Credit card fee is required</span>
                            </md-field>
                        </CCol>
                    </CRow>
                  </md-card-content>
                </md-card>
              </CCol>
            </CRow>
          </md-tab>
          <md-tab id="notifications" md-label="Notifications" :md-template-data="{ badge: tabs.notifications.errors }">
            <CRow>
              <CCol sm="12" lg="6">
                <md-card class="uplimo-card">
                  <md-card-header>
                      <div class="md-title">Notifications</div>
                  </md-card-header>
                  <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('mailList')">
                                  <label for="mailList">Enter a mail</label>
                                  <md-input name="mailList" id="mailList" autocomplete="given-name" v-model="companyProfile.mailList" :disabled="sending" />
                                  <span class="md-error" v-if="!$v.companyProfile.mailList.required">Mail is required</span>
                                  <span class="md-error" v-if="!$v.companyProfile.mailList.email">Invalid mail format</span>
                              </md-field>
                        </CCol>
                    </CRow>
                    <CRow>
                          <CCol sm="12">
                              <md-field :class="getValidationClass('smsPhoneTarget')">
                                  <label for="smsPhoneTarget">SMS phone target</label>
                                  <md-input name="smsPhoneTarget" id="smsPhoneTarget" autocomplete="given-name" v-model="companyProfile.smsPhoneTarget" :disabled="sending" />
                                  <span class="md-error" v-if="!$v.companyProfile.smsPhoneTarget.required">SMS Phone Target is required</span>
                              </md-field>
                          </CCol>
                      </CRow>
                  </md-card-content>
                </md-card>
              </CCol>
            </CRow>
          </md-tab>
          <md-tab id="licenses" md-label="Licenses" :md-template-data="{ badge: tabs.licenses.errors }">
            <CRow>
              <CCol sm="12" lg="6">
                <md-card class="uplimo-card">
                  <md-card-header>
                      <div class="md-title">Licenses</div>
                  </md-card-header>
                  <md-card-content>
                    <CRow>
                        <CCol sm="12" md="12">
                            <md-button class="md-dense md-raised md-primary" @click="addLicense">Add license</md-button>
                            
                            <CRow v-if="companyProfile.licenses && companyProfile.licenses.length > 0">
                                <CCol sm="12" v-for="(license , index ) in companyProfile.licenses" v-bind:key="license.number">
                                    <md-card style="margin-top:20px">
                                        <md-card-content>
                                            <md-field>
                                                <label :for="'license-name-' + license.number">License Name</label>
                                                <md-input :name="'license-name-' + license.number" :id="'license-name-' + license.number" autocomplete="given-name" v-model="license.name" :disabled="sending" />
                                            </md-field> 
                                            <md-field>
                                                <label :for="'license-number-' + license.number">License number</label>
                                                <md-input :name="'license-number-' + license.number" :id="'license-number-' + license.number" autocomplete="given-name" v-model="license.name" :disabled="sending" />
                                            </md-field>  
                                              
                                        </md-card-content>
                                        <md-card-actions>
                                            <md-button @click="removeLicense(index)">Remove</md-button>
                                            <md-button>Confirm</md-button>
                                        </md-card-actions>
                                    </md-card>
                                </CCol>
                            </CRow>

                        </CCol>
                    </CRow>
                  </md-card-content>
                </md-card>
              </CCol>
            </CRow>
          </md-tab>
      </md-tabs>
      
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

  </div>


  
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
import { timeZones } from '../../timeZones.js'
import axios from 'axios'
export default {
  name: 'Company',
  mixins: [validationMixin],
  data() {
    return {
        areaValidation: {
            message: '',
            valid: true
        },
        mapTypes: ['political'],
        removedAreas: [],
        inputFocused: false,
        areas:null,
        mainActiveTab: 'general',
        timeZones:timeZones,
      tabs: {
          general: {
              errors: null,
          },
          coveredArea: {
              errors: null,
          },
          taxes: {
              errors: null,
          },
          notifications: {
              errors: null,
          },
          licenses: {
              errors: null,
          },
          
      },
      geofence: {
            name: null,
            code:null,
            path: [],
            zoom:7,
            center: {lat: 1.38, lng: 103.8},
        },
    
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      companyProfile: null,
      zoom:4,
      coordinates: { 
          lat: 24.886, lng: -70.268 
      },
      edited: null,
      paths: [
          [
              { lat: 25.774, lng: -80.19 },
              { lat: 18.466, lng: -66.118 },
              { lat: 32.321, lng: -64.757 }
          ]
          //[ {lat: 1.382, lng: 103.802}, {lat:1.382, lng: 103.808}, {lat: 1.388, lng: 103.808}, {lat: 1.388, lng: 103.802} ],
      ],
      isBusy: true,
      sending: false,
      ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('company-profiles'),
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    areaPaths() {
        console.log('computing')
        let paths = []
        

        

        const areas = this.areas.map( (area,index) => {
            let pathsToSave = []
            for (const path in area.paths) {
                if (Object.hasOwnProperty.call(area.paths, path)) {
                    const element = area.paths[path];
                    let dataToSave = {
                        existant: true,
                        path: null
                    }
                    dataToSave.path = element
                    console.log(element)
                    pathsToSave.push(dataToSave)
                   
                    
                }
            }
            return pathsToSave
        })

        for (let j = 0; j <  areas.length; j++) {
            
            paths = paths.concat(areas[j])
        }


        
        
        return paths
    }
  },
  created () {
    let th = this;
    
      this.obtainViewResources()

    
  },
  methods: {
    resetCursor(){
        this.inputFocused = false
       
    },
    locateCursor(){
        console.log(this.$refs.autocomplete)
        this.$refs.autocomplete.$el.focus()
        this.inputFocused = true
    },
    removeFence(area,index,newArea) {
        console.log(area)
        console.log(index)
        console.log(newArea)

        if(newArea) {
            // for (let i = 0; i < area.indexes.length; i++) {
            //     this.companyProfile.coveredArea.paths.splice(area.indexes[i],1)
                
            // }

            this.areas.splice(index,1)
            this.$refs.autocomplete.$el.focus()
            this.$refs.autocomplete.$el.blur()
        } else {
            this.removedAreas.push(area)
            this.areas.splice(index,1)
        }
        
        
    },
    resetCursor(){
        this.inputFocused = false
    },
    async getBoundarie(lat, lon) {
        let params = {
            apiKey: '3f5d675290ce41e08917bc9f40516382',
            geometry: 'geometry_10000',
            lat: lat,
            lon: lon

        }
        return new Promise((resolve, reject) => {
            axios.create({
                headers: {},                    
            }).get('https://api.geoapify.com/v1/boundaries/part-of',
            {params: params})
            .then(response =>{
                console.log(response)
                resolve(response.data)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    async setFence(place) {
        console.log(place)
        let newFence = {
            formatted_address:place.formatted_address,
            indexes: [],
            address_components: place.address_components,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            },
            paths: [],
            newArea: true
        }
        this.geofence.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };
        let placeLevel = place.address_components[0].types[0]
        console.log('placeLevel',placeLevel)

        newFence.name = place.formatted_address
        // Google naming 

        // country
        // administrative_area_level_1
        // locality
        // sublocality_level_1

        let boundarie = await this.getBoundarie(this.geofence.center.lat, this.geofence.center.lng)
        this.companyProfile.coveredArea.coordinates.lat = this.geofence.center.lat
        this.companyProfile.coveredArea.coordinates.lng = this.geofence.center.lng
        this.companyProfile.coveredArea.zoom = 10
        let categoryToLook = null;
        let alternativeCategoryToLook = null;
        let secondAlternativeCategoryToLook = null;
        switch(placeLevel) {
            case 'country':
                //categoryToLook = "administrative.country_part_level"
                categoryToLook = "administrative.country_level"
                break;
            case 'administrative_area_level_1':
                //categoryToLook = "administrative.state_level"
                //categoryToLook = "administrative.county_level"
                categoryToLook = "administrative.country_part_level"
                break;
            case 'administrative_area_level_2':
                //categoryToLook = "administrative.state_level"
                categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.district_level"
                break;
            case 'locality':
                //categoryToLook = "administrative.county_level"
                //categoryToLook = "administrative.city_level"
                categoryToLook = "administrative.state_level"
                alternativeCategoryToLook = "administrative.district_level"
                // ithaca, newy york : syracuse ny
                secondAlternativeCategoryToLook = "administrative.city_level"
                    // ex New York , New York
                // ex New York , New York
                break;
            case 'sublocality_level_1':
                categoryToLook = "administrative.city_level"
                // ex. Manhattan, Bronx, Queens
                break;
            case 'neighborhood':
                //categoryToLook = "administrative.city_level"
                this.areaValidation.message = 'Search a place larger than a neighborhood'
                break;
            
        }
        let finalFeature = null
        if(categoryToLook || alternativeCategoryToLook || secondAlternativeCategoryToLook) {
            for (let i = 0; i < boundarie.features.length; i++) {
                if(
                    boundarie.features[i].properties.categories[1] === categoryToLook
                    || boundarie.features[i].properties.categories[1] === alternativeCategoryToLook
                    || boundarie.features[i].properties.categories[1] === secondAlternativeCategoryToLook
                    ) 
                {
                    finalFeature = boundarie.features[i]
                }
            }

        } else {
            this.areaValidation.valid = false;

        }
        console.log('finalFeature',finalFeature)

        if(finalFeature) {
            if(finalFeature.geometry.type === "Polygon") {
                console.log("Polygon")
                let pathToSave = []

                for (let i = 0; i < finalFeature.geometry.coordinates[0].length; i++) {
                    pathToSave.push({
                        lng: finalFeature.geometry.coordinates[0][i][0],
                        lat: finalFeature.geometry.coordinates[0][i][1],
                    })
                    
                    
                }
                //let newIndex = this.companyProfile.coveredArea.paths.push(pathToSave) - 1
                let newIndex = newFence.paths.push(pathToSave) - 1
                newFence.indexes.push(newIndex)
            } else if(finalFeature.geometry.type === "MultiPolygon") {
                console.log("MultiPolygon")
                for (let i = 0; i < finalFeature.geometry.coordinates.length; i++) {
                    let pathToSave = []
                    for (let j = 0; j < finalFeature.geometry.coordinates[i][0].length; j++) {
                        pathToSave.push({
                            lng: finalFeature.geometry.coordinates[i][0][j][0],
                            lat: finalFeature.geometry.coordinates[i][0][j][1],
                        })
                        
                        
                    }
                    //let newIndex =  this.companyProfile.coveredArea.paths.push(pathToSave) - 1
                    let newIndex =  newFence.paths.push(pathToSave) - 1
                    newFence.indexes.push(newIndex)
                    
                }
            }
            //this.companyProfile.coveredArea.fencesList.push(newFence)
            this.areas.push(newFence)
        }

        
        

        // Boundaries API naming 
        
        // "administrative.country_part_level"
        // "administrative.state_level"
        // "administrative.county_level"
        // "administrative.city_level"

        //Get the boundaries of the Map.
        
        var bounds = this.$refs.fenceMap.$mapObject.getBounds();
        this.$refs.fenceMap.fitBounds(bounds)

        this.$refs.autocomplete.$el.value = ''

        //this.$nextTick(() => {
           
        //})
        
        this.$refs.autocomplete.$el.focus()
        this.$refs.autocomplete.$el.blur()
        //Center map and adjust Zoom based on the position of all markers.
        // map.setCenter(latlngbounds.getCenter());
        // map.fitBounds(latlngbounds);

        
    },
    async obtainViewResources() {
        this.isBusy = true

        await this.getCompany()
        await this.getAreas()
        this.isBusy = false
    },
    async getCompany() {
        let th = this
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('company-profiles').doc(this.$route.params.id).get()
            .then(function(doc) {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    th.companyProfile = doc.data();
                    th.companyProfile.coveredArea.paths = []
                    th.companyProfile.coveredArea.fencesList = []
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
                resolve()
            }).catch(function(error) {
                console.log("Error getting document:", error);
                reject()
            });

        })
    },
    async getAreas(){
        let th = this
        return new Promise((resolve, reject) => {
            const snapshot = firebase
                            .firestore()
                            .collection('users')
                            .doc(this.user.data.uid)
                            .collection('company-profiles')
                            .doc(this.$route.params.id).collection('areas')
                            .get().then(snapshot => {
                                th.areas = [];
                                
                                snapshot.forEach((doc) => {
                                    console.log(doc)
                                    let dataToSave = doc.data();
                                    dataToSave.id = doc.id ;
                                    th.areas.push(dataToSave);
                                    

                                });
                                resolve()
                            })
            
        }) 
    },
    mainTabChanged(tabId) {
        //console.log(tabId);
    },
    addLicense() {
          let license = {
              name: null,
              number: null
          }

      this.companyProfile.licenses.push(license)
    },
    removeLicense(index) {
      this.companyProfile.licenses.splice(index, 1);
    },
    updateEdited(mvcArray,existant,actualPath,index) {
        let paths = [];
        for (let i=0; i<mvcArray.getLength(); i++) {
            let path = [];
            for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
            }
            paths.push(path);
        }

        if(existant) {
            this.areas[index].path = paths[0]
            this.areas[index].edited = true
        } else {
            this.companyProfile.coveredArea.paths[index] = paths[0]
        }
        
        
    },
    fireUpdate() {
      this.validateAllSteps()
      // if(this.validate()) {
      //   this.update()
      // } else {
      //   this.$v.companyProfile.name.$touch()
      
    },
    async deleteRemovedAreas() {
        let th = this
        const promises = this.removedAreas.map(async (area, index) => {
            return firebase
            .firestore()
            .collection('users')
            .doc(th.user.data.uid)
            .collection('company-profiles')
            .doc(th.$route.params.id)
            .collection('areas')
            .doc(area.id)
            .delete()
        })

        return Promise.all(promises)
        .then((callback) => {
            console.log(callback, 'callback');
            
        })
        .catch((err) => alert(err));

    },
    async updateMainDoc() {
        let th = this
        return new Promise((resolve, reject) => {
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('company-profiles').doc(this.$route.params.id).update(th.companyProfile)
            .then(function(){
                console.log('Updated');
                th.snackMessage = 'Company profile updated';
                th.showSnackbar = true;
                resolve()
            
            }).catch(function(error) {
                th.snackMessage = 'Ooopss something went wrong';
                th.showSnackbar = true;
                console.log("Error getting document:", error);
                reject()
            });
        })
    },
    async createPolygonsAreas(id) {
        let th = this
        const promises = this.areas.map( async (fence, index) => {


            if(fence.newArea) {
                
                return new Promise((resolve, reject) => {
                    
                    console.log(fence)
                    let pathsToSave = {}
                    for (let j = 0; j < fence.paths.length; j++) {
                        pathsToSave[j] = fence.paths[j]
                        
                    }
    
                    console.log(pathsToSave)
                    fence.paths = pathsToSave
                    delete fence.newArea
                    firebase.
                    firestore()
                    .collection('users')
                    .doc(th.user.data.uid)
                    .collection('company-profiles')
                    .doc(id)
                    .collection('areas')
                    .add(fence)
                    .then(function(docRef) {
                        console.log("Tutorial created with ID: ", docRef.id);
                        resolve()
                    })
                    .catch(function(error) {
                        console.error("Error adding Tutorial: ", error);
                        reject()
                    });
                    
    
                    // firebase.
                    // firestore()
                    // .collection('users')
                    // .doc(th.user.data.uid)
                    // .collection('company-profiles')
                    // .doc(id)
                    // .collection('areas')
                    // .add(dataToSave)
                    // .then(function(docRef) {
                    //     console.log("Tutorial created with ID: ", docRef.id);
                    //     resolve()
                    // })
                    // .catch(function(error) {
                    //     console.error("Error adding Tutorial: ", error);
                    //     reject()
                    // });
                    
                })
            }

        })

        return Promise.all(promises)
        .then(() => {
            console.log('Promises full filled')
        })
        .catch((err) => {
            console.log(err)
        });
    },
    async updateEditedAreas() {
        let th = this
        const promises = this.areas.map( async (area, index) => {
            if(area.edited) {
                return new Promise((resolve, reject) => {
                    console.log(area)
                    let pathsToSave = {}
                    for (let j = 0; j < area.paths.length; j++) {
                        pathsToSave[j] = area.paths[j]
                        
                    }
    
                    console.log(pathsToSave)
                    area.paths = pathsToSave


                    delete area.edited
                    firebase.
                    firestore()
                    .collection('users')
                    .doc(th.user.data.uid)
                    .collection('company-profiles')
                    .doc(th.$route.params.id)
                    .collection('areas')
                    .doc(area.id)
                    .update(area)
                    .then(function(docRef) {
                        console.log(docRef);
                        resolve()
                    })
                    .catch(function(error) {
                        console.error("Error adding Tutorial: ", error);
                        reject()
                    });

                })

            }
            
        })

        return Promise.all(promises)
        .then(() => {
            console.log('Promises full filled')
        })
        .catch((err) => {
            console.log(err)
        });
    },
    async update() {
       this.isBusy = true;
       let th = this;

       
        await this.deleteRemovedAreas()
        let polygons = this.companyProfile.coveredArea.paths
        let fencesList = this.companyProfile.coveredArea.fencesList
        let newDocId = this.$route.params.id
        delete this.companyProfile.coveredArea.paths
        delete this.companyProfile.coveredArea.fencesList

        await this.createPolygonsAreas(newDocId)
        await this.updateEditedAreas()
        await this.updateMainDoc()
        //await this.getCompany()
        await this.getAreas()

     
       this.isBusy = false;
    
      
    },
    destroy() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('company-profiles').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/company-profiles`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    },
    validateAllSteps(){
        let validSteps = [];
        let firstCorrectableStep = undefined;
        let steps = ['general','coveredArea','taxes','notifications','licenses'] ;
        for (let i = 0; i < steps.length; i++) {
            if(this.validateStep(steps[i])) {
                validSteps.push(true)
            } else {
                validSteps.push(false)
                if(firstCorrectableStep === undefined) {
                    firstCorrectableStep = steps[i] ;
                }
            }
                  
        }
        if(validSteps.includes(false)) {
            
            this.$refs.mainTabs.activeTab = firstCorrectableStep
            //this.mainActiveTab = 'general' ;
            this.$v.$touch();
        } else {
            console.log('All steps are valid')
            this.update();
        }

        
    },
    validateStep(step){
        
        
        switch(step) {
            case 'general':
                let name,address,phones,logo,timeZone;
                name = this.$v.companyProfile.name.$invalid;
                address = this.$v.companyProfile.address.$invalid;
                phones = this.$v.companyProfile.phones.$invalid;
                //logo = this.$v.files.$invalid;
                 timeZone = this.$v.companyProfile.timeZone.$invalid;
                
                this.tabs.general.errors = 0 ;
                name ? this.tabs.general.errors++ : false;
                address ? this.tabs.general.errors++ : false;
                phones ? this.tabs.general.errors++ : false;
                //logo ? this.tabs.general.errors++ : false;
                timeZone ? this.tabs.general.errors++ : false;
                
                return !name && !address && !phones && !timeZone? true : false ;
            
            case 'coveredArea':
                let paths ;
                paths = this.$v.companyProfile.coveredArea.paths.$invalid;
                
                this.tabs.coveredArea.errors = 0;
                paths ? this.tabs.coveredArea.errors++ : false;
                
                
                return !paths ? true : false ;

            case 'taxes':
                let saleTax,creditCardFee;
                saleTax = this.$v.companyProfile.saleTax.$invalid;
                creditCardFee = this.$v.companyProfile.creditCardFee.$invalid;
                this.tabs.taxes.errors = 0;
                saleTax ? this.tabs.taxes.errors++ : false;
                creditCardFee ? this.tabs.taxes.errors++ : false;
                return  !saleTax && !creditCardFee? true : false ;

            case 'notifications':
                let mailList,smsPhoneTarget;
                mailList = this.$v.companyProfile.mailList.$invalid;
                smsPhoneTarget = this.$v.companyProfile.smsPhoneTarget.$invalid;
                this.tabs.notifications.errors = 0;
                mailList ? this.tabs.notifications.errors++ : false;
                smsPhoneTarget ? this.tabs.notifications.errors++ : false;
                return  !mailList && !smsPhoneTarget? true : false ;
                
            case 'licenses':
                let licenses;
                licenses = this.$v.companyProfile.licenses.$invalid;
                //licenses ? this.tabs.licenses.errors++ : false;
                //return  !licenses ? true : false ;
                return  true ;
            
                
        }
    },                    
   
    getValidationClass (fieldName) {
        let field = null;

        if(fieldName == 'files') {
            field = this.$v[fieldName];
        } else {
            field = this.$v.companyProfile[fieldName];
        } 
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
  },
  
  validations: {
       
      companyProfile: {
          
          name: {
              required
          },
          address: {
              required
          },
          phones: {
              required
          },
          logo: {
              required
          },
          timeZone: {
              required
          },
          coveredArea: {
              paths: {
                  required: requiredIf(function(){
                        console.log(this.areas)
                        console.log(this.companyProfile)
                        console.log('requiredIf',this.areas.length == 0 && this.companyProfile.coveredArea.fencesList.length == 0)
                        return this.areas.length == 0 && this.companyProfile.coveredArea.fencesList.length == 0 ? true : false ;
                  })
              }
          },
          saleTax: {
              required
          },
          creditCardFee: {
              required
          },
          mailList: {
              required,
              email
          },
          smsPhoneTarget: {
              required
          },
          licenses: {
              required
          }
          
      }
  }
}

</script>
<style >
  .badge {
    width: 19px;
    height: 19px;
    line-height:19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }

  .badge.md-primary {
      background-color: rgb(119, 207, 119);
  }

  .badge.md-primary .md-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    font-size: 12px!important;
    color:white!important;

  }

  .text-decoration-line-through .md-table-head-label {
      text-decoration: line-through;
  }

  .bg-black {
      background-color: black;
      color:white;
      height: 32px;
      /*padding: 0px 32px 0px 24px!important;*/
  }

  .buttons-container .md-button{
        min-width: initial;
    }

  
</style>

