<template>
    <div>
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1">Communities</h3>
          
          
          <md-button class="md-primary md-raised" @click="add">New</md-button>
          
      </md-toolbar>
      <md-progress-bar md-mode="indeterminate" v-if="isBusy"></md-progress-bar>
     
      <div class="md-content md-table md-theme-default">
        <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
                
                  <tr class="md-table-row">
                    <th class="md-table-head">
                      <div class="md-table-cell-container">
                        Name
                      </div>
                      
                    </th>
                    <th class="md-table-head">
                      <div class="md-table-cell-container">
                        Type
                      </div>
                      
                    </th>
                   
                   
                   
                    <th class="md-table-head">
                      <div class="md-table-cell-container">
                        Order
                      </div>
                      
                    </th>
                  </tr>
                
                <draggable v-model="communities" tag="tbody" @change="listUpdated" class="table-content">
                  <tr v-for="item in communities" :key="item.id" class="md-table-row" @click="rowClicked(item)">
                    <td class="md-table-cell">
                        <div class="md-table-cell-container">{{ item.name }}
                        </div>
                    </td>
                    <td class="md-table-cell">
                        <div class="md-table-cell-container">{{ item.role }}
                        </div>
                    </td>
                    
                    
                   
                    <td class="md-table-cell">
                        <div class="md-table-cell-container">
                          <md-icon class="grab-cursor">drag_indicator</md-icon>
                        </div>
                    </td>
                  </tr>
                </draggable>
            </table>
            <div class="md-layout pl-3 pr-3 md-alignment-center-left">
              <div class="md-layout-item">
                <vue-ads-pagination
                  :total-items="total"
                  :max-visible-pages="5"
                  :page="activePage"
                  :items-per-page="rowsPerPage"
                  :loading="isBusy"
                  @page-change="pageChange"
                  @range-change="rangeChange"
              >
                  <template slot-scope="props">
                      <div class="vue-ads-pr-2 vue-ads-leading-loose">
                          Showing Items {{ props.start }} to {{ props.end }} of {{ props.total }}
                      </div>
                  </template>
                  
                  <template
                      slot="buttons"
                      slot-scope="props"
                  >
                  
                      <vue-ads-page-button
                          v-for="(button, key) in props.buttons"
                          :key="key"
                          v-bind="button"
                          @page-change="activePage = button.page"
                      />
                  </template>
                </vue-ads-pagination>
              </div>
              <div class="md-layout-item md-size-10">
                <md-field class="mb-0">
                    <label>Rows</label>
                    <md-select v-model="rowsPerPage" md-dense>
                      <md-option :value="option" v-for="option in pageOptions" v-bind:key="option">{{option}}</md-option>
                    </md-select>
                </md-field>
              </div>
            </div>
          
           
        </div>
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>
      
       
    </div>
  </template>
  
  <script>
  import firebase from '../../Firebase'
  import CTableWrapper from '../base/Table'
  import draggable from 'vuedraggable'
  import 'vue-ads-pagination/dist/vue-ads-pagination.css';
   
  import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
  export default {
    name: 'Commmunities',
    components: { 
      CTableWrapper, 
      draggable , 
      VueAdsPagination,
      VueAdsPageButton, 
    },
    data() {
      return {
        showSnackbar: false,
        position: 'center',
        duration: 7000,
        isInfinity: false,
        snackMessage: '',
        isBusy: false,
        communities: [],
        //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('taxes'),
        //items: usersData,
        fields: [
          {key: 'name', label: 'Name'},
          {key: 'type', label: 'Type'},
        ],
        paginated: [],
        activePage: 0,
        rowsPerPage: 10,
        total:0,
        pageOptions: [5,10,15,20,25,30],
        partialStart: null,
        partialEnd: null
      }
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
        
    },
    created () {
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('communities').onSnapshot((querySnapshot) => {
        console.log('Snap shot')
        this.communities = [];
        querySnapshot.forEach((doc) => {
  
          let dataToSave = doc.data();
          dataToSave.id = doc.id ;
          this.communities.push(dataToSave);
  
        });
        this.total = this.communities.length;
      });
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },
    methods: {
      pageChange (page) {
          this.page = page;
      },
      
      rangeChange (start, end) {
          this.paginated = this.communities.slice(start,end)
          this.partialStart = start;
          this.partialEnd = end;
  
  
      },
      listUpdated(ev){
        // console.log(ev);
        // console.log(ev.moved.element.type);
        // console.log('List updated');
        for (let i = this.partialStart  ; i < this.paginated.length + this.partialStart ; i++) {
            this.communities.splice(i,1,this.paginated[i - this.partialStart]);
          
        }
        
        this.updateOrder();
      },
      
      rowClicked (item, index) {
        console.log(item);
        this.$router.push({path: `communities/${item.name}/${item.id}`})
      },
      
      add(){
        this.$router.push({path: `communities/new`})
      },
      listUpdated(ev){
        console.log(ev);
        this.updateOrder();
      },
      updateOrder() {
        let th = this;
        for (let index = 0; index < this.communities.length; index++) {
            this.communities[index].order = index + 1;
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('communities').doc(this.communities[index].id).update({
              order: index + 1
            })
            .then(function(){
                console.log('Updated');
                th.snackMessage = 'Communities order updated';
                th.isBusy = false;
                th.showSnackbar = true;
            }).catch(function(error) {
               console.log("Error getting document:", error);
               th.isBusy = false;
            });
        }
        
      },
    }
  }
  </script>