<template>
    <div>
       <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1">Partner search</h3>
          
          <md-button class="md-primary md-raised" @click="search">Search partner</md-button>
          
      </md-toolbar>
      <div v-if="isBusy">
        <md-progress-bar md-mode="query" ></md-progress-bar> 
      </div>
      <div v-else>
        <md-tabs 
            class="md-transparent" 
            md-alignment="left" 
            @md-changed="mainTabChanged" 
            ref="mainTabs" 
            :md-active-tab="mainActiveTab" 
            :md-dynamic-height="true"
        > 
            <template slot="md-tab" slot-scope="{ tab }"> 
              {{ tab.label }} 
              <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
            </template>
            <md-tab id="active" md-label="Active linked partners" :md-template-data="{ badge: newActive }">
                <md-empty-state
                    v-show="users.length === 0 && !isBusy"
                    
                        md-icon="person_add"
                        md-label="Add your first partner"
                        md-description="Adding a partner will let you receive offers">
                        <md-button class="md-primary md-raised" @click="search">Add first partner</md-button>
                </md-empty-state>
                <md-table v-show="users.length > 0" v-if="users">
                    <md-table-row>
                        <md-table-head>Email</md-table-head>
                        <md-table-head>Phones</md-table-head>
                        <md-table-head>Areas</md-table-head>
                        <md-table-head>Role</md-table-head>
                        <md-table-head>Code</md-table-head>
                        <md-table-head>Actions</md-table-head>
                        
                    </md-table-row>
            
                    <md-table-row v-for="user in users" v-bind:key="user.uid + '-table-row'" :class="{'pulse lightyellow': user.highlight}" class="white-space-no-wrap">
                        <md-table-cell>
                            {{user.email}}
                            <md-chip class="md-primary ml-2" v-if="!user.readed">New</md-chip>
                        </md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <li v-for="(phone, index) in user.phones" v-bind:key="phone + index + 'linked'">
                                    {{ phone }}
                                </li>
                            </ul>
                        </md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <li v-for="(area,index) in user.areas" v-bind:key="area.formatted_address + index + 'linked'">
                                    {{ area.formatted_address }}
                                </li>
                            </ul>
                        </md-table-cell>
                        <md-table-cell>{{user.role}}</md-table-cell>
                        <md-table-cell>{{user.code}}</md-table-cell>
                        <md-table-cell>
                            <md-button class="md-raised md-primary" @click="viewInfo(user)">View info</md-button>
                            <md-button class="md-raised md-accent" @click="unlinkUser(user)">Unlink user</md-button>
                        </md-table-cell>
                        
                    </md-table-row>
                </md-table>
            </md-tab>
            <md-tab id="requests" md-label="Partner enrollment requests">
                <md-empty-state
                    v-if="requests.length === 0"
                    md-icon="person_add"
                    :md-label="users.length == 0 ? 'Add your first partner enrollment request' : 'Create new enrollment request'"
                    md-description="Send linking requests to partners you want to receive offers from.">
                    <md-button class="md-primary md-raised" @click="search">Search partner</md-button>
                </md-empty-state>
                <md-table v-show="requests.length > 0" v-if="requests">
                    <md-table-row>
                        <md-table-head>Email</md-table-head>
                        <md-table-head>Phone</md-table-head>
                        <md-table-head>Areas</md-table-head>
                        <md-table-head>Role</md-table-head>
                        <md-table-head>Code</md-table-head>
                        <md-table-head>Created at</md-table-head>
                        <md-table-head>Status</md-table-head>
                        <md-table-head>Actions</md-table-head>
                        
                    </md-table-row>
            
                    <md-table-row v-for="request in requests" v-bind:key="request.uid" :class="{'pulse lightyellow': request.highlight}" class="white-space-no-wrap">
                        <md-table-cell>{{request.email}}</md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <template v-for="profile in request.profiles" >
                                    <li v-for="(phone,index) in profile.data.phones" v-bind:key="phone + index + '-inner-list'">
                                        {{ phone }}
                                    </li>
                                </template>
                                
                            </ul>
                        </md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <template v-for="profile in request.profiles" >
                                    <li v-for="matchedArea in profile.matchedAreas" v-bind:key="matchedArea.name">
                                        {{ matchedArea.name }}
                                    </li>
                                </template>
                                
                            </ul>
                        </md-table-cell>
                        <md-table-cell>{{request.role}}</md-table-cell>
                        <md-table-cell>{{request.code}}</md-table-cell>
                        <md-table-cell>{{request.timestamp | moment("YYYY-MM-DD hh:mm A (HH:mm [HS])")}}</md-table-cell>
                        <md-table-cell>
                          <md-chip class="md-accent" v-if="request.rejected">Rejected</md-chip>
                          <md-chip v-else>Waiting user confirmation</md-chip>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button class="md-raised md-accent" @click="deleteOutgoingRequest(request)">Delete request</md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </md-tab>
            <md-tab id="incoming" md-label="Incoming enrollment requests" :md-template-data="{ badge: newIncoming }">
                <md-empty-state
                    v-if="incoming.length === 0"
                    md-icon="person_add"
                    :md-label="users.length == 0 ? 'Add your first partner enrollment request' : 'Create new enrollment request'"
                    md-description="Send linking requests to partners you want to receive offers from.">
                    <md-button class="md-primary md-raised" @click="search">Search partner</md-button>
                </md-empty-state>
                <md-table v-show="incoming.length > 0" v-if="incoming">
                    <md-table-row>
                        <md-table-head>Email</md-table-head>
                        <md-table-head>Phones</md-table-head>
                        <md-table-head>Areas</md-table-head>
                        <md-table-head>Role</md-table-head>
                        <md-table-head>Code</md-table-head>
                        <md-table-head>Created at</md-table-head>
                        <md-table-head>Actions</md-table-head>
                    </md-table-row>
            
                    <md-table-row v-for="request in incoming" v-bind:key="request.uid" :class="{'pulse lightyellow': request.highlight}" class="white-space-no-wrap">
                        <md-table-cell>
                            {{request.email}}
                            <md-chip class="md-primary ml-2" v-if="!request.readed">New</md-chip>
                        </md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <li v-for="(phone,index) in request.phones" v-bind:key="phone + index +  '-another-inner-list'">
                                    {{ phone }}
                                </li>
                            </ul>
                        </md-table-cell>
                        <md-table-cell >
                            <ul class="table-inner-list">
                                <li v-for="(area,index) in request.areas" v-bind:key="area.formatted_address + index + 'incoming'">
                                    {{ area.formatted_address }}
                                </li>
                            </ul>
                        </md-table-cell>


                        <md-table-cell>{{request.role}}</md-table-cell>
                        <md-table-cell>{{request.code}}</md-table-cell>
                        <md-table-cell>{{request.timestamp | moment("YYYY-MM-DD hh:mm A (HH:mm [HS])")}}</md-table-cell>
                        <md-table-cell>
                            <md-button class="md-raised md-primary" @click="acceptIncomingRequest(request)">Accept</md-button>
                            <md-button class="md-raised md-accent" @click="rejectIncomingRequest(request)" :disabled="request.rejected">{{request.rejected ? 'Rejected' : 'Reject'}}</md-button>
                        </md-table-cell>
                        
                    </md-table-row>
                </md-table>
            </md-tab>
        </md-tabs>
      </div>
      <md-dialog
            :style="[dialogDimensions]"
            :md-active.sync="searchDialog"
        >
        <md-dialog-title>Search by unique code, email or zone</md-dialog-title>
        <md-dialog-content>
            <div v-if="requesting">
                <p>Performing request</p>
                <md-progress-bar md-mode="indeterminate" ></md-progress-bar>
                
            </div>
            <div v-else>
                
                
                <md-switch v-model="zoneQuery">{{ zoneQuery ? 'Searching by zone' : 'Searching by code or email' }}</md-switch>
                <md-field :class="{'md-invalid':getValidationClass('role')}" v-if="zoneQuery">
                    <label for="role">Role</label>
                    <md-select v-model="role" name="role" id="role" @md-selected="roleSelected()">
                        <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                        
                    </md-select>
                    <span class="md-error" v-if="!$v.role.required">Role is required</span>
                </md-field>
                <md-field :class="{'md-focused':inputFocused || searching, 'md-invalid' : getValidationClass('searchTerm')}" >
                    <label>Type {{ zoneQuery ? 'zone': 'code or email'}}</label>
                    <md-input v-model="searchTerm" @keyup="resetQuery" v-show="!zoneQuery" :disabled="searching"></md-input>
                    
                    
                    <gmap-autocomplete
                        ref="autocomplete"
                        placeholder=""
                        @place_changed="setFence($event)" class="pu-autocomplete"
                        @blur="resetCursor()"
                        @focus="locateCursor()"
                    
                        v-show="zoneQuery"
                        :disabled="searching"
                        :types="mapTypes"
                        >
                    </gmap-autocomplete>
                    <span class="md-helper-text">{{zoneQuery ? 'Type place, city, state, country' : 'e.g. COA-1003, companyname@mail.com'}}</span>
                     <span class="md-error" v-if="!$v.searchTerm.required">Field is required</span>
                     <span class="md-error" v-if="!$v.coveredArea.fencesList.required">Field is required</span>
                </md-field>
                <md-button class="md-primary full-width-button md-raised" @click="performQuery" :disabled="searching">
                    
                    <!--md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" v-show="searching"></md-progress-spinner-->
                    {{ searching ? 'Searching ' + role: 'Search ' + role }} 
                    <md-progress-bar md-mode="indeterminate" v-show="searching"></md-progress-bar>
                    
                </md-button>
                <div v-if="queryResults.length > 0">
                    <md-divider class="mt-2 mb-2"></md-divider>

                    <div v-if="activeQueryListType == 'zone' && zonePreview.searched.geometry.lat &&  zonePreview.searched.geometry.lng">
                        
                    </div>

                    <h3 class="md-headline mt-3">Search results</h3>
                    <md-table v-if="activeQueryListType == 'string'" @md-selected="onSelect" v-model="queryResults">
                        

                        <md-table-toolbar class="alternate-toolbar" slot="md-table-alternate-header" slot-scope="{ count }">
                            <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>

                            <div class="md-toolbar-section-end">
                                <md-button class="ml-0 md-raised md-primary" @click="bulkRequest()" >
                                    <md-icon class="mr-2">send</md-icon>
                                    send enrollment request
                            </md-button>
                            </div>
                        </md-table-toolbar>
                        <!--md-table-row>
                            <md-table-head>Email</md-table-head>
                            <md-table-head>Role</md-table-head>
                            <md-table-head>Code</md-table-head>
                            <md-table-head>Matched company profiles</md-table-head>
                            <md-table-head>Action</md-table-head>
                        </md-table-row-->
                        
                        <md-table-row 
                            slot="md-table-row" 
                            slot-scope="{ item }" 
                            :md-disabled="item.exists" md-selectable="multiple" md-auto-select>
                            <md-table-cell md-label="Name" md-sort-by="auth.displayName">
                                {{ item.auth.displayName }}
                            </md-table-cell>
                            <md-table-cell md-label="Email" md-sort-by="auth.email">
                                {{ item.auth.email }}
                                <md-chip v-if="item.exists" class="md-primary mt-2">Already linked</md-chip>
                            </md-table-cell>
                            <md-table-cell md-label="Phones">
                                <ul class="table-inner-list">
                                    <template v-for="profile in item.profiles" >
                                        <li v-for="phone in profile.data.phones" v-bind:key="phone">
                                            {{ phone }}
                                        </li>
                                    </template>
                                    
                                </ul>
                            </md-table-cell>
                            <md-table-cell md-label="Areas">
                                <ul class="table-inner-list">
                                    <template v-for="profile in item.profiles" >
                                        <li v-for="matchedArea in profile.matchedAreas" v-bind:key="matchedArea.name">
                                            {{ matchedArea.name }}
                                        </li>
                                    </template>
                                    
                                </ul>
                            </md-table-cell>
                            <md-table-cell  md-label="Role" md-sort-by="auth.customClaims.role">{{item.auth.customClaims.role}}</md-table-cell>
                            <md-table-cell  md-label="Code" md-sort-by="auth.customClaims.code">{{item.auth.customClaims.code}}</md-table-cell>
                            <md-table-cell  md-label="Matched partner profiles" >{{item.profiles.length}}</md-table-cell>
                            <md-table-cell md-label="Action">
                            <md-button class="md-raised" @click="viewRefMap(item)" v-if="!zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                View area map</md-button>
                                <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                Hide area map</md-button>
                            
                            </md-table-cell>
                        </md-table-row>
                
                        <!--md-table-row v-for="user in queryResults" v-bind:key="'result-' + user.uid" >
                            <md-table-cell>
                                {{user.auth.email}} 
                                <md-chip v-if="user.exists" class="md-primary mt-2">Already linked</md-chip>
                            </md-table-cell>
                            <md-table-cell>{{user.auth.customClaims.role}}</md-table-cell>
                            <md-table-cell>{{user.auth.customClaims.code}}</md-table-cell>
                            <md-table-cell>{{ user.profiles.length }}</md-table-cell>
                            <md-table-cell>
                            <md-button class="md-raised" @click="viewRefMap(user)" v-if="!zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                View area map</md-button>
                                <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                Hide area map</md-button>
                            <md-button class="ml-0 md-raised md-primary" @click="sendRequest(user.auth)" :disabled="user.exists">
                                <md-icon class="mr-2">send</md-icon>
                                send enrollment request
                                <md-tooltip v-if="user.exists" md-direction="bottom">Already linked</md-tooltip>
                            </md-button>
                            </md-table-cell>
                        </md-table-row-->
                    </md-table>

                    <md-table v-if="activeQueryListType == 'zone'" @md-selected="onSelect" v-model="queryResults">
                        

                        <md-table-toolbar class="alternate-toolbar" slot="md-table-alternate-header" slot-scope="{ count }">
                            <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>

                            <div class="md-toolbar-section-end">
                                <md-button class="ml-0 md-raised md-primary" @click="bulkRequest()" >
                                    <md-icon class="mr-2">send</md-icon>
                                    send enrollment request
                            </md-button>
                            </div>
                        </md-table-toolbar>
                        <!--md-table-row>
                            <md-table-head>Email</md-table-head>
                            <md-table-head>Role</md-table-head>
                            <md-table-head>Code</md-table-head>
                            <md-table-head>Matched company profiles</md-table-head>
                            <md-table-head>Action</md-table-head>
                        </md-table-row-->
                        
                        <md-table-row 
                            slot="md-table-row" 
                            slot-scope="{ item }" 
                            :md-disabled="item.exists" md-selectable="multiple" md-auto-select>
                            <md-table-cell md-label="Name" md-sort-by="auth.displayName">
                                {{ item.auth.displayName }}
                            </md-table-cell>
                            <md-table-cell md-label="Email" md-sort-by="auth.email">
                                {{ item.auth.email }}
                                <md-chip v-if="item.exists" class="md-primary mt-2">Already linked</md-chip>
                            </md-table-cell>
                            <md-table-cell md-label="Phones">
                                <ul class="table-inner-list">
                                    <template v-for="profile in item.profiles" >
                                        <li v-for="phone in profile.data.phones" v-bind:key="phone">
                                            {{ phone }}
                                        </li>
                                    </template>
                                    
                                </ul>
                            </md-table-cell>
                            <md-table-cell md-label="Areas">
                                <ul class="table-inner-list">
                                    <template v-for="profile in item.profiles" >
                                        <li v-for="matchedArea in profile.matchedAreas" v-bind:key="matchedArea.name">
                                            {{ matchedArea.name }}
                                        </li>
                                    </template>
                                    
                                </ul>
                            </md-table-cell>
                            <md-table-cell  md-label="Role" md-sort-by="auth.customClaims.role">{{item.auth.customClaims.role}}</md-table-cell>
                            <md-table-cell  md-label="Code" md-sort-by="auth.customClaims.code">{{item.auth.customClaims.code}}</md-table-cell>
                            <md-table-cell  md-label="Matched partner profiles" >{{item.profiles.length}}</md-table-cell>
                            <md-table-cell md-label="Action">
                            <md-button class="md-raised" @click="viewRefMap(item)" v-if="!zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                View area map</md-button>
                                <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                Hide area map</md-button>
                            
                            </md-table-cell>
                        </md-table-row>
                
                        <!--md-table-row v-for="user in queryResults" v-bind:key="'result-' + user.uid" >
                            <md-table-cell>
                                {{user.auth.email}} 
                                <md-chip v-if="user.exists" class="md-primary mt-2">Already linked</md-chip>
                            </md-table-cell>
                            <md-table-cell>{{user.auth.customClaims.role}}</md-table-cell>
                            <md-table-cell>{{user.auth.customClaims.code}}</md-table-cell>
                            <md-table-cell>{{ user.profiles.length }}</md-table-cell>
                            <md-table-cell>
                            <md-button class="md-raised" @click="viewRefMap(user)" v-if="!zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                View area map</md-button>
                                <md-button class="md-raised" @click="hideRefMap()" v-if="zonePreview.showMap">
                                <md-icon class="mr-2">map</md-icon>
                                Hide area map</md-button>
                            <md-button class="ml-0 md-raised md-primary" @click="sendRequest(user.auth)" :disabled="user.exists">
                                <md-icon class="mr-2">send</md-icon>
                                send enrollment request
                                <md-tooltip v-if="user.exists" md-direction="bottom">Already linked</md-tooltip>
                            </md-button>
                            </md-table-cell>
                        </md-table-row-->
                    </md-table>
                </div>
                <md-empty-state
                        v-else-if="queryResults.length == 0 && searchTerm != '' && firstRequestDone"
                        md-icon="person_add"
                        :md-label="'No results found for ' + searchTerm + '.'"
                        md-description="Double check inserted info">
                    
                </md-empty-state>
          </div>
        </md-dialog-content>
      </md-dialog>

      <md-dialog
            :style="[dialogDimensions]"
            :md-active.sync="zonePreview.showMap"
            @md-closed="zonePreviewClosed()"
        >
        <md-dialog-title class="up-text-primary md-headline mb-2">Area map</md-dialog-title>
        <md-dialog-content>
            <div v-if="zonePreview.showMap">
                <md-divider class="mb-2"></md-divider>
                <CRow>
                    <CCol sm="12" md="6">
                        <h2 class="mt-4 md-title">{{ zonePreview.user.displayName }}</h2>
                        <CRow>
                            <CCol sm="12" md="6">
                                <h3 class="mt-0 mb-0 md-body-2">Role:<br> <b>{{ zonePreview.user.customClaims.role }}</b></h3>
                            </CCol>
                            <CCol sm="12" md="6">
                                <h4 class="mt-2 mb-0 md-body-1">Code: <br><b>{{ zonePreview.user.customClaims.code }}</b></h4>
                            </CCol>
                        </CRow>
                        
                        
                    
                        <h2 class="md-subheading mt-4">Matched partner profiles</h2>
                        <md-table>
                            <md-table-row>
                                <md-table-head>Name</md-table-head>
                                <md-table-head>Time Zone</md-table-head>
                                
                            </md-table-row>
                            <md-table-row v-for="profile in zonePreview.profiles" v-bind:key="'profile-' + profile.id" >
                                <md-table-cell>{{profile.data.name}}</md-table-cell>
                                <md-table-cell>{{profile.data.timeZone}}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </CCol>
                
                    <CCol sm="12" md="6">
                        <h2 class="mt-4 mb-3 md-title">Searched area vs marched area</h2>
                        <GmapMap 
                            ref="fenceMap" 
                            :center="{lat:zonePreview.searched.geometry.lat,lng:zonePreview.searched.geometry.lng}" 
                            :zoom="10" 
                            style="width:100%;height:300px"
                            
                        >
                        <!--gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                        </gmap-info-window-->
                        <gmap-info-window 
                            :options="infoOptions" 
                            :position="infoWindowPos" 
                            :opened="infoWinOpen" 
                            @closeclick="infoWinOpen=false"
                        >
                        </gmap-info-window>

                            <!--gmap-polygon v-for="(area,index) in zonePreview.matched" v-bind:key="'path' + index" :paths="area.paths" :editable="false" :options="{strokeColor: '#F00', visible: zonePreview.showMatched}" >
                            </gmap-polygon>
                            <gmap-polygon v-for="(path,index) in zonePreview.searched.paths" v-bind:key="'new-path' + index" :paths="path" :editable="false" :options="{strokeColor: '#0F0', visible:zonePreview.searched.show}">
                            </gmap-polygon-->
                            <gmap-polygon 
                                v-for="(polygon,index) in polygons" 
                                v-bind:key="'polygon-' + index" 
                                :paths="polygon.paths" 
                                :editable="false" 
                                :options="polygon.options"
                                @click="toggleInfoWindow(polygon,index)"
                            >
                            </gmap-polygon>
                        </GmapMap>
                        <CRow class="mt-4 mb-4">
                            <CCol sm="6">
                                <md-switch v-model="zonePreview.searched.show" class="md-primary yellow-switch">Show Searched</md-switch>
                            </CCol>
                            <CCol sm="6">
                                <md-switch v-model="zonePreview.showMatched" class="md-primary green-switch">Show Matched</md-switch>
                            </CCol>
                        </CRow>
                        
                    </CCol>
                </CRow>
                

            </div>

        </md-dialog-content>
        <md-dialog-actions v-if="zonePreview.showMap">
            <md-button class="md-raised mr-2" @click="zonePreview.showMap = false;">Close</md-button>
            <md-button class="ml-0 md-raised md-primary" @click="sendRequest(zonePreview.user,zonePreview.profiles)" :disabled="zonePreview.user.exists || requesting">
                <md-icon class="mr-2">send</md-icon>
                {{ requesting ? 'sending-request' : 'send enrollment request' }}
                <md-progress-bar md-mode="indeterminate" v-show="requesting"></md-progress-bar>
                <md-tooltip v-if="zonePreview.user.exists" md-direction="bottom">Already linked</md-tooltip>
            </md-button>
        </md-dialog-actions>
      </md-dialog>
      
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent >
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false" v-if="snackType == 'standard'">Ok</md-button>
          <md-button class="md-primary" @click="showSnackbar = false; snackbarClosed()" v-if="snackType == 'existing'">Ok</md-button>
      </md-snackbar>
    </div>
</template>
  
<script>

import axios from "./../../helpers/axios"
import firebase from '../../Firebase'
import moment from 'moment'
import moment_timezone from "moment-timezone"; 
import newAxios from "axios"
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'

export default {
    name: 'PartnerSearch',
    mixins: [validationMixin],
    data () {
        return {
            areaValidation: {
                message: '',
                valid: true
            },
            selected: [],
            newActive: 0,
            newIncoming:0,
            mapTypes: [
                'political'
                // 'country', 
                // 'administrative_area_level_1', 
                // 'administrative_area_level_2',
                // 'administrative_area_level_3',
                // 'locality',
                // 'sublocality_level_1',
            ],
            dialogLeft: 0,
            infoWindowPos: null,
            infoWinOpen: false,
            infoOptions: {
                content: ''
            },
            zonePreview: {
                showMap:false,
                searched: {
                    geometry: {
                        lat: null,
                        lng: null
                    },
                    paths: [],
                    show: true

                },
                matched: [],
                showMatched: true,
                user: null,
                profiles: []
            },
            activeQueryListType: null , //'string' | 'zone',
            inputFocused: false,
            zoneQuery: true,
            users: [],
            incoming: [],
            isBusy: true,
            mainActiveTab: 'active',
            searchDialog: false,
            requests: [],
            queryResults: [],
            searchTerm:'',
            role: '',
            subrole: null,
            roles: [
                
                {
                    name: 'company-admin',
                    text: 'Company admin'
                },
                {
                    name: 'driver',
                    text: 'Driver'
                },
                {
                    name: 'dispatcher',
                    text: 'Dispatcher'
                },
                {
                    name: 'affiliate',
                    text: 'Affiliate'
                },
                
            ],
            searching: false,
            firstRequestDone: false,
            requesting: false,

            // Snack
            showSnackbar: false,
            position: 'center',
            duration: 7000,
            isInfinity: false,
            snackMessage: '',
            snackType: null,
            geofence: {
                name: null,
                code:null,
                path: [],
                zoom:7,
                center: {lat: 1.38, lng: 103.8},
            },

            coveredArea: {
                paths: [
                    // [
                    //     { lat: 25.774, lng: -80.19 },
                    //     { lat: 18.466, lng: -66.118 },
                    //     { lat: 32.321, lng: -64.757 }
                    // ],
                    // [
                    //     { lat: 23.774, lng: -80.19 },
                    //     { lat: 12.466, lng: -66.118 },
                    //     { lat: 28.321, lng: -64.757 }
                    // ]
                ],
                zoom:4,
                coordinates: { 
                    lat: 24.886, lng: -70.268 
                },
                fencesList: []

            },
        }
    },
    mounted() {
        this.$store.commit('centerDialog',window.innerWidth)
    },

    created() {
        //this.getUsers();
        //this.getUsers();
        this.isBusy = true
        this.obtainViewResources();
        this.isBusy = false
        window.addEventListener("resize", this.myEventHandler);
    },
    computed: {
        allTypeUsers() {
            let usersList = []
            for (let i = 0; i < this.users.length; i++) {
                usersList.push(this.users[i])
            }

            for (let i = 0; i < this.requests.length; i++) {
                usersList.push(this.requests[i])
            }

            for (let i = 0; i < this.incoming.length; i++) {
                usersList.push(this.incoming[i])
            }

            return usersList
        },
        polygons() {
            let polygons = [];
            for (let i = 0; i < this.zonePreview.matched.length; i++) {


                ///
                let pathsToSave = []
                for (const path in this.zonePreview.matched[i].paths) {
                    if (Object.hasOwnProperty.call(this.zonePreview.matched[i].paths, path)) {
                        const element = this.zonePreview.matched[i].paths[path];
                       
                        console.log(element)
                        pathsToSave.push(element)
                    
                        
                    }
                }
                ///
                let polygon = {
                    paths:pathsToSave,
                    formatted_address: this.zonePreview.matched[i].formatted_address,
                    geometry: this.zonePreview.matched[i].geometry,
                    options: {
                        strokeColor: '#67c38e',
                        visible: this.zonePreview.showMatched ? true : false,
                        zIndex: 1,
                        strokeWeight: 5
                    },
                    matched: true

                }
                polygons.push(polygon)
                
            }

            for (let i = 0; i < this.zonePreview.searched.paths.length; i++) {
                let polygon = {
                    paths:this.zonePreview.searched.paths[i],
                    formatted_address: this.zonePreview.searched.formatted_address,
                    geometry: this.zonePreview.searched.geometry,
                    options: {
                        strokeColor: '#f9ba00',
                        visible: this.zonePreview.searched.show ? true : false,
                        strokeOpacity: 1,
                        zIndex: 2,
                        strokeWeight: 5
                    },
                    searched: true

                }
                polygons.push(polygon)
                
            }
            return polygons
        },
        user() {
            return this.$store.state.user;
        },
        dialogDimensions () {
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
       
        
    },
    methods: {
        
        validate(){
            let role,searchTerm, fencesList;
           
            role = this.$v.role.$invalid;
            searchTerm = this.$v.searchTerm.$invalid;
            fencesList = this.$v.coveredArea.fencesList.$invalid;

            
            return !role && !searchTerm && !fencesList ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            if(fieldName === 'searchTerm') {
                if(this.zoneQuery) {
                    field = this.$v.coveredArea.fencesList;
                } else {
                    field = this.$v[fieldName];
                }
            } else {

                field = this.$v[fieldName];
            }
            
            
            
            if (field) {
                //return true
                return field.$invalid && field.$dirty
            }
        },
        roleSelected() {
      
            if(this.role != 'driver') {
                this.subrole = null
            }
        },
        bulkRequest() {
            for (let i = 0; i < this.selected.length; i++) {
                this.sendRequest(this.selected[i].auth, this.selected[i].profiles)
                
            }

        },
        getAlternateLabel (count) {
            let plural = ''

            if (count > 1) {
            plural = 's'
            }

            return `${count} user${plural} selected`
        },
        onSelect(items){
            this.selected = items
        },
        myEventHandler(e) {
            // your code for handling resize...
            this.$store.commit('centerDialog',window.innerWidth)
        },
        
        toggleInfoWindow(polygon, idx) {
            console.log(polygon)
            console.log(idx)
            this.infoWinOpen = true
            this.infoWindowPos = polygon.geometry;
            this.infoOptions.content = `    <div class="md-subheading up-text-soft-black">${polygon.searched ? 'Searched area' : ''}${polygon.matched ? 'Matched area' : ''}
                                            </div>
                                            <div class="up-text-soft-black">
                                                ${polygon.formatted_address}
                                            </div>`;

            // //check if its the same marker that was selected if yes toggle
            // if (this.currentMidx == idx) {
            //     this.infoWinOpen = !this.infoWinOpen;
            // }
            // //if different marker set infowindow to open and reset current marker index
            // else {
            //     this.infoWinOpen = true;
            //     this.currentMidx = idx;

            // }
        },
        polygonHovered(event) {
            console.log(event)
            this.infoWinOpen = true;
            this.infoOptions.content = 'Opened'

        },
        zonePreviewClosed() {
            this.searchDialog = true
        },
        async getBoundarie(lat, lon) {
            let params = {
                apiKey: '3f5d675290ce41e08917bc9f40516382',
                geometry: 'geometry_10000',
                lat: lat,
                lon: lon

            }
            return new Promise((resolve, reject) => {
                newAxios.create({
                    headers: {},                    
                }).get('https://api.geoapify.com/v1/boundaries/part-of',
                {params: params})
                .then(response =>{
                    console.log(response)
                    resolve(response.data)
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
        resetCursor(){
            if(this.$refs.autocomplete.$el.value == '') {
                this.inputFocused = false
            }
            
        
        },
        locateCursor(){
            this.$refs.autocomplete.$el.focus()
            this.inputFocused = true
        },
        async setFence(place) {
            this.hideRefMap()
            this.queryResults = []
            this.coveredArea.fencesList = []
            console.log(place)
            let newFence = {
                formatted_address:place.formatted_address,
                indexes: [],
                address_components: place.address_components,
                geometry: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }
            }

            this.geofence.center = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            
            let placeLevel = place.address_components[0].types[0]
            console.log('placeLevel',placeLevel)

            newFence.name = place.formatted_address
            // Google naming 

            // country
            // administrative_area_level_1
            // locality
            // sublocality_level_1

            let boundarie = await this.getBoundarie(this.geofence.center.lat, this.geofence.center.lng)
            this.coveredArea.coordinates.lat = this.geofence.center.lat
            this.coveredArea.coordinates.lng = this.geofence.center.lng
            this.coveredArea.zoom = 10
            let categoryToLook = null;
            let alternativeCategoryToLook = null;
            let secondAlternativeCategoryToLook = null;
            switch(placeLevel) {
                case 'country':
                    //categoryToLook = "administrative.country_part_level"
                    categoryToLook = "administrative.country_level"
                    break;
                case 'administrative_area_level_1':
                    //categoryToLook = "administrative.state_level"
                    //categoryToLook = "administrative.county_level"
                    categoryToLook = "administrative.country_part_level"
                    break;
                case 'administrative_area_level_2':
                    //categoryToLook = "administrative.state_level"
                    categoryToLook = "administrative.county_level"
                    //categoryToLook = "administrative.district_level"
                    break;
                case 'locality':
                    //categoryToLook = "administrative.county_level"
                    //categoryToLook = "administrative.city_level"
                    categoryToLook = "administrative.state_level"
                    alternativeCategoryToLook = "administrative.district_level"
                    // ithaca, newy york : syracuse ny
                    secondAlternativeCategoryToLook = "administrative.city_level"
                        // ex New York , New York
                    // ex New York , New York
                    break;
                case 'sublocality_level_1':
                    categoryToLook = "administrative.city_level"
                    // ex. Manhattan, Bronx, Queens
                    break;
                case 'neighborhood':
                    //categoryToLook = "administrative.city_level"
                    this.areaValidation.message = 'Search a place larger than a neighborhood'
                    break;
                
            }
            let finalFeature = null
            if(categoryToLook || alternativeCategoryToLook || secondAlternativeCategoryToLook) {
                for (let i = 0; i < boundarie.features.length; i++) {
                    if(
                        boundarie.features[i].properties.categories[1] === categoryToLook
                        || boundarie.features[i].properties.categories[1] === alternativeCategoryToLook
                        || boundarie.features[i].properties.categories[1] === secondAlternativeCategoryToLook
                        ) 
                    {
                        finalFeature = boundarie.features[i]
                    }
                }

            } else {
                this.areaValidation.valid = false;

            }
            console.log('finalFeature',finalFeature)

            if(finalFeature) {
                if(finalFeature.geometry.type === "Polygon") {
                    console.log("Polygon")
                    let pathToSave = []

                    for (let i = 0; i < finalFeature.geometry.coordinates[0].length; i++) {
                        pathToSave.push({
                            lng: finalFeature.geometry.coordinates[0][i][0],
                            lat: finalFeature.geometry.coordinates[0][i][1],
                        })
                        
                        
                    }
                    let newIndex = this.coveredArea.paths.push(pathToSave) - 1
                    newFence.indexes.push(newIndex)
                } else if(finalFeature.geometry.type === "MultiPolygon") {
                    console.log("MultiPolygon")
                    for (let i = 0; i < finalFeature.geometry.coordinates.length; i++) {
                        let pathToSave = []
                        for (let j = 0; j < finalFeature.geometry.coordinates[i][0].length; j++) {
                            pathToSave.push({
                                lng: finalFeature.geometry.coordinates[i][0][j][0],
                                lat: finalFeature.geometry.coordinates[i][0][j][1],
                            })
                            
                            
                        }
                        let newIndex =  this.coveredArea.paths.push(pathToSave) - 1
                        newFence.indexes.push(newIndex)
                        
                    }
                }
                this.coveredArea.fencesList.push(newFence)
            }

            
            

            // Boundaries API naming 
            
            // "administrative.country_part_level"
            // "administrative.state_level"
            // "administrative.county_level"
            // "administrative.city_level"

            //Get the boundaries of the Map.
            
        // var bounds = this.$refs.fenceMap.$mapObject.getBounds();
        // this.$refs.fenceMap.fitBounds(bounds)

            //this.$refs.autocomplete.$el.value = ''

            //this.$nextTick(() => {
            //})
            
            //this.$refs.autocomplete.$el.focus()
            //this.$refs.autocomplete.$el.blur()
            //Center map and adjust Zoom based on the position of all markers.
            // map.setCenter(latlngbounds.getCenter());
            // map.fitBounds(latlngbounds);

            this.performQuery()
        },
        viewInfo(user){
            console.log(user)
            this.$router.push({path: `/partner-search/${user.role}/${user.uid}/${user.email}`})
        },
        async unlinkUser(user) {
            console.log(user)
            this.isBusy = true
            let th = this
            let data = {
                executer: {
                email: this.user.data.email,
                role: this.user.data.role,
                subrole: this.user.data.subrole,
                uid: this.user.data.uid,
                code: this.user.data.code,
                docId: user.id
                },
                other: {
                uid: user.uid,
                email: user.email,
                code: user.code,
                role: user.role
                }
            }
            console.log(data)
            axios.patch('users/unlink', data).then(response =>{
                    console.log(response);
                    th.snackMessage = 'User succesfully unlinked';
                    th.isBusy = false;
                    th.snackType = 'standard'
                    th.showSnackbar = true;
                }).catch(e => {
                th.snackMessage = 'Oops, something went wrong';
                    th.isBusy = false;
                    th.snackType = 'standard'
                    th.showSnackbar = true;
                })
        },
        deleteOutgoingRequest(user) {
            let timestamp = moment().unix()
            console.log(user)
            console.log(this.user)
            let th = this;
            let data = {
                outgoingId: user.id,
                receiver: {
                    email: user.email,
                    role: user.role,
                    subrole: user.subrole,
                    uid: user.uid,
                    code: user.code
                },
                
                sender: {
                    email: this.user.data.email,
                    role: this.user.data.role,
                    subrole: this.user.data.subrole,
                    uid: this.user.data.uid,
                    code: this.user.data.code
                },
                timestamp: timestamp,
            }
            console.log(data)
            axios.patch('users/request', data).then(response =>{
                console.log(response);
                th.snackMessage = 'Request deleted succesfully';
                th.snackType = 'standard'
                th.showSnackbar = true;
            }).catch(e => {
                console.log(e);  
                th.snackMessage = 'Something went wrong';
                th.snackType = 'standard'
                th.showSnackbar = true;              
            })

        },
        async acceptIncomingRequest(request){
            this.isBusy = true
            let dataToSave = request;
            let th = this
            if(dataToSave.rejected) {
                delete dataToSave.rejected
            }
            let data = {
                receiver: {
                    email: this.user.data.email,
                    role: this.user.data.role,
                    subrole: this.user.data.subrole,
                    uid: this.user.data.uid,
                    code: this.user.data.code,
                    readed: false
                },
                sender:dataToSave
            }
            console.log(data)
            
            axios.post('users/request/accept', data).then(response =>{
                console.log(response);
                th.snackMessage = 'Request succesfully accepted';
                th.isBusy = false;
                th.snackType = 'standard'
                th.showSnackbar = true;
            }).catch(e => {
                
            })
            
            
        },
        rejectIncomingRequest(request){
        
            let data = {
                receiver: {
                    email: this.user.data.email,
                    role: this.user.data.role,
                    subrole: this.user.data.subrole,
                    uid: this.user.data.uid,
                    code: this.user.data.code
                },
                sender:request

            }
            console.log(data)
            axios.patch('users/request/reject', data).then(response =>{
                console.log(response);
                th.snackMessage = 'Request succesfully rejected';
                th.isBusy = false;
                th.snackType = 'standard'
                th.showSnackbar = true;
            }).catch(e => {
                
            })
            
        },
        resetRequestForm(){
            this.searchTerm = '';
            this.queryResults = [],
            this.searchDialog = false
        },
        async sendRequest(user,profiles) {
            this.requesting = true;
            
            let timestamp = moment().unix()
            let data = {
                
                receiver: {
                    email: user.email,
                    role: user.customClaims.role,
                    subrole: user.customClaims.subrole,
                    uid: user.uid,
                    code: user.customClaims.code,
                    profiles: profiles
                },
                
                sender: {
                    email: this.user.data.email,
                    role: this.user.data.role,
                    subrole: this.user.data.subrole,
                    uid: this.user.data.uid,
                    code: this.user.data.code,
                    readed: false
                },
                timestamp: timestamp,

            }
            console.log(data)

            axios.post('users/request', data).then(response =>{
                console.log(response);

                this.getRequests()
                this.resetRequestForm();
                this.requesting = false;
                this.zonePreview.showMap = false
                this.searchDialog = false
                
            }).catch(e => {
                console.log(e);
                this.resetRequestForm();
                this.requesting = false;
                //this.zonePreview.showMap = false
                //this.searchDialog = false
                
            })
            
        },
        resetQuery(){
            this.firstRequestDone = false
        },
        search(){
            this.searchDialog = true 
        },
        markAsReaded(tab) {
          let collection = null
          switch (tab) {
            case 'active':
              collection = this.getCollectionPath(this.user)
              break;
            case 'requests':
              collection = this.getOutgoingCollectionPath(this.user)
              break;

            case 'incoming':
              collection = this.getIncomingCollectionPath(this.user)
              break;
          
            default:
              break;
          }

          setTimeout(function(){
            collection.where('readed', '==', false).get()
            .then((snapShot) => {
                snapShot.forEach((doc) => {
                    doc.ref.update({readed: true})
                });
                
            }).catch((error) => console.log(error.message));
          }, 2000)
          
         
          

        },
        mainTabChanged() {
            //console.log(this.$refs.mainTabs)
            this.markAsReaded(this.$refs.mainTabs.activeTab)
        },
        async obtainViewResources() {
            this.isBusy = true;
            await this.getUsers()
            await this.getRequests()
            await this.getIncomingRequests()
            this.isBusy = false
        },  
        getCollectionPath (user) {
            switch(user.data.role){
               
                case 'driver':
                    return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('linked-users')
                    break;
                default:
                    return firebase.firestore().collection('users').doc(this.user.data.uid).collection('linked-users')
            }   
        },
        getOutgoingCollectionPath (user) {
            switch(user.data.role){
                
                case 'driver':
                return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('outgoing-user-requests')
                break;
                default:
                return firebase.firestore().collection('users').doc(this.user.data.uid).collection('outgoing-user-requests')
            }   
            
        },
        getIncomingCollectionPath (user) {
            switch(user.data.role){
                
                case 'driver':
                return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('incoming-user-requests')
                break;
                default:
                return firebase.firestore().collection('users').doc(this.user.data.uid).collection('incoming-user-requests')
            }
        },
        rowClicked (item, index) {
        console.log(item);
        //this.$router.push({path: `/drivers/company-drivers/${item.email}`})
        },

        newUser() {
        //this.$router.push({path: `/drivers/company-drivers/new`})
        },
        getBadge (status) {
        switch (status) {
            case 'Active': return 'success'
            case 'Inactive': return 'secondary'
            case 'Pending': return 'warning'
            case 'Banned': return 'danger'
            default: 'primary'
        }
        },
        
        pageChange (val) {
        this.$router.push({ query: { page: val }})
        },
        getToken() {

        },
        snackbarClosed() {
            console.log('snack closed')
            for (let i = 0; i < this.users.length; i++) {
                delete this.users[i].highlight 
            }

            for (let i = 0; i < this.requests.length; i++) {
                delete this.requests[i].highlight 
            }

            for (let i = 0; i < this.incoming.length; i++) {
                delete this.incoming[i].highlight 
            }

            this.isInfinity = false
        },
        checkExisting(){

            let query = this.searchTerm
            let exists = false
            let tabToGo = null
            let message = ''
            for (let i = 0; i < this.users.length; i++) {
                if(
                    this.users[i].code === query ||
                    this.users[i].email === query
                ) {
                    exists = true
                    tabToGo = 'active'
                    message = 'User is already linked'
                    this.users[i].highlight = true;
                }
                
            }

            for (let i = 0; i < this.requests.length; i++) {
                if(
                    this.requests[i].code === query ||
                    this.requests[i].email === query
                ) {
                    exists = true
                    tabToGo = 'requests'
                    message = `You have already requested ${this.searchTerm} user`
                    this.requests[i].highlight = true;

                }
                
            }

            for (let i = 0; i < this.incoming.length; i++) {
                if(
                    this.incoming[i].code === query ||
                    this.incoming[i].email === query
                ) {
                    exists = true
                    tabToGo = 'incoming'
                    message = `${this.searchTerm} has already requested you linking, accept incoming request'`
                    this.incoming[i].highlight = true;
                }
                
            }

            if(exists){
                
                this.mainActiveTab = tabToGo
                this.snackMessage = message;
                this.isInfinity = true
                this.resetRequestForm()
                this.snackType = 'existing'
                this.showSnackbar = true;
            }
            
            
            return exists;
        },
        hideRefMap() {
            this.zonePreview.showMap = false
            this.zonePreview.matched = []
        },
        viewRefMap(resultantUser) {
            this.zonePreview.user = resultantUser.auth
            this.zonePreview.profiles = resultantUser.profiles
            for (let i = 0; i < resultantUser.profiles.length; i++) {
                
                if(resultantUser.profiles[i].matched) {
                    for (let j = 0; j < resultantUser.profiles[i].matchedAreas.length; j++) {
                        this.zonePreview.matched.push(resultantUser.profiles[i].matchedAreas[j])
                        
                    }
                }
                
            }
            this.zonePreview.showMap = true
            this.searchDialog = false
            
        },
        performQuery() {
            let exists = false
            
            
            if(this.validate()) {
                console.log('This valid')
                
                if(this.zoneQuery) {
    
    
                    this.locateCursor()
                    let data = {
                        requiredFence: this.coveredArea.fencesList[0],
                        requiredPaths: [],
                        roleToGet: this.role,
                        subroleToGet: ''
                    }

                    if(this.role === 'driver') {
                        data.subroleToGet = 'owner-operator'
                    }
                    
                    for (let i = 0; i < this.coveredArea.fencesList[0].indexes.length; i++) {
                        data.requiredPaths.push(this.coveredArea.paths[this.coveredArea.fencesList[0].indexes[i]])
                        
                    }
    
                    console.log(data)
                    this.zonePreview.searched = {
                        geometry: {
                            lat: data.requiredFence.geometry.lat,
                            lng: data.requiredFence.geometry.lng
                        },
                        paths: data.requiredPaths,
                        show: true,
                        formatted_address: data.requiredFence.formatted_address
                    }
    
                    let th = this
                    th.coveredArea.fencesList = []
                    //th.coveredArea.fencesList[0].indexes = []
                    this.coveredArea.paths = []
                    this.searching = true;
                    axios.patch('users/search-by-zone', data).then(response =>{
                        console.log(response);
                        th.activeQueryListType = 'zone'
                        let results = response.data.users
                        console.log('results', results)
                        console.log('th.allTypeUser', th.allTypeUsers)
                        for (let i = 0; i < results.length; i++) {
                            for (let j = 0; j < th.allTypeUsers.length; j++) {
                                
                                if(
                                    results[i].auth.email === th.allTypeUsers[j].email
                                ) {
                                    results[i].exists = true
                                }
                            }
                            
                        }
                        th.queryResults = results ;
                        th.searching = false;
                        th.firstRequestDone = true
                    }).catch(e => {
                    console.log(e);
                        th.searching = false;
                    })
    
    
                    
    
                } else {
                    exists = this.checkExisting()
    
                    if(!exists) {
                        let data = {  
                            query: this.searchTerm ,
                            // roleToGet: this.role,
                            // subroleToGet: ''
                        }
    
                        let th = this
                        th.queryResults = []
                        this.searching = true;
                        axios.get('users/search', { params: data }).then(response =>{
                            console.log(response);
                            th.activeQueryListType = 'string'
                            th.queryResults = response.data.users ;
                            th.searching = false;
                            th.firstRequestDone = true
                        }).catch(e => {
                        console.log(e);
                            th.searching = false;
                        })
                    }
    
                }
                
            } else {
                console.log('Not valid,. $touch')
                this.$v.$touch()
            }

                
        

        },
        async getUsers(){
        
            let th = this;
            return new Promise((resolve, reject) => {

                var collection = th.getCollectionPath(this.user)
                collection.onSnapshot((querySnapshot) => {
                    th.users = [];
                    th.newActive = 0
                    querySnapshot.forEach(function(doc) {
                        console.log(doc.id, " => ", doc.data());
                        let dataToSave = doc.data()
                        if(!dataToSave.readed) {
                            th.newActive++
                        }
                        dataToSave.id = doc.id
                        th.users.push(dataToSave)
                    });
                    resolve()
                    
                }, (error) => { 
                    console.log("Error getting documents: ", error);
                    reject()
                    
                });  
                    
            }) 
        },
        async getRequests(){
        
        let th = this;
        return new Promise((resolve, reject) => {

            



            var collection = th.getOutgoingCollectionPath(this.user)
            collection.onSnapshot((querySnapshot) => {
                th.requests = [];
                querySnapshot.forEach(function(doc) {
                    console.log(doc.id, " => ", doc.data());
                    let dataToSave = doc.data()
                    dataToSave.id = doc.id
                    th.requests.push(dataToSave)
                });
                resolve()
                
            }, (error) => { 
                console.log("Error getting documents: ", error);
                reject()
                
            });  
                
        }) 
        },
        async getIncomingRequests(){
        
            let th = this;
            return new Promise((resolve, reject) => {

                
                var collection = th.getIncomingCollectionPath(this.user)
                collection.onSnapshot((querySnapshot) => {
                    th.incoming = [];
                    th.newIncoming = 0
                    querySnapshot.forEach(function(doc) {
                        console.log(doc.id, " => ", doc.data());
                        let dataToSave = doc.data()
                        if(!dataToSave.readed) {
                            th.newIncoming++
                        }
                        dataToSave.id = doc.id
                        th.incoming.push(dataToSave)
                    });
                    resolve()
                    
                }, (error) => { 
                        console.log(error)
                        th.isBusy = false
                        reject(error)
                    
                });        
                    
            }) 
        }
    },
    watch: {

        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        },
    },
    destroyed() {
        
        window.removeEventListener("resize", this.myEventHandler);
    },
    validations: { 
       role: {
        required: requiredIf(function(){
            return this.zoneQuery ? true : false;
        }),
       },
       searchTerm: {
        //required
        required: requiredIf(function(){
            return !this.zoneQuery ? true : false;
        }),

       },
       coveredArea: {
           fencesList: {
             minLength: minLength(1),
             required: requiredIf(function(){
                return this.zoneQuery ? true : false;
            }),
           }
       }
       
    }
}
</script>
<style >
    .badge {
        width: 19px;
        height: 19px;
        line-height:19px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 5px;
        right: 5px;
        background: #67C38E;
        border-radius: 100%;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -.05em;
        font-family: 'Roboto Mono', monospace;
    }
</style>