<template>
  <div>
     <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Drivers</h3>
        
        <md-button class="md-primary md-raised" @click="newUser">New user</md-button>
        
    </md-toolbar>
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar> 
    <div v-else>

      <md-empty-state
        v-if="users.length == 0"
        md-icon="person_add"
        md-label="Add your first driver"
        md-description="Adding a driver will enable it when new reservation is created">
        <md-button class="md-primary md-raised" @click="newUser">Add first driver</md-button>
      </md-empty-state>
      <md-table v-else>
        <md-table-row>
          <md-table-head>UID</md-table-head>
          <md-table-head>Display Name</md-table-head>
          <md-table-head>Email</md-table-head>
          <md-table-head>Role</md-table-head>
          
        </md-table-row>

        <md-table-row v-for="user in users" v-bind:key="user.uid">
          <md-table-cell>{{user.uid}}</md-table-cell>
          <md-table-cell>{{user.displayName}}</md-table-cell>
          <md-table-cell>{{user.email}}</md-table-cell>
          <md-table-cell>{{user.customClaims.role}}</md-table-cell>
          
        </md-table-row>
      </md-table>
      
      <md-button class="md-fab md-primary fixed-button" @click="newUser">
        <md-icon>add</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>

import axios from "./../../helpers/axios"


export default {
  name: 'Drivers',
  data () {
    return {
      users: [],
      isBusy: true
      
    }
  },
  
  created() {
    //this.getUsers();
    this.getUsers();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  methods: {
    newUser() {
      this.$router.push({path: `/users/drivers/new`})
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getToken() {

    },
    getUsers(){
      let th = this ;
      console.log(th.user);
      let data = {  
            ownerEmail: this.user.data.email,
            roleToGet: 'driver' 
      }
      
      
     
        
      axios.get('drivers', { params: data }).then(response =>{
        th.users = response.data.users ;
        th.isBusy = false;
      }).catch(e => {
        console.log(e);
          th.isBusy = false;
      })
      
      

     
    }
  }
}
</script>