var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CSidebar',{ref:"sidebar",attrs:{"fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function (value) { return _vm.$store.commit('set', ['sidebarShow', value]); }}},[_c('CSidebarBrand',{staticClass:"d-md-down-none",attrs:{"to":"/"}},[_c('div',{staticClass:"logo c-sidebar-brand-full"},[_vm._v("Up."),_c('span',[_vm._v("limo")])]),_c('div',{staticClass:"logo c-sidebar-brand-minimized"},[_vm._v("U."),_c('span',[_vm._v("L")])])]),(_vm.user.data)?_c('CSidebarNav',{ref:"sidebarNav"},[_vm._l((_vm.$options.nav[0]._children),function(item,index){return [(
          item.allowedRoles.indexOf(_vm.user.data.role) > -1 &&
            item.items &&
            item.items.length > 0
        )?_c('CSidebarNavDropdown',{key:index,attrs:{"name":item.name}},[_vm._l((item.items),function(subitem,i){return [(
              subitem.allowedRoles && subitem.allowedRoles.indexOf(_vm.user.data.role) > -1
            )?_c('CSidebarNavItem',{key:i,attrs:{"to":subitem.to,"name":subitem.name}}):(
              !subitem.allowedRoles && item.allowedRoles.indexOf(_vm.user.data.role) > -1
            )?_c('CSidebarNavItem',{key:'subitem-' + subitem.name,attrs:{"to":subitem.to,"name":subitem.name}}):_vm._e()]})],2):(item.allowedRoles.indexOf(_vm.user.data.role) > -1 && !item.items)?_c('CSidebarNavItem',{key:index + item.name,attrs:{"name":item.name,"to":item.to}}):_vm._e()]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }