<template>
  <div v-if="!isBusy">
     <CRow>
      <CCol sm="6">
        
        <md-field>
            <label>Name</label>
            <md-input v-model="jobType.name"></md-input>
        </md-field>
        <div>
            <md-radio v-model="jobType.type" value="Distance">Distance</md-radio>
            <md-radio v-model="jobType.type" value="Hourly" class="md-primary">Hourly</md-radio>
        </div>
      </CCol>
      <CCol sm="6">
         
      </CCol>
     </CRow>
     <md-button class="md-accent" @click="destroy">Delete</md-button>
     <md-button class="md-fab md-primary" @click="update">
          <md-icon>save</md-icon>
      </md-button>
  </div>
</template>

<script>
import firebase from '../../Firebase'
export default {
  name: 'JobType',
  data() {
    return {
      jobType: null,
      isBusy: true,
      ref: firebase.firestore().collection('job-types')
    }
  },
  created () {
    let th = this;
    this.ref.doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.jobType = doc.data();
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  },
  methods: {
    update() {
      let th = this;
      this.ref.doc(this.$route.params.id).update(th.jobType)
      .then(function(){
        console.log('Updated');
        th.$router.push({path: `/job-types`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      this.ref.doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/job-types`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    }
  }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

