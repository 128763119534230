<template>
    <div>
        <md-field>
            <label>Price</label>
            <md-input v-model="roundedPrice" readonly></md-input>
            <span class="md-helper-text" v-if="activeFlatRate">Matched flat rate <b>{{ activeFlatRate.code }}</b></span>
            
            <md-progress-spinner class="md-suffix" :md-diameter="20" :md-stroke="2" md-mode="indeterminate" v-if="isBusy"></md-progress-spinner>
        </md-field>
        
    </div>
    
</template>

<script>
import firebase from '../../Firebase'
import moment from 'moment'
import moment_timezone from "moment-timezone";

export default {
    name: 'FeeCalculator',
    props: {
        rideDetailsOverridedData: {
            type: Object
        },
        datetime: {
            type: Number
        },
        jobType: {
            type: Object,
            required: true
        },
        companyProfile: {
            type: Object
        },
        vehicleType: {
            type: Object
        },
        minutesQty: {
            type: Number
        },
        calculatedDistance: {
            type: Number
        },
        pu: {
            type: Object
        },
        do: {
            type: Object
        },
        addons: {
            type: Array
        },
        waypoints: {
            type: Array
        },
        overviewPath: {
            type: Array
        },
        extras: {
            type: Object
        },
        promoCodes: {
            type: Array
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
        
    },
    
    
    beforeCreate(){
        
    },
    created() {
        
    },
    beforeMount(){
        this.obtainViewResources()
    },
    mounted() {
        
    },
    data() {
        return {
            isBusy: false,
            rateSpread: [],
            geofences:[],
            bonuses:[],
            overrides:[],
            taxes: null,
            roundedPrice: null,
            price: null,
            rates: null,
            validPromoCodes:[],
            validOverrides:[],
            distancePrice: null,
            activeFlatRate: null,
            subtotals: {
                
                fixedTaxes:0,
                distancePrice:0,
                addonsTotal:0,
                waitingTimePriceTotal:0,
                waitingTimeMinutesTotal:0,
                stops:0,
                afterOverrides:0
            }
            // rates: {
            //     base: {
            //         price: null,
            //         includedMiles: null
            //     },
            //     distanceRates: [],
            //     time: null,
            //     traffic: null,
            //     waitingTime: null
            // }
        }
    },
    watch: {
        
        companyProfile: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            },
            deep:true
        },
        jobType: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            },
            deep:true
        },
        vehicleType: {
            handler(val,oldVal) {
                
                this.validateRequiredFields()
            },
            deep: true
        },
        minutesQty: {
            handler(val,oldVal) {
                this.validateRequiredFields()

            }
        },
        calculatedDistance: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            }
        },
        datetime: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            }
        },
        addons: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            }
        },
        waypoints: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            },
            deep: true
        },
        extras: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            },
            deep: true
        },
        promoCodes: {
            handler(val,oldVal) {
                this.validateRequiredFields()
            },
            deep:true
        },
       
        // pu: {
        //     handler(val,oldVal) {
                
        //         this.validateRequiredFields()
        //     },
        //     deep: true
        // },
        // do: {
        //     handler(val,oldVal) {
                
        //         this.validateRequiredFields()
        //     },
        //     deep: true
        // }

    },
    methods: {
        
        async getGeofences() {
            let th = this;
            return new Promise((resolve, reject) => {
                 firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences').onSnapshot((querySnapshot) => {
                    this.geofences = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.geofences.push(dataToSave);

                    });
                    console.log('Geofences ready');
                    resolve()
                });
            })
        },
        async getBonuses() {
            let th = this;
            return new Promise((resolve, reject) => {
                 firebase.firestore().collection('users').doc(this.user.data.uid).collection('bonuses').onSnapshot((querySnapshot) => {
                    this.bonuses = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        if(dataToSave.enabled) {
                            this.bonuses.push(dataToSave);
                        }
                        

                    });
                    resolve()
                });
            })
        },
        async getTaxes() {
            let th = this;
            return new Promise((resolve, reject) => {
                 firebase.firestore().collection('users').doc(this.user.data.uid).collection('taxes').onSnapshot((querySnapshot) => {
                    this.taxes = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.taxes.push(dataToSave);

                    });
                    resolve()
                });
            })
        },
        async getOverrides() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('overrides').onSnapshot((querySnapshot) => {
                    this.overrides = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.overrides.push(dataToSave);

                    });
                    resolve()
                });
            })
        },
        async obtainViewResources() {
            await this.getBonuses()
            await this.getGeofences()
            await this.getTaxes()
            await this.getOverrides()
        },
        roundNumber(number) {
            number = number.toString(); //If it's not already a String
            if(number.indexOf(".") != -1) {
                number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
            } 
            
            return number
        },
        calculateFare() {

        },
        async validateRequiredFields() {
           
            this.isBusy = true;
            let th = this;
            this.rateSpread = [];
            this.activeFlatRate = null;
            this.rates = null;
            // await this.getVehicleTypeRates().then(function(data){
            //     console.log(data);
            //     th.rates = data;
            // })
            if(this.vehicleType) {
                this.rates = this.vehicleType.rates ;
            }
            

            let locations = false ;
            if(this.pu.coordinates.lat && this.pu.coordinates.lng && this.do.coordinates.lat && this.do.coordinates.lng) {
                locations = true ;
            }

            if(this.companyProfile && this.jobType.type && this.rates && locations && this.datetime) {
                let type = this.jobType.type ;
                if(type.toLowerCase() === 'distance'){
                    
                    this.getDistanceRate();
                    
                } else if(type.toLowerCase() === 'hourly') {
                    console.log('hourly');
                }
            }
            this.isBusy = false;
        },
        checkForGoesThroughGeofencedTaxes(fence){
            let fenceToCheck = null
            for (let j = 0; j < this.geofences.length; j++) {
                
                if(fence === this.geofences[j].id) {
                    fenceToCheck =  JSON.parse(JSON.stringify(this.geofences[j]))
                    
                }
            }
            //console.log('fenceToCheck',fenceToCheck)
            var fencePoly = new google.maps.Polygon({ paths: fenceToCheck.path });

            for (let j = 0; j < this.overviewPath.length; j++) {
                //var location = new google.maps.LatLng(JSON.parse(JSON.stringify(this.pu.coordinates.lat)),JSON.parse(JSON.stringify(this.pu.coordinates.lng)));
                var location = new google.maps.LatLng(JSON.parse(JSON.stringify(this.overviewPath[j].lat())),JSON.parse(JSON.stringify(this.overviewPath[j].lng())));

                var routeLocation = google.maps.geometry.poly.containsLocation(
                    location,
                    fencePoly
                )
                ? true
                : false;

                if(routeLocation) {
                    return true;
                }
            }

            return false;

            
        },
        checkIfPUorDOisInsideFence(fence, type) {
            let fenceToCheck = null;

            for (let j = 0; j < this.geofences.length; j++) {
                if(fence === this.geofences[j].id) {
                    fenceToCheck =  JSON.parse(JSON.stringify(this.geofences[j]))
                }
            }
            var puLocationInFence = false;
            var doLocationInFence = false;
            var locationInFence = false;

            let lat,lng;
            // Location fence check
            if(type == 'pu-do') {
                // check pu
                
                lat = this.pu.coordinates.lat
                lng = this.pu.coordinates.lng
                var locationPoly = new google.maps.Polygon({ paths: fenceToCheck.path });
                var location = new google.maps.LatLng(JSON.parse(JSON.stringify(lat)),JSON.parse(JSON.stringify(lng)));

                puLocationInFence = google.maps.geometry.poly.containsLocation(
                    location,
                    locationPoly
                )
                ? true
                : false;

                // check do
                
                lat = this.do.coordinates.lat
                lng = this.do.coordinates.lng
                var locationPoly = new google.maps.Polygon({ paths: fenceToCheck.path });
                var location = new google.maps.LatLng(JSON.parse(JSON.stringify(lat)),JSON.parse(JSON.stringify(lng)));

                doLocationInFence = google.maps.geometry.poly.containsLocation(
                    location,
                    locationPoly
                )
                ? true
                : false;


            } else {
                
                if(type == 'pu'){
                    lat = this.pu.coordinates.lat
                    lng = this.pu.coordinates.lng
                } else if(type == 'do') {
                    lat = this.do.coordinates.lat
                    lng = this.do.coordinates.lng
                }
                var locationPoly = new google.maps.Polygon({ paths: fenceToCheck.path });
                var location = new google.maps.LatLng(JSON.parse(JSON.stringify(lat)),JSON.parse(JSON.stringify(lng)));

                locationInFence = google.maps.geometry.poly.containsLocation(
                    location,
                    locationPoly
                )
                ? true
                : false;
            }

            return ( puLocationInFence || doLocationInFence ) || locationInFence ? true : false;
            
        },
        checkAndAddGeofencedTaxes(fence){
            let pickup = null;
            let dropoff = null;

            //this.rates.flat[i].do 
            for (let j = 0; j < this.geofences.length; j++) {
                if(fence === this.geofences[j].id) {
                    pickup =  JSON.parse(JSON.stringify(this.geofences[j]))
                    dropoff = JSON.parse(JSON.stringify(this.geofences[j]))
                }
            }

            // Pick up fence check
            var puPoly = new google.maps.Polygon({ paths: pickup.path });
            var puLocation = new google.maps.LatLng(JSON.parse(JSON.stringify(this.pu.coordinates.lat)),JSON.parse(JSON.stringify(this.pu.coordinates.lng)));

            var pickUpInLocation = google.maps.geometry.poly.containsLocation(
                puLocation,
                puPoly
            )
            ? true
            : false;

            
            // Dropoff fence check
            var doPoly = new google.maps.Polygon({ paths: dropoff.path });
            var doLocation = new google.maps.LatLng(JSON.parse(JSON.stringify(this.do.coordinates.lat)),JSON.parse(JSON.stringify(this.do.coordinates.lng)));

            var dropOffInLocation = google.maps.geometry.poly.containsLocation(
                doLocation,
                doPoly
            )
            ? true
            : false;


            // Stops fence check
            let stopFenceDetected = false
            if(this.waypoints.length > 0) {
                for (let i = 0; i < this.waypoints.length; i++) {
                    var stopPoly = new google.maps.Polygon({ paths: dropoff.path });
                    var stopLocation = new google.maps.LatLng(JSON.parse(JSON.stringify(this.waypoints[i].coordinates.lat)),JSON.parse(JSON.stringify(this.waypoints[i].coordinates.lng)));

                    var stopInLocation = google.maps.geometry.poly.containsLocation(
                        stopLocation,
                        stopPoly
                    )
                    ? true
                    : false;

                    if(stopInLocation){
                        stopFenceDetected = true
                    }
                    
                }
            }

            if(pickUpInLocation || dropOffInLocation || stopFenceDetected) {
                return true
            } else {
                return false
            }


        },
        checkFlatRate(){
            console.log('Check falt rate')
            if(Object.entries(this.vehicleType).length !== 0) {
                if(this.rates.flat.length > 0){
                    for (let i = 0; i < this.rates.flat.length; i++) {


                        if(this.rates.flat[i].pu && this.rates.flat[i].do) {

                            let pickup = null;
                            let dropoff = null;
                            console.log(this.rates.flat[i])
                            //this.rates.flat[i].do 
                            for (let j = 0; j < this.geofences.length; j++) {
                                if(this.rates.flat[i].pu == this.geofences[j].id ) {
                                    //pickup = this.geofences[j]
                                    pickup =  JSON.parse(JSON.stringify(this.geofences[j]))
                                }
    
                                if(this.rates.flat[i].do == this.geofences[j].id ) {
                                    dropoff = JSON.parse(JSON.stringify(this.geofences[j]))
                                }
                                
                            }
    
                           
                            
                            var puPoly = new google.maps.Polygon({ paths: pickup.path });
                            var puLocation = new google.maps.LatLng(JSON.parse(JSON.stringify(this.pu.coordinates.lat)),JSON.parse(JSON.stringify(this.pu.coordinates.lng)));
    
                            var pickUpInLocation = google.maps.geometry.poly.containsLocation(
                                puLocation,
                                puPoly
                            )
                            ? true
                            : false;
    
                           
    
                            var doPoly = new google.maps.Polygon({ paths: dropoff.path });
                            var doLocation = new google.maps.LatLng(JSON.parse(JSON.stringify(this.do.coordinates.lat)),JSON.parse(JSON.stringify(this.do.coordinates.lng)));
    
                            var dropOffInLocation = google.maps.geometry.poly.containsLocation(
                                doLocation,
                                doPoly
                            )
                            ? true
                            : false;
    
                            
                            // If both are true flat rate
                            if(pickUpInLocation && dropOffInLocation) {
                                if(this.waypoints.length == 0) {
                                    if(!this.rideDetailsOverridedData){
                                        console.log('Flat without override')
                                        this.activeFlatRate = this.rates.flat[i];
                                        this.price = parseFloat(this.rates.flat[i].price);
                                    } else {
                                        console.log('Flat with override')
                                        this.activeFlatRate = this.rideDetailsOverridedData.activeFlatRate;
                                        this.price = parseFloat(this.rideDetailsOverridedData.activeFlatRate.price);
                                    }
                                    
    
                                    this.isBusy = false
                                    return true;
                                } else {
                                    return false;
                                }
                                
                            } else {
                                return false;
                            }
                        } else {
                            return false
                        }
                        
                        

                    }
                } 
            }
        },
        checkAndApplyOverrides() {
            this.validOverrides = [];
            let fixedTotal = 0
            let percentageAmount = 0
            this.subtotals.afterOverrides = 0;
            if(this.overrides.length > 0){
                for (let i = 0; i < this.overrides.length; i++) {
                    if(this.overrides[i].enabled) {
                        let validations = [];
                        // Vehicle type control
                        if(this.overrides[i].vehicleTypes && this.overrides[i].vehicleTypes.length > 0){
                            this.overrides[i].vehicleTypes.includes(this.vehicleType.id) ? validations.push(true) : validations.push(false)
                        } else {
                            validations.push(true)
                        }

                        // Service type control
                        if(this.overrides[i].serviceTypes && this.overrides[i].serviceTypes.length > 0){
                            this.overrides[i].serviceTypes.includes(this.jobType.id) ? validations.push(true) : validations.push(false)
                        } else {
                            validations.push(true)
                        }

                        // Geofence enabled and type
                        if(this.overrides[i].geofenced) {
                            // PU-DO inside fence check
                            if(this.checkIfPUorDOisInsideFence(this.overrides[i].fence,this.overrides[i].geofenceType)) {
                                validations.push(true)    
                            } else {
                                validations.push(false)  
                                //console.log('Geofenced  bonus DID NOT MATCH')
                            }

                            
                        } else {
                            validations.push(true)
                        }

                        // Datetime validation
                        if( ( this.overrides[i].convertedStartDateTime || this.overrides[i].convertedEndDateTime ) && this.datetime) {
                            
                            var startDate = null ;
                            var endDate = null ;

                            if(this.overrides[i].convertedStartDateTime) {
                                startDate = moment.unix(parseInt(this.overrides[i].convertedStartDateTime))
                            }
                            if(this.overrides[i].convertedEndDateTime) {
                                endDate = moment.unix(parseInt(this.overrides[i].convertedEndDateTime))
                            }
                            
                            var datetime = moment.unix(parseInt(this.datetime))
                            
                            
                            if(startDate && !endDate) {
                                if(datetime.isAfter(startDate)) {
                                    validations.push(true)
                                    
                                } else {
                                    validations.push(false)
                                }

                            } else if(!startDate && endDate) {
                                if(datetime.isBefore(endDate)) {
                                    validations.push(true)
                                    
                                } else {
                                    validations.push(false)
                                }
                            } else if(startDate && endDate) {
                                if(datetime.isBetween(startDate, endDate)){
                                    validations.push(true)
                                    
                                    
                                } else {
                                    validations.push(false)
                                }
                            }

                            

                            
                                
                        } else {
                                validations.push(true)
                        }

                        // Hour range
                        if(( this.overrides[i].startTime || this.overrides[i].endTime ) && this.datetime) {
                            var startTime = null ;
                            var endTime = null ;

                            
                            if(this.overrides[i].startTime) {
                                startTime = moment(this.overrides[i].startTime,'HH:mm')
                            }
                            if(this.overrides[i].endTime) {
                                endTime = moment(this.overrides[i].endTime,'HH:mm')
                            }
                           
                            var datetime = moment.unix(parseInt(this.datetime))
                            var reservationHour = moment(datetime.tz(this.overrides[i].companyProfile.timeZone).format('HH:mm'),'HH:mm')
                            
                            if(startTime && !endTime) {
                                if(reservationHour.isAfter(startTime)) {
                                    validations.push(true)
                                    
                                } else {
                                    validations.push(false)
                                }

                            } else if(!startTime && endTime) {
                                if(reservationHour.isBefore(endTime)) {
                                    validations.push(true)
                                    
                                } else {
                                    validations.push(false)
                                }
                            } else if(startTime && endTime) {
                                if(reservationHour.isBetween(startTime, endTime)){
                                    validations.push(true)  
                                } else {
                                    validations.push(false)
                                }
                            }

                        } else {
                                validations.push(true)
                        }

                        // Check day name
                        var datetime = moment.unix(parseInt(this.datetime))
                        var reservationDayIndex = datetime.tz(this.overrides[i].companyProfile.timeZone).format('d')
                        var reservationDayName = datetime.tz(this.overrides[i].companyProfile.timeZone).format('ddd')
                        
                        
                        if(this.overrides[i].repeatEvery.length > 0) 
                        {
                            let dayMatch = false;
                            for (let k = 0; k < this.overrides[i].repeatEvery.length; k++) {
                                
                                if(this.overrides[i].repeatEvery[k] == reservationDayIndex){
                                    dayMatch = true;
                                }
                            }

                            dayMatch ? validations.push(true) : validations.push(false)
                            
                        
                        } else {
                            validations.push(true)
                        }

                        // Check includes in validation here
                        if(!validations.includes(false)) {
                            if(this.overrides[i].type === "fixed"){
                                fixedTotal += parseFloat(this.overrides[i].value);
                            } else if(this.overrides[i].type === "percentage"){
                                
                                this.overrides[i].unitValue = parseFloat(this.overrides[i].value) * this.subtotals.afterRideDetails / 100
                                percentageAmount += parseFloat(this.overrides[i].value) * this.subtotals.afterRideDetails / 100
                                // 100                                      this.subtotals.afterRideDetails                              
                                // parseFloat(this.overrides[i].value)
                                
                            }

                            this.validOverrides.push(this.overrides[i]);

                        }


                    }
                }
            }
            
            this.subtotals.afterOverrides = this.subtotals.afterRideDetails + fixedTotal + percentageAmount ;

        },
        checkAndAddPromoCodes(){
            this.validPromoCodes = [];
            let fixedDiscountTotal = 0;
            let percentageDiscountAmount = 0;
            this.subtotals.afterDiscount = 0;
            if(this.bonuses.length > 0){
                for (let i = 0; i < this.bonuses.length; i++) {
                    for (let j = 0; j < this.promoCodes.length; j++) {
                        if(this.bonuses[i].code === this.promoCodes[j]){

                            let validations = [];

                            // Vehicle type control
                            if(this.bonuses[i].vehicleTypes && this.bonuses[i].vehicleTypes.length > 0){
                                this.bonuses[i].vehicleTypes.includes(this.vehicleType.id) ? validations.push(true) : validations.push(false)
                            } else {
                                validations.push(true)
                            }

                            // Service type control
                            if(this.bonuses[i].serviceTypes && this.bonuses[i].serviceTypes.length > 0){
                                this.bonuses[i].serviceTypes.includes(this.jobType.id) ? validations.push(true) : validations.push(false)
                            } else {
                                validations.push(true)
                            }

                            // Geofence enabled and type
                            if(this.bonuses[i].geofenced) {
                                // PU-DO inside fence check
                                if(this.checkIfPUorDOisInsideFence(this.bonuses[i].fence,this.bonuses[i].geofenceType)) {
                                    validations.push(true)    
                                } else {
                                    validations.push(false)  
                                    //console.log('Geofenced  bonus DID NOT MATCH')
                                }

                                
                            } else {
                                validations.push(true)
                            }

                            
                            // Control fecha
                            if(this.bonuses[i].startDatetime && this.bonuses[i].endDatetime && this.datetime) {
                                let startDate = moment.unix(parseInt(this.bonuses[i].startDatetime))
                                let endDate = moment.unix(parseInt(this.bonuses[i].endDatetime))
                                let datetime = moment.unix(parseInt(this.datetime))
                                
                                if(datetime.isBetween(startDate, endDate)){
                                    console.log('Date inside');
                                     validations.push(true)
                                    
                                } else {
                                    console.log('Date outside');
                                     validations.push(false)
                                }
                                 
                            } else {
                                 validations.push(true)
                            }
                            ////

                            // Check includes in validation here
                            if(!validations.includes(false)) {
                                if(this.bonuses[i].type === "fixed"){
                                    fixedDiscountTotal += parseFloat(this.bonuses[i].value);
                                } else if(this.bonuses[i].type === "percentage"){
                                    
                                    this.bonuses[i].unitValue = parseFloat(this.bonuses[i].value) * this.subtotals.afterRideDetails / 100
                                    percentageDiscountAmount += parseFloat(this.bonuses[i].value) * this.subtotals.afterRideDetails / 100
                                    // 100                                      this.subtotals.afterRideDetails                              
                                    // parseFloat(this.bonuses[i].value)
                                    
                                }

                                this.validPromoCodes.push(this.bonuses[i]);

                            }
                            



                            
                            
                        }
                        
                    }
                    
                }
            }   
            
            this.subtotals.afterDiscount = this.subtotals.afterRideDetails - fixedDiscountTotal - percentageDiscountAmount ;
        },
        getJobTypeKind() {

        },
        getDistanceRate() {
            
            
            if(this.calculatedDistance != null) {
                this.price = 0;
                if(this.calculatedDistance <= parseFloat(this.rates.base.includedMiles)) {
                    this.rateSpread = [];
                    this.price = this.rates.base.price
                    this.isBusy = false
                } else {
                    let milesCount = parseFloat(this.rates.base.includedMiles) ;
                    let price = this.rates.base.price ;
                    this.rateSpread = [];
                    let rateSpread = [];
                    rateSpread.push({
                        base: true,
                        milesQty: parseFloat(this.rates.base.includedMiles) ,
                        ratePerMile: '-',
                        rate: parseFloat(this.rates.base.price)
                    });
                    for (let i = 0; i < this.rates.distance.length; i++) {
                        // ej 130 - calculated
                        this.rates.distance[i].rate = parseFloat(this.rates.distance[i].rate)
                        this.rates.distance[i].till = parseFloat(this.rates.distance[i].till)
                        if(i == 0) {
                            let rate;
                            // How many miles are from this fraction ??

                            if(this.calculatedDistance <= this.rates.distance[i].till) {
                                // Total distance is lower or equal than actual rate range
                                let milesQty = this.calculatedDistance - parseFloat(this.rates.base.includedMiles) ;
                                rate = milesQty * this.rates.distance[i].rate ;

                                rateSpread.push({
                                    milesQty: milesQty,
                                    ratePerMile: this.rates.distance[i].rate,
                                    rate: rate
                                })
                            } else {
                                let milesQty = this.rates.distance[i].till - parseFloat(this.rates.base.includedMiles) ;
                                rate = milesQty * this.rates.distance[i].rate ;
                                rateSpread.push({
                                    milesQty: milesQty,
                                    ratePerMile: this.rates.distance[i].rate,
                                    rate: rate
                                })
                            }
                        } else {
                            let rate;
                            if(this.rates.distance[i].till !== null &&  !isNaN(this.rates.distance[i].till) ) {
                                if(this.calculatedDistance > this.rates.distance[i - 1].till) {
                                    if(this.calculatedDistance <= this.rates.distance[i].till) {
                                        let milesQty = this.calculatedDistance - this.rates.distance[i - 1].till;
                                        rate = milesQty * this.rates.distance[i].rate;
                                        rateSpread.push({
                                            milesQty: milesQty,
                                            ratePerMile: this.rates.distance[i].rate,
                                            rate: rate
                                        })
                                    } else {
                                        let milesQty = this.rates.distance[i].till - this.rates.distance[i - 1].till ;
                                        rate = milesQty * this.rates.distance[i].rate;
                                        rateSpread.push({
                                            milesQty: milesQty,
                                            ratePerMile: this.rates.distance[i].rate,
                                            rate: rate
                                        })
                                    }
                                }
                            } else {
                                console.log('else If !isNAN');
                                let aux = this.calculatedDistance - parseFloat(this.rates.base.includedMiles);
                                if( aux > this.rates.distance[i - 1].till) {
                                    let milesQty = this.calculatedDistance - this.rates.distance[i - 1].till;
                                    rate = milesQty * this.rates.distance[i].rate;
                                    rateSpread.push({
                                        milesQty: milesQty,
                                        ratePerMile: this.rates.distance[i].rate,
                                        rate: rate
                                    })
                                }
                            }
                        }
                    
                    }
                    

                    this.rateSpread = rateSpread;
                    if(this.rideDetailsOverridedData){
                        console.log('this.rideDetailsOverridedData.rateSpread');
                        console.log(this.rideDetailsOverridedData.rateSpread);
                        this.rateSpread = this.rideDetailsOverridedData.rateSpread
                    }
                    
                    let distancePrice = 0;
                    for (let j = 0; j < this.rateSpread.length; j++) {
                        if(this.rateSpread[j].rate) {
                            distancePrice += parseFloat(this.rateSpread[j].rate) ;
                        }
                        
                    }



                    

                    this.subtotals.distancePrice = distancePrice;
                    this.subtotals.afterRideDetails = distancePrice ;
                    this.price = distancePrice;
                   
                    this.isBusy = false


                }
                let payload = {};

                

                if(this.checkMinuimPrice()){
                    console.log('Minium price');
                    payload.rateSpread = this.rateSpread
                    payload.miniumRate = true
                    console.log('this.rideDetailsOverridedData',this.rideDetailsOverridedData);
                    if(this.rideDetailsOverridedData){
                        console.log('Si');
                        payload.rates = this.rideDetailsOverridedData.rates
                        this.subtotals.afterRideDetails = parseFloat(this.rideDetailsOverridedData.rates.miniumRate);
                    } else {
                        console.log('No');
                        payload.rates = this.rates
                        this.subtotals.afterRideDetails = this.price;
                    }
                   
                   
                        
                } else {
                    
                    payload.rateSpread = this.rateSpread
                    payload.rates = this.rates
                    payload.miniumRate = false
                        
                }

                if(this.checkFlatRate()) {
                    // Flat rate matched
                    console.log('this.checkFlatRate()');
                    payload.activeFlatRate = this.activeFlatRate;
                   
                } else {
                    console.log(' else    this.checkFlatRate()');
                    payload.activeFlatRate = this.activeFlatRate;
                    
                }


                if(this.checkAndAddStopsFixedAndTime()){
                    payload.stops = this.waypoints ;
                } else {
                    payload.stops = this.waypoints ;
                }

                
                // Check increases and decreases
                if(this.checkAndApplyOverrides()){
                    
                    payload.validOverrides = this.validOverrides ;
                } else {
                    payload.validOverrides = this.validOverrides ;
                }

                // Check promo codes
                if(this.checkAndAddPromoCodes()){
                    
                    payload.validPromoCodes = this.validPromoCodes ;
                } else {
                    payload.validPromoCodes = this.validPromoCodes ;
                }
                
                
                if(this.checkAndAddTaxes()){
                    let finalTaxes = [];
                    let fixedTaxes = 0;
                    for (let i = 0; i < this.taxes.length; i++) {
                        if(this.taxes[i].enabled) {
                            if(this.taxes[i].geofenced) {
                                //console.log(i,this.taxes[i]);
                                if(this.taxes[i].geofenceType == 'pu-do') {
                                    if(this.checkAndAddGeofencedTaxes(this.taxes[i].fence)) {
                                        finalTaxes.push(this.taxes[i])
                                        fixedTaxes += parseFloat(this.taxes[i].value);
                                    } else {
                                        //console.log('Geofenced DID NOT MATCH')
                                    }

                                } else if(this.taxes[i].geofenceType == 'through') {
                                    //console.log('Geofence through')
                                    if(this.checkForGoesThroughGeofencedTaxes(this.taxes[i].fence)) {
                                        finalTaxes.push(this.taxes[i])
                                        fixedTaxes += parseFloat(this.taxes[i].value);
                                    } else {
                                        //console.log('Geofenced GOES THROUGH tax DID NOT MATCH')
                                    }
                                }
                                
                                
                            } else {
                                //finalTaxes.push(this.taxes[i])
                            }
                            
                        }
                        
                    }
                    this.subtotals.fixedTaxes = fixedTaxes;
                    this.subtotals.afterFixedTaxesNoTaxable = this.subtotals.fixedTaxes + this.subtotals.afterAppliedOnBaseNoTaxable
                    
                    payload.taxes = finalTaxes;
                } else {
                    payload.taxes = [];
                }
                if(this.checkAndAddAddOns()){
                    payload.addons = this.addons;
                } else {
                    payload.addons = [];
                }

                if(this.checkAndAddExtras()){
                    payload.extras = this.extras;
                } else {
                    payload.extras = [];
                }

                this.subtotals.afterFixedTaxesTaxable = this.subtotals.afterAddonsTaxable

                

                // Add company profile sale tax and credit card fee

                // Sale Tax
                this.subtotals.afterSaleTaxNoTaxable = this.subtotals.afterFixedTaxesNoTaxable
                this.subtotals.afterSaleTaxUnit = parseFloat(this.companyProfile.saleTax)  * this.subtotals.afterFixedTaxesTaxable / 100 ;
                this.subtotals.afterSaleTaxTaxable = this.subtotals.afterFixedTaxesTaxable + this.subtotals.afterSaleTaxUnit ;
                //  100                                     this.subtotals.afterFixedTaxesTaxable
                //  parseFloat(this.companyProfile.saleTax) this.subtotals.afterSaleTaxUnit   

                // Credit Card Fee

                // No taxable
                this.subtotals.creditCardFeeUnitNoTaxable =  parseFloat(this.companyProfile.creditCardFee) * this.subtotals.afterSaleTaxNoTaxable / 100;
                // 100                                              this.subtotals.afterSaleTaxNoTaxable
                //  parseFloat(this.companyProfile.creditCardFee)

                // Taxable
                this.subtotals.creditCardFeeUnitTaxable =  parseFloat(this.companyProfile.creditCardFee) * this.subtotals.afterSaleTaxTaxable / 100;
                // 100                                              this.subtotals.afterSaleTaxTaxable
                //  parseFloat(this.companyProfile.creditCardFee)
                

                this.subtotals.lastNoTaxable = this.subtotals.afterSaleTaxNoTaxable + this.subtotals.creditCardFeeUnitNoTaxable ;
                this.subtotals.lastTaxable = this.subtotals.afterSaleTaxTaxable + this.subtotals.creditCardFeeUnitTaxable;

                this.price = this.subtotals.lastNoTaxable + this.subtotals.lastTaxable

                payload.subtotals = this.subtotals;
                payload.price = this.price;
                this.roundedPrice = this.roundNumber(this.price);
                console.log('this.price',this.price);
                console.log('this.subtotals',this.subtotals);
                this.$emit('priceTable', payload); 
                
                

            }
            
            
           
        },
        checkAndAddStopsFixedAndTime(){
            if(this.waypoints.length > 0){
                let stops = 0
                let waitingTimePriceTotal = 0
                let waitingTimeMinutesTotal = 0
                for (let i = 0; i < this.waypoints.length; i++) {
                    stops += parseFloat(this.vehicleType.rates.stop) ;
                    if(this.waypoints[i].waitingTime){
                        waitingTimeMinutesTotal += parseFloat(this.waypoints[i].waitingTime)

                        
                    }
                    
                }
                if(this.rideDetailsOverridedData) {
                    console.log('waitingTimePriceTotal overrided')
                    waitingTimePriceTotal = this.rideDetailsOverridedData.subtotals.waitingTimePriceTotal ;
                } else {
                    waitingTimePriceTotal = waitingTimeMinutesTotal * parseFloat(this.vehicleType.rates.waitingTime) / 60 ;
                }

                this.subtotals.stops = stops;
                this.subtotals.waitingTimePriceTotal = waitingTimePriceTotal;
                this.subtotals.waitingTimeMinutesTotal = waitingTimeMinutesTotal;
                
                this.price += stops;
                this.price += waitingTimePriceTotal;
                this.subtotals.afterRideDetails += stops
                this.subtotals.afterRideDetails += waitingTimePriceTotal

                return true;
                /// 60                          this.vehicleType.rates.waitingTime
                // waitingTimeMinutesTotal
            } else {
                return false;
            }
        },
        checkAndAddTaxes() {
            if(this.taxes.length > 0){
                let fixedTaxes = 0;
                let appliedOnBase = false;
                let onBaseTaxList = [];
                let onTotalTaxList = [];
                let onBaseNoTaxablePercent = 0;
                let onBaseTaxablePercent = 0;
                let onTotalNoTaxablePercent = 0;
                let onTotalTaxablePercent = 0;

                for (let i = 0; i < this.taxes.length; i++) {
                    if(this.taxes[i].enabled){
                        if(this.taxes[i].type === 'percentage'){
                            if(this.taxes[i].percentageType === 'base') {
                                appliedOnBase = true;
                                if(this.taxes[i].taxable) {
                                    onBaseTaxablePercent += parseFloat(this.taxes[i].value);
                                } else {
                                    onBaseNoTaxablePercent += parseFloat(this.taxes[i].value);
                                }
                                onBaseTaxList.push(this.taxes[i]);

                            } else if(this.taxes[i].percentageType === 'total'){

                                if(this.taxes[i].taxable) {
                                    onTotalTaxablePercent += parseFloat(this.taxes[i].value);
                                } else {
                                    onTotalNoTaxablePercent += parseFloat(this.taxes[i].value);
                                }
                                 onTotalTaxList.push(this.taxes[i]);
                            }
                        } else if(this.taxes[i].type === 'fixed'){
                            // console.log(this.taxes[i].name);
                            // console.log('parseFloat(this.taxes[i].value)');
                            // console.log(parseFloat(this.taxes[i].value));
                            // fixedTaxes += parseFloat(this.taxes[i].value);
                        }   
                    }        
                }
                
                this.subtotals.appliedOnBase = appliedOnBase;
                this.subtotals.onBaseTaxList = onBaseTaxList;
                this.subtotals.onTotalTaxList = onTotalTaxList;

                this.subtotals.onBaseNoTaxablePercent = onBaseNoTaxablePercent;
                this.subtotals.onBaseTaxablePercent = onBaseTaxablePercent;
                this.subtotals.onTotalNoTaxablePercent = onTotalNoTaxablePercent;
                this.subtotals.onTotalTaxablePercent = onTotalTaxablePercent;
                
                if(this.rideDetailsOverridedData){
                    console.log('Si');
                    console.log(this.rideDetailsOverridedData.subtotals.afterRideDetails);
                    this.subtotals.afterRideDetails = this.rideDetailsOverridedData.subtotals.afterRideDetails
                } else {
                    console.log('No');
                }
                
                
                this.subtotals.afterAppliedOnBaseNoTaxable = (this.subtotals.onBaseNoTaxablePercent * this.subtotals.afterRideDetails / 100);
               // 100     this.subtotals.afterRideDetails
               // this.subtotals.onBaseNoTaxablePercent
               if(this.validOverrides.length > 0) {
                   this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterOverrides
               }

               if(this.validPromoCodes.length > 0) {
                   this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterDiscount
               }

                if(this.subtotals.onBaseTaxablePercent === 0) {
                    
                    this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterRideDetails ;
                    if(this.validOverrides.length > 0) {
                        this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterOverrides
                    }
                    if(this.validPromoCodes.length > 0) {
                        this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterDiscount
                    }
                } else {
                    
                    this.subtotals.afterAppliedOnBaseTaxable = (this.subtotals.onBaseTaxablePercent * this.subtotals.afterRideDetails / 100) + this.subtotals.afterRideDetails;
                }
                
                // 100 this.price / 
                
                
                this.subtotals.priceTotalNoTaxes = this.price ;

                if(this.activeFlatRate) {
                    console.log('this.subtotals.afterRideDetails = parseFloat(this.activeFlatRate.price)');
                    this.subtotals.afterRideDetails = parseFloat(this.activeFlatRate.price);
                    console.log('this.subtotals.afterRideDetails',this.subtotals.afterRideDetails);
                    if(this.subtotals.onBaseTaxablePercent === 0) {
                        this.subtotals.afterAppliedOnBaseTaxable = parseFloat(this.activeFlatRate.price);
                        if(this.validPromoCodes.length > 0) {
                            this.subtotals.afterAppliedOnBaseTaxable = this.subtotals.afterDiscount
                        }
                    } else {
                        this.subtotals.afterAppliedOnBaseTaxable = (this.subtotals.onBaseTaxablePercent * this.subtotals.afterRideDetails / 100) + parseFloat(this.activeFlatRate.price);
                    }
                
                }
                     
                
                

                
                


                if(fixedTaxes > 0 ) {
                    this.price += fixedTaxes;
                } 

                return true;
            } else {
                return false;
            }
        },
        checkAndAddAddOns() {
            let addonsTotal = 0;
            if(this.addons.length > 0){
                for (let i = 0; i < this.addons.length; i++) {
                    if(this.addons[i].price && parseFloat(this.addons[i].qty) > 0){
                        
                        this.price += parseFloat(this.addons[i].price) * parseFloat(this.addons[i].qty);
                        addonsTotal += parseFloat(this.addons[i].price) * parseFloat(this.addons[i].qty);
                    }
                    
                }
                this.subtotals.addonsTotal = addonsTotal ;
                this.subtotals.afterAddonsTaxable = this.subtotals.afterAppliedOnBaseTaxable + this.subtotals.addonsTotal;
                return true;
            } else {
                this.subtotals.addonsTotal = addonsTotal ;
                this.subtotals.afterAddonsTaxable = this.subtotals.afterAppliedOnBaseTaxable;
                return false;
            }
            
            
        },
        checkAndAddExtras(){
            let extrasTotal = 0;
            
            if(this.extras){
                
                if(this.extras.childSeat) {
                    this.price += parseFloat(this.rates.extras.childSeat.price);
                    extrasTotal += parseFloat(this.rates.extras.childSeat.price);
                }
                
                if(this.extras.accessible) {
                    this.price += parseFloat(this.rates.extras.accessible.price);
                    extrasTotal += parseFloat(this.rates.extras.accessible.price);
                }

                if(this.extras.meetAndGreet) {
                    this.price += parseFloat(this.rates.extras.meetAndGreet.price);
                    extrasTotal += parseFloat(this.rates.extras.meetAndGreet.price);
                }

                
                this.subtotals.extrasTotal = extrasTotal ;
               
                this.subtotals.afterAddonsTaxable = this.subtotals.afterAppliedOnBaseTaxable + this.subtotals.addonsTotal + this.subtotals.extrasTotal;

                return true;
            } else {
                this.subtotals.afterAddonsTaxable = this.subtotals.afterAppliedOnBaseTaxable;
                return false;
            }
        },
        checkMinuimPrice(){
            if(this.rates.miniumRate) {
                this.rates.miniumRate = parseFloat(this.rates.miniumRate)
                
                
                
                if(this.rates.miniumRate > this.price) {
                    this.price = this.rates.miniumRate
                    return true;
                }  else {
                    return false
                }   
            } else {
                return false
            }
        },
        async getVehicleTypeRates() {
            return new Promise((resolve, reject) => {
                setTimeout(function(){
                    // Call firestore vehicle company settings
                    let rates = {
                        base: {
                            price: 65,
                            includedMiles: 10
                        },
                        distanceRates: [
                            { rate: 2 , till: 100},
                            { rate: 1.90 , till: 200},
                            { rate: 1.80 , till: 500},
                            { rate: 1.70 , till: null}
                        ],
                        time: 0.5,
                        traffic: 0.5,
                        waitingTime: 0.75
                    }
                    console.log('finished firestore');
                    resolve(rates)
                },1000)
                
            })
            
        }

    }
}
</script>

<style scoped>

</style>
