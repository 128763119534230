<template>
  <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
  <div v-else>
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="account.name">{{account.name}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>New Merchant Account</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update Merchant Account</md-button>
      </md-toolbar>
      <div class="main-inner-wrapper">
        <CRow :gutters="true">
            <CCol sm="12" lg="8">
                <md-card>
                    <md-card-header>
                        <div class="md-title">Merchant Account info</div>
                    </md-card-header>

                    <md-card-content>
                        <CRow>
                            <CCol sm="12">
                                <md-field :class="getValidationClass('gateway')">
                                        <md-select v-model="account.gateway" name="type" id="type" placeholder="Select your gateway">
                                            <md-option v-for="gateway in gateways" :value="gateway" v-bind:key="gateway">{{gateway}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.account.gateway.required">Gateway is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('name')">
                                        <label>Name</label>
                                        <md-input v-model="account.name"></md-input>
                                        <span class="md-error" v-if="!$v.account.name.required">Name is required</span>
                                    </md-field>
                                    <div v-switch="account.gateway">
                                        <div v-case="'US Bank Account'">
                                            
                                        </div>
                                        <div v-case="'Paypal PayFlow Pro'">
                                        
                                            
                                            <md-field :class="[getValidationClass('partner'), checkKeys ? 'md-invalid' : '']">
                                                <label>Partner</label>
                                                <md-input v-model="account.credentials.partner"></md-input>
                                                <span class="md-error" v-if="!$v.account.credentials.partner.required">Partner is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check partner</span>
                                                
                                            </md-field>
                                            <md-field :class="[getValidationClass('vendor'), checkKeys ? 'md-invalid' : '']">
                                                <label>Vendor</label>
                                                <md-input v-model="account.credentials.vendor"></md-input>
                                                <span class="md-error" v-if="!$v.account.credentials.vendor.required">vendor is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check vendor</span>
                                                
                                            </md-field>
                                            
                                            <md-field :class="[getValidationClass('username'), checkKeys ? 'md-invalid' : '']">
                                                <label>User</label>
                                                <md-input v-model="account.credentials.username"></md-input>
                                                <span class="md-error" v-if="!$v.account.credentials.username.required">User is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check Username</span>
                                                
                                            </md-field>
                                            <md-field :class="[getValidationClass('password'), checkKeys ? 'md-invalid' : '']">
                                                <label>Password</label>
                                                <md-input v-model="account.credentials.password" type="password"></md-input>
                                                <span class="md-error" v-if="!$v.account.credentials.password.required">Password is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check Password</span>
                                                
                                            </md-field>
                                            

                                        </div>
                                        <div v-case="'Stripe'">
                                            
                                        
                                            <!--md-field :class="[getValidationClass('id'), checkKeys ? 'md-invalid' : '']">
                                                <label>Client Id</label>
                                                <md-input v-model="account.id"></md-input>
                                                <span class="md-error" v-if="!$v.account.id.required">Client Id is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check client Id</span>
                                                
                                            </md-field>
                                            <md-field :class="[getValidationClass('secret'), checkKeys ? 'md-invalid' : '']">
                                                <label>Secret</label>
                                                <md-input v-model="account.secret"></md-input>
                                                <span class="md-error" v-if="!$v.account.secret.required">Secret is required</span>
                                                <span class="md-error" v-else-if="checkKeys">Check secret</span>
                                                
                                            </md-field-->

                                        </div>
                                        <div v-case="'Square'">

                                        </div>
                                        <div v-case="'Braintree'">

                                        </div>
                                        <div v-case="'Athorize.net'">

                                        </div>
                                        <div v-case="'Cayan'">

                                        </div>
                                    </div>
                                    <CRow>
                                        <CCol sm="6">
                                            <md-switch v-model="account.active">Active</md-switch>
                                        </CCol>
                                        <CCol sm="6">
                                            <md-switch v-model="account.default">Default</md-switch>
                                        </CCol>
                                    </CRow>
                                
                            </CCol>
                        </CRow>
                        <CRow >
                                <CCol sm="12">
                                <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete account</md-button>
                            </CCol>
                        </CRow>
                    </md-card-content>
                
                </md-card>
            </CCol>
        </CRow>
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

  </div>


  
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
export default {
  name: 'Account',
  mixins: [validationMixin],
  data() {
    return {
      gateways: [
          'US Bank Account',
          'Paypal PayFlow Pro',
          'Stripe',
          'Square',
          'Braintree',
          'Athorize.net',
          'Cayan',
      ],
      checkKeys: false,
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      account: null,
      isBusy: true,
      ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('merchant-accounts'),
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created () {
    let th = this;
    firebase.firestore().collection('users').doc(this.user.data.uid).collection('merchant-accounts').doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.account = doc.data();
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  },
  methods: {
    fireUpdate() {
     
      if(this.validate()) {
        this.update()
      } else {
        this.$v.account.name.$touch()
      }
    },
    update() {
       this.isBusy = true;
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('merchant-accounts').doc(this.$route.params.id).update(th.account)
      .then(function(){
        console.log('Updated');
        th.snackMessage = 'Merchant account updated';
        th.isBusy = false;
        th.showSnackbar = true;
       
      }).catch(function(error) {
          th.snackMessage = 'Ooopss something went wrong';
          th.isBusy = false;
          th.showSnackbar = true;
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('merchant-accounts').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/merchant-accounts`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    },
    validate(){
       let gateway,name,partner,vendor,username, password;
        gateway = this.$v.account.gateway.$invalid;
        name = this.$v.account.name.$invalid;

        partner = this.$v.account.credentials.partner.$invalid;
        vendor = this.$v.account.credentials.vendor.$invalid;
        username = this.$v.account.credentials.username.$invalid;
        password = this.$v.account.credentials.password.$invalid;
        
        
        return !name && !gateway && !username && !password && !partner && !vendor  ? true : false ;


    },
    getValidationClass (fieldName) {
       let field = null;
        if(
            fieldName == 'username' 
            || fieldName == 'partner' 
            || fieldName == 'vendor' 
            || fieldName == 'password' 
            || fieldName == 'signature' 
            ) {

            field = this.$v.account.credentials[fieldName]
        }  else {
            field = this.$v.account[fieldName];
        }
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
  },
  validations: {
       
      account: {
          credentials: {
              partner: {
                required: requiredIf(function(account){
                    return account.gateway == 'Paypal PayFlow Pro' ? false : true ;
                })
            },
            vendor: {
                required: requiredIf(function(account){
                    return account.gateway == 'Paypal PayFlow Pro' ? false : true ;
                })
            },
            username: {
                required: requiredIf(function(account){
                    return account.gateway == 'Paypal PayFlow Pro' ? false : true ;
                })
            },
            password: {
                required: requiredIf(function(account){
                    return account.gateway == 'Paypal PayFlow Pro' ? false : true ;
                })
            },
           
              
              
          },
          
          name: {
              required
          },
          gateway: {
              required
          },
          
          
      }
  }
}

</script>

