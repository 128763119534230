import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axiosSetUp from './helpers/axios'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'
import 'vue-material/dist/theme/default-dark.css'
//import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

import VueNativeNotification from 'vue-native-notification'
Vue.use(VueNativeNotification, {
  requestOnNotify: true,
  
})

Vue.notification.requestPermission().then(console.log)



import VueBreadcrumbs from 'vue-2-breadcrumbs';
Vue.use(VueBreadcrumbs)

import VSwitch from 'v-switch-case'
Vue.use(VSwitch)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCJO5qBtIkbBAolUkYnPxEOklM1A0ZP-RI',
      libraries: 'places', // This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)
   
      //// If you want to set the version, you can do so:
      // v: '3.26',
    },
   
    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,
   
    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})

import draggable from 'vuedraggable'
Vue.use(draggable)

import {ServerTable, ClientTable, Event} from 'vue-tables-2';
Vue.use(ClientTable);

import airlines from 'airline-codes/airlines.json';
Vue.prototype.$airlines = airlines ;
//require('airline-codes').toJSON();

import airports from './assets/json/airports.json'
Vue.prototype.$airports = airports ;

import firebase from './Firebase'
Vue.prototype.$firebase = firebase;

Vue.prototype.$window = window;

firebase.auth().onAuthStateChanged(user => {
  if(user) {
    user.getIdTokenResult().then(payload => {
      
      payload.refreshToken = user.refreshToken
      store.dispatch("fetchUser", payload);
      //store.dispatch("refreshToken");
    }).catch(function(error) {
       //Handle error
    });
    
  } else {
    store.dispatch("fetchUser", user);

    firebase
            .auth()
            .signOut()
            .then(() => {
                if(router.history.current.name !== 'Login' ) {
                  router.replace({
                    name: "Login"
                  });
                }
                
            });
  }
  
  
});

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
          
      }, reject);
  })
};

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})

import Vuelidate from "vuelidate"; 
Vue.use(Vuelidate);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import 'vue2-timepicker/dist/VueTimepicker.css';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

//axiosSetUp()

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
    
  }
})



