<template>
    <div>
        <md-toolbar class="md-primary mb-2" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="email">{{displayName ? displayName : 'User name'}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New user</h3>
           
            <md-button class="md-primary md-raised" @click="validateUser">Save user</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <form novalidate class="md-layout" @submit.prevent="validateUser">
            <md-card class="md-layout-item md-size-50 md-small-size-100">
                <md-card-header>
                <div class="md-title">Users</div>
                </md-card-header>

                <md-card-content>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item">
                        <md-field :class="getValidationClass('email')">
                            <label for="email">Email</label>
                            <md-input type="email" name="email" id="email" autocomplete="email" v-model="email" :disabled="sending" />
                            <span class="md-error" v-if="!$v.email.required">The email is required</span>
                            <span class="md-error" v-else-if="!$v.email.email">Invalid email</span>
                        </md-field>
                        <md-field :class="getValidationClass('displayName')">
                            <label for="display-name">Display Name</label>
                            <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="displayName" :disabled="sending" />
                            <span class="md-error" v-if="!$v.displayName.required">The first name is required</span>
                            <span class="md-error" v-else-if="!$v.displayName.minlength">Invalid first name</span>
                        </md-field>
                        <md-field :class="getValidationClass('password')">
                            <label for="password">Password</label>
                            <md-input type="password" name="password" id="password" autocomplete="given-name" v-model="password" :disabled="sending" />
                            <span class="md-error" v-if="!$v.password.required">Password is required</span>
                        </md-field>
                        <md-field :class="getValidationClass('role')">
                            <label for="role">Role</label>
                            <md-select v-model="role" name="role" id="role">
                                <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                                
                            </md-select>
                            <span class="md-error" v-if="!$v.role.required">Role is required</span>
                        </md-field>
                    </div>
                
                
                </div>
                <!--
                <div class="md-layout md-gutter">
                    <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('gender')">
                        <label for="gender">Gender</label>
                        <md-select name="gender" id="gender" v-model="form.gender" md-dense :disabled="sending">
                        <md-option></md-option>
                        <md-option value="M">M</md-option>
                        <md-option value="F">F</md-option>
                        </md-select>
                        <span class="md-error">The gender is required</span>
                    </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('age')">
                        <label for="age">Age</label>
                        <md-input type="number" id="age" name="age" autocomplete="age" v-model="form.age" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.age.required">The age is required</span>
                        <span class="md-error" v-else-if="!$v.form.age.maxlength">Invalid age</span>
                    </md-field>
                    </div>
                </div>

                <md-field :class="getValidationClass('email')">
                    <label for="email">Email</label>
                    <md-input type="email" name="email" id="email" autocomplete="email" v-model="form.email" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
                    <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
                </md-field>
                -->
                </md-card-content>

                <md-progress-bar md-mode="indeterminate" v-if="sending" />

                <md-card-actions>
                <!--md-button type="submit" class="md-primary" :disabled="sending">Create user</md-button-->
                </md-card-actions>
            </md-card>

            <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
            </form>
        </div>
    </div>
</template>

<script>

/*
* super-admin (up.limo, nosotros)
* company-admin (compania limo service)
* dispatcher (persona encargada de despachar los trabajos)
   *  level-1
   *  level-2
   *  level-3
* driver 
* affiliate - es agregado por el admin, otra compañia de car service que quiere poner trabajos en tu compañia
   *  level-1
   *  level-2
   *  level-3
* passenger
   * single
   * corporate

*/

import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators'

import axios from "./../../helpers/axios"

export default {
  name: 'NewUser',
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      password: '',
      displayName: '',
      role: '',
      userSaved: false,
      sending: false,
      lastUser: null,
      roles: [
          
          {
              name: 'company-admin',
              text: 'Owner admin'
          },
          {
              name: 'driver',
              text: 'Driver'
          },
          {
              name: 'dispatcher',
              text: 'Dispatcher'
          },
          {
              name: 'affiliate',
              text: 'Affiliate'
          },
          {
              name: 'passenger',
              text: 'Passenger'
          },
      ]
    }
  },
  methods: {
    getValidationClass (fieldName) {
        const field = this.$v[fieldName]

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    clearForm () {
        this.$v.$reset()
        this.email = null
        this.password = null
        this.displayName = null
        this.role = null
        
    },
    saveUser () {
        this.sending = true
        this.registerButtonPressed()
        // Instead of this timeout, here you can call your API
        // window.setTimeout(() => {
        //     this.lastUser = `${this.form.firstName} ${this.form.lastName}`
        //     this.userSaved = true
        //     this.sending = false
        //     this.clearForm()
        // }, 1500)
    },
    validateUser () {
        this.$v.$touch()

        if (!this.$v.$invalid) {
            this.saveUser()
        }
    },
    async registerButtonPressed() {
      try {
          ////
        let th = this ;
        let data = {
            displayName: this.displayName, 
            password: this.password, 
            email: this.email, 
            role: this.role
        }
        this.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {


            axios.post('users',data).then(response =>{
                console.log(response);
                th.sending = false
                th.$router.push({path: '/users/list'})
            }).catch(e => {
                console.log(e);
                th.sending = false
            }) 

        
        }).catch(function(error) {
        //Handle error
        });
          ////
       
      } catch (error) {
        console.log(error.message);
      }
      
    }
    // async registerButtonPressed() {
    //   try {
    //     var {
    //       user
    //     } = await this.$firebase
    //       .auth()
    //       .createUserWithEmailAndPassword(this.email, this.password);
    //     // signout
    //     this.$firebase
    //       .auth()
    //       .signOut()
    //       .then(user => {
    //         this.$router.push("/pages/login");
    //       });
    //   } catch (error) {
    //     console.log(error.message);
    //   }
      
    // }

  },
  validations: {
    email: {
        required,
        email
    },
    password: {
        required,
        minLength: minLength(3)
    },
    displayName: {
        required,
        minLength: minLength(3)
    },
    role: {
        required
    },
    },
}
</script>