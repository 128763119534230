<template>
        <div v-if="isBusy">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
        <div v-else>

            <div v-if="!vehicleTypesExist">
                <md-empty-state
                    md-icon="drive_eta"
                    md-label="Add at least one vehicle type to create new vehicle"
                    md-description="Vehicle types will be asked in new vehicle general tab">
                    <md-button class="md-primary md-raised" @click="newVehicleType()">Add new vehicle type</md-button>
                </md-empty-state>
            </div>
            <div v-else>
                <md-toolbar class="md-primary mb-2" md-elevation="1">
                    <h3 class="md-title" style="flex: 1" v-if="vehicle.name">{{vehicle.name}}</h3>
                    <h3 class="md-title" style="flex: 1" v-else>Vehicle name</h3>
                
                    <md-button class="md-raised" @click="addPicture">Add image</md-button>
                    <!--md-button class="md-primary">Save vehicle type</md-button-->
                    <md-button class="md-primary md-raised" @click="add" :disabled="isBusy">Save vehicle</md-button>
                </md-toolbar>
                <md-tabs class="md-transparent" md-alignment="left" @md-changed="mainTabChanged" ref="mainTabs" :md-active-tab="mainActiveTab" :md-dynamic-height="true"> 
                    <template slot="md-tab" slot-scope="{ tab }"> 
                        {{ tab.label }} 
                        <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
                        <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
                    </template>
                    <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
                            <CRow :gutters="true">
                                <CCol sm="12" lg="5">
                                    <md-card class="uplimo-card">
                                        <md-card-header>
                                            <div class="md-title">General</div>
                                        </md-card-header>
    
                                        <md-card-content>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field :class="getValidationClass('name')">
                                                        <label>Name</label>
                                                        <md-input v-model="vehicle.name"></md-input>
                                                        <span class="md-error" v-if="!$v.vehicle.name.required">Name is required</span>
                                                    </md-field>
                                                    <md-field>
                                                        <label>Description</label>
                                                        <md-textarea v-model="vehicle.description" md-counter="250"></md-textarea>
                                                    </md-field>
                                                    <md-field :class="getValidationClass('base')">
                                                        <md-select v-model="vehicle.base" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type">
                                                            <md-option v-for="vehicle in baseVehicleTypes" :value="vehicle.id" v-bind:key="vehicle.id">{{vehicle.type ? vehicle.type : vehicle.name }}</md-option>
                                                        </md-select>
                                                        <span class="md-error" v-if="!$v.vehicle.base.required">Vehicle Type is required</span>
                                                    </md-field>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="6">
                                                    <md-field :class="getValidationClass('passengers')">
                                                        <md-icon>people</md-icon>
                                                        <label>Passengers</label>
                                                        <md-input v-model="vehicle.passengers" type="number" :max="selectedBase.passengers" :disabled="!selectedBase.passengers"></md-input>
                                                        <span class="md-error" v-if="!$v.vehicle.passengers.required">Required</span>
                                                        <span class="md-error" v-if="!$v.vehicle.passengers.between">Number must be between 1 and {{selectedBase.passengers}}</span>
                                                    </md-field>
                                                </CCol>
                                                <CCol sm="6">
                                                    <md-field :class="getValidationClass('luggage')">
                                                        <md-icon>luggage</md-icon>
                                                        <label>Luggage</label>
                                                        <md-input v-model="vehicle.luggage" type="number" :max="selectedBase.luggage" :disabled="!selectedBase.luggage"></md-input>
                                                        <span class="md-error" v-if="!$v.vehicle.luggage.required">Required</span>
                                                        <span class="md-error" v-if="!$v.vehicle.luggage.between">Number must be between 1 and {{selectedBase.luggage}}</span>
                                                    </md-field>
                                                </CCol>
                                                
                                            </CRow>
                                            <md-divider class="mt-3"></md-divider>
                                            <CRow >
                                                <CCol sm="12">
                                                    <md-switch v-model="vehicle.visible" class="md-primary">Visible</md-switch>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field class="d-none">
                                                        <label for="vehicle-pictures">Vehicle images</label>
                                                        <md-file ref="carfile" multiple="multiple" name="vehicle-pictures" id="vehicle-pictures" :disabled="sending" @change="imageChanged($event, 'car-picture')"/>
                                                    </md-field>
                                                    
                                                </CCol>
                                            </CRow>
                                        </md-card-content>
                                        
                                    </md-card>
                                </CCol>
                            </CRow>
                    </md-tab>
                    <md-tab id="mainInfo" md-label="Main info" :md-template-data="{ badge: tabs.mainInfo.errors }">
                        <CRow>
                            <CCol sm="12" md="6" lg="6" xl="4">
                                <md-card class="uplimo-card">
                                    <md-card-header >
                                        <div class="md-title">Main info</div>
                                    </md-card-header>
                                    <md-card-content >
                                        <md-field :class="getValidationClass('mainInfo.plate')">
                                            <label for="plate">Plate</label>
                                            <md-input name="plate" id="plate" autocomplete="given-name" v-model="vehicle.mainInfo.plate" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.vehicle.mainInfo.plate.required">Plate is required</span>
                                        </md-field>
    
                                        <md-field :class="getValidationClass('mainInfo.brand')">
                                            <label for="brand">Brand</label>
                                            <md-input name="brand" id="brand" autocomplete="given-name" v-model="vehicle.mainInfo.brand" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.vehicle.mainInfo.brand.required">Brand is required</span>
                                        </md-field>
    
                                        <md-field :class="getValidationClass('mainInfo.carModel')">
                                            <label for="car-model">Car model</label>
                                            <md-input name="car-model" id="car-model" autocomplete="given-name" v-model="vehicle.mainInfo.carModel" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.vehicle.mainInfo.carModel.required">Car model is required</span>
                                        </md-field>
                                    
    
                                        <md-field :class="getValidationClass('mainInfo.year')">
                                            <label for="year">Year</label>
                                            <md-input name="year" id="year" autocomplete="given-name" v-model="vehicle.mainInfo.year" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.vehicle.mainInfo.year.required">Year is required</span>
                                        </md-field>
    
                                        <md-field :class="getValidationClass('mainInfo.color')">
                                            <label for="color">Color</label>
                                            <md-input name="color" id="color" autocomplete="given-name" v-model="vehicle.mainInfo.color" :disabled="sending" />
                                            <span class="md-error" v-if="!$v.vehicle.mainInfo.color.required">Color is required</span>
                                        </md-field>
    
                                        
                                    </md-card-content>
                                </md-card>
                            </CCol>
                        </CRow>
                    </md-tab>
                    <md-tab id="documents" md-label="Documents" :md-template-data="{ badge: tabs.documents.errors }">
                        <CRow>
                        
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                  
                                    <md-card-content class="listed-doc-card" >
                                        <div class="doc-asset">
                                            <div class="pdf-preview-holder" @click="openDocDialog('FH1', pictures.fh1.preview, pictures.fh1.image, pictures.fh1.pdf)" v-if="pictures.fh1.pdf">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="pictures.fh1.preview"    />
                                            </div>
    
                                            <div class="img-preview-holder"  v-if="pictures.fh1.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="pictures.fh1.image"
                                                    @click="openDocDialog('fh1', pictures.fh1.preview, pictures.fh1.image, pictures.fh1.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="this.pictures.fh1.preview ? this.pictures.fh1.preview : ''" alt=""  />
                                            </div>
                                        </div> 
    
                                        <div class="doc-data">
                                            <div class="md-title">FH1</div>
                                            <md-field :class="getValidationClass('documents.fileName','fh1')">
                                                <label for="fh1-picture">FH1 attachment (Image or PDF)</label>
                                                <md-file name="fh1-picture" id="fh1-picture" v-model="vehicle.documents.fh1.fileName" :disabled="sending" @change="docImageChanged($event, 'fh1')"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.fh1.fileName.required">FH1 picture is required</span>
                                            </md-field>
                                            <md-datepicker :class="getValidationClass('documents.expiration','fh1')" name="fh1-expiration-date" id="fh1-expiration-date" v-model="vehicle.documents.fh1.expiration" :disabled="sending" :md-model-type="Number">
                                                <label for="fh1-expiration-date">FH1 Expiration Date</label>
                                                <span class="md-error" v-if="!$v.vehicle.documents.fh1.expiration.required">FH1 Expiration Date is required</span>
                                            </md-datepicker>
                                            <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.fh1.expiration)" v-if="vehicle.documents.fh1.expiration"></div>
                                        
                                        </div>
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                   
                                    <md-card-content class="listed-doc-card" >
                                        <div class="doc-asset">
                                            <div class="pdf-preview-holder" @click="openDocDialog('registration', pictures.registration.preview, pictures.registration.image, pictures.registration.pdf)" v-if="pictures.registration.pdf">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="pictures.registration.preview"    />
                                            </div>
                                            <div class="img-preview-holder"  v-if="pictures.registration.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="pictures.registration.image"
                                                    @click="openDocDialog('Registration', pictures.registration.preview, pictures.registration.image, pictures.registration.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="this.pictures.registration.preview ? this.pictures.registration.preview : ''" alt="" />
                                            </div>
                                            
                                        </div>
    
                                        <div class="doc-data">
                                             <div class="md-title">Registration</div>
                                            <md-field :class="getValidationClass('documents.fileName','registration')">
                                                <label for="registration-picture">Attachment (Image or PDF)</label>
                                                <md-file name="registration-picture" id="registration-picture" v-model="vehicle.documents.registration.fileName" :disabled="sending"  @change="docImageChanged($event, 'registration')"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.registration.fileName.required">Registration picture is required</span>
                                            </md-field>
                                            <md-datepicker :class="getValidationClass('documents.expiration','registration')" name="registration-expiration-date" id="registration-expiration-date" v-model="vehicle.documents.registration.expiration" :disabled="sending" :md-model-type="Number">
                                                <label for="registration-expiration-date">Expiration date</label>
                                                <span class="md-error" v-if="!$v.vehicle.documents.registration.expiration.required">Registration expiration date is required</span>
                                            </md-datepicker>
                                            <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.registration.expiration)" v-if="vehicle.documents.registration.expiration"></div>
    
                                        </div>
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                   
                                    <md-card-content class="listed-doc-card" >
                                        <div class="doc-asset">
                                            <div class="pdf-preview-holder" @click="openDocDialog('TLC window sticker', pictures.tlc.preview, pictures.tlc.image, pictures.tlc.pdf)" v-if="pictures.tlc.pdf">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="pictures.tlc.preview"    />
                                            </div>
    
    
                                            <div class="img-preview-holder"  v-if="pictures.tlc.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="pictures.tlc.image"
                                                    @click="openDocDialog('TLC', pictures.tlc.preview, pictures.tlc.image, pictures.tlc.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="this.pictures.tlc.preview ? this.pictures.tlc.preview : ''" alt="" />
                                            </div>
                                            
    
                                        </div>
                                        <div class="doc-data">
                                            <div class="md-title">TLC window sticker</div>
                                            <md-field :class="getValidationClass('documents.fileName','tlc')">
                                                <label for="tlc-window-sticker-picture">Attachment (Image or PDF)</label>
                                                <md-file name="tlc-window-sticker-picture" id="tlc-window-sticker-picture" v-model="vehicle.documents.tlc.fileName" :disabled="sending"  @change="docImageChanged($event, 'tlc', pictures.tlc.image, pictures.tlc.pdf)"/>
                                                <span class="md-error" v-if="!$v.vehicle.documents.tlc.fileName.required">picture is required</span>
                                            </md-field>
                                            <md-datepicker :class="getValidationClass('documents.expiration','tlc')" name="tlc-window-sticker-expiration" id="tlc-window-sticker-expiration" v-model="vehicle.documents.tlc.expiration" :disabled="sending" :md-model-type="Number">
                                                <label for="tlc-window-sticker-expiration">Expiration</label>
                                                <span class="md-error" v-if="!$v.vehicle.documents.tlc.expiration.required">expiration is required</span>
                                            </md-datepicker>
                                            <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.tlc.expiration)" v-if="vehicle.documents.tlc.expiration"></div>
                                        </div>
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                    
                                    <md-card-content class="listed-doc-card" >
                                        <div class="doc-asset">
                                            <div class="pdf-preview-holder" @click="openDocDialog('DMV inspection sticker', pictures.dmv.preview, pictures.dmv.image, pictures.dmv.pdf)" v-if="pictures.dmv.pdf">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="pictures.dmv.preview"    />
                                            </div>
                                            <div class="img-preview-holder"  v-if="pictures.dmv.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="pictures.dmv.image"
                                                    @click="openDocDialog('DMV inspection sticker', pictures.dmv.preview, pictures.dmv.image, pictures.dmv.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="this.pictures.dmv.preview ? this.pictures.dmv.preview : ''" alt="" />
                                            </div>
                                        </div>
    
                                         <div class="doc-data">
                                             <div class="md-title">DMV inspection sticker</div>
                                            <md-field :class="getValidationClass('documents.fileName','dmv')">
                                                <label for="dmv-inspection-sticker-picture">Attachment (Image or PDF)</label>
                                                <md-file name="dmv-inspection-sticker-picture" id="dmv-inspection-sticker-picture" v-model="vehicle.documents.dmv.fileName" :disabled="sending" @change="docImageChanged($event, 'dmv', pictures.dmv.image, pictures.dmv.pdf)" />
                                                <span class="md-error" v-if="!$v.vehicle.documents.dmv.fileName.required">picture is required</span>
                                            </md-field>
                                            <md-datepicker :class="getValidationClass('documents.expiration','dmv')" name="dmv-inspection-sticker-expiration" id="dmv-inspection-sticker-expiration" v-model="vehicle.documents.dmv.expiration" :disabled="sending" :md-model-type="Number">
                                                <label for="dmv-inspection-sticker-expiration">Expiration</label>
                                                <span class="md-error" v-if="!$v.vehicle.documents.dmv.expiration.required">expiration is required</span>
                                            </md-datepicker>
                                            <div class="mt-2" v-html="checkTimeToGo(vehicle.documents.dmv.expiration)" v-if="vehicle.documents.dmv.expiration"></div>
                                        </div>
    
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                  
                                    <md-card-content class="listed-doc-card" >
                                        <div class="doc-asset">
                                            <div class="pdf-preview-holder" @click="openDocDialog('Liability insurance with driver listed', pictures.liability.preview, pictures.liability.image, pictures.liability.pdf)" v-if="pictures.liability.pdf">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="pictures.liability.preview"    />
                                            </div>
                                            <div class="img-preview-holder"  v-if="pictures.liability.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="pictures.liability.image"
                                                    @click="openDocDialog('Liability insurance with driver listed', pictures.liability.preview, pictures.liability.image, pictures.liability.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="this.pictures.liability.preview ? this.pictures.liability.preview : ''" alt="" />
                                            </div>
                                        </div>
                                        <div class="doc-data">
                                             <div class="md-title">Liability insurance with driver listed</div>
                                            <md-field :class="getValidationClass('documents.fileName','liability')">
                                                <label for="liability-insurance-with-driver-listed-picture">Attachment (Image or PDF)</label>
                                                <md-file name="liability-insurance-with-driver-listed-picture" id="liability-insurance-with-driver-listed-picture" v-model="vehicle.documents.liability.fileName" :disabled="sending" @change="docImageChanged($event, 'liability', pictures.liability.image, pictures.liability.pdf)" />
                                                <span class="md-error" v-if="!$v.vehicle.documents.liability.fileName.required">Liability insurance with driver listed picture is required</span>
                                            </md-field>
                                        </div>
                                    </md-card-content>
                                </md-card>
                            </CCol>         
                        </CRow>
                    </md-tab>
                    <md-tab id="pictures" md-label="Pictures" :md-template-data="{ badge: tabs.pictures.errors }">
                        <CRow>
                            <CCol sm="12" lg="4" v-for="(picture,index) in files" v-bind:key="picture.id">
                                <md-card class="uplimo-card">      
                                    <md-card-content >
                                        <p>Framing result (public image)</p>
                                        <div ref="cropperPreviewContainer" class="cropper-container mb-4">
                                            <div :class="'cropper-preview-' + index" ref="cropperPreview"></div>
                                        </div>
                                        <div v-if="picture.editing">
                                            <md-divider class="mb-2"></md-divider> 
                                            <p class="md-body">Custom image framing (zoom out, zoom in, move , crop)</p>   
                                        </div>
                                        <md-button v-if="!picture.editing" @click="enableCropper(picture)" class="full-width-button mt-2 md-raised">edit framing</md-button>
                                        <md-button v-else @click="disableCropper(picture)" class="full-width-button mt-2 md-raised md-primary">confirm framing</md-button>
                                        <div class="d-flex justify-content-start buttons-container mt-3" v-if="picture.editing">
                                            
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="zoomIn(index)"><md-icon>zoom_in</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="zoomOut(index)"><md-icon>zoom_out</md-icon></md-button>
                                            </div>
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="flip(index)"><md-icon>flip</md-icon></md-button>
                                            </div>
    
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="move(-10,0,index)"><md-icon>arrow_back</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(10,0,index)"><md-icon>arrow_forward</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(0,-10,index)"><md-icon>arrow_upward</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(0,10,index)"><md-icon>arrow_downward</md-icon></md-button>
                                            </div>
                                            <md-button class="md-raised md-dense position-relative" :class="{ 'remove-button': files.length > 1 }" @click="removePicture(picture,true,index)"><md-icon class="mr-2">delete</md-icon>Remove image <b>#{{activeIndex + 1}}</b></md-button>
                                        </div>
                                    
                                        <vue-cropper 
                                            :zoomOnWheel="false"          
                                            :src="picture.imgSrc"
                                            :aspect-ratio="16 / 9"     
                                            ref="cropper" 
                                            :preview="'.cropper-preview-' + index"
                                            v-show="picture.cropper"
                                            
                                            >
                                            
                                        </vue-cropper>
    
    
    
    
    
    
    
                                        <!--p class="mb-0">Showing image <b>{{activeIndex + 1}}</b> of <b>{{files.length}}</b></p>    
                                        <carousel 
                                                    @pageChange="carouselChanged" 
                                                    :per-page="1" :mouse-drag="true"  
                                                    paginationActiveColor="#f9ba00" 
                                                    paginationColor="#c3c3c3" 
                                                    paginationPosition="top" 
                                                    ref="carousel"
                                                    :adjustableHeight="true"
                                                
                                                    
                                            >
                                            <slide v-for="picture in files" v-bind:key="picture.id">
                                            <vue-cropper 
                                                    :zoomOnWheel="false" 
                                                    :src="picture.imgSrc"
                                                    :aspect-ratio="16 / 9"     
                                                    ref="cropper" 
                                                    >
                                                    
                                                </vue-cropper>
                                            </slide>
                                        </carousel> 
                                        <div class="d-flex justify-content-start buttons-container mt-3">
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="zoomIn"><md-icon>zoom_in</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="zoomOut"><md-icon>zoom_out</md-icon></md-button>
                                            </div>
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="flip"><md-icon>flip</md-icon></md-button>
                                            </div>
    
                                            <div class="d-inline-flex">
                                                <md-button class="md-dense md-raised" @click="move(-10,0)"><md-icon>arrow_back</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(10,0)"><md-icon>arrow_forward</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(0,-10)"><md-icon>arrow_upward</md-icon></md-button>
                                                <md-button class="md-dense md-raised" @click="move(0,10)"><md-icon>arrow_downward</md-icon></md-button>
                                            </div>
                                            <md-button class="md-raised md-dense position-relative" :class="{ 'remove-button': files.length > 1 }" @click="removePicture"><md-icon class="mr-2">delete</md-icon>Remove image <b>#{{activeIndex + 1}}</b></md-button>
                                        </div-->
                                        
                                        
    
                                                            
                                        
                                    </md-card-content>
                                </md-card>
                            </CCol>
                             <CCol sm="12" lg="4">
                                <md-card class="uplimo-card">
                                    <md-card-content >
                                        <md-empty-state
                                            md-icon="photo_library"
                                            md-label="Add vehicle pictures here"
                                            md-description="Car pictures will be display in your booking system">
                                            <md-button class="md-primary md-raised" @click="addPicture">Add image</md-button>
                                        </md-empty-state>
                                        <!--md-field :class="getValidationClass('files')">
                                            <span class="md-error" v-if="!$v.files.required">Add at least one image</span>
                                        </md-field-->
                                    </md-card-content>
                                </md-card>
                            </CCol>
                        </CRow>
                    </md-tab>
                    <md-tab id="otherLicenses" md-label="Other Licenses" :md-template-data="{ badge: tabs.otherLicenses.errors }">
                        <md-empty-state
                            v-if="otherLicenses.length === 0"
                            
                            md-icon="badge"
                            md-label="Custom licenses and permissions"
                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                        </md-empty-state>
                        <CRow v-else>
                            <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in otherLicenses" v-bind:key="'otherLicenses-' + index ">
                               
                                <md-card class="uplimo-card" >
                                    <!--md-card-header >
                                            <div class="md-title">FH1</div>
                                    </md-card-header-->
                                    <md-card-content class="listed-doc-card">
                                        <div class="doc-asset">
    
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                            </div>
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                            </div>
    
                                        
                                            <div class="img-preview-holder"  v-if="license.attachment.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="license.attachment.image && license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <div 
                                                    class="doc-overlay"
                                                    v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                                <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />
    
                                            </div>
                                            
                                        </div>
                                    
                                        <div class="doc-data">
                                            <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field :class="getValidationClass('otherLicenses.name',index)">
                                                        <label>Name</label>
                                                        <md-input v-model="license.name"></md-input>
                                                        <span class="md-error" v-if="!$v.otherLicenses.$each[index].name.required">Name is required</span>
                                                    </md-field>
                                                    <md-field :class="getValidationClass('otherLicenses.file',index)">
                                                        <label >Attachment (Image or PDF)</label>
                                                        <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                        <span class="md-error" v-if="!$v.otherLicenses.$each[index].attachment.file.required">Attachment is required</span>
                                                    </md-field>
                                                    <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" :class="getValidationClass('otherLicenses.expiration',index)">
                                                        <label>Expiration Date</label>
                                                        <span class="md-error" v-if="!$v.otherLicenses.$each[index].expiration.required">Expiration Date is required</span>
                                                    </md-datepicker>
                                                    <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                                    <md-button class="md-raised md-dense full-width-button" @click="removeLicense(index)">Remove license</md-button>
                                                </CCol>
                                                
                                            </CRow>
                                            
                                            
    
                                        </div>  
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            <CCol sm="12" md="12" lg="12" xl="12">
                                <md-card class="uplimo-card">
                                    <md-card-header >
                                    </md-card-header>
                                    <md-card-content >
                                        <md-empty-state
                                
                                            
                                            md-icon="badge"
                                            md-label="Add new"
                                            md-description="Here you can create custom licenses that are not predifined in the documents tab .">
                                            <md-button class="md-raised md-dense md-primary" @click="createLicense()">Create</md-button>
                                        </md-empty-state>
                                    </md-card-content>
                                </md-card>
                            </CCol>
    
                        </CRow>
                        
                        
                    </md-tab>
                </md-tabs>
                <md-dialog :md-active.sync="docDialog.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog"  >
                    <md-dialog-title v-if="docDialog.name">
                        {{ docDialog.name}}
                        
                    </md-dialog-title>
                    <md-divider class=" mb-4">
                    </md-divider>
                    <div class="modal-up-content text-center">
                        <img :src="docDialog.src" alt="" v-if="docDialog.image">
    
    
                        <md-button :disabled="docDialog.page == 1" @click="prevPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Prev</md-button>
                        <md-button :disabled="docDialog.page == docDialog.pageCount" @click="nextPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Next</md-button>
    
                        <div class="popup-pdf-holder" v-if="docDialog.pdf">
                            <vue-pdf-embed :source="docDialog.src"   :page="docDialog.page" ref="masterPdf" :width="pdfWidth"/>
                        </div>
                        
                    </div>
                    <md-dialog-actions>
                        <md-button @click="docDialog.active = false">Close</md-button>
                    
                    </md-dialog-actions>
                </md-dialog>
            </div>
        
            
        </div>
</template>

<script>
import Vue from 'vue'
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import moment from 'moment'
import moment_timezone from "moment-timezone";
export default {
    name: 'NewVehicle',
    components: { VueCropper,VuePdfEmbed },
    mixins: [validationMixin],
    
    data() {
        return {
            vehicleTypesExist: false,
            isBusy: true,
            dialogLeft: 0,
            docDialog: {
                name: null,
                active: false,
                src: null,
                pdf: false,
                image: false,
                pageCount: null,
                page: 1

            },
            tabs: {
                general: {
                    errors: null,
                },
                mainInfo: {
                    errors: null,
                },
                documents: {
                    errors: null,
                },
                pictures: {
                    errors: null,
                },
                otherLicenses: {
                    errors: null
                }
                
                
            },
            mainActiveTab: 'general',
            activeIndex: 0,
            sending: false,
            files: [],
            baseVehicleTypes: [],
            selectedBase: {
                passengers: null,
                luggage: null
            },
            pictures: {
                fh1: {
                    preview: null,
                    file:null,
                    pdf: false,
                    image: false
                },
                registration: {
                    preview: null,
                    file:null,
                    pdf: false,
                    image: false
                },
                tlc: {
                    preview: null,
                    file:null,
                    pdf: false,
                    image: false
                },
                dmv:{
                    preview: null,
                    file:null,
                    pdf: false,
                    image: false
                },
                liability:{
                    preview: null,
                    file:null,
                    pdf: false,
                    image: false
                }
            },
            otherLicenses: [],
            vehicle: {
                otherLicenses: [],
                documents: {

                    fh1: {
                        expiration: null,
                        fileName: null,
                        pdf: null,
                        image: null,
                        storage: null
                    },
                    registration: {
                        expiration: null,
                        fileName: null,
                        pdf: null,
                        image: null,
                        storage: null
                    },
                    tlc: {
                        expiration: null,
                        fileName: null,
                        pdf: null,
                        image: null,
                        storage: null
                    },
                    dmv: {
                        expiration: null,
                        fileName: null,
                        pdf: null,
                        image: null,
                        storage: null
                    },
                    liability: {
                        expiration: null,
                        fileName: null,
                        pdf: null,
                        image: null,
                        storage: null
                    },


                    // fh1ExpirationDate: null,
                    // fh1Picture: null,

                    // registrationExpirationDate: null,
                    // registrationPicture: null,

                    // tlcWindowStickerExpiration: null,
                    // tlcWindowStickerPicture: null,

                    // dmvInspectionStickerExpiration: null,
                    // dmvInspectionStickerPicture: null,
                    // liabilityInsuranceWithDriverListedPicture: null,
                    
                    
                    

                },
                mainInfo: {
                    plate: null,
                    brand: null,
                    carModel: null,
                    year: null,
                    color: null,
                    
                },
                order:null,
                name: null,
                passengers: null,
                luggage: null,
                description:null,
                pictures: [],
                visible:true,
                base:null,
                rates: {
                    base: {
                        price: null,
                        includedMiles: null
                    },
                    distance: [
                        
                    ],
                    hourly: [
                        { upto: 1 , minutes: 60 ,price: null},
                        { upto: 2 , minutes: 120 ,price: null},
                        { upto: 3 , minutes: 180 ,price: null},
                        { upto: 4 , minutes: 240 ,price: null},
                        { upto: 5 , minutes: 300 ,price: null},
                        { upto: 6 , minutes: 360 ,price: null},
                        { upto: 7 , minutes: 420 ,price: null},
                        { upto: 8 , minutes: 480 ,price: null},
                        { upto: 9 , minutes: 540 ,price: null},
                        { upto: 10 , minutes: 600 ,price: null},
                        { upto: 11 , minutes: 660 ,price: null},
                        { upto: 12 , minutes: 720 ,price: null}

                    ],
                    hourlyEnabled: false,
                    flat: [

                    ],
                    time: null,
                    traffic: null,
                    waitingTime: null,
                    stop: null,
                    extras: {
                        childSeat: {
                            enabled: false,
                            price:null
                        },
                        accessible: {
                            enabled: false,
                            price:null
                        },
                        meetAndGreet: {
                            enabled: false,
                            price:null
                        }
                    }
                }
            },
            ref:   firebase.firestore().collection('vehicle-types'),
            
           
        }
    },
    
    methods: {
        newVehicleType(){
            this.$router.push({path: `/vehicle-types/new`})
        },
        getDocPath (user,hash) {
            switch(user.data.role){
                case 'company-admin':
                return firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet').doc(hash)
                case 'driver':
                return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('fleet').doc(hash)
                
                case 'dispatcher':
                break;
                case 'affiliate':
                break;
            }
        },
        getCollectionPath (user) {
            switch(user.data.role){
                case 'company-admin':
                return firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types')
                
                case 'driver':
                return firebase.firestore().collection('vehicle-types')
                
                case 'dispatcher':
                break;
                case 'affiliate':
                break;
            }
        },
        userStorageFolder(user) {
            switch(user.data.role){
                case 'company-admin':
                return 'users'
                
                case 'driver':
                return 'drivers'
                
                case 'dispatcher':
                break;
                case 'affiliate':
                break;
            }

        },
        pdfLoaded(call){
            //console.log(call)
        },
        enableCropper(picture){
            // let width = this.$refs.cropperPreviewContainer[0].offsetWidth
            // this.cropper.width = width
            // this.cropper.height = width * ( 9 / 16) 
            picture.cropper = true
            picture.editing = true
            // this.$nextTick(()=>{
            //     let width = this.$refs.cropperPreviewContainer[0].offsetWidth
            //     this.cropper.width = width
            //     this.cropper.height = width * ( 9 / 16) 
            // })
            
        },
        disableCropper(picture){
            picture.cropper = false
            picture.editing = false
        },
        async getVehicleTypes() {
            let th = this;
            let collection = this.getCollectionPath(this.user)
            return new Promise((resolve, reject) => {
                
                collection.orderBy("order").onSnapshot((querySnapshot) => {
                //this.$firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    this.baseVehicleTypes = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.baseVehicleTypes.push(dataToSave);
                        
                    });
                    this.baseVehicleTypes.length === 0 ? this.vehicleTypesExist = false : this.vehicleTypesExist = true ;
                    resolve()
                });
            })  

            
        },
        async obtainViewResources(){
            this.isBusy = true;
            await this.getVehicleTypes()
            this.isBusy = false ;
        },
        createLicense(){
            let license = {
                name: null,
                expiration: null,
                attachment: {
                    preview: null,
                    file:null,
                    name: null,
                    pdf: false,
                    image: false
                }
            }

            this.otherLicenses.push(license)
        },
        removeLicense(index) {
             this.otherLicenses.splice(index, 1);
        },
        checkTimeToGo(date) {
            var startTime = moment();
            //var endTime = moment.unix(date);
            var endTime = moment(date);
            
            //var hoursDiff = endTime.diff(startTime, 'hours');
            
            var minutesDiff = endTime.diff(startTime, 'minutes');
            var daysDiff = endTime.diff(startTime, 'days');
            
            //var secondsDiff = endTime.diff(startTime, 'seconds');

            var ms = endTime.diff(startTime);
            var d = moment.duration(ms);
            //var s = moment.utc(d.as('milliseconds')).format('HH:mm:ss')
            let className ;
            // if(minutesDiff >= 180) {
            //     className = 'white-pu'
            // } else if(minutesDiff >= 60 ) {
            //     className = 'yellow-pu'
            // } else if(minutesDiff > 0) {
            //     className = 'red-pu'
            // }

            

            if(daysDiff < 0  || minutesDiff < 0) {
                className = 'up-text-cancelled'
            } else if(daysDiff >= 0 && daysDiff <= 30) {
                className = 'up-text-caution'
            } else if(daysDiff > 30) {
                className = 'up-text-confirmed'
            }

            let payload ;
            // if(d >= 0 ) {
            //     payload = '<div class="time-to-go ' + className + '">' + d.days() + ' D, ' + d.hours() + ' H, ' + d.minutes() + ' M' + ' to PU' + '</div>';
            //     //return 'Days:' + d.days() + ' , Hours:' + d.hours() + ' , Minutes:' + d.minutes() + ' to pick up';
            // } else {
            //     // Here goes logic with trip status , on location, dropped off etc
            //     className = 'green-pu'
            //     className = 'grey-pu'
            //     if(minutesDiff < 0 &&  minutesDiff >= -60) {
            //     payload = '<div class="time-to-go ' + className + '">' + 'Picked up' + '</div>';
            //     //payload = '<div class="' + className + '">' + d.days() + ' days, ' + d.hours() + ' h and ' + d.minutes() + ' min' + ' to PU' + '</div>';
            //     } else {
            //     payload = ''
            //     }
            
            // }
            let text = minutesDiff >= 0 ? 'Expires ' : 'Expired '  
            payload = '<div class="text-uppercase time-to-go ' + className + '">'+ text + endTime.fromNow() + '</div>'
            return payload ;
            //return ''
            //moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
        },
        
        myEventHandler(e) {
            // your code for handling resize...
            this.$store.commit('centerDialog',window.innerWidth)
           
            ////
        },
        nextPdfPage() {
            console.log(this.$refs.masterPdf.pageCount)
            this.docDialog.pageCount = this.$refs.masterPdf.pageCount
            if(this.docDialog.pageCount > this.docDialog.page) {
                this.docDialog.page =  this.docDialog.page + 1
            }
            
        },
        prevPdfPage() {
            if(this.docDialog.page > 1) {
                this.docDialog.page =  this.docDialog.page - 1
            }
            
        },

        openDocDialog(name,src,image,pdf) {
            this.docDialog.page = 1
            this.docDialog.name = name
            this.docDialog.src = src
            
            this.docDialog.image = image
            this.docDialog.pdf = pdf
            this.docDialog.active = true
            this.$nextTick(() => {
               
                let pdf = this.$refs.masterPdf
                
                
                if(pdf) {
                    this.docDialog.pageCount = this.$refs.masterPdf.pageCount
                }
                this.$store.commit('centerDialog',window.innerWidth)
            })
            
        },
        docAttachLicenseChanged(e, index) {
            console.log(e)
            console.log(e.target.files[0].type)
            if(e.target.files[0].type == 'application/pdf') {
                this.otherLicenses[index].attachment.pdf = true
                this.otherLicenses[index].attachment.image = false
            } else {
                this.otherLicenses[index].attachment.pdf = false
                this.otherLicenses[index].attachment.image = true
            }
            console.log(index)
            this.otherLicenses[index].attachment.file = e.target.files[0]
            this.otherLicenses[index].attachment.preview = URL.createObjectURL(e.target.files[0]);
            
        },
        docImageChanged(e, type) {
            console.log(e)
            console.log(e.target.files[0].type)
            if(e.target.files[0].type == 'application/pdf') {
                this.pictures[type].pdf = true
                this.pictures[type].image = false
            } else {
                this.pictures[type].pdf = false
                this.pictures[type].image = true
            }
            console.log(type)
            this.pictures[type].file = e.target.files[0]
            this.pictures[type].preview = URL.createObjectURL(e.target.files[0]);
            
        },
        mainTabChanged(tabId) {
        //console.log(tabId);
            //console.log('this.$refs.mainTabs');
            //console.log(this.$refs.mainTabs);
        },
        zoomIn(){
            this.$refs.cropper[this.activeIndex].relativeZoom(0.1);
            
        },
        zoomOut(){
            this.$refs.cropper[this.activeIndex].relativeZoom(-0.1);
        },
        flip(){
            
            const dom =  this.$refs.cropper[this.activeIndex].cropper.canvas;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper[this.activeIndex].scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        move(offsetX, offsetY) {
            this.$refs.cropper[this.activeIndex].move(offsetX, offsetY);
        },
        
        carouselChanged(index){
            this.activeIndex = index;
        },
        getCanvasBlob(canvas) {
            return new Promise(function(resolve, reject) {
                canvas.toBlob(function(blob) {
                resolve(blob)
                })
            })
        },
        async uploadDoc(ref, file) {
            return new Promise((resolve, reject) => {
                ref.put(file)
                .then(async (url) => {
                    
                    let payload = await ref.getDownloadURL();
                    resolve(payload)
                })

            })
        },

        async uploadStandardDocs(hash) {
            let email = this.user.data.email ;
            // const promises = this.files.map(async (file, index) => {

            //     let ref = this.$firebase.storage().ref()
            //                 .child(`users/`+ email +`/fleet/${this.vehicle.name}/other-licenses/${license.name}`)
            //     return ref.put(license.attachment.file).then(() => ref.getDownloadURL());
                
            // })
            let folderPath = this.userStorageFolder(this.user)

            for (const doc in this.pictures) {
                if (Object.hasOwnProperty.call(this.pictures, doc)) {
                    const element = this.pictures[doc];
                    if(this.vehicle.documents[doc].fileName && this.vehicle.documents[doc].expiration) {


                        

                        let ref = this.$firebase.storage().ref()
                                .child(`${folderPath}/`+ email +`/fleet/${hash}/documents/${doc}/${element.file.name}`)
                        //return ref.put(element.file).then(() => ref.getDownloadURL());

                        this.vehicle.documents[doc].image = element.image
                        this.vehicle.documents[doc].pdf = element.pdf

                        //let promise = ref.put(element.file).then(() => ref.getDownloadURL())

                        let storage  = await this.uploadDoc(ref,element.file)
                        this.vehicle.documents[doc].storage = storage
                        

                        //promises.push(ref.put(element.file).then(() => ref.getDownloadURL()))
                    }
                }
            }



            // return Promise.all(promises)
            // .then((uploadedMediaList) => {
                
            // })
            // .catch((err) => alert(err.code));
        },
        async uploadPictures(hash) {
            let email = this.user.data.email ;
            
            let folderPath = this.userStorageFolder(this.user)
            const promises = this.files.map(async (file, index) => {

                // const canvas = this.$refs.cropper[index].getCroppedCanvas()
                // let ref = this.$firebase.storage().ref()
                //             .child(`users/`+ email +`/fleet/${hash}/pictures/${file.file.name}`)
                // var blobOutput = await this.getCanvasBlob(canvas);

                
                // return ref.put(blobOutput).then(() => ref.getDownloadURL());

                var timestamp = moment().format('x')
                let options = {
                    imageSmoothingQuality: 'high',
                    minWidth: 854,
                    minHeight: 480,
                    fillColor: '#fff',
                    maxWidth: 854,
                    maxHeight: 480,
                }
                const canvas = this.$refs.cropper[index].getCroppedCanvas(options)
                let ref = this.$firebase.storage().ref()
                            .child(`${folderPath}/`+ email +`/fleet/${hash}/pictures/picture-${index}-${timestamp}`)
                var blobOutput = await this.getCanvasBlob(canvas);

                
                return ref.put(blobOutput).then(() => ref.getDownloadURL());
                
            })

            return Promise.all(promises)
            .then((uploadedMediaList) => {
                console.log(uploadedMediaList, 'all');
                this.vehicle.pictures = uploadedMediaList ;
            })
            .catch((err) => alert(err.code));

        },
        async uploadDocumentsAttachments(hash) {
            let email = this.user.data.email ;
            let folderPath = this.userStorageFolder(this.user)
            const promises = this.otherLicenses.map( async (license, index) => {
                let ref = this.$firebase.storage().ref()
                            .child(`${folderPath}/`+ email +`/fleet/${hash}/other-licenses/${license.name}`)
                return ref.put(license.attachment.file).then(() => ref.getDownloadURL());
            })

            return Promise.all(promises)
            .then((uploadedMediaList) => {
                console.log(uploadedMediaList, 'all');
                for (let k = 0; k < this.otherLicenses.length; k++) {
                    
                    this.otherLicenses[k].attachment.storage = uploadedMediaList[k]
                    let dataToSave = this.otherLicenses[k]
                    dataToSave.attachment.preview = null
                    delete dataToSave.attachment.file

                    this.vehicle.otherLicenses.push(dataToSave)
                    
                }
                //this.vehicle.pictures = uploadedMediaList ;
            })
            .catch((err) => alert(err.code));
        },
        removePicture(){
            console.log(this.$refs.carousel.currentPage);
            this.files.splice(this.$refs.carousel.currentPage,1);
        },
        add(){
            this.validateAllSteps()
        },
        async saveVehicle(){

            let th = this;
            this.isBusy = true;
            this.sending = true ;
            let hash = this.vehicle.name + '-' +  (Math.random() + 1).toString(36).substring(2);
            
            await this.uploadPictures(hash)
            await this.uploadDocumentsAttachments(hash)
            await this.uploadStandardDocs(hash)


            
             
            let doc = this.getDocPath(this.user,hash)
            doc.set(th.vehicle)
            .then(function(docRef) {
                th.sending = false;
                th.isBusy = false;
                th.$router.push({path: `/fleet`})
            })
            .catch(function(error) {
                th.sending = false;
                th.isBusy = false;
                console.error("Error adding Tutorial: ", error);
            });


            // firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet')            
            // .add(th.vehicle)
        
            // .then(function(docRef) {
            //     th.sending = false;
            //     th.$router.push({path: `/fleet`})
            // })
            // .catch(function(error) {
            //     th.sending = false;
            //     console.error("Error adding Tutorial: ", error);
            // });
        },
        addPicture()
        {
            this.$refs.carfile.$refs.inputFile.click();
            
        },
        getValidationClass (fieldName,index) {
            let field = null;

            if(fieldName === 'files') {
                field = this.$v[fieldName];
            } else if(
                
                fieldName == 'mainInfo.plate' 
                || fieldName == 'mainInfo.brand' 
                || fieldName == 'mainInfo.carModel' 
                || fieldName == 'mainInfo.year' 
                || fieldName == 'mainInfo.color' 
            ){
                let fieldSplit = fieldName.split('mainInfo.')[1];
                field = this.$v.vehicle.mainInfo[fieldSplit]
            } else if (
                fieldName === 'documents.fileName'
                || fieldName === 'documents.expiration'
            ) {

                 let fieldSplit = fieldName.split('documents.')[1];
                field = this.$v.vehicle.documents[index][fieldSplit]


            }
            
            
            else if(
                fieldName === 'otherLicenses.name'
                || fieldName === 'otherLicenses.file'
                || fieldName === 'otherLicenses.expiration'

            
            ) {
                
                let fieldSplit = fieldName.split('otherLicenses.')[1];
                if(fieldSplit == 'file') {
                    field = this.$v.otherLicenses.$each[index].attachment[fieldSplit]
                } else {
                    field = this.$v.otherLicenses.$each[index][fieldSplit]
                }
                
            } else {
                field = this.$v.vehicle[fieldName];
            }
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        validateStep(step){
            // nombre
            // tipo vehicle
            // Pass
            // lugga

            // color
            // placa
            // año
            // marca
            // modelo

            switch(step) {
                case 'general':
                    let name,passengers,luggage,pictures,base;
                    name = this.$v.vehicle.name.$invalid;
                    passengers = this.$v.vehicle.passengers.$invalid;
                    luggage = this.$v.vehicle.luggage.$invalid;
                    base = this.$v.vehicle.base.$invalid;
                    


                    this.tabs.general.errors = 0 ;
                    name ? this.tabs.general.errors++ : false;
                    passengers ? this.tabs.general.errors++ : false;
                    luggage ? this.tabs.general.errors++ : false;
                    base ? this.tabs.general.errors++ : false;
                    
                    

                    return !name && !passengers && !luggage && !base ? true : false ;

                   
                case 'mainInfo':
                    let plate,
                    brand,
                    carModel,
                    year,
                    color
                    
                    plate = this.$v.vehicle.mainInfo.plate.$invalid;
                    brand = this.$v.vehicle.mainInfo.brand.$invalid;
                    carModel = this.$v.vehicle.mainInfo.carModel.$invalid;
                    year = this.$v.vehicle.mainInfo.year.$invalid;
                    color = this.$v.vehicle.mainInfo.color.$invalid;
                    

                    this.tabs.mainInfo.errors = 0 ;
                    plate ? this.tabs.mainInfo.errors++ : false;
                    brand ? this.tabs.mainInfo.errors++ : false;
                    carModel ? this.tabs.mainInfo.errors++ : false;
                    year ? this.tabs.mainInfo.errors++ : false;
                    color ? this.tabs.mainInfo.errors++ : false;
                    
                

                    return !plate && !brand && !carModel && !year && !color ? true : false ;
                case 'documents':
                    this.tabs.documents.errors = 0 ;
                    let otherDocErrors = []
                     for (const doc in this.vehicle.documents) {
                        if (Object.hasOwnProperty.call(this.vehicle.documents, doc)) {
                            const element = this.vehicle.documents[doc];
                            

                            let fileName, expiration ;

                            fileName = this.$v.vehicle.documents[doc].fileName.$invalid
                            expiration = this.$v.vehicle.documents[doc].expiration.$invalid

                            fileName ? this.tabs.documents.errors++ : false;
                            expiration ? this.tabs.documents.errors++ : false;

                            !fileName && !expiration ? otherDocErrors.push(true) : otherDocErrors.push(false)
                            
                        }
                    }
                     return otherDocErrors.includes(false) ? false : true ;
                    
                case 'pictures':
                    return true
                
                case 'otherLicenses':
                    this.tabs.otherLicenses.errors = 0 ;
                    let otherErrors = []
                    for (let i = 0; i < this.otherLicenses.length; i++) {
                        
                        let licName,expiration,file;
                        licName = this.$v.otherLicenses.$each[i].name.$invalid;
                        expiration = this.$v.otherLicenses.$each[i].expiration.$invalid;
                        file = this.$v.otherLicenses.$each[i].attachment.file.$invalid;

                        licName ? this.tabs.otherLicenses.errors++ : false;
                        expiration ? this.tabs.otherLicenses.errors++ : false;
                        file ? this.tabs.otherLicenses.errors++ : false;

                        !licName && !expiration && !file  ? otherErrors.push(true) : otherErrors.push(false) ;
                        
                    }

                    

                    return otherErrors.includes(false) ? false : true ;
               
            }                    
        },
        validateAllSteps(){
            let validSteps = [];
            let firstCorrectableStep = undefined;
            let steps = ['general','mainInfo','documents','pictures','otherLicenses'] ;
            for (let i = 0; i < steps.length; i++) {
                if(this.validateStep(steps[i])) {
                    validSteps.push(true)
                } else {
                    validSteps.push(false)
                    if(firstCorrectableStep === undefined) {
                        firstCorrectableStep = steps[i] ;
                    }
                }
                      
            }
           
            if(validSteps.includes(false)) {
                
                this.$refs.mainTabs.activeTab = firstCorrectableStep
                //this.mainActiveTab = 'general' ;
                this.$v.$touch();
            } else {
                console.log('All steps are valid')
                //this.add();
                this.saveVehicle()
            }

            
        },
        validate(){
           


        },
        imageChanged(e, type) {
           
            var newFiles = [];
            for (let i = 0; i < e.target.files.length; i++) {
                
                const file = e.target.files[i];
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {  
                            let pushedIndex = this.files.push({
                            imgSrc: event.target.result,
                            file: file,
                            editing: false,
                            cropper:false
                        }) - 1 ;

                        console.log(pushedIndex)
                        this.$nextTick(() => {
                        
                            let width = this.$refs.cropperPreviewContainer[pushedIndex].offsetWidth
                            console.log(width)
                            //let height = ( 360 * width / 640 ) + 'px' 
                            let height = width * ( 9 / 16) + 'px'
                            this.$refs.cropperPreviewContainer[pushedIndex].firstChild.style.width = width + 'px'
                            this.$refs.cropperPreviewContainer[pushedIndex].firstChild.style.height = width * ( 9 / 16) + 'px'


                        
                            this.files[pushedIndex].cropper = true
                            this.files[pushedIndex].editing = true
                            
                            setTimeout(() => {
                                let container = this.$refs.cropper[pushedIndex].getContainerData()
                                let canvas = this.$refs.cropper[pushedIndex].getCanvasData()
                                let cropbox = this.$refs.cropper[pushedIndex].getCropBoxData()
                                
                                let left = ( container.width -  canvas.width ) / 2
                                console.log(left)
                                let data = {
                                    width: width,
                                    height: width * ( 9 / 16),
                                    //width:200,
                                    //height:100,
                                    left:left,
                                    top:0
                                }
                                this.$refs.cropper[pushedIndex].setCropBoxData(data)
                            },500)
                            
                            
    
                        })
                        
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            }

            this.$refs.carfile.$refs.inputFile.value = '';
        
        },
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      dialogDimensions () {
            console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
       headerHeight() {
        return this.$store.state.headerHeight;
        },
    
        pdfWidth () {
            return  (window.innerWidth - this.dialogLeft) * 0.9 
            
        },

        dialogHeight() {
            console.log(window)
            console.log(window.innerHeight)
            
            return {
                height: window.innerHeight - this.headerHeight + 'px',
                top: this.headerHeight + 'px'
                //height: this.$store.state.sidebarNavHeight + 'px'
            }
        }
      
       
    },
    watch: {
        'vehicle.base': function(id){
            console.log(id)
            for (let i = 0; i < this.baseVehicleTypes.length; i++) {
                if(id == this.baseVehicleTypes[i].id) {
                    this.selectedBase.passengers = this.baseVehicleTypes[i].passengers;
                    this.selectedBase.luggage = this.baseVehicleTypes[i].luggage;
                }
                
            }
        },
        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.myEventHandler);
    },
    created () {
        window.addEventListener('resize', this.myEventHandler);
        this.obtainViewResources()
        
       
    },
    validations: {
        files: {
            required,
            minLength: minLength(1),
        },
        vehicle: {
            name: {
                required
            },
            base: {
                required
            },
            passengers: {
                required,
                between (value) {
                    return between(1, this.selectedBase.passengers)(value)
                }
                
            },
            luggage: {
                required,
                between (value) {
                    return between(1, this.selectedBase.luggage)(value)
                }
            },
            // pictures: {
            //     required,
            //     minLength: minLength(1),
            // },
            documents: {
                fh1: {
                    expiration: {
                        required: requiredIf(function(){
                            return !this.vehicle.documents.fh1.fileName ? false : true;
                            console.log(this.vehicle.documents)
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.fh1.expiration ? false : true;
                            console.log(this.vehicle.documents)
                         }),
                    }
                },
                
                
                registration: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.registration.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.registration.expiration ? false : true;
                         }),
                    }
                },
                tlc: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.tlc.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.tlc.expiration ? false : true;
                         }),
                    }
                },
                dmv: {
                    expiration: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.dmv.fileName ? false : true;
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.dmv.expiration ? false : true;
                         }),
                    }
                },
                liability: {
                    expiration: {
                        required: requiredIf(function(){
                             //return !this.vehicle.documents.liability.fileName ? false : true;
                             return false
                         }),
                    },
                    fileName: {
                        required: requiredIf(function(){
                             return !this.vehicle.documents.liability.fileName ? false : true;
                         }),
                    }
                }

            },
            // documents: {
            //     fh1ExpirationDate: {
            //         required
            //     },
            //     fh1Picture: {
            //         required
            //     },
            //     registrationExpirationDate: {
            //         required
            //     },
            //     registrationPicture: {
            //         required
            //     },
            //     tlcWindowStickerExpiration: {
            //         required
            //     },
            //     dmvInspectionStickerExpiration: {
            //         required
            //     },
            //     liabilityInsuranceWithDriverListedPicture: {
            //         required
            //     },
                
            //     tlcWindowStickerPicture: {
            //         required
            //     },
            //     dmvInspectionStickerPicture: {
            //         required
            //     }

            // },
            mainInfo: {
                plate: {
                    required
                },
                brand: {
                    required
                },
                carModel: {
                    required
                },
                year: {
                    required
                },
                color: {
                    required
                },
                
            }
        },
        otherLicenses: {
            $each: {
                name: {
                    required
                },
                expiration: {
                    required
                },
                attachment: {
                    //preview: null,
                    file:{
                        required
                    },
                    //pdf: false,
                    //image: false
                }
            }
        }
    }
}
</script>  
<style>
    .badge {
        width: 19px;
        height: 19px;
        line-height:19px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 5px;
        right: 5px;
        background: red;
        border-radius: 100%;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -.05em;
        font-family: 'Roboto Mono', monospace;
    }

    .badge.md-primary {
        background-color: rgb(119, 207, 119);
    }

    .badge.md-primary .md-icon {
        width: 12px;
        min-width: 12px;
        height: 12px;
        font-size: 12px!important;
        color:white!important;

    }


    .VueCarousel-pagination {
        text-align: left!important;
    }


    .buttons-container .md-button{
        min-width: initial;
    }

    .VueCarousel-slide-adjustableHeight {
        display: block !important;
    }
    .VueCarousel-inner {
        align-items: flex-start !important;
    }

    .VueCarousel-dot-container {
        margin-top: 0px!important;
    }

    .VueCarousel-dot {
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }

</style>