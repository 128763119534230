<template>
  <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
  <div v-else>
      <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="addon.name">{{addon.name}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>New add on</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update add on</md-button>
      </md-toolbar>
      <div class="main-inner-wrapper">
        <CRow :gutters="true">
            <CCol sm="12" lg="5">
                <md-card>
                    <md-card-header>
                        <div class="md-title">Add on info</div>
                    </md-card-header>

                    <md-card-content>
                        <CRow>
                            <CCol sm="12">
                                <md-field :class="getValidationClass('name')">
                                    <label>Name</label>
                                    <md-input v-model="addon.name"></md-input>
                                      <span class="md-error" v-if="!$v.addon.name.required">Name is required</span>
                                </md-field>
                            </CCol>
                        </CRow>
                        <CRow >
                              <CCol sm="12">
                                <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete addon</md-button>
                            </CCol>
                        </CRow>
                    </md-card-content>
                
                </md-card>
            </CCol>
        </CRow>
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

  </div>


  
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
  name: 'Addon',
  mixins: [validationMixin],
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      addon: null,
      isBusy: true,
      ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created () {
    let th = this;
    firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.addon = doc.data();
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  },
  methods: {
    fireUpdate() {
     
      if(this.validate()) {
        this.update()
      } else {
        this.$v.addon.name.$touch()
      }
    },
    update() {
       this.isBusy = true;
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').doc(this.$route.params.id).update(th.addon)
      .then(function(){
        console.log('Updated');
        th.snackMessage = 'Addon updated';
        th.isBusy = false;
        th.showSnackbar = true;
       
      }).catch(function(error) {
          th.snackMessage = 'Ooopss something went wrong';
          th.isBusy = false;
          th.showSnackbar = true;
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/add-ons`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    },
    validate(){
        let name;
        name = this.$v.addon.name.$invalid;
        
        return !name ? true : false ;


    },
    getValidationClass (fieldName) {
        let field = null;

        
        field = this.$v.addon[fieldName];
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
  },
  validations: {
       
        addon: {
            
            name: {
                required
            },
            
        }
    }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

