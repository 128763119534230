<template>
  <div v-if="!isBusy">
     <CRow>
      <CCol sm="6">
        
        <md-field>
          <label>Name</label>
          <md-input v-model="dispatcher.name"></md-input>
        </md-field>
      </CCol>
      <CCol sm="6">
         
      </CCol>
     </CRow>
     <md-button class="md-accent" @click="destroy">Delete</md-button>
     <md-button class="md-fab md-primary" @click="update">
          <md-icon>save</md-icon>
      </md-button>
  </div>
</template>

<script>
import firebase from '../../Firebase'
export default {
  name: 'Dispatcher',
  data() {
    return {
      dispatcher: null,
      isBusy: true,
      ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('dispatchers'),
    }
  },
  created () {
    let th = this;
    this.ref.doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.dispatcher = doc.data();
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  },
  methods: {
    update() {
      let th = this;
      this.ref.doc(this.$route.params.id).update(th.dispatcher)
      .then(function(){
        console.log('Updated');
        th.$router.push({path: `/dispatchers`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      this.ref.doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/dispatchers`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    }
  }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

