<template>
    <div>
        <md-toolbar class="md-primary mb-2" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="bonus.name">{{bonus.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New bonus or discount</h3>
           
            <md-button class="md-primary md-raised" @click="add">Save</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Info</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('name')">
                                        <label>Name</label>
                                        <md-input v-model="bonus.name"></md-input>
                                        <span class="md-error" v-if="!$v.bonus.name.required">Name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('code')">
                                        <label>Code</label>
                                        <md-input v-model="bonus.code"></md-input>
                                        <span class="md-error" v-if="!$v.bonus.code.required">Code is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('type')">
                                        <label for="type">Method</label>
                                        <md-select v-model="bonus.type" name="type" id="type" placeholder="Method">
                                            <md-option value="fixed">Fixed amount
                                            </md-option>
                                            <md-option value="percentage">Percentage
                                            </md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.bonus.type.required">Type is required</span>
                                    </md-field>
                                    
                                    <md-field :class="getValidationClass('value')">
                                        <span class="md-prefix" v-if="bonus.type == 'fixed'">$</span>
                                        <span class="md-prefix" v-if="bonus.type == 'percentage'">%</span>
                                        <label>Value</label>
                                        <md-input v-model="bonus.value" type="number"></md-input>
                                        <span class="md-error" v-if="!$v.bonus.value.required">Value is required</span>
                                    </md-field>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('startDatetime')">
                                                <VueCtkDateTimePicker 
                                                    v-model="bonus.startDatetime" 
                                                    :dark="true" 
                                                    :inline="false" 
                                                    :formatted="'llll (HH:mm [HS])'"
                                                    :format="'YYYY-MM-DD hh:mm a'"
                                                    :output-format="'X'"
                                                    :color="'#448aff'"
                                                    :button-color="'#448aff'"	
                                                    :minute-interval="5"
                                                    id="ul-timepicker"
                                                    :label="'Start Date time'"
                                                />
                                            <span class="md-error" v-if="!$v.bonus.startDatetime.required">If end Date &amp; time is filled start date time is required</span>
                                            </md-field>
                                        </CCol>
                                        
                                        
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('endDatetime')">
                                                <VueCtkDateTimePicker 
                                                    v-model="bonus.endDatetime" 
                                                    :dark="true" 
                                                    :inline="false" 
                                                    :formatted="'llll (HH:mm [HS])'"
                                                    :format="'YYYY-MM-DD hh:mm a'"
                                                    :output-format="'X'"
                                                    :color="'#448aff'"
                                                    :button-color="'#448aff'"	
                                                    :minute-interval="5"
                                                    id="ul-timepicker"
                                                    :label="'End Date time'"
                                                />
                                            <span class="md-error" v-if="!$v.bonus.endDatetime.required">If start Date &amp; time is filled end date time is required</span>
                                            </md-field>
                                        </CCol>
                                        
                                        
                                    </CRow>
                                    <md-field>
                                        <md-select  v-model="bonus.vehicleTypes" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" multiple>
                                            <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                        </md-select>
                                        <span class="md-helper-text">If none is selected will apply for all available vehicles</span>
                                    </md-field>
                                    <md-field>
                                        <md-select  v-model="bonus.serviceTypes" name="service_type" id="service_type" placeholder="Service Type" multiple>
                                            <md-option v-for="serviceType in serviceTypes" :value="serviceType.id" v-bind:key="serviceType.id">{{serviceType.name}}</md-option>
                                        </md-select>
                                        <span class="md-helper-text">If none is selected will apply for all available services</span>
                                    </md-field>
                                    <CRow>
                                        <CCol sm="4">
                                            <md-switch v-model="bonus.enabled" class="md-primary">Enabled</md-switch>
                                        </CCol> 
                                        <CCol sm="4">
                                            <md-switch v-model="bonus.geofenced" class="md-primary">Use Geofence</md-switch>
                                        </CCol>
                                    </CRow>
                                    <md-field v-if="bonus.geofenced" :class="getValidationClass('geofenceType')">
                                        <label :for="'geofence-type'">Geofence trigger type</label>
                                        <md-select v-model="bonus.geofenceType" :name="'geofence-type'" :id="'geofence-type'">
                                            <md-option value="pu" >On Pick Up</md-option>
                                            <md-option value="do" >On Drop Off</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.bonus.geofenceType.required">Geofence type is required</span>
                                    </md-field>
                                    <md-field v-if="bonus.geofenced && bonus.geofenceType" :class="getValidationClass('fence')">
                                        <label :for="'bonus-fence'">{{ bonus.geofenceType == 'pu' ? 'Pick up fence' : 'Drop off fence' }}</label>
                                        <md-select v-model="bonus.fence" :name="'bonus-fence'" :id="'bonus-fence'">
                                            <md-option v-for="geofence in geofences" :value="geofence.id" v-bind:key="geofence.id">{{geofence.code}} | {{geofence.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.bonus.fence.required">Value is required</span>
                                        <span class="md-helper-text">{{ bonus.geofenceType == 'pu' ? 'bonus will be applied if PU location is inside selected geofence' : 'bonus will be applied if DO location is inside selected geofence' }}</span>
                                    </md-field>

                                
                                    
                                </CCol>
                            </CRow>
                            
                        </md-card-content>
                    
                    </md-card>
                </CCol>
                <CCol sm="12" lg="7" v-if="bonus.geofenced && selectedFence">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Selected geofence</div>
                        </md-card-header>
                        <md-card-content>
                             <GmapMap :center="selectedFence.center" :zoom="selectedFence.zoom" style="width:100%;height:500px" ref="map">
                                <gmap-polygon :paths="selectedFence.path" :editable="true">
                                </gmap-polygon>
                            </GmapMap>
                        </md-card-content>
                    </md-card>
                </CCol>
            </CRow>
        </div>


        
        
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
    name: 'NewBonus',
    mixins: [validationMixin],
    components: {
        VueTimepicker,
    },
    data() {
        return {
            geofences: [],
            vehicleTypes: [],
            serviceTypes: [],
            isBusy: true,
            bonus: {
                name: null,
                code:null,
                startDatetime:null,
                endDatetime:null,
                type: null,
                order:null,
                value:null,
                enabled:false,
                fence:null,
                geofenced:false,
                geofenceType:null,
                vehicleTypes: [],
                serviceTypes: [],
            }, 
            selectedFence: null           
           
        }
    },
    
    methods: {
        async obtainViewResources() {
            this.isBusy = true;
            await this.getVehicleTypes()
            await this.getServiceTypes()
            await this.getGeofences()
            this.isBusy = false;
        },
        add(){
             if(this.validate()) {
                let th = this;
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('bonuses')
                
                .add(th.bonus)
            
                .then(function(docRef) {
                    console.log("Tutorial created with ID: ", docRef.id);
                    th.$router.push({path: `/bonuses-and-discounts`})
                })
                .catch(function(error) {
                    console.error("Error adding Tutorial: ", error);
                });
             } else {
                 this.$v.bonus.$touch()
             }
        },
        validate(){
            let name,code,type,value,startDatetime,endDatetime;
            name = this.$v.bonus.name.$invalid;
            code = this.$v.bonus.code.$invalid;
            type = this.$v.bonus.type.$invalid;
            value = this.$v.bonus.value.$invalid;
            startDatetime = this.$v.bonus.startDatetime.$invalid;
            endDatetime = this.$v.bonus.endDatetime.$invalid;
           
            return !name && !type && !value && !startDatetime && !endDatetime  ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.bonus[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.vehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getServiceTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('service-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.serviceTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.serviceTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getGeofences() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences').onSnapshot((querySnapshot) => {
                    console.log('Snap shot')
                    this.geofences = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        this.geofences.push(dataToSave);

                    });
                });
            })  
        },
         refreshGeofencePreview(fenceId) {
            for (let i = 0; i < this.geofences.length; i++) {
                
                if(this.geofences[i].id == fenceId) {
                    this.selectedFence = this.geofences[i];
                }
            }
        },
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
       
    },
    watch: {
        'bonus.fence': {
            handler: function(val,oldVal) {
                this.refreshGeofencePreview(val)
            }
        }
    },
    created () {
       this.obtainViewResources()
       
    },
    
    validations: {
       
        bonus: {
            
            name: {
                required
            },
             code: {
                required
            },
            type: {
                required
            },
            value: {
                required
            },
            startDatetime: {
                required: requiredIf(function(bonus){
                    return bonus.endDatetime ? true : false ;

                })
            },
            endDatetime: {
                required: requiredIf(function(bonus){
                    return bonus.startDatetime ? true : false ;

                })
            },
            fence: {
                required: requiredIf(function(bonus){
                    return bonus.geofenced ? true : false ;

                })
            },
            geofenceType: {
                required: requiredIf(function(bonus){
                    return bonus.geofenced ? true : false ;

                })
            },
            
            
            
        }
    }
}
</script>  