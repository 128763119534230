export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      
     
      {
        _name: "CSidebarNavItem",
        name: "User profile",
        to: "/user-profile",
        icon: "cil-speedometer",
        allowedRoles: ["driver",'dispatcher','affiliate'],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      {
        _name: "CSidebarNavItem",
        name: "Fleet",
        to: "/fleet",
        icon: "cil-speedometer",
        allowedRoles: ["driver"],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      {
        _name: "CSidebarNavItem",
        name: "Partner search",
        to: "/partner-search",
        icon: "cil-speedometer",
        allowedRoles: ["driver","dispatcher","affiliate","company-admin"],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      




      {
        _name: "CSidebarNavItem",
        name: "Offers",
        to: "/offers",
        icon: "cil-speedometer",
        allowedRoles: ["driver"],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      // Company users
      
      {
        _name: "CSidebarNavDropdown",
        name: "Company users",
        route:"",
        allowedRoles: ["company-admin"],
        items: [
          {
            name: "Company drivers",
            to: "/drivers/company-drivers",
            allowedRoles: ["company-admin"],
          },
          {
            name: "Company dispatchers",
            to: "/",
            allowedRoles: ["company-admin"],
          },

        ]
      },
      {
        _name: "CSidebarNavItem",
        name: "Communities",
        to: "/communities",
        icon: "cil-speedometer",
        allowedRoles: ["company-admin"],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Associated users",
      //   route:"",
      //   allowedRoles: ["company-admin"],
      //   items: [
      //     {
      //       name: "Owner operators",
      //       to: "/associated-users/owner-operators",
      //       allowedRoles: ["company-admin"],
      //     },
      //     {
      //       name: "Independent drivers",
      //       to: "/",
      //       allowedRoles: ["company-admin"],
      //     },
      //     {
      //       name: "Freelance dispatchers",
      //       to: "/",
      //       allowedRoles: ["company-admin"],
      //     },
      //     {
      //       name: "Affiliated companies",
      //       to: "/",
      //       allowedRoles: ["company-admin"],
      //     },

      //   ]
      // },
      {
        _name: "CSidebarNavDropdown",
        name: "Reservations",
        route: "/reservations",
        icon: "cil-puzzle",
        allowedRoles: ["company-admin", "dispatcher","driver","affiliate"],
        items: [
          {
            name: "New",
            to: "/reservations/new",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "All",
            to: "/reservations",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "Confirmed",
            to: "/reservations/status/confirmed",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "Cancelled",
            to: "/reservations/status/cancelled",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "Late cancellation",
            to: "/reservations/status/late-cancellation",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "Quoted",
            to: "/reservations/status/quoted",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
          {
            name: "Confirmed uncollected",
            to: "/reservations/status/confirmed-uncollected",
            allowedRoles: ["super-admin", "company-admin", "dispatcher","driver","affiliate"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Preferences",
        route: "/preferences",
        icon: "cil-puzzle",
        allowedRoles: ["company-admin"],
        items: [
          
          {
            name: "Add ons",
            to: "/add-ons",
            allowedRoles: ["company-admin"],
          },
          {
            name: "Vehicle types",
            to: "/vehicle-types",
            allowedRoles: ["company-admin"],
          },
          {
            name: "Fleet",
            to: "/fleet",
            allowedRoles: ["company-admin"],
          },
          {
            name: "Service types",
            to: "/service-types",
            allowedRoles: ["company-admin"],
          },
          // {
          //   name: "We dispatch job for",
          //   to: "/preferences/distpatch-for",
          // },
          // {
          //   name: "Time zone",
          //   to: "/preferences/time-zone",
          // },
          {
            name: "Company profile",
            to: "/company-profiles",
            allowedRoles: ["company-admin"],
          },
          {
            name: "Merchant accounts",
            to: "/merchant-accounts",
            allowedRoles: ["company-admin"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Rate managment",
        route: "/rate-managment",
        icon: "cil-puzzle",
        allowedRoles: ["company-admin"],
        items: [
          {
            name: "Rate generator",
            to: "/rates",
          },
          {
            name: "Geofences",
            to: "/geofences",
          },
          
          {
            name: "Subcharges, taxes and fees",
            to: "/taxes-and-fees",
          },
          {
            name: "Bonus and promo code",
            to: "/bonuses-and-discounts",
          },
          {
            name: "Increases and decreases",
            to: "/increases-and-decreases",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Users",
        route: "/users",
        icon: "cil-puzzle",
        allowedRoles: [],
        items: [
          // {
          //   name: "NewUser",
          //   to: "users/new",
          //   allowedRoles: ["super-admin", "company-admin"],
          // },
          {
            name: "All",
            to: "/users/",
            allowedRoles: ["super-admin", "company-admin"],
          },
          {
            name: "Owner Admins",
            to: "/users/company-admins",
            allowedRoles: []
            //allowedRoles: ["super-admin"],
          },
          {
            name: "Dispatchers",
            to: "/users/dispatchers",
            allowedRoles: []
            //allowedRoles: ["super-admin", "company-admin"],
          },
          {
            name: "Drivers",
            to: "/users/drivers",
            allowedRoles: []
            //allowedRoles: ["super-admin", "company-admin", "dispatcher"],
          },
          {
            name: "Passengers",
            to: "/users/passengers",
            allowedRoles: []
            //allowedRoles: ["super-admin", "company-admin", "dispatcher"],
          },
        ],
      },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Merchant accounts",
      //   route: "/merchant-accounts",
      //   icon: "cil-puzzle",
      //   allowedRoles: ["company-admin"],
      //   items: [
      //     {
      //       name: "Setups",
      //       to: "/merchant-accounts/setups",
      //     },
      //   ],
      // },
      {
        _name: "CSidebarNavItem",
        name: "Fixer",
        to: "/fixer",
        icon: "cil-speedometer",
        allowedRoles: [],
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
    ],
  },
];
