<template>
  <div>
    <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Users</h3>
        
        <md-button class="md-primary md-raised" @click="newUser">New user</md-button>
        
    </md-toolbar>
    <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar> 
    <div v-else>

      <md-empty-state
        v-if="users.length == 0"
        md-icon="person_add"
        md-label="Add your user">
        <md-button class="md-primary md-raised" @click="newUser">Add user</md-button>
      </md-empty-state>       
      <md-table v-else>
        <md-table-row>
          <md-table-head>Email</md-table-head>
          
          <md-table-head>Display Name</md-table-head>
          
          <md-table-head>Role</md-table-head>
          <md-table-head>Sub Role</md-table-head>
          <md-table-head>code</md-table-head>
          <md-table-head>UID</md-table-head>
          
        </md-table-row>

        <md-table-row v-for="user in users" v-bind:key="user.uid" @click="rowClicked(user)">
          <md-table-cell>{{user.email}}</md-table-cell>
          
          <md-table-cell>{{user.displayName}}</md-table-cell>
          
          <md-table-cell>{{user.role ? user.role : ''}}</md-table-cell>
          <md-table-cell>{{user.subrole ? user.subrole : ''}}</md-table-cell>
          <md-table-cell>{{user.code ? user.code : ''}}</md-table-cell>
          <md-table-cell>{{user.uid}}</md-table-cell>
          
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>

import axios from "./../../helpers/axios"

export default {
  name: 'Users',
  data () {
    return {
      users: [],
      isBusy: true
      
    }
  },
  mounted() {
    
  },
  created() {
    this.getUsers();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  methods: {
    newUser() {
      this.$router.push({path: `/users/new`})
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item) {
     
      this.$router.push({path: `/users/${item.uid}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getToken() {

    },
    getUsers(){
      let th = this ;
      let url = null;
      
      let email = this.user.data.email ;
      let role = this.user.data.role ;
      let data = {  
            ownerEmail: email
      }
      

      
      if(role == 'super-admin') {
        url = 'users';
      } else if (role == 'company-admin') {
        url = 'company-users';
      }
      
      
        
      
      axios.get(url ,{ params: data }).then(response =>{
        console.log(response);
        th.users = response.data.users ;
        th.isBusy = false;
      }).catch(e => {
        console.log(e);
        th.isBusy = false;
      })
      
          
    }
  }
}
</script>
