<template>
  <div>
    <div class="main-inner-wrapper">
      <CRow class="mb-4">
        <CCol sm="12" md="6">
          <CChartBar
            :datasets="defaultChartDatasets"
            labels="months"
            :options="{ maintainAspectRatio: false }"
          />
        </CCol>
        <CCol sm="12" md="6">
          <CChartLine
            :datasets="defaultDatasets"
            labels="months"
            :options="{ maintainAspectRatio: false }"
          />
        </CCol>
        
      </CRow>
      <CRow>
        <CCol sm="12" md="6" class="mb-3">
          <md-card>
            <md-card-header>
                <div class="md-title">Upcoming reservations</div>
            </md-card-header>
            <md-card-content>
              <md-table >
                <md-table-row>
                    <md-table-head>Date</md-table-head>
                    <md-table-head>Passenger</md-table-head>
                    <md-table-head>PU</md-table-head>
                    <md-table-head>DO</md-table-head>
                </md-table-row>

                <md-table-row v-for="(reservation) in upcomingReservations" v-bind:key="reservation.id">
                  <md-table-cell>{{ reservation.day | moment("YYYY-MM-DD") }}</md-table-cell>
                  <md-table-cell>{{ reservation.passenger.name }} {{ reservation.passenger.lastName}}</md-table-cell>
                  <md-table-cell>{{ reservation.pu.formatted_address }}</md-table-cell>
                  <md-table-cell>{{ reservation.do.formatted_address }}</md-table-cell>
                </md-table-row>
              </md-table>
              <md-progress-bar md-mode="indeterminate" v-if="upcomingReservationsBusy"></md-progress-bar>
            </md-card-content>
          </md-card>
        </CCol>
        <CCol sm="12" md="6" class="mb-3">
          <md-card>
            <md-card-header>
                <div class="md-title">Done reservations</div>
            </md-card-header>
            <md-card-content>
              <md-table >
                <md-table-row>
                    <md-table-head>Date</md-table-head>
                    <md-table-head>Passenger</md-table-head>
                    <md-table-head>PU</md-table-head>
                    <md-table-head>DO</md-table-head>
                </md-table-row>

                <md-table-row v-for="(reservation) in doneReservations" v-bind:key="reservation.id">
                  <md-table-cell>{{ reservation.day | moment("YYYY-MM-DD") }}</md-table-cell>
                  <md-table-cell>{{ reservation.passenger.name}} {{ reservation.passenger.lastName}}</md-table-cell>
                  <md-table-cell>{{ reservation.pu.formatted_address}}</md-table-cell>
                  <md-table-cell>{{reservation.do.formatted_address}}</md-table-cell>
                </md-table-row>
              </md-table>
              <md-progress-bar md-mode="indeterminate" v-if="doneReservationsBusy"></md-progress-bar>
            </md-card-content>
          </md-card>
        </CCol>
        <CCol sm="12" md="6" class="mb-3">
          <md-card>
            <md-card-header>
                <div class="md-title">Recently linked users</div>
            </md-card-header>
            <md-card-content>
              <md-table >
                <md-table-row>
                    <md-table-head>Role</md-table-head>
                    <md-table-head>Phone</md-table-head>
                    <md-table-head>Email</md-table-head>
                </md-table-row>

                <md-table-row v-for="(user) in recentlyLinkedUsers" v-bind:key="user.id">
                  <md-table-cell>{{ user.role }}</md-table-cell>
                  <md-table-cell>
                    <ul class="table-inner-list">
                      <li v-for="(phone,index) in user.phones" v-bind:key="phone + index + '-recently-added-phones'">
                          {{ phone }}
                      </li>                      
                    </ul>
                  </md-table-cell>
                  <md-table-cell>{{ user.email }}</md-table-cell>
                </md-table-row>
              </md-table>
              <md-progress-bar md-mode="indeterminate" v-if="recentlyLinkedUsersBusy"></md-progress-bar>
            </md-card-content>
          </md-card>
        </CCol>
      </CRow>

    </div>
      
  </div>
</template>

<script>

import firebase from '../Firebase'
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { mapGetters } from "vuex";
import { CChartBar } from '@coreui/vue-chartjs'
import { CChartLine } from '@coreui/vue-chartjs'

import axios from "./../helpers/axios";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CChartBar,
    CChartLine
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    user() {
      return this.$store.state.user;
    },
    defaultChartDatasets () {
      return [
        {
          label: 'GitHub Commits',
          backgroundColor: '#f87979',
          data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]
    },
    defaultDatasets () {
      return [
        {
          label: 'Data One',
          backgroundColor: 'rgb(228,102,81,0.9)',
          data: [30, 39, 10, 50, 30, 70, 35]
        },
        {
          label: 'Data Two',
          backgroundColor: 'rgb(0,216,255,0.9)',
          data: [39, 80, 40, 35, 40, 20, 45]
        }
      ]
    }
  },
  created() {

    //this.updateClock();
    this.obtainViewResources()
  },
  data() {
    return {
      doneReservations: [],
      upcomingReservations: [],
      recentlyLinkedUsers: [],
      selected: "Month",
      tableItems: [
        {
          avatar: { url: "img/avatars/1.jpg", status: "success" },
          user: {
            name: "Yiorgos Avraamu",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "USA", flag: "cif-us" },
          usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
          activity: "10 sec ago",
        },
        {
          avatar: { url: "img/avatars/2.jpg", status: "danger" },
          user: {
            name: "Avram Tarasios",
            new: false,
            registered: "Jan 1, 2015",
          },
          country: { name: "Brazil", flag: "cif-br" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Visa", icon: "cib-cc-visa" },
          activity: "5 minutes ago",
        },
        {
          avatar: { url: "img/avatars/3.jpg", status: "warning" },
          user: { name: "Quintin Ed", new: true, registered: "Jan 1, 2015" },
          country: { name: "India", flag: "cif-in" },
          usage: { value: 74, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Stripe", icon: "cib-stripe" },
          activity: "1 hour ago",
        },
        {
          avatar: { url: "img/avatars/4.jpg", status: "" },
          user: { name: "Enéas Kwadwo", new: true, registered: "Jan 1, 2015" },
          country: { name: "France", flag: "cif-fr" },
          usage: { value: 98, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "PayPal", icon: "cib-paypal" },
          activity: "Last month",
        },
        {
          avatar: { url: "img/avatars/5.jpg", status: "success" },
          user: {
            name: "Agapetus Tadeáš",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Spain", flag: "cif-es" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Google Wallet", icon: "cib-google-pay" },
          activity: "Last week",
        },
        {
          avatar: { url: "img/avatars/6.jpg", status: "danger" },
          user: {
            name: "Friderik Dávid",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Poland", flag: "cif-pl" },
          usage: { value: 43, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Amex", icon: "cib-cc-amex" },
          activity: "Last week",
        },
      ],
      tableFields: [
        { key: "avatar", label: "", _classes: "text-center" },
        { key: "user" },
        { key: "country", _classes: "text-center" },
        { key: "usage" },
        { key: "payment", label: "Payment method", _classes: "text-center" },
        { key: "activity" },
      ],
      clocks: [
        {
          city: "New York",
          hour: "--",
          hourDeg: "0deg",
          mins: "--",
          minsDeg: "0deg",
          secs: "--",
          secsDeg: "0deg",
        },
        {
          city: "London",
          hour: "--",
          hourDeg: "0deg",
          mins: "--",
          minsDeg: "0deg",
          secs: "--",
          secsDeg: "0deg",
        },
        {
          city: "Hong Kong",
          hour: "--",
          hourDeg: "0deg",
          mins: "--",
          minsDeg: "0deg",
          secs: "--",
          secsDeg: "0deg",
        },
      ],
      hour: "--",
      hourDeg: "0deg",
      mins: "--",
      minsDeg: "0deg",
      secs: "--",
      secsDeg: "0deg",
      doneReservationsBusy: true,
      upcomingReservationsBusy: true,
      recentlyLinkedUsersBusy: true

    };
  },
  methods: {
    getReservationsPath (user) {
        switch(user.data.role){
            case 'company-admin':
            return firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations')
            break;
            case 'driver':
            return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('reservations')
            break;
            // case 'dispatcher':
            // break;
            // case 'affiliate':
            // break;
            default:
              return firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations')
        }
    },
    getLinkedUsersPath (user) {
        switch(user.data.role){
            case 'company-admin':
            return firebase.firestore().collection('users').doc(this.user.data.uid).collection('linked-users')
            break;
            case 'driver':
            return firebase.firestore().collection('drivers').doc(this.user.data.email).collection('linked-users')
            break;
            // case 'dispatcher':
            // break;
            // case 'affiliate':
            // break;
            default:
              return firebase.firestore().collection('users').doc(this.user.data.uid).collection('reservations')
        }
    },
    async obtainViewResources(){
        this.isBusy = true
        await this.getDoneReservations()
        await this.getUpcomingReservations()
        await this.getRecentyAddedUsers()
        this.isBusy = false
    },
    async getDoneReservations() {
      let th = this;

      return new Promise((resolve, reject) => {
        th.doneReservations = []
        let collection = th.getReservationsPath(th.user)
        // Trip status dropped off
        collection.where('tripStatus', '==', 'EjzXPazPlo7b2WO1LvdH').get()
        .then((snapShot) => {
              snapShot.forEach((doc) => {
                   let dataToSave = doc.data()
                   dataToSave.id = doc.id
                   th.doneReservations.push(dataToSave)
                  //doc.ref.update({readed: true})
              });

              th.doneReservationsBusy = false
              resolve()
              
          }).catch((error) => {
            console.log(error.message)
            th.doneReservationsBusy = false

            reject()
          });
      })
    },
    async getUpcomingReservations() {
      let th = this;
      let datatime = parseInt(moment(new Date()).format('X'))
      return new Promise((resolve, reject) => {
        th.upcomingReservations = []
        let collection = th.getReservationsPath(th.user)
        collection.where('day', '>=', datatime).get()
        .then((snapShot) => {
              snapShot.forEach((doc) => {
                   let dataToSave = doc.data()
                   dataToSave.id = doc.id
                   th.upcomingReservations.push(dataToSave)
              });
              th.upcomingReservationsBusy = false
              resolve()
              
          }).catch((error) => {
            console.log(error.message)
            th.upcomingReservationsBusy = false
            reject()
          });
      })
    },
    async getRecentyAddedUsers() {
      let th = this;
      return new Promise((resolve, reject) => {
        th.recentlyLinkedUsers = []
        let collection = th.getLinkedUsersPath(th.user)
        collection.limit(5).get()
        .then((snapShot) => {
              snapShot.forEach((doc) => {
                let dataToSave = doc.data()
                dataToSave.id = doc.id
                th.recentlyLinkedUsers.push(dataToSave)
              });

              th.recentlyLinkedUsersBusy = false
              resolve()
              
          }).catch((error) => {
            th.recentlyLinkedUsersBusy = false

            console.log(error.message)
            reject()
          });
      })
    },
    getUserData() {
      console.log(this.user);

    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    updateClock() {
      var ctx = this;
      setInterval(function() {
        for (let i = 0; i < ctx.clocks.length; i++) {
          switch (ctx.clocks[i].city) {
            case "New York":
              var date = moment(new Date()).tz("America/New_York");
              break;
            case "London":
              var date = moment(new Date()).tz("Europe/London");
              break;
            case "Hong Kong":
              var date = moment(new Date()).tz("Asia/Hong_Kong");
              break;
          }

          var hour = date.hours(),
            min = date.minutes(),
            sec = date.seconds();
          ctx.clocks[i].hour = hour < 10 ? "0" + hour : hour;
          ctx.clocks[i].mins = min < 10 ? "0" + min : min;
          ctx.clocks[i].secs = sec < 10 ? "0" + sec : sec;
          var analogHour = hour > 12 ? hour - 12 : hour,
            analogMins = min,
            analogSecs = sec;
          ctx.clocks[i].hourDeg = analogHour * 30 + "deg";
          ctx.clocks[i].minsDeg = (analogMins / 60) * 360 + "deg";
          ctx.clocks[i].secsDeg = (analogSecs / 60) * 360 + "deg";
        }
      }, 1000);
      /*
      setInterval(function(){
        var newYork = moment(new Date()).tz('America/New_York'),
            nyHour = newYork.format('HH'),
            nyMin = newYork.format('mm'),
            nySec = newYork.format('ss');

        // console.log(nyHour);
        // console.log(nyMin);
        // console.log(nySec);
        var date = new Date(),  

            hour = date.getHours(),
            min = date.getMinutes(),
            sec = date.getSeconds();
       
        // Set Display
        ctx.hour = (hour < 10) ? '0' + hour : hour;
        ctx.mins = (min < 10) ? '0' + min : min;
        ctx.secs = (sec < 10) ? '0' + sec : sec;
        
        // Set Analog
        var analogHour = (hour > 12) ? hour - 12 : hour,
            analogMins = min,
            analogSecs = sec;
        
        ctx.hourDeg = (analogHour * 30) + 'deg';
        ctx.minsDeg = (analogMins / 60 * 360) + 'deg';
        ctx.secsDeg = (analogSecs / 60 * 360) + 'deg';
      }, 1000);  
      */
    },
  },
};
</script>
