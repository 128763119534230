<template>
  <div v-if="!isBusy && vehicleType">
    <md-toolbar class="md-primary mb-2" md-elevation="1" >
      <md-avatar >
        <img :src="vehicleType.pictures[0]" alt="Avatar">
      </md-avatar>
        <h3 class="md-title" style="flex: 1">{{vehicleType.type}} rates</h3>
       
        <md-button class="md-primary md-raised" @click="update">Update rates</md-button>
    </md-toolbar>
    <md-tabs class="md-transparent"  md-alignment="left">

        <md-tab id="distance" md-label="Distance">
          <md-toolbar class="md-dense">
            <h3 class="md-title" style="flex: 1">Distance rates</h3>
            <!--md-button>Refresh</md-button-->
            <md-button @click="addRateRow" class="md-primary">Add row</md-button>
            <!--md-button @click="update" :disabled="vehicleType.rates.distance.length > 0 ? false : true" class="md-primary md-raised md-accent">Update rates</md-button-->
          </md-toolbar>
          <transition name="fade" mode="out-in">
            <CRow class="mt-3 mb-3">
              <CCol sm="6">
                <md-card>
                  <md-card-content>
                    <md-field>
                      <label>Minium rate</label>
                      <span class="md-prefix">$</span>
                      <md-input type="number" v-model="vehicleType.rates.miniumRate"></md-input>
                    </md-field>
                  </md-card-content>

                </md-card>
              </CCol>
              <CCol sm="6">
                <md-card>
                  <md-card-content>
                    <CRow>
                        <CCol sm="6">
                          <md-field>
                            <label>Base rate</label>
                            <span class="md-prefix">$</span>
                            <md-input type="number" v-model="vehicleType.rates.base.price"></md-input>
                          </md-field>
                        </CCol>
                        <CCol sm="6">
                          <md-field>
                            <label>Included miles</label>
                            <md-input type="number" v-model="vehicleType.rates.base.includedMiles"></md-input>
                            <span class="md-suffix">miles</span>
                          </md-field>
                        </CCol>
                    </CRow>
                  </md-card-content>

                </md-card>
              </CCol>
            </CRow>
          </transition>
          <transition  v-if="vehicleType.rates.distance.length > 0" name="fade" mode="out-in">
            <md-table>
              <md-table-row>
                <md-table-head>From mile #</md-table-head>
                <md-table-head>Till (miles)</md-table-head>
                <md-table-head>Rate</md-table-head>
                
                <md-table-head>Remaining</md-table-head>
                <md-table-head>Actions</md-table-head>
              </md-table-row>
              <!--md-table-row>
                <md-table-cell>
                  <md-field>
                    <span class="md-prefix">$</span>
                    <md-input type="number" v-model="vehicleType.rates.base.price"></md-input>
                  </md-field>
                </md-table-cell>
                <md-table-cell>
                  <md-field>
                    <md-input type="number" v-model="vehicleType.rates.base.includedMiles"></md-input>
                  </md-field>
                </md-table-cell>
                <md-table-cell>
                  Base price
                </md-table-cell>
                <md-table-cell>
                  
                </md-table-cell>
              </md-table-row-->

             
                <md-table-row v-for="(rate, index) in vehicleType.rates.distance" v-bind:key="index" >
                   <md-table-cell>
                     <md-field v-if="index === 0">
                      <md-input type="number" :value="parseFloat(vehicleType.rates.base.includedMiles) + 1" readonly></md-input>
                      <span class="md-suffix">miles</span>
                    </md-field>
                    <md-field v-else>
                      <md-input type="number" :value="parseFloat(vehicleType.rates.distance[index - 1].till) + 1" readonly></md-input>
                      <span class="md-suffix">miles</span>
                    </md-field>

                  </md-table-cell>

                   <md-table-cell>
                    <md-field v-if="!rate.remaining">
                      <md-input type="number" v-model="rate.till"></md-input>
                      <span class="md-suffix">miles</span>
                    </md-field>
                    <div v-else>
                      <div v-if="index > 0">
                        Trips greater than <b>{{vehicleType.rates.distance[index - 1].till}}</b> miles will charge <b>{{rate.rate}}</b> per mile
                      </div>
                      <div v-else>

                      </div>
                    </div>
                  </md-table-cell>
                  <md-table-cell>
                    <md-field>
                      <span class="md-prefix">$</span>
                      <md-input type="number" v-model="rate.rate"></md-input>
                    </md-field>
                  </md-table-cell>
                 
                  <md-table-cell>
                    <md-switch @change="remainingChanged(rate)" v-model="rate.remaining"></md-switch>
                  </md-table-cell>
                  <md-table-cell>
                    <md-button class="md-icon-button" @click="removeRow(index)">
                      <md-icon>delete</md-icon>
                    </md-button>
                    
                  </md-table-cell>
                </md-table-row>
              

            </md-table>
          </transition>
           <transition v-else name="fade" mode="out-in">
            <md-empty-state 
              md-icon="request_quote"
              md-label="Create your first rate"
              md-description="This rates will apply to the to fare requests that users will ask through your website. You can modify these rates at any time">
              <md-button class="md-primary md-raised" @click="addRateRow">Create first rate</md-button>
            </md-empty-state>
           </transition>
            
        </md-tab>
        <md-tab id="hourly" md-label="Hourly">
          <md-toolbar class="md-dense">
            <h3 class="md-title" style="flex: 1">Hourly rates</h3>
            <md-switch v-model="vehicleType.rates.hourlyEnabled"></md-switch>
          </md-toolbar>
          <md-toolbar class="md-dense mt-4" md-elevation="0" v-if="!vehicleType.rates.hourlyEnabled">
            <p class="mb-0"><md-icon class="mr-2 md-primary">announcement</md-icon>Power on switch to use hourly services</p>
          </md-toolbar>
          <md-toolbar class="md-dense mt-4" md-elevation="0" v-else>
            <p class="mb-0"><md-icon class="mr-2 md-primary">announcement</md-icon>Fill rates for hours range list</p>
          </md-toolbar>

          <transition  v-if="vehicleType.rates.hourlyEnabled" name="fade" >
            <md-card >

              <md-card-content>
                <CRow>
                  <CCol md="3" v-for="(hourly, index) in vehicleType.rates.hourly" v-bind:key="hourly.upto">
                    <md-field md-dense>
                          <label v-if="index == 0">Up to {{hourly.upto}} hour</label>
                          <label v-else>From hour {{vehicleType.rates.hourly[index - 1].upto}} to {{hourly.upto}}</label>
                          <span class="md-prefix">$</span>
                          <md-input type="number" v-model="hourly.price"></md-input>
                          <span v-if="index == 0" class="md-helper-text">Up to {{hourly.minutes}} minutes rate</span>
                          <span v-else class="md-helper-text">From minute {{vehicleType.rates.hourly[index - 1].minutes}} to {{hourly.minutes}} rate</span>
                      </md-field>
                  </CCol>
                </CRow>
                
                
              </md-card-content>
            </md-card>
          </transition>
        </md-tab>
        <md-tab id="flat-rate" md-label="Flat rate">
            <md-toolbar class="md-dense">
              <h3 class="md-title" style="flex: 1">Flat rates</h3>
              
              <!--md-button>Refresh</md-button-->
              <md-button @click="addFlatRateRow" class="md-primary">Add flat rate</md-button>
              <!--md-button @click="update" :disabled="vehicleType.rates.flat && vehicleType.rates.flat.length > 0 ? false : true" class="md-primary md-raised md-accent">Update rates</md-button-->
            </md-toolbar>
            <md-toolbar class="md-dense mt-4" md-elevation="0">
              <p class="mb-0"><md-icon class="mr-2 md-primary">announcement</md-icon>These rates <b>DO NOT INCLUDE</b> addons, extras and taxes</p>
            </md-toolbar>
            <transition  v-if="vehicleType.rates.flat.length > 0" name="fade" mode="out-in">
              <md-table>
              <md-table-row>
                <md-table-head>Code</md-table-head>
                <md-table-head>Name</md-table-head>
                <md-table-head>PU</md-table-head>
                <md-table-head>DO</md-table-head>
                <md-table-head>Price</md-table-head>
                <md-table-head>Actions</md-table-head>
              </md-table-row>
              

             
                <md-table-row v-for="(rate, index) in vehicleType.rates.flat" v-bind:key="index" >
                  <md-table-cell>
                    <md-field>
                      <md-input v-model="rate.code"></md-input>
                    </md-field>
                  </md-table-cell>
                  <md-table-cell>
                    <md-field>
                      <md-input v-model="rate.name"></md-input>
                    </md-field>
                    
                  </md-table-cell>

                  <md-table-cell>
                      <md-field>
                        <label :for="'pu-' + index">PU</label>
                        <md-select v-model="rate.pu" :name="'pu-' + index" :id="'pu-' + index">
                          <md-option v-for="geofence in geofences" :value="geofence.id" v-bind:key="geofence.id">{{geofence.code}} | {{geofence.name}}</md-option>
                        </md-select>
                      </md-field>
                  </md-table-cell>

                  <md-table-cell>
                    <md-field>
                        <label :for="'pu-' + index">DO</label>
                        <md-select v-model="rate.do" :name="'pu-' + index" :id="'pu-' + index">
                          <md-option v-for="geofence in geofences" :value="geofence.id" v-bind:key="geofence.id">{{geofence.code}} | {{geofence.name}}</md-option>
                        </md-select>
                      </md-field>
                  </md-table-cell>
                  <md-table-cell>
                    <md-field>
                      <md-input type="number" v-model="rate.price"></md-input>
                    </md-field>
                  </md-table-cell>
                  
                  <md-table-cell>
                    <md-button class="md-icon-button" @click="removeRow(index)">
                      <md-icon>delete</md-icon>
                    </md-button>
                    
                  </md-table-cell>
                </md-table-row>
              

            </md-table>
            </transition>
            <transition v-else name="fade" mode="out-in">
              <md-empty-state 
                md-icon="request_quote"
                md-label="Create your first flat rate"
                md-description="This rates will apply to the to fare requests that users will ask through your website. You can modify these rates at any time">
                <md-button class="md-primary md-raised" @click="addFlatRateRow">Create first flat rate</md-button>
              </md-empty-state>
            </transition>
        </md-tab>
        <md-tab id="addons" md-label="Add ons">
             <md-toolbar class="md-dense">
              <h3 class="md-title" style="flex: 1">Addons rates</h3>
            </md-toolbar>
            <md-table>
              <md-table-row>
                <md-table-head>Name</md-table-head>
                <md-table-head>Enabled</md-table-head>
                <md-table-head>Price</md-table-head>
              </md-table-row>
              <md-table-row v-for="addon in vehicleType.rates.addons" v-bind:key="addon.id">
                <md-table-cell>{{addon.name}}</md-table-cell>
                <md-table-cell>
                  <md-switch v-model="addon.enabled"></md-switch>
                </md-table-cell>
                <md-table-cell>
                  <md-field :class="{'md-invalid': !addon.price && addon.enabled }">
                    
                    <span class="md-prefix">$</span>
                    <md-input type="number" v-model="addon.price" :required="addon.enabled" :disabled="!addon.enabled"></md-input>
                    <span class="md-error">If addon is enabled price is required</span>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
        </md-tab>
        <md-tab id="extras" md-label="Extras">
          <md-toolbar class="md-dense mb-3">
            <h3 class="md-title" style="flex: 1">Extras</h3>
          </md-toolbar>
          <md-card class="uplimo-card">
              <md-card-header>
                <div class="md-title">Stops</div>
              </md-card-header>

              <md-card-content>
                <CRow>
                  <CCol sm="6">
                    <md-field>
                      <label>Per hour waiting time rate</label>
                      <span class="md-prefix">$</span>
                      <md-input v-model="vehicleType.rates.waitingTime" type="number"></md-input>
                    </md-field>
                  </CCol>
                  <CCol sm="6">
                    <md-field>
                      <label>Per stop fixed rate</label>
                      <span class="md-prefix">$</span>
                      <md-input v-model="vehicleType.rates.stop" type="number"></md-input>
                    </md-field>
                  </CCol>
                </CRow>
                <md-table>
                  <md-table-row>
                    <md-table-head></md-table-head>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Price</md-table-head>
                    <md-table-head>Enabled</md-table-head>
                    
                  </md-table-row>
                  <md-table-row>
                    <md-table-cell>
                      <md-icon  class="special-icon " v-bind:class="{ 'active-icon': vehicleType.rates.extras.childSeat.enabled }">child_friendly</md-icon> 
                    </md-table-cell>


                    <md-table-cell>
                      <span class="md-list-item-text">Childseat</span>
                    </md-table-cell>
                    
                    <md-table-cell>
                      <md-field>
                        <md-input type="number" v-model="vehicleType.rates.extras.childSeat.price"></md-input>
                      </md-field>
                    </md-table-cell>

                    <md-table-cell>
                      
                      <md-switch v-model="vehicleType.rates.extras.childSeat.enabled"></md-switch>
                    </md-table-cell>
                  </md-table-row>

                  <md-table-row>
                    <md-table-cell>
                      <md-icon class="special-icon " v-bind:class="{ 'active-icon': vehicleType.rates.extras.accessible.enabled }">accessible</md-icon> 
                    </md-table-cell>


                    <md-table-cell>
                      <span class="md-list-item-text">Accessible</span>
                    </md-table-cell>

                    <md-table-cell>
                      <md-field>
                        <md-input type="number" v-model="vehicleType.rates.extras.accessible.price"></md-input>
                      </md-field>
                    </md-table-cell>

                    <md-table-cell>
                      <md-switch v-model="vehicleType.rates.extras.accessible.enabled"></md-switch>
                    </md-table-cell>
                  </md-table-row>

                  <md-table-row>
                    <md-table-cell>
                      <md-icon class="special-icon " v-bind:class="{ 'active-icon': vehicleType.rates.extras.meetAndGreet.enabled }">face</md-icon> 
                    </md-table-cell>

                    <md-table-cell>
                      <span class="md-list-item-text">Meet and greet</span>
                    </md-table-cell>

                    <md-table-cell>
                      <md-field>
                        <md-input type="number" v-model="vehicleType.rates.extras.meetAndGreet.price"></md-input>
                      </md-field>
                    </md-table-cell>

                    <md-table-cell>
                      <md-switch v-model="vehicleType.rates.extras.meetAndGreet.enabled"></md-switch>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                
                
              </md-card-content>
          </md-card>
        </md-tab>

       
    </md-tabs>
     
     <!--md-button class="md-fab md-primary" @click="update">
          <md-icon>save</md-icon>
      </md-button-->
      <!--div class="calculator-container md-elevation-6">
        <div class="title-spec"><md-icon class="md-size-1x mr-2">calculate</md-icon>Rate tester</div>
        <CRow>
          <CCol sm="6">
               <md-field>
                  <label>Miles</label>
                  <md-input type="number" v-model="feeCalculatorData.miles"></md-input>
                </md-field>
          </CCol>
           <CCol sm="6">
              <FeeCalculator :jobType="feeCalculatorData.jobType" :vehicleType="vehicleType" :calculatedDistance="feeCalculatorData.miles"/> 
          </CCol>
        </CRow>
       
        
      </div-->
       
  </div>
</template>

<script>
import firebase from '../../Firebase'
import FeeCalculator from './../reservations/FeeCalculator.vue';
export default {
  name: 'Rate',
  components: {
    FeeCalculator
  },
  data() {
    return {
        addons: [],
        feeCalculatorData: {
            miles: null,
            jobType: {
                name: 'One way',
                type: 'Distance'
            },
        },
        drawerVisible: true,
        vehicleType: null,
        isBusy: true,
        geofences: [],
        
        // rates: {
        //     base: {
        //         price: 65,
        //         includedMiles: 10
        //     },
        //     distance: [
        //         { rate: 2 , till: 100},
        //         { rate: 1.90 , till: 200},
        //         { rate: 1.80 , till: 500},
        //         { rate: 1.70 , till: null}
        //     ],
        //     time: 0.5,
        //     traffic: 0.5,
        //     waitingTime: 0.75
        // }
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('service-types')
    }
  },
  watch:{
    'feeCalculatorData.miles': {
      handler(val,oldVal){
        this.feeCalculatorData.miles = parseFloat(val)
        console.log(val);
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

      
  },
  
  destroyed(){
    
  },
  // mounted() {
  //   console.log('Mounted');
  //   //this.$route.meta.label = 'Bla' + new Date()
    
  // },
 
  mounted () {
    let th = this;

    this.getVehicleType();

    firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences').onSnapshot((querySnapshot) => {
      console.log('Snap shot')
      this.geofences = [];
      querySnapshot.forEach((doc) => {

        let dataToSave = doc.data();
        dataToSave.id = doc.id ;
        this.geofences.push(dataToSave);

      });
    });

    

  },
  methods: {
    async getVehicleType(){
      let th = this;
      await this.getCompanyAddons();
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log('This addons',th.addons);
            console.log("Document data:", doc.data());
            th.vehicleType = doc.data();
            if(!th.vehicleType.rates.addons) {
              console.log('Add ons do not exists inside vehicle type rates');
              let addons = []
              for (let i = 0; i < th.addons.length; i++) {
                let addon = th.addons[i];
                addon.enabled = false;
                addon.price = null;

                addons.push(addon);
              }
              th.vehicleType.rates.addons = addons
            } else {
              console.log('Add ons Exists inside vehicle type rates');
              for (let j = 0; j < th.addons.length; j++) {
                  //th.addons[j].id
                  let exists = false;
                  for (let i = 0; i < th.vehicleType.rates.addons.length; i++) {
                    if(th.addons[j].id == th.vehicleType.rates.addons[i].id) {
                      exists = true;
                    }
                    
                  }

                  if(!exists) {
                    let addon = th.addons[j];
                    addon.enabled = false;
                    addon.price = null;
                    th.vehicleType.rates.addons.push(addon)
                  }
                  
              }
              
            }
            th.update()

            
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    async getCompanyAddons(){
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').orderBy("order").onSnapshot((querySnapshot) => {
          this.addons = [];
          querySnapshot.forEach((doc) => {

              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              this.addons.push(dataToSave);

            });
            console.log(this.addons);
            this.isBusy = false
            resolve();
          });
          //reject(error);
          //
        });
    },
    addRateRow() {
      this.vehicleType.rates.distance.push(
        { rate: null, till:null }
      );
    },
    addFlatRateRow() {
      if(this.vehicleType.rates.flat){
        this.vehicleType.rates.flat.push(
          { code: null, name:null, pu: null, do:null }
        );
      } else {
        this.vehicleType.rates.flat = [];
        this.vehicleType.rates.flat.push(
          { code: null, name:null, pu: null, do:null }
        );
      }
    },
    update() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types').doc(this.$route.params.id).update(th.vehicleType)
      .then(function(){
        console.log('Updated');
        //th.$router.push({path: `/vehicle-types`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    removeRow(index) {
      this.vehicleType.rates.distance.splice(index,1)
    },
    remainingChanged(rate) {
      console.log(rate)
      // if(rate.remaining) {
      //   for (let i = 0; i < this.vehicleType.rates.distance.length; i++) {
      //     this.vehicleType.rates.distance[i].remaining = false;
      //   }
      // }

      // rate.remaining = true
    }
  }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

<style>
  .md-avatar {
    width: auto!important;
  }
</style>

