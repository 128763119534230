<template>
  <CHeader fixed with-subheader light ref="mainHeader">
    <CSubheader class="px-3 super-clock">
      
        <a class="d-lg-none uplimo-nav"
            @click="$store.commit('toggleSidebarMobile')">
        <md-icon
            
            >menu
        </md-icon>
        </a>
        <a class="d-md-down-none uplimo-nav"
            @click="$store.commit('toggleSidebarDesktop')">
        <md-icon
            
            >menu
        </md-icon>
        </a>
        <!--CToggler
          in-header
          class="ml-3 d-lg-none"
          @click="$store.commit('toggleSidebarMobile')"
        />
        <CToggler
          in-header
          class="ml-3 d-md-down-none"
          @click="$store.commit('toggleSidebarDesktop')"
        /-->
      <Breadcrumbs/>
      <!--CBreadcrumbRouter class="border-0 mb-0"/-->
       <!--div class="date">{{ date }}</div>
       <div class="time">{{ time }} {{ timezone }} {{ utc }}</div-->


       <div class="clock-holder">
          <div class="md-layout md-alignment-center-right">
            <div class="md-layout-item"> 
              <span class="up-chip  up-text-confirmed mb-1">TZ {{ timezone }}</span>
              <span class="up-chip up-text-secondary">UTC {{ parseInt(utc) > 0 ? '+' : ''}}{{ parseInt(utc) / 60 }}</span>
            </div>
            <div class="md-layout-item ml-2 mr-2">
               <div class="white-space-no-wrap">              
                  {{ unix | moment('timezone', timezone , 'dddd, MMM Do YYYY') }}           
               </div>
               <div class="white-space-no-wrap"> 
                   {{ unix | moment('timezone', timezone , 'hh:mm A (HH:mm [HS])') }}
                </div>
            </div>
            
           
          </div>
        
         
       </div>
       
       <TheHeaderDropdownAccnt/>
       
    </CSubheader>
  </CHeader>
</template>

<script>

import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import moment from 'moment'
import moment_timezone from "moment-timezone";

export default {
  name: 'TheHeader',
  data(){
    return {
      unix: null,
      week: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
      timerId: null,
      localTimeZone: null,
      //timeZone: null,
    }
    
  },
  mounted() {
    let height = this.$refs.mainHeader.$el.offsetHeight
    this.$store.commit('set', ['headerHeight', height])
  },
  created(){
    var zone_name =  moment.tz.guess();
    console.log('zone_name TheHeader.vue')
    console.log(zone_name)
    this.localTimeZone = zone_name 
    this.updateTime()
    setInterval(() => {
      this.updateTime()
    }, 1000)
  },
  components: {
    TheHeaderDropdownAccnt
  },
  methods: {
    updateTime() {
      var cd = new Date();
      this.unix = parseInt(moment().format('x'))
      
      //this.time = moment().format('hh:mm A (HH:mm [HS])')
      //this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
      //this.date = this.week[cd.getDay()] + ' ' + this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getDate(), 2) ;
      //console.log(this.time);
    },
    zeroPadding(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    utc() {
      return this.$store.state.utc;
    },
    date() {
      return this.$store.state.date;
    },
    time() {
      return this.$store.state.time;
    },
    headerHeight() {
      return this.$store.state.headerHeight;
    },
  }
}
</script>
