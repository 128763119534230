<template>
    <div>
        <md-toolbar class="md-primary mb-2" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="addon.name">{{addon.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New add on</h3>
           
            <md-button class="md-primary md-raised" @click="add">Save add on</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Add on info</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('name')">
                                        <label>Name</label>
                                        <md-input v-model="addon.name"></md-input>
                                        <span class="md-error" v-if="!$v.addon.name.required">Name is required</span>
                                    </md-field>
                                </CCol>
                            </CRow>
                        </md-card-content>
                    
                    </md-card>
                </CCol>
            </CRow>
        </div>
       
        
    </div>
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'

export default {
    name: 'NewAddon',
    mixins: [validationMixin],
    data() {
        return {
           
            addon: {
                name:null,
                order:null
            },
            //addonRef:  firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons'),
            
           
        }
    },
    
    methods: {
        // add(){
            
            
        //     if(this.validate()) {
        //         // Save vehicle
        //         this.saveVehicleType()

        //     } else {
        //         this.$v.vehicleType.type.$touch()
        //         this.$v.vehicleType.passengers.$touch()
        //         this.$v.vehicleType.luggage.$touch()
        //         this.$v.vehicleType.base.$touch()
        //         this.$v.files.$touch()
        //     }
            
            
        // },
        add(){
            if(this.validate()) {
                let th = this;
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').add(th.addon)
                .then(function(docRef) {
                    console.log("Tutorial created with ID: ", docRef.id);
                    th.$router.push({path: `/add-ons`})
                })
                .catch(function(error) {
                    console.error("Error adding Tutorial: ", error);
                });
            } else {
                this.$v.addon.name.$touch()
                
            }

        },
        validate(){
            let name;
            name = this.$v.addon.name.$invalid;
           
            return !name ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.addon[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
       
       
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    created () {
        
    },
    validations: {
       
        addon: {
            
            name: {
                required
            },
            
        }
    }
}
</script>  