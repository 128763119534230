<template>
    <div>
       <md-toolbar class="md-primary mb-2" md-elevation="1">
          <h3 class="md-title" style="flex: 1">Company Drivers</h3>
          
          <md-button class="md-primary md-raised" @click="newUser">New company driver</md-button>
          
      </md-toolbar>
      <md-progress-bar md-mode="query" v-if="isBusy"></md-progress-bar> 
      <div v-else>
  
        <md-empty-state
          v-if="users.length == 0"
          md-icon="person_add"
          md-label="Add your first driver"
          md-description="Adding a driver will enable it when new reservation is created">
          <md-button class="md-primary md-raised" @click="newUser">Add first driver</md-button>
        </md-empty-state>
        <md-table v-else>
          <md-table-row>
            
            <md-table-head>Display Name</md-table-head>
            <md-table-head>Email</md-table-head>
            <md-table-head>Role</md-table-head>
            
          </md-table-row>
  
          <md-table-row v-for="user in users" v-bind:key="user.uid" @click="rowClicked(user)">
            
            <md-table-cell>{{user.auth.displayName}}</md-table-cell>
            <md-table-cell>{{user.auth.email}}</md-table-cell>
            <md-table-cell>{{user.auth.customClaims.role}}</md-table-cell>
            
          </md-table-row>
        </md-table>
        
       
      </div>
    </div>
  </template>
  
  <script>
  
  import axios from "./../../helpers/axios"
  
  
  export default {
    name: 'CompanyDrivers',
    data () {
      return {
        users: [],
        isBusy: true
        
      }
    },
    
    created() {
      //this.getUsers();
      this.getUsers();
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
        
    },
    methods: {
       rowClicked (item, index) {
        console.log(item);
        this.$router.push({path: `/drivers/company-drivers/${item.auth.email}`})
      },
    
      newUser() {
        this.$router.push({path: `/drivers/company-drivers/new`})
      },
      getBadge (status) {
        switch (status) {
          case 'Active': return 'success'
          case 'Inactive': return 'secondary'
          case 'Pending': return 'warning'
          case 'Banned': return 'danger'
          default: 'primary'
        }
      },
      
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      },
      getToken() {
  
      },
      getUsers(){
        let th = this ;
        console.log(th.user);
        let data = {  
              ownerEmail: this.user.data.email,
              roleToGet: 'driver' ,
              ownerUid: this.user.data.uid
        }
        
          
        axios.get('drivers', { params: data }).then(response =>{
          th.users = response.data.users ;
          th.isBusy = false;
        }).catch(e => {
          console.log(e);
            th.isBusy = false;
        })
       
      }
    }
  }
  </script>