<template>
  <div v-if="!isBusy">
     <CRow>
      <CCol sm="6">
        <md-field>
          <label>Day</label>
          <md-input v-model="offer.day"></md-input>
        </md-field>
        <md-field>
          <label>DO</label>
          <md-input v-model="offer.do"></md-input>
        </md-field>
        <md-field>
          <label>PU</label>
          <md-input v-model="offer.pu"></md-input>
        </md-field>
        <md-field>
          <label>Miles</label>
          <md-input v-model="offer.miles"></md-input>
        </md-field>
        <md-field>
          <label>Price</label>
          <md-input v-model="offer.price"></md-input>
        </md-field>
        <md-field>
          <label>Time</label>
          <md-input v-model="offer.time"></md-input>
        </md-field>
        <md-field>
          <label>Vehicle type</label>
          <md-input v-model="offer.vehicleType"></md-input>
        </md-field>
      </CCol>
      <CCol sm="6">
          <div>MAP</div>
      </CCol>
     </CRow>
     <md-button class="md-accent" @click="destroy">Delete</md-button>
     <md-button class="md-fab md-primary" @click="update">
          <md-icon>save</md-icon>
      </md-button>
  </div>
</template>

<script>
import firebase from '../../Firebase'
export default {
  name: 'Offer',
  data() {
    return {
      
      offer: null,
      isBusy: true,
      ref: firebase.firestore().collection('offers')
    }
  },
  created () {
    let th = this;
    this.ref.doc(this.$route.params.id).get()
      .then(function(doc) {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            th.offer = doc.data();
            th.isBusy = false
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  },
  methods: {
    update() {
      let th = this;
      this.ref.doc(this.$route.params.id).update(th.offer)
      .then(function(){
        console.log('Updated');
        th.$router.push({path: `/offers`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      this.ref.doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/offers`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    }
  }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

