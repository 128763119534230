import axios from "axios";
import store from "../store";
import router from "../router/index";

const instance = axios.create({
  //baseURL : "http://localhost:5001/uplimoapp/us-central1/api/",
  baseURL : "https://us-central1-uplimoapp.cloudfunctions.net/api/",
  timeout: 30000,
  params:{}
})
// point to your API endpoint
//axios.defaults.baseURL = "http://localhost:5001/uplimoapp/us-central1/api/"
//axios.defaults.baseURL = "https://us-central1-uplimoapp.cloudfunctions.net/api/"
// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    const token = store.getters.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json'
    
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('interceptor error', error)
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url.includes("auth/jwt/refresh/")
    ) {
      store.commit("clearUserData");
      router.push("/login");
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch("refreshToken");
      const token = store.getters.accessToken;
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default instance


