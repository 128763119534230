<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">


          <md-card :class="{'back-success': $route.params.messageType === 'success'}" class="md-primary" v-if="$route.params.message" >
            <md-card-header>
              <md-card-header-text>
                
                <div class="md-subheading">
                  <md-icon class="mr-2" >done</md-icon>
                  <span>{{$route.params.message}}</span>
                </div>
              </md-card-header-text>
            </md-card-header>
          </md-card>
          <md-card>
              <form action="#" @submit.prevent="submit">
                <md-card-header>
                    <h1 class="md-title">Login</h1>
                </md-card-header>

                <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('email')">
                                <label>email</label>
                                <md-input v-model="email"></md-input>
                                <span class="md-error" v-if="!$v.email.required">email is required</span>
                                <span class="md-error" v-if="!$v.email.email">Not valid email address</span>
                            </md-field>

                            <md-field :class="getValidationClass('password')">
                                <label>password</label>
                                <md-input v-model="password" type="password"></md-input>
                                <span class="md-error" v-if="!$v.password.required">Password is required</span>
                                <span class="md-error" v-if="!$v.password.minLength">Password must be at least 6 characters long</span>
                            </md-field>
                            <md-field class="md-invalid" v-if="error">
                              <span class="md-error">{{ error }}</span>
                            </md-field>
                            
                        </CCol>
                        
                    </CRow>
                    <CRow class="mt-3">
                          <CCol sm="12">
                            <md-button class="md-raised full-width-button md-primary" type="submit">Login</md-button>
                        </CCol>
                    </CRow>
                    <p>Do not have an account?</p>
                    <md-button class="md-raised full-width-button" @click="register">Register</md-button>
                </md-card-content>
              </form>
          
          </md-card>




          
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "./../../helpers/axios";

import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'

export default {
  name: "Login",
  mixins: [validationMixin],
  data() {
    return {
      email: "",
      password: "",
      error: null
    };
  },
  methods: {
    validate(){
        let email,password;
        email = this.$v.email.$invalid;
        password = this.$v.password.$invalid;
        
        return !email && !password? true : false ;


    },
    getValidationClass (fieldName) {
        let field = null;

        
        field = this.$v[fieldName];
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    register () {
    
      this.$router.push({
        path: `register`,
      })
    },
    submit() {

      if(this.validate()) {
        this.$firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          console.log(data);

          // this.$root.refreshToken = data.user.refreshToken;
          // this.$root.role = data.user.role;
          // this.$root.uid = data.user.uid;
          // this.$root.email = data.user.email;
          
          let th = this;
          th.$router.replace({ name: "Home" });
          // this.$firebase
          //   .auth()
          //   .currentUser.getIdToken(true)
          //   .then(function(idToken) {
          //     axios
          //       .create({
          //         headers: {
          //           "Content-Type": "application/json",
          //           Authorization: "Bearer " + idToken,
          //         },

          //         //}).get('http://localhost:5000/uplimoapp/us-central1/api/users/' + data.user.uid).then(response =>{
          //       })
          //       .get(
          //         "https://us-central1-uplimoapp.cloudfunctions.net/api/users/" +
          //           data.user.uid
          //       )
          //       .then((response) => {
                  
          //         th.$router.replace({ name: "Home" });
          //         th.$root.role = response.data.user.role;
          //       })
          //       .catch((e) => {
          //         console.log(e);
          //       });
          //   });
        })
        .catch((err) => {
          console.log(err)
          this.error = err.message;
        });

      } else {
        this.$v.$touch()
      }
      
    },
  },
  validations: {
               
    email: {
        required,
        email
    },
    password: {
        required,
        minLength: minLength(6)
    }
          
      
  }
};
</script>
