<template>
  <div>
    <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
    </div>
    <div v-else>
        
        <div v-if="(role === 'driver' && requestedUser)">
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="requestedUser.general.name || requestedUser.general.lastname">{{requestedUser.general.name}} {{requestedUser.general.lastname}}</h3>
                <h3 class="md-title" style="flex: 1" v-else>Driver name</h3>
            </md-toolbar>
            <form enctype="multipart/form-data">
                <md-steppers  class="md-elevation-4 display-pdf-fix" :md-active-step="step" md-vertical>
                    <md-step id="general" md-label="General">  
                        <md-list class="md-double-line">
                                <md-subheader>Main info</md-subheader>
                                <CRow>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.name }}</span>
                                            <span>Name</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.phone }}</span>
                                            <span>phone</span>
                                            </div>
                                        </md-list-item>
                                       
                                    </CCol>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>

                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.email }}</span>
                                            <span>email</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>

                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.displayName }}</span>
                                            <span>Display name</span>
                                            </div>
                                        </md-list-item>

                                    </CCol>
                                    
                                </CRow>
                        </md-list>   
                        
                    
                            
                        
                        
                    </md-step>
                    <md-step id="fleet" md-label="Fleet" >
                        <div v-if="requestedUser.fleet.length === 0">
                            <md-empty-state
                                md-icon="drive_eta"
                                md-label="No vehicles available"
                                md-description="Driver has not added any vehicle yet">
                            </md-empty-state>
                        </div>
                        <div v-else>
                            <CRow class="pt-4">  
                            <CCol v-for="vehicleType in requestedUser.fleet" v-bind:key="vehicleType.id" sm="12" md="12" >
                                    <md-card md-with-hover class="mb-3" >
                                        <div class="md-layout md-alignment-center-left p-2" >
                                            <div class="md-layout-item flex-none">
                                                <div class="special-avatar " v-if="vehicleType.pictures.length > 0">
                                                    <img :src="vehicleType.pictures[0]" alt="People" >
                                                    
                                                </div>
                                                <div v-else>
                                                    <div>
                                                        <md-icon class="up-text-primary md-size-4x clean-back mr-4 ml-4" >directions_car</md-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md-layout-item flex-1 pl-4 pr-4">
                                                <div class="mb-1 md-body-2">{{vehicleType.type.name}}</div>
                                                <div class="md-subhead"><md-icon>luggage</md-icon>{{vehicleType.luggage}} <md-icon class="ml-2">people_alt</md-icon> {{vehicleType.passengers}}</div>
                                                </div>
                                                <div class="md-layout-item flex-none">
                                                <md-button @click="viewCarDetails(vehicleType)">
                                                    View details
                                                </md-button>
                                            </div>
                                        </div>
                                    </md-card>
                                </CCol>
                            </CRow>
                            <md-button class="md-raised" @click="step = 'general'">Previous step</md-button>
                            <md-button class="md-raised" @click="step = 'dmv'">Next step</md-button>
                            
                        </div>
                        
                    </md-step>
                
                    <md-step id="dmv" md-label="DMV" >
                        <CRow>
                            <!--CCol sm="12" md="12" lg="4" xl="4">
                                <md-datepicker  name="driver-license" id="driver-license-expiration" v-model="requestedUser.dmv.expiration" :disabled="true" :md-model-type="Number">
                                    <label for="driver-license-expiration">DMV driver license expiration</label>
                                </md-datepicker>
                                
                                
    
                            </CCol-->
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">
                                            <md-icon v-if="!requestedUser.dmv.driverLicensePreview && !requestedUser.dmv.storage" class="md-size-5x">badge</md-icon>
                                            <div class="doc-asset picture-asset">
    
                                               
    
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', requestedUser.dmv.driverLicensePreview, requestedUser.dmv.image, requestedUser.dmv.pdf)" v-if="requestedUser.dmv.pdf && requestedUser.dmv.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="requestedUser.dmv.driverLicensePreview"    />
                                                </div>
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', requestedUser.dmv.storage, requestedUser.dmv.image, requestedUser.dmv.pdf)" v-else-if="requestedUser.dmv.storage != null && !requestedUser.dmv.driverLicensePreview && !requestedUser.dmv.deleted">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="requestedUser.dmv.storage"    :loaded="pdfLoaded()"/>
                                                </div>
    
                                                
    
    
                                                <div class="img-preview-holder"  v-if="requestedUser.dmv.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="requestedUser.dmv.image && requestedUser.dmv.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', requestedUser.dmv.driverLicensePreview, requestedUser.dmv.image, requestedUser.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <div 
                                                        class="doc-overlay"
                                                        v-else-if="requestedUser.dmv.image && requestedUser.dmv.storage != null && !requestedUser.dmv.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', requestedUser.dmv.storage, requestedUser.dmv.image, requestedUser.dmv.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
    
                                                    <img class="ml-0 mt-0" :src="this.requestedUser.dmv.driverLicensePreview ? this.requestedUser.dmv.driverLicensePreview : this.requestedUser.dmv.storage" alt="" />
    
                                                </div>
                                            </div>
                                        </md-card-media>
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ requestedUser.dmv.driverLicense ? requestedUser.dmv.driverLicense : 'DMV driver license'}}</span>
                                            <span class="md-subhead" v-if="requestedUser.dmv.expiration">{{ requestedUser.dmv.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>
    
                                        
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                        
                        
                    </md-step> 
                    <md-step id="tlc" md-label="TLC" >
                        <CRow>
                            <!--CCol sm="12" md="12" lg="4" xl="4">
                                <md-datepicker  name="tlc-driver-license-expiration" id="tlc-driver-license-expiration" v-model="requestedUser.tlc.expiration" :disabled="true" :md-model-type="Number">
                                    <label for="tlc-driver-license-expiration">TLC driver license expiration</label>
                                </md-datepicker>
                            </CCol-->
                            <CCol sm="12" md="12" lg="4" xl="4">
                                <md-card class="license-preview mt-3">   
                                    <md-card-media-cover>
                                        <md-card-media class="text-center">
    
                                            
                                            <div class="doc-asset picture-asset">
    
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', requestedUser.tlc.driverLicensePreview, requestedUser.tlc.image, requestedUser.tlc.pdf)" v-if="requestedUser.tlc.pdf && requestedUser.tlc.driverLicensePreview">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="requestedUser.tlc.driverLicensePreview"    />
                                                </div>
                                                <div class="pdf-preview-holder" @click="openDocDialog('DMV driver license', requestedUser.tlc.storage, requestedUser.tlc.image, requestedUser.tlc.pdf)" v-else-if="requestedUser.tlc.storage != null && !requestedUser.tlc.driverLicensePreview && !requestedUser.tlc.deleted">
                                                    <div class="doc-overlay">
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <vue-pdf-embed :page="1" :source="requestedUser.tlc.storage"    :loaded="pdfLoaded()"/>
                                                </div>
                                                <div class="img-preview-holder"  v-if="requestedUser.tlc.image">
                                                    <div 
                                                        class="doc-overlay"
                                                        v-if="requestedUser.tlc.image && requestedUser.tlc.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', requestedUser.tlc.driverLicensePreview, requestedUser.tlc.image, requestedUser.tlc.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
                                                    <div 
                                                        class="doc-overlay"
                                                        v-else-if="requestedUser.tlc.image && requestedUser.tlc.storage != null && !requestedUser.tlc.driverLicensePreview"
                                                        @click="openDocDialog('DMV driver license', requestedUser.tlc.storage, requestedUser.tlc.image, requestedUser.tlc.pdf)"
                                                    >
                                                        <md-icon class="md-size-2x">search</md-icon>
                                                    </div>
    
                                                    <img class="ml-0 mt-0" :src="this.requestedUser.tlc.driverLicensePreview ? this.requestedUser.tlc.driverLicensePreview : this.requestedUser.tlc.storage" alt="" />
    
                                                </div>
                                            </div>
                                        </md-card-media>
                                        
                                        <md-card-header>
                                            <span class="md-title">{{ requestedUser.tlc.driverLicense ? requestedUser.tlc.driverLicense : 'TLC driver license'}}</span>
                                            <span class="md-subhead" v-if="requestedUser.tlc.expiration">{{ requestedUser.tlc.expiration | moment("MMMM Do YYYY") }}</span>
                                            <span class="md-subhead" v-else>Expiration date</span>
                                        </md-card-header>
    
                                       
                                    
                                    </md-card-media-cover>
                                </md-card>
                            </CCol>
                        </CRow>
                       
                    </md-step>
               
                    <md-step id="otherLicenses" md-label="Other licenses" >
                        <md-empty-state
                            v-if="requestedUser.otherLicenses.length === 0"
                            
                            md-icon="badge"
                            md-label="No licenses or permissions created"
                            md-description="This user has not create custom licenses .">
                            
                        </md-empty-state>
                        <md-list class="md-double-line" v-else>
                            <md-subheader>Documents</md-subheader>
                            <CRow v-if="requestedUser.otherLicenses.length > 0">
                                <CCol sm="12" md="6">
                                    <md-list-item v-for="license in requestedUser.otherLicenses" v-bind:key="license.expiration">
                                        
    
                                        <div class="md-list-item-text">
                                        <span class="mb-1 md-title">{{ license.name }}</span>
                                        <span v-if="license.expiration">Expiration date: <b>{{ license.expiration | moment("MMMM Do YYYY")}}</b></span>
                                        <span v-if="!license.expiration && !license.storage">Na data available</span>
                                        </div>
                                        <md-button :disabled="!license.attachment.storage" @click="downloadFile(license.attachment.storage,license.name )">

                                            <md-icon :class="{'md-primary': license.attachment.storage}">download</md-icon>
                                        </md-button>
                                    </md-list-item>
                                    
                                </CCol>
                                
                            </CRow>
                            
                        </md-list>
                        <!--CRow v-else>
                            <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in requestedUser.otherLicenses" v-bind:key="'otherLicenses-' + index ">
                            
                                <md-card class="uplimo-card" >
                                    
                                    <md-card-content class="listed-doc-card">
                                        <div class="doc-asset">
    
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                            </div>
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                            </div>
    
                                        
                                            <div class="img-preview-holder"  v-if="license.attachment.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="license.attachment.image && license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <div 
                                                    class="doc-overlay"
                                                    v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                                <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />
    
                                            </div>
                                            
                                        </div>
                                    
                                        <div class="doc-data">
                                            <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field >
                                                        <label>Name</label>
                                                        <md-input v-model="license.name"></md-input>
                                                    </md-field>
                                                    <md-field >
                                                        <label >Attachment (Image or PDF)</label>
                                                        <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                    </md-field>
                                                    <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" >
                                                        <label>Expiration Date</label>
                                                    </md-datepicker>
                                                    <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                                </CCol>
                                                
                                            </CRow>
                                            
                                            
    
                                        </div>  
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            
                        </CRow-->
                    </md-step> 
                    
                  
                
                </md-steppers>
            </form>
            <md-dialog :md-active.sync="docDialog.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog" ref="dialog"  >
                <md-dialog-title v-if="docDialog.name">
                    {{ docDialog.name}}
                    
                </md-dialog-title>
                <md-divider class=" mb-4">
                </md-divider>
                <div class="modal-up-content text-center">
                    <img :src="docDialog.src" alt="" v-if="docDialog.image">
    
    
                    <md-button :disabled="docDialog.page == 1" @click="prevPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Prev</md-button>
                    <md-button :disabled="docDialog.page == docDialog.pageCount" @click="nextPdfPage" v-if="docDialog.pdf && docDialog.pageCount > 1">Next</md-button>
    
                    <div class="popup-pdf-holder" v-if="docDialog.pdf">
                        <vue-pdf-embed :source="docDialog.src"   :page="docDialog.page" ref="masterPdf" :width="pdfWidth"/>
                    </div>
                    
                </div>
                <md-dialog-actions>
                    <md-button @click="docDialog.active = false">Close</md-button>
                
                </md-dialog-actions>
            </md-dialog>

            <md-dialog :md-active.sync="vehicleInfoModal.active" :style="[dialogDimensions,dialogHeight]" :md-fullscreen="false" class="free-dialog"  >
                <md-dialog-title v-if="vehicleInfoModal.vehicle">
                    {{ vehicleInfoModal.vehicle.name}}
                    
                </md-dialog-title>
                <md-divider class=" mb-4">
                </md-divider>
                <div class="modal-up-content text-center" v-if="vehicleInfoModal.vehicle">
                    <md-tabs class="md-transparent" md-alignment="left"  ref="vehicleTabs" :md-active-tab="vehicleInfoModal.mainActiveTab" :md-dynamic-height="true"> 
                        <md-tab id="general" md-label="General" >
                            <md-list class="md-double-line">
                                <md-subheader>Vehicle info</md-subheader>

                                <md-list-item>
                                    <md-icon class="md-primary">people_alt</md-icon>

                                    <div class="md-list-item-text">
                                    <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.passengers }}</span>
                                    <span>Passengers</span>
                                    </div>

                                    
                                </md-list-item>

                                <md-list-item >
                                    <md-icon class="md-primary">luggage</md-icon>
                                    <div class="md-list-item-text">
                                    <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.luggage }}</span>
                                    <span>Luggage</span>
                                    </div>

                                   
                                </md-list-item>

                                

                               

                                <md-list-item>
                                    <md-icon class="md-primary">description</md-icon>

                                    <div class="md-list-item-text">
                                    <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.description }}</span>
                                    <span>Description</span>
                                    </div>
                                </md-list-item>

                               
                            </md-list>
                        </md-tab>
                        <md-tab id="mainInfo" md-label="Main info" >
                            <md-list class="md-double-line">
                                <md-subheader>Main info</md-subheader>
                                <CRow>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.mainInfo.plate }}</span>
                                            <span>Plate</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.mainInfo.brand }}</span>
                                            <span>Brand</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.mainInfo.carModel }}</span>
                                            <span>Car model</span>
                                            </div>
                                        </md-list-item>
                                    </CCol>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.mainInfo.year }}</span>
                                            <span>Year</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ vehicleInfoModal.vehicle.mainInfo.color }}</span>
                                            <span>Color</span>
                                            </div>
                                        </md-list-item>
                                    </CCol>
                                </CRow>
                            </md-list>
                        </md-tab>
                        <md-tab id="documents" md-label="Documents" >
                            <md-list class="md-double-line">
                                <md-subheader>Documents</md-subheader>
                                <CRow>
                                    <CCol sm="12" md="6">
                                        <md-list-item>
                                           
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">FH1</span>
                                            <span v-if="vehicleInfoModal.vehicle.documents.fh1.expiration">Expiration date: <b>{{ vehicleInfoModal.vehicle.documents.fh1.expiration | moment("MMMM Do YYYY")}}</b></span>
                                            <span v-if="!vehicleInfoModal.vehicle.documents.fh1.expiration && !vehicleInfoModal.vehicle.documents.fh1.storage">Na data available</span>
                                            </div>
                                            <md-button :disabled="!vehicleInfoModal.vehicle.documents.fh1.storage" @click="downloadFile(vehicleInfoModal.vehicle.documents.fh1.storage,'fh1-' + vehicleInfoModal.vehicle.name )">

                                                <md-icon :class="{'md-primary': vehicleInfoModal.vehicle.documents.fh1.storage}">download</md-icon>
                                            </md-button>
                                        </md-list-item>
                                        <md-list-item>
                                           
        
                                           <div class="md-list-item-text">
                                           <span class="mb-1 md-title">Registration</span>
                                           <span v-if="vehicleInfoModal.vehicle.documents.registration.expiration">Expiration date: <b>{{ vehicleInfoModal.vehicle.documents.registration.expiration | moment("MMMM Do YYYY")}}</b></span>

                                           <span v-if="!vehicleInfoModal.vehicle.documents.registration.expiration && !vehicleInfoModal.vehicle.documents.registration.storage">Na data available</span>
                                           </div>
                                           <md-button :disabled="!vehicleInfoModal.vehicle.documents.registration.storage" @click="downloadFile(vehicleInfoModal.vehicle.documents.registration.storage,'registration-' + vehicleInfoModal.vehicle.name)">

                                                <md-icon :class="{'md-primary': vehicleInfoModal.vehicle.documents.registration.storage}">download</md-icon>
                                            </md-button>
                                       </md-list-item>
                                       <md-list-item>
                                           
        
                                           <div class="md-list-item-text">
                                           <span class="mb-1 md-title">TLC window sticker</span>
                                           <span v-if="vehicleInfoModal.vehicle.documents.tlc.expiration">Expiration date: <b>{{ vehicleInfoModal.vehicle.documents.tlc.expiration | moment("MMMM Do YYYY")}}</b></span>
                                           <span v-if="!vehicleInfoModal.vehicle.documents.tlc.expiration && !vehicleInfoModal.vehicle.documents.tlc.storage">Na data available</span>
                                           </div>
                                           <md-button :disabled="!vehicleInfoModal.vehicle.documents.tlc.storage" @click="downloadFile(vehicleInfoModal.vehicle.documents.tlc.storage,'tlc-' + vehicleInfoModal.vehicle.name)">

                                                <md-icon :class="{'md-primary': vehicleInfoModal.vehicle.documents.tlc.storage}">download</md-icon>
                                            </md-button>
                                       </md-list-item>
                                    </CCol>
                                    <CCol sm="12" md="6">
                                        <md-list-item>
                                           
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">DMV</span>
                                            <span v-if="vehicleInfoModal.vehicle.documents.dmv.expiration">Expiration date: <b>{{ vehicleInfoModal.vehicle.documents.dmv.expiration | moment("MMMM Do YYYY")}}</b></span>
                                            <span v-if="!vehicleInfoModal.vehicle.documents.dmv.expiration && !vehicleInfoModal.vehicle.documents.dmv.storage">Na data available</span>
                                            </div>
                                            <md-button :disabled="!vehicleInfoModal.vehicle.documents.dmv.storage" @click="downloadFile(vehicleInfoModal.vehicle.documents.dmv.storage,'dmv-' + vehicleInfoModal.vehicle.name )">

                                                <md-icon :class="{'md-primary': vehicleInfoModal.vehicle.documents.dmv.storage}">download</md-icon>
                                            </md-button>
                                        </md-list-item>
                                        <md-list-item>
                                           
        
                                           <div class="md-list-item-text">
                                           <span class="mb-1 md-title">Liability</span>
                                           <span v-if="vehicleInfoModal.vehicle.documents.liability.expiration">Expiration date: <b>{{ vehicleInfoModal.vehicle.documents.liability.expiration | moment("MMMM Do YYYY")}}</b></span>

                                           <span v-if="!vehicleInfoModal.vehicle.documents.liability.expiration && !vehicleInfoModal.vehicle.documents.liability.storage">Na data available</span>
                                           </div>
                                           <md-button :disabled="!vehicleInfoModal.vehicle.documents.liability.storage" @click="downloadFile(vehicleInfoModal.vehicle.documents.liability.storage,'liability-' + vehicleInfoModal.vehicle.name)">

                                                <md-icon :class="{'md-primary': vehicleInfoModal.vehicle.documents.liability.storage}">download</md-icon>
                                            </md-button>
                                       </md-list-item>
                                      
                                    </CCol>
                                </CRow>
                            </md-list>
                        </md-tab>
                        <md-tab id="pictures" md-label="Pictures" >
                            <div v-if="vehicleInfoModal.vehicle.pictures.length > 0">
                                <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :autoplay="true" :loop="true" :adjustableHeight="false">
                                    <slide v-for="(picture,ind) in vehicleInfoModal.vehicle.pictures"
                                        v-bind:key="'note-' + ind"
                                        :data-index="ind"
                                        :data-name="'picture'"
                                        >
                                    
                                    <img :src="picture">
                                        
                                    </slide>
                                
                                </carousel>
                            </div>
                            <CRow v-else>
                                <CCol sm="12" >
                                    <md-empty-state
                                        class="md-primary"
                                        md-icon="camera"
                                        md-label="No pictures added"
                                        md-description="Request user to add some photos">
                                        </md-empty-state>
                                </CCol>
                            </CRow>
                        </md-tab>
                        <md-tab id="otherLicenses" md-label="Other Licenses" >
                            <md-list class="md-double-line">
                                <md-subheader>Documents</md-subheader>
                                <CRow v-if="vehicleInfoModal.vehicle.otherLicenses.length > 0">
                                    <CCol sm="12" md="6">
                                        <md-list-item v-for="license in vehicleInfoModal.vehicle.otherLicenses" v-bind:key="license.expiration">
                                           
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ license.name }}</span>
                                            <span v-if="license.expiration">Expiration date: <b>{{ license.expiration | moment("MMMM Do YYYY")}}</b></span>
                                            <span v-if="!license.expiration && !license.storage">Na data available</span>
                                            </div>
                                            <md-button :disabled="!license.storage" @click="downloadFile(license.storage,license.name+ vehicleInfoModal.vehicle.name )">

                                                <md-icon :class="{'md-primary': license.storage}">download</md-icon>
                                            </md-button>
                                        </md-list-item>
                                       
                                    </CCol>
                                    
                                </CRow>
                                <CRow v-else>
                                    <CCol sm="12" >
                                        <md-empty-state
                                            class="md-primary"
                                            md-icon="inventory"
                                            md-label="No custom licenses added"
                                            md-description="Call user for further details">
                                            </md-empty-state>
                                    </CCol>
                                </CRow>
                            </md-list>
                        </md-tab>
                    </md-tabs>
                    
                </div>
                <md-dialog-actions>
                    <md-button @click="vehicleInfoModal.active = false">Close</md-button>
                
                </md-dialog-actions>
            </md-dialog>
        </div>
    
        <div v-if="(role === 'company-admin' && requestedUser)">
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1">Linked company profiles</h3>
            </md-toolbar>
            <md-table>
                <md-table-row>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Phones</md-table-head>
                    <md-table-head>Mail</md-table-head>
                </md-table-row>
    
                <md-table-row v-for="(profile) in requestedUser.profiles" v-bind:key="profile.id">
                    <md-table-cell>{{ profile.name }}</md-table-cell>
                    <md-table-cell>
                        <template v-for="(phone) in profile.phones" >
                            {{ phone }}
                        </template>
                        
                    </md-table-cell>
                    <md-table-cell>{{ profile.mailList }}</md-table-cell>
                    
                </md-table-row>
            </md-table>
        </div>

        <div v-if="((role === 'dispatcher' || role === 'affiliate') && requestedUser)">
            <md-toolbar class="md-primary mb-2" md-elevation="1">
                <h3 class="md-title" style="flex: 1" v-if="requestedUser.general.name || requestedUser.general.lastname">{{requestedUser.general.name}} {{requestedUser.general.lastname}}</h3>
                <h3 class="md-title" style="flex: 1" v-else>User name</h3>
            </md-toolbar>
            <form enctype="multipart/form-data">
                <md-steppers  class="md-elevation-4 display-pdf-fix" :md-active-step="step" md-vertical>
                    <md-step id="general" md-label="General">  
                        <md-list class="md-double-line">
                                <md-subheader>Main info</md-subheader>
                                <CRow>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.name }}</span>
                                            <span>Name</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>
        
                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.phone }}</span>
                                            <span>phone</span>
                                            </div>
                                        </md-list-item>
                                       
                                    </CCol>
                                    <CCol sm="12" md="6">

                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>

                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.email }}</span>
                                            <span>email</span>
                                            </div>
                                        </md-list-item>
                                        <md-list-item>
                                            <md-icon class="md-primary">description</md-icon>

                                            <div class="md-list-item-text">
                                            <span class="mb-1 md-title">{{ requestedUser.general.displayName }}</span>
                                            <span>Display name</span>
                                            </div>
                                        </md-list-item>

                                    </CCol>
                                    
                                </CRow>
                        </md-list>   
                        
                    
                            
                        
                        
                    </md-step>
                   
               
                    <md-step id="otherLicenses" md-label="Other licenses" >
                        <md-empty-state
                            v-if="requestedUser.otherLicenses.length === 0"
                            
                            md-icon="badge"
                            md-label="No licenses or permissions created"
                            md-description="This user has not create custom licenses .">
                            
                        </md-empty-state>
                        <md-list class="md-double-line" v-else>
                            <md-subheader>Documents</md-subheader>
                            <CRow v-if="requestedUser.otherLicenses.length > 0">
                                <CCol sm="12" md="6">
                                    <md-list-item v-for="license in requestedUser.otherLicenses" v-bind:key="license.expiration">
                                        
    
                                        <div class="md-list-item-text">
                                        <span class="mb-1 md-title">{{ license.name }}</span>
                                        <span v-if="license.expiration">Expiration date: <b>{{ license.expiration | moment("MMMM Do YYYY")}}</b></span>
                                        <span v-if="!license.expiration && !license.storage">Na data available</span>
                                        </div>
                                        <md-button :disabled="!license.attachment.storage" @click="downloadFile(license.attachment.storage,license.name )">

                                            <md-icon :class="{'md-primary': license.attachment.storage}">download</md-icon>
                                        </md-button>
                                    </md-list-item>
                                    
                                </CCol>
                                
                            </CRow>
                            
                        </md-list>
                       
                            <CCol sm="12" md="12" lg="12" xl="12" v-for="(license, index) in requestedUser.otherLicenses" v-bind:key="'otherLicenses-' + index ">
                            
                                <md-card class="uplimo-card" >
                                    
                                    <md-card-content class="listed-doc-card">
                                        <div class="doc-asset">
    
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)" v-if="license.attachment.pdf && license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.preview"    />
                                            </div>
                                            <div class="pdf-preview-holder" @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)" v-else-if="license.attachment.storage != null && !license.attachment.preview">
                                                <div class="doc-overlay">
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <vue-pdf-embed :page="1" :source="license.attachment.storage"    :loaded="pdfLoaded()"/>
                                            </div>
    
                                        
                                            <div class="img-preview-holder"  v-if="license.attachment.image">
                                                <div 
                                                    class="doc-overlay"
                                                    v-if="license.attachment.image && license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.preview, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
                                                <div 
                                                    class="doc-overlay"
                                                    v-else-if="license.attachment.image && license.attachment.storage != null && !license.attachment.preview"
                                                    @click="openDocDialog(license.name, license.attachment.storage, license.attachment.image, license.attachment.pdf)"
                                                >
                                                    <md-icon class="md-size-2x">search</md-icon>
                                                </div>
    
                                                <img :src="license.attachment.preview ? license.attachment.preview : ''" alt="" />
                                                <img :src="license.attachment.storage ? license.attachment.storage : ''" alt="" />
    
                                            </div>
                                            
                                        </div>
                                    
                                        <div class="doc-data">
                                            <div class="md-title">{{license.name ? license.name : 'Name'}}</div>
                                            <CRow>
                                                <CCol sm="12">
                                                    <md-field >
                                                        <label>Name</label>
                                                        <md-input v-model="license.name"></md-input>
                                                    </md-field>
                                                    <md-field >
                                                        <label >Attachment (Image or PDF)</label>
                                                        <md-file v-model="license.attachment.name" :disabled="sending" @change="docAttachLicenseChanged($event, index)"/>
                                                    </md-field>
                                                    <md-datepicker v-model="license.expiration" :disabled="sending" :md-model-type="Number" >
                                                        <label>Expiration Date</label>
                                                    </md-datepicker>
                                                    <div class="mt-2" v-html="checkTimeToGo(license.expiration)" v-if="license.expiration"></div>
                                                </CCol>
                                                
                                            </CRow>
                                            
                                            
    
                                        </div>  
                                    </md-card-content>
                                </md-card>
                            </CCol>
                            
                        </CRow-->
                    </md-step> 
                    
                  
                
                </md-steppers>
            </form>
        </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import moment_timezone from "moment-timezone";
import firebase from '../../Firebase'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import axiosInstance from 'axios'

// https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20IN%20(%225376339%22)
import axios from "./../../helpers/axios"
export default {
    name: 'UserLinkingDetail',
    components:{VuePdfEmbed},
    data() {
        return {
            isBusy: true,
            role: this.$route.params.role,
            requestedUser: null,
            step: 'general',
            sending: true,
            dialogLeft:0,
            docDialog: {
                name: null,
                active: false,
                src: null,
                pdf: false,
                image: false,
                pageCount: null,
                page: 1

            },
            vehicleInfoModal: {
                active: false,
                vehicle: null,
                mainActiveTab: 'general'
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        dialogDimensions () {
            console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
        dialogHeight() {
            
            
            return {
                height: window.innerHeight - this.headerHeight + 'px',
                top: this.headerHeight + 'px'
                //height: this.$store.state.sidebarNavHeight + 'px'
            }
        },
        pdfWidth () {
            return  (window.innerWidth - this.dialogLeft) * 0.9 
            
        },
        headerHeight() {
            return this.$store.state.headerHeight;
        },
        selectedFleet: function() {
            let th = this
            if(this.requestedUser.role === 'driver') {
                return this.requestedUser.vehicles.map(function(i) {
                    if(th.requestedUser.fleet.length) {
                        for (let j = 0; j < th.requestedUser.fleet.length; j++) {
                            if(th.requestedUser.fleet[j].id == i) {
                                return th.requestedUser.fleet[j]
                            }
                            
                        }
                    }
                    
                });
            }
            
        },
    },
    methods: {
        downloadFile(url,fileName) {
            console.log(url)
            axiosInstance({
                url: url, // File URL Goes Here
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                    console.log(res)
                    var FILE = window.URL.createObjectURL(new Blob([res.data]));
                    
                    var docUrl = document.createElement('a');
                    docUrl.href = FILE;
                    let extension 
                    switch(res.data.type)
                    {
                        case "application/pdf":
                            extension = '.pdf';
                        break;
                        case "image/jpeg":
                            extension = '.jpg';
                        case "image/png":
                            extension = '.png';
                        break;

                    }
                    let completeFileName = fileName + extension
                    docUrl.setAttribute('download',completeFileName );
                    
                    document.body.appendChild(docUrl);
                    docUrl.click();
            });
        },
        viewCarDetails(vehicle) {
            console.log('viewCarDetails')
            this.vehicleInfoModal.active = true
            this.vehicleInfoModal.vehicle = vehicle
        },
        checkTimeToGo(date) {
            var startTime = moment();
            var endTime = moment(date); 
            var minutesDiff = endTime.diff(startTime, 'minutes');
            var daysDiff = endTime.diff(startTime, 'days');
            var ms = endTime.diff(startTime);
            var d = moment.duration(ms);
            let className ;
            
            if(daysDiff < 0  || minutesDiff < 0) {
                className = 'up-text-cancelled'
            } else if(daysDiff >= 0 && daysDiff <= 30) {
                className = 'up-text-caution'
            } else if(daysDiff > 30) {
                className = 'up-text-confirmed'
            }

            let payload ; 
            let text = minutesDiff >= 0 ? 'Expires ' : 'Expired '  
            payload = '<div class="text-uppercase time-to-go ' + className + '">'+ text + endTime.fromNow() + '</div>'
            return payload ;
            
        },
        pdfLoaded(call){
            //console.log(call)
        },
        async obtainViewResources() {
            this.isBusy = true
            await this.getSharedInfo()
            this.isBusy = false

        },
        async getSharedInfo() {
            let th = this;
            return new Promise((resolve, reject) => {
                let data = {  
                    claimer: {
                        uid: th.user.data.uid,
                        role: th.user.data.role,
                        email: th.user.data.email,
                        code: th.user.data.code,
                    },
                    requestedUser: {
                        uid: th.$route.params.uid,
                        role: th.$route.params.role,
                        email: th.$route.params.email,
                    }
                }
                
                
                axios.get('users/shared-info', { params: data }).then(response =>{
                    console.log(response)
                    switch(data.requestedUser.role) {
                        case 'driver':
                            
                            response.data.sharedInfo.dmv.expiration = response.data.sharedInfo.dmv.expiration * 1000
                            response.data.sharedInfo.tlc.expiration = response.data.sharedInfo.tlc.expiration * 1000
                            for (let i = 0; i < response.data.sharedInfo.otherLicenses.length; i++) {
                                response.data.sharedInfo.otherLicenses[i].expiration = response.data.sharedInfo.otherLicenses[i].expiration * 1000
                            }

                            break;
                        case 'company-admin':
                            break
                        default:
                            if(response.data.sharedInfo.otherLicenses) {

                                for (let i = 0; i < response.data.sharedInfo.otherLicenses.length; i++) {
                                   response.data.sharedInfo.otherLicenses[i].expiration = response.data.sharedInfo.otherLicenses[i].expiration * 1000
                                }
                            } else {
                               response.data.sharedInfo.otherLicenses = []
                            }
                            break;
                    }


                    th.requestedUser = response.data.sharedInfo
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
        
            })
        },
        openDocDialog(name,src,image,pdf) {
            this.docDialog.page = 1
            this.docDialog.name = name
            this.docDialog.src = src
            
            this.docDialog.image = image
            this.docDialog.pdf = pdf
            this.docDialog.active = true
            this.$nextTick(() => {
                
                let pdf = this.$refs.masterPdf
                
                
                if(pdf) {
                    this.docDialog.pageCount = this.$refs.masterPdf.pageCount
                }
                this.$store.commit('centerDialog',window.innerWidth)
            })
            
        },
        
    },
    created() {
        this.obtainViewResources()
    },
    mounted() {

    }

}
</script>

<style>

</style>