<template>
    <div>
        <CRow>
            <CCol sm="12" md="6" lg="5">
                <md-field>
                    <md-select v-model="offer.jobType" name="vehicle_type" id="job-type" placeholder="Job Type">
                        <md-option v-for="jobType in jobTypes" :value="jobType.id" v-bind:key="jobType.id">{{jobType.name}}</md-option>
                    </md-select>
                </md-field>
                <md-field>
                    <md-select v-model="offer.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type">
                        <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                    </md-select>
                </md-field>
                <CRow>
                    <CCol sm="8">
                        <md-datepicker v-model="offer.day" :md-model-type="Number">
                            <label>Date</label>
                        </md-datepicker>
                        
                    </CCol>
                    <CCol sm="4">

                        <vue-timepicker v-model="offer.time"></vue-timepicker>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="8">
                        <md-field>
                            <gmap-autocomplete
                                :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                placeholder="Pick up"
                                @place_changed="setPU" class="pu-autocomplete">
                            </gmap-autocomplete>
                            <!--md-button class="md-icon-button" @click="usePlace" >
                                <md-icon>add</md-icon>
                            </md-button-->
                        </md-field>

                    </CCol>

                     <CCol sm="4">
                         <md-field>
                            <label>Zip code</label>
                            <md-input v-model="offer.pu.postalCode" readonly></md-input>
                        </md-field>
                    </CCol>
                </CRow>
                 <CRow>
                    <CCol sm="8">
                        <md-field>
                            <gmap-autocomplete
                                placeholder="Drop off"
                                @place_changed="setDO" class="pu-autocomplete">
                            </gmap-autocomplete>
                            <!--md-button class="md-icon-button" @click="usePlace" >
                                <md-icon>add</md-icon>
                            </md-button-->
                        </md-field>
                     </CCol>

                     <CCol sm="4">
                         <md-field>
                            <label>Zip code</label>
                            <md-input v-model="offer.do.postalCode" readonly></md-input>
                        </md-field>
                    </CCol>
                </CRow>

                

                

                <CRow>
                    <CCol sm="8">
                        <md-field>
                            <label>Miles</label>
                            <md-input v-model="offer.miles" readonly></md-input>
                        </md-field>
                    </CCol>
                    <CCol sm="4">
                        <md-field>
                            <label>Flight #</label>
                            <md-input v-model="offer.flightNumber"></md-input>
                        </md-field>
                    </CCol>
                </CRow>
                
               
                <CRow>
                    <CCol sm="8">
                        <md-autocomplete v-model="offer.dispatcher" :md-options="dispatchers">
                            <label>Dispatcher</label>
                        </md-autocomplete>
                     </CCol>
                
                    <CCol sm="4">        
                        <md-field>
                            <label>Hs before</label>
                            <md-input v-model="offer.publishAnticipation" type="number"></md-input>
                        </md-field>
                     </CCol>
                </CRow>

                <md-field>
                <label>Price</label>
                <md-input v-model="offer.price"></md-input>
                </md-field>

                

                <CRow>
                    <CCol sm="4">
                         
                        <md-icon  class="special-icon super" v-bind:class="{ 'active-icon': offer.childSeat }">child_friendly</md-icon> 
                        <md-switch v-model="offer.childSeat">Childseat</md-switch>
                    </CCol>
                    <CCol sm="4">
                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': offer.accessible }">accessible</md-icon> 
                        <md-switch v-model="offer.accessible">Accessible</md-switch>
                    </CCol>
                    <CCol sm="4">
                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': offer.meetAndGreet }">face</md-icon> 
                        <md-switch v-model="offer.meetAndGreet">Meet and greet</md-switch>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol sm="6">
                        <md-field>
                            <label>Notes for all</label>
                            <md-textarea v-model="offer.notesForAll" md-autogrow md-counter="300"></md-textarea>
                        </md-field>
                    </CCol>
                    <CCol sm="6">
                        <md-field>
                            <label>Notes for driver</label>
                            <md-textarea v-model="offer.notesForDriver" md-autogrow md-counter="300"></md-textarea>
                        </md-field>
                    </CCol>
                </CRow>
                
            </CCol>
            <CCol sm="12" md="6" lg="7">
                
                <GmapMap :center="{lat:coordinates.lat,lng:coordinates.lng}" :zoom="15" style="width:100%;height:600px">
                    <Gmap-Marker v-for="(marker, index) in markers"
                        :key="index"
                        :position="marker.position"
                        ></Gmap-Marker>
                    <!--Gmap-Marker
                        v-if="this.place"
                        label="&#x2605;"
                        :position="{
                        lat: this.place.geometry.location.lat(),
                        lng: this.place.geometry.location.lng(),
                        }"
                        ></Gmap-Marker-->

                    <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="origin"
                        :destination="destination"
                        @updated-miles="updatedMiles"
                        ref="road" />
                </GmapMap>
            </CCol>
        </CRow>
        <md-button class="md-fab md-primary" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import DirectionsRenderer from './../maps/DirectionsRenderer';
import firebase from '../../Firebase'
export default {
    name: 'NewOffer',
    components: {
        DirectionsRenderer,
        VueTimepicker
    },
    data() {
        return {
            date: new Date(),
            dispatchers: [],
            vehicleTypes: [],
            jobTypes: [],
            offer: {
                dispatcher:null,
                day: null,
                do: {
                    formatted_address: '',
                    postalCode: null,
                    coordinates: {
                        lat: null,
                        lng: null
                    }
                },
                pu: {
                    formatted_address: '',
                    postalCode: null,
                    coordinates: {
                        lat: null,
                        lng: null
                    }
                },
                miles: null,
                price: null,
                time: null,
                vehicleType: null,
                jobType: null,
                flightNumber: null,
                childSeat: false,
                accessible: false,
                meetAndGreet: false,
                publishAnticipation: 0,
                notesForAll: null,
                notesForDriver: null,

            },
            ref: firebase.firestore().collection('offers'),
            dispatchRef:  firebase.firestore().collection('dispatchers'),
            vehicleRef:  firebase.firestore().collection('vehicle-types'),
            jobTypesRef:  firebase.firestore().collection('job-types'),
            coordinates: {
                lat: 0,
                lng:0
            },
            markers: [],
            // start: "",
            // end: "",
           
        }
    },
    
    methods: {
        add(){
            let th = this;
            this.ref.add(th.offer)
            .then(function(docRef) {
                console.log("Tutorial created with ID: ", docRef.id);
                th.$router.push({path: `/offers`})
            })
            .catch(function(error) {
                console.error("Error adding Tutorial: ", error);
            });;
        },
        setDescription(description) {
            this.description = description;
        },
        setPU(place) {

            //this.offer.pu = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.offer.pu = {
                    formatted_address: place.formatted_address,
                    postalCode: postal,
                    coordinates: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    }
            }

        },
        setDO(place) {

            //this.offer.do = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.offer.do = {
                    formatted_address: place.formatted_address,
                    postalCode: postal,
                    coordinates: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    }
            }

        },
        updatedMiles(miles) {
            this.offer.miles = miles;
        }
        // usePlace(place) {
        //     if (this.place) {
        //     this.markers.push({
        //         position: {
        //         lat: this.place.geometry.location.lat(),
        //         lng: this.place.geometry.location.lng(),
        //         }
        //     })
        //     this.place = null;
        //     }
        // }
    },
    computed: {
        origin() {
            if (!this.offer.pu) return null;
            return { query: this.offer.pu.coordinates.lat + ',' + this.offer.pu.coordinates.lng };
        },
        destination() {
            if (!this.offer.do) return null;
            return { query: this.offer.do.coordinates.lat + ',' + this.offer.do.coordinates.lng };
        }
       
    },
    created () {
        this.dispatchRef.onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.distpachers = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data().name;
                //dataToSave.id = doc.id ;
                this.dispatchers.push(dataToSave);
                
            });
        });

        this.vehicleRef.orderBy('order').onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.vehicleTypes = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.vehicleTypes.push(dataToSave);
                
            });
        });

        this.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.jobTypes = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.jobTypes.push(dataToSave);
                
            });
        });
    },
}
</script>  