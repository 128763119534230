<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    ref="sidebar"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!--CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /-->
      <div class="logo c-sidebar-brand-full">Up.<span>limo</span></div>
      <div class="logo c-sidebar-brand-minimized">U.<span>L</span></div>
    </CSidebarBrand>

    <CSidebarNav ref="sidebarNav" v-if="user.data">
      <template v-for="(item, index) in $options.nav[0]._children">
        <CSidebarNavDropdown
          
          :name="item.name"
          v-if="
            item.allowedRoles.indexOf(user.data.role) > -1 &&
              item.items &&
              item.items.length > 0
          "
          v-bind:key="index"
        >
          <template v-for="(subitem, i) in item.items">
            <CSidebarNavItem
              :to="subitem.to"
              :name="subitem.name"
              v-if="
                subitem.allowedRoles && subitem.allowedRoles.indexOf(user.data.role) > -1
              "
              v-bind:key="i"
            >
            </CSidebarNavItem>
            <CSidebarNavItem
              :to="subitem.to"
              :name="subitem.name"
              v-else-if="
                !subitem.allowedRoles && item.allowedRoles.indexOf(user.data.role) > -1
              "
              v-bind:key="'subitem-' + subitem.name"
            >
            </CSidebarNavItem>
          </template>
        </CSidebarNavDropdown>
        <CSidebarNavItem
         
          :name="item.name"
          :to="item.to"
          v-bind:key="index + item.name"
          v-else-if="item.allowedRoles.indexOf(user.data.role) > -1 && !item.items"
        >
        </CSidebarNavItem>
      </template>
    </CSidebarNav>
    <!--CRenderFunction flat :content-to-render="$options.nav"/-->
    <!--CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    /-->
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    sidebarNavHeight() {
      return this.$store.state.sidebarNavHeight;
    },
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    
  },
  mounted() {
    let height = this.$refs.sidebarNav.$el.offsetHeight
    this.$store.commit('set', ['sidebarNavHeight', height])
  }
};
</script>
