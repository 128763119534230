<template>
  <div>
    <!--CDataTable
            hover
            striped
            :items="jobTypes"
            :fields="fields"
            :items-per-page="5"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
     
    </CDataTable-->
    <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Name</th>
            
          </tr>
        </thead>
        <draggable v-model="jobTypes" tag="tbody" @change="listUpdated">
          <tr v-for="item in jobTypes" :key="item.id">
            <td>{{ item.name }}</td>
            
          </tr>
        </draggable>
    </table>
    
   
    <md-button class="md-fab md-primary" @click="newDispatcher">
      <md-icon>add</md-icon>
    </md-button>
      <v-client-table :data="jobTypes" :columns="columns" :options="options">
        
        
        <!--div slot="name" slot-scope="{row}">
          <span>
            <a>{{row.name}}</a>
          </span>
         
        </div-->

      </v-client-table>
  </div>
</template>

<script>
import firebase from '../../Firebase'
import CTableWrapper from '../base/Table'
import draggable from 'vuedraggable'
export default {
  name: 'Dispatchers',
  components: { CTableWrapper, draggable },
  data() {
    return {
      jobTypes: [],
      ref: firebase.firestore().collection('job-types').orderBy('order'),
      //items: usersData,
      fields: [
        {key: 'name', label: 'Name'},
      ],
      activePage: 1,
      columns: ['name','type'],
      tableData: [
          { id: 1, name: "John", age: "20" },
          { id: 2, name: "Jane", age: "24" },
          { id: 3, name: "Susan", age: "16" },
          { id: 4, name: "Chris", age: "55" },
          { id: 5, name: "Dan", age: "40" }
      ],
      options: {
        perPageValues: [2,5,10,100]
          // see the options API
      }
    }
  },
  created () {
    this.ref.onSnapshot((querySnapshot) => {
      console.log('Snap shot')
      this.jobTypes = [];
      querySnapshot.forEach((doc) => {

        let dataToSave = doc.data();
        dataToSave.id = doc.id ;
        this.jobTypes.push(dataToSave);

      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    
    rowClicked (item, index) {
      console.log(item);
      this.$router.push({path: `job-types/${item.id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    newDispatcher(){
      this.$router.push({path: `job-types/new`})
    },
    listUpdated(ev){
      console.log(ev);
      this.updateOrder();
    },
    updateOrder() {
      let th = this;
      for (let index = 0; index < this.jobTypes.length; index++) {
          this.jobTypes[index].order = index + 1;
          firebase.firestore().collection('job-types').doc(this.jobTypes[index].id).update({
            order: index + 1
          })
          .then(function(){
              console.log('Updated');
          }).catch(function(error) {
             console.log("Error getting document:", error);
          });
      }
      
    }
  }
}
</script>